<template>
    <div>
        <div
            v-if="selectedScope"
            class="columns">
            <div class="column is-half">
                <b-table
                    v-model:selected="selectedContractor"
                    :loading="contractorsLoading"
                    :data="filteredContractors"
                    default-sort="Name"
                    striped
                    narrowed
                    hoverable
                    paginated
                    per-page="20">
                    <b-table-column
                        v-slot="props"
                        sortable
                        searchable
                        field="Name"
                        label="Name">
                        <b>{{ props.row.Name }}</b>
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        sortable
                        searchable
                        field="Company"
                        label="Company">
                        {{ props.row.Company }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        sortable
                        searchable
                        field="Description"
                        label="Description">
                        {{ props.row.Description }}
                    </b-table-column>
                    <b-table-column
                        searchable
                        field="IsValid"
                        label="Valid"
                        width="65"
                        centered>
                        <template
                            #searchable>
                            <b-select
                                v-model="contractIsValidFilter"
                                expanded>
                                <option :value="'all'">
                                    All
                                </option>
                                <option
                                    :value="true"
                                    selected>
                                    Yes
                                </option>
                                <option :value="false">
                                    No
                                </option>
                            </b-select>
                        </template>
                        <template
                            #default="props">
                            <bool-element
                                custom-size="mdi-18px"
                                :value="props.row.IsValid" />
                        </template>
                    </b-table-column>
                    <template #empty>
                        <div class="has-text-centered">
                            No contractors
                        </div>
                    </template>
                </b-table>
            </div>
            <div class="column is-half">
                <div
                    class="block"
                    style="text-align: right">
                    <b-button
                        v-require-can-edit-code="accessArguments"
                        :model-value="isEditing"
                        class="pull-right"
                        type="is-primary"
                        @click="onNewContractorClicked">
                        <b-icon
                            icon="plus"
                            size="is-small" />
                        <span>Create new contractor</span>
                    </b-button>
                </div>
                <section v-if="selectedContractor && !editFormActive">
                    <div class="panel">
                        <div class="panel-heading is-flex is-justify-content-space-between">
                            <p>
                                Contractor attributes
                            </p>
                            <b-button
                                v-require-can-edit-code="accessArguments"
                                :disabled="!selectedContractor"
                                :model-value="isCreating"
                                @click="editFormActive = true">
                                <b-icon
                                    icon="pencil"
                                    size="is-small" />
                                <span>Edit contractor</span>
                            </b-button>
                        </div>
                        <b-table
                            class="headless-table"
                            :data="selectedContractorAttributes"
                            narrowed
                            striped>
                            <b-table-column
                                v-slot="props"
                                field="property">
                                <b>{{ props.row.property }}</b>
                            </b-table-column>
                            <b-table-column
                                v-slot="props"
                                field="value">
                                <template v-if="props.row.property === 'IsValid'">
                                    <bool-element :value="props.row.value" />
                                </template>
                                <template v-else>
                                    {{ props.row.value }}
                                </template>
                            </b-table-column>
                            <template #empty>
                                <div class="has-text-centered">
                                    No attributes
                                </div>
                            </template>
                        </b-table>
                    </div>
                </section>
                <div v-if="editFormActive">
                    <code-edit
                        :key="selectedContractorId"
                        :show-cancel="true"
                        :code-id="selectedContractorId"
                        :form-title="codeEditTitle"
                        :code-set-name="codeSetName"
                        :fields-to-ignore="['description']"
                        :before-save-code="beforeSaveCodeInCodeEdit"
                        library="LCIContractor"
                        :scopes="selectedScope"
                        form-title-class="is-4"
                        submit-button-label="Save"
                        @refresh="afterContractorSave"
                        @cancel="editFormActive = false" />
                </div>
            </div>
        </div>
        <b-message v-else>
            Please select a scope.
        </b-message>
    </div>
</template>

<script>
    import BoolElement from '@/shared/components/BoolElement.vue';
    import CodeEdit from '@/shared/components/CodeEdit.vue';
    import { requireCanEditCode } from '@/shared/directives/requirePermission';
    import { genericViewQueryAsText, getCodeSets } from '@/shared/helpers/api';
    import { encodeIdBase64 } from '@/shared/helpers/utils';

    export default {
        directives: {
            'require-can-edit-code': requireCanEditCode
        },
        components: { CodeEdit, BoolElement },
        props: {
            selectedScope: {
                type: String,
                required: false,
                default: () => null
            },
            navigateToContractor: {
                type: String,
                required: false,
                default: () => null
            }
        },
        emits: [
            'updateContractor'
        ],
        data() {
            return {
                contractors: [],
                contractorsLoading: false,
                selectedContractor: null,
                editFormActive: false,
                codeSetName: '',
                contractIsValidFilter: true
            };
        },
        computed: {
            accessArguments() {
                return {
                    libraryName: 'LCIContractor',
                    scopes: [this.selectedScope]
                };
            },
            selectedContractorId() {
                return this.selectedContractor ? encodeIdBase64('Code', this.selectedContractor.Id) : null;
            },
            codeEditTitle() {
                const verb = this.isEditing ? 'Edit' : 'New';
                return verb + ' Contractor';
            },
            isCreating() {
                return this.editFormActive && !this.selectedContractorId;
            },
            isEditing() {
                return this.editFormActive && !!this.selectedContractorId;
            },
            selectedContractorAttributes() {
                return Object.keys(this.selectedContractor)
                    .filter(key =>
                        key !== 'Id'
                        && this.selectedContractor[key] !== null
                        && this.selectedContractor[key] !== '')
                    .map(key => ({
                        property: key,
                        value: this.selectedContractor[key]
                    }));
            },
            filteredContractors() {
                if (this.contractIsValidFilter === 'all') {
                    return this.contractors;
                } else {
                    return this.contractors.filter(contractor => {
                        return contractor.IsValid === this.contractIsValidFilter;
                    });
                }
            }
        },
        watch: {
            selectedScope: {
                async handler(newValue) {
                    if (newValue) {
                        await this.loadContractors();
                    } else {
                        this.contractors = [];
                    }
                },
                immediate: true
            },
            selectedContractor: {
                handler(newValue, oldValue) {
                    if (!oldValue)
                        this.editFormActive = false;
                    if (this.selectedContractor) {
                        this.$emit('updateContractor', this.selectedContractor.Name);
                    }
                },
                emmidiate: true
            },
            async contractorsLoading(newValue) {
                if (newValue) {
                    this.codeSetName = await this.fetchCodeSetName('LCIContractor', this.selectedScope);
                    this.editFormActive = false;
                    this.selectedContractor = null;
                }
            }
        },
        async created() {
            this.codeSetName = await this.fetchCodeSetName('LCIContractor', this.selectedScope);
        },
        methods: {
            async loadContractors() {
                this.contractorsLoading = true;
                const res = await genericViewQueryAsText(
                    this,
                    `FROM LCIContractor
                        SELECT Id, Name, Company, Description, IsValid
                        WHERE Scope = @scope
                        ORDER BY Name`,
                    [{ name: '@scope', value: `"${this.selectedScope}"` }]
                );
                this.contractors = res.data;
                this.contractorsLoading = false;
                this.openContractor(this.navigateToContractor);
            },
            openContractor(contractor) {
                if (contractor) {
                    this.selectedContractor = this.contractors.find(x => x.Name === contractor);
                }
            },
            onNewContractorClicked() {
                this.editFormActive = true;
                this.selectedContractor = null;
            },
            async fetchCodeSetName(libraryName, scope) {
                const res = await getCodeSets(this, libraryName, scope);
                return res.length === 1 ? res[0].name : null;
            },
            beforeSaveCodeInCodeEdit(code, dropdownContent) {
                const selectedCompany = code['Company'];
                code['description'].value = dropdownContent['Company'].filter(c => c['name'] === selectedCompany['value'])[0].description;
            },
            async afterContractorSave() {
                this.editFormActive = false;
                await this.loadContractors();
            }
        }
    };
</script>

<style scoped>

</style>
