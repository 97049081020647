<template>
    <div>
        <div
            v-if="!noRelations"
            ref="umlWrapper"
            style="width: auto; height: 300px">
            <p>
                This diagram shows the library in context with libraries it refers to and
                libraries that refer to it.
            </p>
            <b-loading
                v-if="loading"
                v-model="loading"
                :is-full-page="false" />
            <mermaid-diagram
                v-else
                :uml-source="mermaidCode" />
        </div>
        <p v-else>
            This library has no relations.
        </p>
    </div>
</template>

<script>
    import { getLibraryRelatedLibraries } from '@/shared/helpers/api';
    import MermaidDiagram from '@/shared/MermaidDiagram.vue';
    import { makeLibraryRelationUml } from '@/shared/helpers/umlHelper';
    export default {
        components: {
            MermaidDiagram
        },
        props: {
            libraryName: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                mermaidCode: '',
                noRelations: false,
                loading: true
            };
        },
        async mounted() {
            const relatedLibraries = await getLibraryRelatedLibraries(
                this,
                this.libraryName
            );

            if (relatedLibraries?.relations.length === 0) {
                this.noRelations = true;
            } else if (relatedLibraries) {
                this.mermaidCode = this.renderUml(relatedLibraries);
            }

            this.loading = false;
        },
        methods: {
            renderUml(relatedLibraries) {
                return makeLibraryRelationUml(this.libraryName, relatedLibraries);
            }
        }
    };
</script>

<style scoped>

</style>
