<template>
    <div>
        <b-loading
            v-model="loading"
            is-full-page />
        <section
            v-if="showScopeSelect"
            class="center-in-middle">
            <h2 class="title is-3">
                Lookup
            </h2>
            <p style="font-size: 15px;">
                Library: <b>{{ library }}</b>
            </p>
            <p class="mt-2 mb-1">
                Select scope and click <b>Go!</b> to be redirected
            </p>
            <div class="is-flex is-align-items-center">
                <b-autocomplete
                    v-model="scopeSearch"
                    style="width: 200px;"
                    placeholder="Choose scope"
                    :loading="scopesLoading"
                    :data="scopesForLibraryFiltered"
                    clearable
                    open-on-focus
                    @select="option => (selectedScope = option)" />
                <b-button
                    class="is-info ml-1"
                    @click="setSelectedScopeAndRedirect">
                    Go!
                </b-button>
            </div>
        </section>
    </div>
</template>

<script>
    import { getCodeSetNameAndCodeIdentity, getLibrary, getLibraryWithScopes } from '@/shared/helpers/api';

    export default {
        data() {
            return {
                library: null,
                scopes: null,
                queries: null,
                name: null,
                regex: null,
                showColumns: null,
                noHeader: null,
                scopesForLibrary: [],
                scopesLoading: false,
                selectedScope: null,
                scopeSearch: '',
                showScopeSelect: false,
                localStorageScopeKey: 'lookupScope',
                isGlobalLibrary: false,
                loading: false
            };
        },
        computed: {
            scopesForLibraryFiltered() {
                const search = this.scopeSearch?.toLowerCase();
                return this.scopesForLibrary
                    .filter(s => search === null || s.toLowerCase().includes(search));
            }
        },
        async mounted() {
            const query = this.$route.query;
            const queryKeys = Object.keys(query);
            queryKeys.forEach((key) => {
                if (key.toLowerCase() === 'library') {
                    this.library = query[key];
                } else if (key.toLowerCase() === 'scope') {
                    this.scopes = query[key] ? query[key].split(',') : [];
                } else if (key.toLowerCase() === 'queries') {
                    this.queries = JSON.parse(query[key]);
                } else if (key.toLowerCase() === 'name') {
                    this.name = query[key];
                } else if (key.toLowerCase() === 'regex') {
                    this.regex = JSON.parse(query[key]);
                } else if (key.toLowerCase() === 'show') {
                    this.showColumns = JSON.parse(query[key]);
                } else if (key.toLowerCase() === 'noheader') {
                    this.noHeader = true;
                }
            });

            if (this.library) {
                this.isGlobalLibrary = await this.libraryIsGlobal();
                if (!this.isGlobalLibrary && this.scopes && this.scopes.length === 0) {
                    await this.checkLocalStorageScope();
                } else {
                    await this.redirect();
                }
            }
        },
        methods: {
            async redirect() {
                this.loading = true;
                let url = this.getLibraryLink(this.library);

                if (this.scopes) {
                    if (this.scopes.length > 1) {
                        url = this.addScopesToURL(url);
                    } else {
                        url = await this.getURLBasedOnNameOrCodeSet();
                        this.saveScopeToLocalStorage();
                        this.saveSelectedColumnsToLocalStorage();
                        this.removeFiltersFromLocalStorage();
                    }
                }

                window.location = url;
            },
            addScopesToURL(url) {
                const params = new URLSearchParams();
                params.set('ScopeFilter', JSON.stringify(this.scopes));
                return url + '?' + params;
            },
            getLibraryLink(libraryName) {
                return `/Library/${encodeURIComponent(libraryName)}`;
            },
            getCodeSetLink(codeSetName) {
                return `/Library/CodeSet/${codeSetName}`;
            },
            getCodeLink(codeSetName, identity) {
                return `/Library/CodeSet/${codeSetName}/Code/${encodeURIComponent(identity)}`;
            },
            async getURLBasedOnNameOrCodeSet() {
                const res = await getCodeSetNameAndCodeIdentity(this, this.library, this.scopes[0], this.name);

                const queries = [];
                if (this.queries) {
                    queries.push(...this.queries);
                }

                if (this.name && res.codeIdentity) {
                    return this.getCodeLink(res.codeSetName, res.codeIdentity);
                } else if (this.name && !res.codeIdentity) {
                    queries.push(['name', '=', this.name]);
                }

                const params = new URLSearchParams();
                const url = this.getCodeSetLink(res.codeSetName);
                if (queries.length > 0)
                    params.set('queries', JSON.stringify(queries));
                if (this.regex)
                    params.set('regex', JSON.stringify([this.regex]));
                if (this.noHeader) {
                    params.set('noheader', 'true');
                }

                return url + '?' + params;
            },
            async getScopesForLibrary() {
                this.scopesLoading = true;
                const res = await getLibraryWithScopes(this, this.library);
                this.scopesForLibrary = res[0].codeSets.flatMap(cs => cs.scopes).sort();
                this.scopesLoading = false;
            },
            async setSelectedScopeAndRedirect() {
                this.scopes = [this.selectedScope];
                this.saveScopeToLocalStorage();
                await this.redirect();
            },
            async checkLocalStorageScope() {
                const localStorageScope = localStorage.getItem(this.localStorageScopeKey);
                if (!localStorageScope || localStorageScope === 'null') {
                    await this.getScopesForLibrary();
                    this.showScopeSelect = true;
                } else {
                    this.scopes = [localStorageScope];
                    await this.redirect();
                }
            },
            saveScopeToLocalStorage() {
                if (!this.isGlobalLibrary)
                    localStorage.setItem(this.localStorageScopeKey, this.scopes[0]);
            },
            saveSelectedColumnsToLocalStorage() {
                if (this.showColumns) {
                    const columns = this.showColumns.toString().toLowerCase();
                    localStorage.setItem('Library.' + this.library + '.SelectedTableColumns', columns);
                }
            },
            async libraryIsGlobal() {
                const res = await getLibrary(this, this.library);
                return res.isGlobal;
            },
            removeFiltersFromLocalStorage() {
                localStorage.removeItem('Library.' + this.library + '.filters');
            }
        }
    };
</script>

<style>
.center-in-middle {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
