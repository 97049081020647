<template>
    <div>
        <div
            v-if="selectedScope"
            class="column-header block">
            <h4
                class="title is-4">
                Component Types
            </h4>
        </div>
        <b-loading
            v-if="loading"
            :model-value="loading" />
        <b-menu v-else>
            <b-menu-list>
                <div>
                    <b-message
                        v-if="computedTreeView.length === 0">
                        No data for {{ selectedScope }}
                    </b-message>
                    <b-menu-item
                        v-for="component in computedTreeView"
                        :key="component.Identity"
                        :icon="component.Children.length > 0 ? 'plus' : 'none'"
                        :label="component.Description || component.Name"
                        :model-value="isActiveParent(component.Identity)"
                        :expanded="isActiveParent(component.Identity)"
                        @click="handleSelectedEleComponentTypeChange(component)">
                        <b-menu-item
                            v-for="componentChild in component.Children"
                            :key="componentChild.Identity"
                            :label="componentChild.Description || componentChild.Name"
                            :model-value="isActiveChild(componentChild.Identity)"
                            :expanded="isActiveChild(componentChild.Identity)"
                            @click="handleSelectedEleComponentTypeChange(componentChild)" />
                    </b-menu-item>
                </div>
            </b-menu-list>
        </b-menu>
    </div>
</template>
<script>
    import { genericViewQueryAsText } from '@/shared/helpers/api.ts';
    import { useElectricalComponentStore } from '@/stores/electricalComponentStore';
    export default {
        props: {
            selectedScope: {
                type: String,
                default: null
            },
            displayOnlyValid: {
                type: Boolean,
                default: true
            }
        },
        emits: [
            'handleSelectedEleComponentTypeChange'
        ],
        data() {
            return {
                rootNodes: null,
                allNodes: null,
                selectedEleComponentType: null,
                loading: true,
                electricalComponentStore: useElectricalComponentStore()
            };
        },
        computed: {
            computedTreeView() {
                const treeView = [];

                const rootNodesFiltered = [];
                this.rootNodes.forEach(element => {
                    if (!this.displayOnlyValid || element.IsValid) {
                        rootNodesFiltered.push(element);
                    }
                });

                rootNodesFiltered.forEach(element => {
                    element.Children = [];
                    this.allNodes.forEach(possibleChild => {
                        if (possibleChild.SuperType_ID === element.Id && (!this.displayOnlyValid || possibleChild.IsValid)) {
                            element.Children.push(possibleChild);
                        }
                    });
                    treeView.push(element);
                });
                return treeView;
            }
        },
        watch: {
            async selectedScope() {
                await this.loadData();
            }
        },
        async mounted() {
            await this.loadData();

            if (this.electricalComponentStore.electricalComponent) {
                const componentType = this.allNodes
                    .find(compType => compType.Id === this.electricalComponentStore.electricalComponent);

                this.handleSelectedEleComponentTypeChange(componentType);
                this.electricalComponentStore.setElectricalComponent(null);
            }
        },
        methods: {
            handleSelectedEleComponentTypeChange(componentType) {
                this.selectedEleComponentType = componentType;
                this.$emit('handleSelectedEleComponentTypeChange', componentType);
            },
            async loadData() {
                this.loading = true;
                const [
                    rootNodesResponse,
                    allNodesResponse
                ] = await Promise.all([
                    this.getRootEleComponentTypes(),
                    this.getAllEleComponentTypes()
                ]);
                this.rootNodes = rootNodesResponse.data;
                this.allNodes = allNodesResponse.data;
                this.loading = false;
            },
            getAllEleComponentTypes() {
                return genericViewQueryAsText(
                    this,
                    'FROM ElectricComponentType WHERE Scope = @Scope',
                    [{ name: '@Scope', value: this.selectedScope }]
                );
            },
            getRootEleComponentTypes() {
                return genericViewQueryAsText(
                    this,
                    'FROM ElectricComponentType WHERE SuperType_ID IS null And Scope = @Scope',
                    [{ name: '@Scope', value: this.selectedScope }]
                );
            },
            isActiveParent(componentId) {
                if (this.selectedEleComponentType) {
                    if (this.selectedEleComponentType.SuperType) {
                        const superType = this.selectedEleComponentType.SuperType;
                        return superType === componentId;
                    } else {
                        return componentId === this.selectedEleComponentType.Identity;
                    }
                }
                return false;
            },
            isActiveChild(componentId) {
                if (this.selectedEleComponentType) {
                    return componentId === this.selectedEleComponentType.Identity;
                }
                return false;
            }
        }
    };
</script>
