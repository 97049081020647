<template>
    <div>
        <b-field
            label="Get table definition:">
            <b-autocomplete
                v-model="input"
                :data="filteredList"
                placeholder="Library name"
                :autofocus="true"
                :keep-first="true"
                :open-on-focus="true"
                icon="magnify"
                :clearable="true"
                @select="option => selectedLibrary = option">
                <template #empty>
                    No library found
                </template>
            </b-autocomplete>
        </b-field>
        <div v-if="tableDefinitions[selectedLibrary]">
            <hr>
            <div
                class="field is-grouped">
                <b-button
                    size="is-small"
                    type="is-info"
                    icon-left="script-text-outline"
                    @click="printFullSelect">
                    <span>Select top 1000 valid rows</span>
                </b-button>
            </div>
            <b-field label="Table definition:">
                <b-table
                    :columns="definitionColumns"
                    :bordered="true"
                    :striped="true"
                    :narrowed="true"
                    :mobile-cards="false"
                    :data="tableDefinitions[selectedLibrary].columns" />
            </b-field>
        </div>
    </div>
</template>

<script>
    import { genericViewDefinition } from '@/shared/helpers/api';
    export default {
        name: 'SqlLibraryBrowser',
        props: {
            libraryList: {
                type: Array,
                required: true
            }
        },
        emits: [
            'selected',
            'set-sql'
        ],
        data() {
            return {
                input: '',
                selectedLibrary: null,
                tableDefinitions: {},
                definitionColumns: [
                    {
                        field: 'column_name',
                        label: 'Col'
                    },
                    {
                        field: 'data_type_full',
                        label: 'Type'
                    },
                    {
                        field: 'is_nullable',
                        label: 'Nullable'
                    }
                ]

            };
        },
        computed: {
            filteredList() {
                if (!this.input)
                    return this.libraryList;

                return this.libraryList.filter((option) =>
                    option
                        .toString()
                        .toLowerCase()
                        .indexOf(this.input.toLowerCase()) >= 0
                );
            }
        },
        watch: {
            async selectedLibrary() {
                const lib = this.selectedLibrary;
                if (lib) {
                    if (!this.tableDefinitions[lib]) {
                        const definition = await genericViewDefinition(this, lib);
                        this.addDataTypeFull(definition);
                        this.tableDefinitions[lib] = definition;
                    }
                    this.$emit('selected', lib);
                }
            }
        },
        methods: {
            printFullSelect() {
                const columns = this.tableDefinitions[this.selectedLibrary].columns.map(c => `[${c.column_name}]`);
                this.$emit('set-sql', `SELECT TOP (1000) ${columns.join('\n    ,')}\n  FROM ${this.selectedLibrary}\n  WHERE IsValid = 1`);
            },
            addDataTypeFull(tableDefinition) {
                const getFullType = (c) => {
                    if (c.data_type === 'nvarchar') {
                        if (c.character_maximum_length === -1) {
                            return 'nvarchar(max)';
                        }
                        return `nvarchar(${c.character_maximum_length})`;
                    }
                    return c.data_type;
                };

                tableDefinition.columns.forEach(c => {
                    c.data_type_full = getFullType(c);
                });
            }
        }
    };
</script>
