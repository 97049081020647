<template>
    <div>
        <h2 class="title is-4">
            Published releases
        </h2>
        <b-table
            :data="releases.data"
            :striped="true"
            :narrowed="true"
            :hoverable="true">
            <b-table-column
                v-slot="props"
                field="dateCreated"
                label="Timestamp"
                sortable>
                {{ $filters.dateFormatLong(props.row.dateCreated) }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="codeSets"
                label="Code sets"
                sortable>
                <div v-if="props.row.codeSets">
                    <router-link
                        v-for="cs in props.row.codeSets"
                        :key="cs.name"
                        :to="getCodeSetLink(cs.name)">
                        {{ cs.description }}
                    </router-link>
                </div>
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="id"
                label="Release Id"
                sortable>
                <router-link :to="getReleaseLink(props.row.id)">
                    {{ props.row.id }}
                </router-link>
            </b-table-column>
        </b-table>
    </div>
</template>

<script>
    import { getFilteredReleases } from '@/shared/helpers/api';
    import { getCodeSetLink, getReleaseLink } from '@/shared/helpers/routing';

    export default {
        props: {
            libraryName: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                releases: []
            };
        },
        async mounted() {
            this.releases = await getFilteredReleases(this, {
                libraries: [this.libraryName],
                states: ['Published']
            });
        },
        methods: {
            getCodeSetLink,
            getReleaseLink
        }
    };
</script>
