<template>
    <div>
        <h1 class="title">
            Electric Component Type
        </h1>
        <b-field grouped>
            <scope-dropdown
                scope-type="Facility" />
        </b-field>
        <hr>
        <b-field
            grouped>
            <b-field>
                <b-field
                    v-require-can-edit-code="{ libraryName, scopes: selectedScope ? [selectedScope] : [] }">
                    <b-button
                        :disabled="enableButtons"
                        @click="handleAddComponentType">
                        <span class="icon is-small">
                            <i
                                class="fa fa-plus"
                                aria-hidden="true" />
                        </span>
                        <span>Add new component type</span>
                    </b-button>
                </b-field>
            </b-field>
            <b-field>
                <b-checkbox-button
                    type=""
                    :disabled="enableButtons"
                    @update:model-value="handleDisplayOnlyValidChange">
                    <b-icon
                        pack="fa-regular"
                        :icon="displayOnlyValid ? 'square-check' : 'square'" />
                    <span>Show only Valid</span>
                </b-checkbox-button>
            </b-field>

            <b-field
                v-require-can-edit-code="{ libraryName, scopes: selectedScope ? [selectedScope] : [] }"
                grouped
                expanded
                position="is-right">
                <b-button
                    type="is-primary"
                    :disabled="releaseDisabled"
                    @click="handleReleaseChanges">
                    <span class="icon is-small">
                        <i
                            class="fa fa-file"
                            aria-hidden="true" />
                    </span>
                    <span>Release Changes</span>
                </b-button>
            </b-field>
        </b-field>

        <br>

        <spinner
            v-if="selectedScope"
            :loading="releaseLoading">
            <div
                class="columns">
                <!-- Treeview -->
                <div class="column is-fifth">
                    <ele-component-type-tree-view
                        v-if="selectedScope"
                        ref="treeView"
                        :selected-scope="selectedScope"
                        :display-only-valid="displayOnlyValid"
                        @handleSelectedEleComponentTypeChange="handleSelectedEleComponentTypeChange" />
                </div>

                <!-- Editor panel -->
                <div
                    class="column is-three-tens">
                    <ele-component-type-edit
                        v-if="selectedEleComponentType"
                        ref="eleComponentTypeEdit"
                        :initial-ele-component-type="selectedEleComponentType"
                        :selected-scope="selectedScope"
                        :library-name="libraryName"
                        @handleEleComponentTypeChange="handleEleComponentTypeChange" />
                </div>

                <!-- Tag type relations -->
                <div
                    class="column is-half">
                    <tag-type-relations-edit
                        v-if="selectedEleComponentType && selectedEleComponentType.Id"
                        ref="tagTypeRelationsEdit"
                        :selected-scope="selectedScope"
                        :selected-ele-component-type="selectedEleComponentType"
                        :library-name="libraryName"
                        :permissions="permissions"
                        @handleTagTypeRelationChange="handleTagTypeRelationChange" />
                </div>
            </div>
        </spinner>
        <b-message
            v-else>
            Please select a scope.
        </b-message>
    </div>
</template>

<script>
    import Spinner from '@/shared/components/Spinner.vue';
    import EleComponentTypeTreeView from './EleComponentTypeTreeView.vue';
    import EleComponentTypeEdit from './EleComponentTypeEdit.vue';
    import TagTypeRelationsEdit from './TagTypeRelationsEdit.vue';
    import { getCodeSets, createReleaseChain } from '@/shared/helpers/api.ts';
    import { showMixin } from '@/shared/mixins/showMixin.js';
    import ScopeDropdown from '@/shared/components/ScopeDropdown.vue';
    import { requireCanEditCode } from '@/shared/directives/requirePermission';
    import { setScopeFilter } from '@/shared/helpers/scopefilterHelpers.js';
    import { useElectricalComponentStore } from '@/stores/electricalComponentStore.js';

    export default {
        components: {
            ScopeDropdown,
            Spinner,
            EleComponentTypeTreeView,
            EleComponentTypeEdit,
            TagTypeRelationsEdit
        },
        directives: {
            'require-can-edit-code': requireCanEditCode
        },
        mixins: [
            showMixin
        ],
        data() {
            return {
                selectedEleComponentType: null,
                displayOnlyValid: true,
                tagTypesUpdateList: [],
                updatedEleComponentType: null,
                releaseLoading: false,
                releaseDisabled: true,
                libraryName: 'ElectricComponentType',
                permissions: ['CanEditCode', 'CanEditRelease'],
                eleCompStore: useElectricalComponentStore()
            };
        },
        computed: {
            selectedScope: {
                get() {
                    return this.eleCompStore.getSelectedScope();
                },
                set(scope) {
                    this.eleCompStore.updateSelectedScope(scope);
                }
            },
            enableButtons() {
                return !this.selectedScope;
            }
        },
        watch: {
            selectedScope() {
                this.selectedEleComponentType = null;
            }
        },
        methods: {
            handleSelectedEleComponentTypeChange(componentType) {
                this.selectedEleComponentType = componentType;
                if (this.selectedEleComponentType.BatteryFunctionality === null) {
                    this.selectedEleComponentType.BatteryFunctionality = false;
                }
            },
            handleDisplayOnlyValidChange() {
                this.displayOnlyValid = !this.displayOnlyValid;
                this.selectedEleComponentType = null;
            },
            async handleAddComponentType() {
                const newComponent = {
                    Id: null,
                    Name: crypto.randomUUID(),
                    Description: null,
                    SuperType: null,
                    Usage: null,
                    Drawer: null,
                    BatteryFunctionality: false,
                    Symbol: null,
                    IsValid: true
                };
                this.selectedEleComponentType = newComponent;
                this.tagTypesUpdateList = [];
            },
            handleTagTypeRelationChange(tagTypesUpdateList) {
                this.tagTypesUpdateList = tagTypesUpdateList;
            },
            handleEleComponentTypeChange(updatedEleComponentType, hasInvalidDescription) {
                this.updatedEleComponentType = updatedEleComponentType;
                this.releaseDisabled = !this.allRequiredFieldsHaveValue() || hasInvalidDescription;
            },
            async getTagTypeCodeSet(scope) {
                const [codeSetResponse] = await Promise.all([getCodeSets(this, 'TagType', scope)]);
                return codeSetResponse[0];
            },
            async handleReleaseChanges() {
                this.releaseLoading = true;

                try {
                    const collection = [];
                    await Promise.all(this.tagTypesUpdateList.map(async element => {
                        collection.push(
                            {
                                'codeSetName': (await this.getTagTypeCodeSet(element.Scope)).name,
                                'libraryName': 'TagType',
                                'codes': [
                                    {
                                        Name: element.Name,
                                        TagCategory: element.TagCategory,
                                        ElectricComponentType: element.ElectricComponentType
                                    }
                                ]
                            });
                    }));

                    // Main electric component data panel
                    const comp = this.updatedEleComponentType;
                    const changedEleComponentType = {
                        Name: comp.Name,
                        Description: comp.Description,
                        SuperType: comp.SuperType === null || comp.SuperType === '' ? '{null}' : comp.SuperType,
                        Drawer: comp.Drawer === null || comp.Drawer === '' ? '{null}' : comp.Drawer,
                        Usage: comp.Usage === null || comp.Usage === '' ? '{null}' : comp.Usage,
                        Symbol: comp.Symbol === null || comp.Symbol === '' ? '{null}' : comp.Symbol,
                        BatteryFunctionality: comp.BatteryFunctionality,
                        IsValid: comp.IsValid
                    };

                    // Code set for component type
                    const [
                        codeSetResponse
                    ] = await Promise.all ([
                        getCodeSets(this, 'ElectricComponentType', this.selectedScope)
                    ]);
                    const codeSet = codeSetResponse[0];

                    collection.push({ 'codeSetName': codeSet.name, 'libraryName': 'ElectricComponentType', codes: [changedEleComponentType] });

                    await Promise.all([createReleaseChain(this, collection)]);
                } catch (error) {
                    this.showError(error);
                    this.releaseLoading = false;
                    return;
                }
                this.showInfo('Release created successfully. It may take a few seconds for changes to take effect.');
                setScopeFilter('Facility', this.selectedScope);
                window.location = '/Release/MyReleases';
            },
            allRequiredFieldsHaveValue() {
                return this.updatedEleComponentType.Usage
                    && this.updatedEleComponentType.Drawer
                    && this.updatedEleComponentType.Description;
            }
        }
    };
</script>
