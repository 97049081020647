/**
 * Accepts a code and extracts a number value from its string attributes.
 *
 * @param {Object} code
 * @param {string} key
 * @returns {number|null}
 */
function getAttributeValue(code, key) {
    const strValue = code.data[key].value;
    return strValue ? Number(strValue) : null;
}

/**
 * Tmin3 calculations
 *
 * @param {number} designPressureMaximum
 * @param {number} outerDiameter
 * @param {number} stress
 * @param {number} nConvFactor
 * @param {number} qualityFactor
 * @param {number} yFactor
 * @returns {number}
 */
function tmin_3_func(designPressureMaximum, outerDiameter, stress, nConvFactor, qualityFactor, yFactor) {
    return designPressureMaximum * outerDiameter / (20 * stress * nConvFactor * qualityFactor + 2 * designPressureMaximum * yFactor);
}

/**
 * nConvFactor for the corresponding sUnit.
 * Currently only 'BARG' and 'PSI' is in use.
 *
 * @param {string} sUnit
 * @returns {number}
 */
function getNConvFactor(sUnit) {
    switch (sUnit) {
        case 'BARG':
            return 1;
        case 'NMM2':
            return 6.895;
        case 'KPA':
            return 100;
        case 'PSI':
            return 14.5038;
        default:
            return 1;
    }
}

/**
 * Gets the current sUnit (Pressure unit) for a Facility.
 *
 * This is a hardcoded list, and should be updated when we import PressureUnit from STID.
 * Currently there is only 5 US Facilities (Plants) that is using `PSI'.
 *
 * @param {string} scope
 * @returns {string}
 */
function getSUnit(scope) {
    const psiScopes = ['TI', 'MRC', 'EFG', 'BAK', 'LAC'];
    if (psiScopes.some(psiScope => psiScope === scope)) {
        return 'PSI';
    } else {
        return 'BARG';
    }
}

/**
 * Tmin fields is a specific STID calculation.
 * This function returns a Object to be used in the CodeEditor.vue component as computed properties.
 *
 * More information can be found on the AB#123980
 * @param {string} scope
 * @param {Object} code
 * @param {string} selectedPipingClass
 * @param {string} _sUnit
 * @returns {[{hasValue: boolean, label: string, value: (string|string)},{hasValue: boolean, label: string, value: (string|string)},{hasValue: boolean, label: string, value: string},{hasValue: boolean, label: string, value: string},{hasValue: boolean, label: string, value: string}]}
 */
export default function tmin_calculations(scope, code, selectedPipingClass, _sUnit) {
    if (selectedPipingClass === null) return;
    const fractionDigits = 2;
    const sUnit = getSUnit(scope);
    const nConvFactor = getNConvFactor(sUnit);

    const designPressureMaximum = getAttributeValue(code, 'DesignPressureMaximum');
    const outerDiameter = getAttributeValue(code, 'OuterDiameter');
    const stress = getAttributeValue(code, 'Stress');
    const qualityFactor = getAttributeValue(code, 'QualityFactor');
    const yFactor = getAttributeValue(code, 'YFactor');
    const wallThickness = getAttributeValue(code, 'WallThickness');

    let tmin_3 = null;
    let tmin_2 = null;

    const condition = ![designPressureMaximum, outerDiameter, stress, qualityFactor, yFactor]
        .some(value => value === null || value.length < 1);

    if (condition) {
        tmin_3 = tmin_3_func(designPressureMaximum, outerDiameter, stress, nConvFactor, qualityFactor, yFactor)
            .toFixed(fractionDigits);

        tmin_2 = selectedPipingClass.Corrosion
            ? (tmin_3_func(designPressureMaximum, outerDiameter, stress, nConvFactor, qualityFactor, yFactor) + selectedPipingClass.Corrosion)
                .toFixed(fractionDigits)
            : 'Missing values';
    } else {
        tmin_3 = 'Missing values';
        tmin_2 = 'Missing values';
    }

    const tmin_1 = wallThickness && selectedPipingClass.Corrosion
        ? (wallThickness - selectedPipingClass.Corrosion).toFixed(fractionDigits)
        : 'Missing values';

    const in_diam = wallThickness && outerDiameter
        ? (-2 * wallThickness + outerDiameter).toFixed(fractionDigits)
        : 'Missing values';

    return [
        { label: 'In diam [mm]', value: in_diam, hasValue: in_diam !== 'Missing values' },
        { label: 'Tmin 1 [mm]', value: tmin_1, hasValue: tmin_1 !== 'Missing values' },
        { label: 'Tmin 2 [mm]', value: tmin_2, hasValue: tmin_2 !== 'Missing values' },
        { label: 'Tmin 3 [mm]', value: tmin_3, hasValue: tmin_3 !== 'Missing values' },
        { label: 'Pressure unit', value: sUnit, hasValue: true }
    ];
}
