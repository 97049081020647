<template>
    <div>
        <h1 class="title">
            {{ title }}
        </h1>
        <div
            v-if="!showReleaseView">
            <div>
                <b-field grouped>
                    <scope-dropdown
                        scope-type="Facility"
                        @update:selectedScope="onSelectScope" />
                    <b-field expanded />
                    <b-field
                        position="is-right">
                        <b-field>
                            <b-button
                                :disabled="!isDirty"
                                @click="clearChanges">
                                <b-icon
                                    size="is-small"
                                    pack="fa"
                                    icon="trash"
                                    aria-hidden="true" />
                                <span>
                                    Discard all changes
                                </span>
                            </b-button>
                        </b-field>

                        <b-field>
                            <b-button
                                type="is-primary"
                                :disabled="!isDirty"
                                @click="switchToReleaseView">
                                <b-icon
                                    pack="fa"
                                    icon="forward"
                                    aria-hidden="true" />
                                <span>
                                    Preview changes and release
                                </span>
                                <b-tag
                                    class="has-margin-left-half"
                                    rounded
                                    type="is-black">
                                    <b>
                                        {{ changesCount }}
                                    </b>
                                </b-tag>
                            </b-button>
                        </b-field>
                    </b-field>
                </b-field>

                <hr>

                <b-tabs
                    v-model="activeTab"
                    size="is-medium"
                    type="is-boxed">
                    <b-tab-item
                        value="Code">
                        <template #header>
                            <span>
                                Cable code
                                <tab-badge
                                    v-if="isInitialized">
                                    {{ filteredCodes('CableCode').length }}
                                </tab-badge>
                            </span>
                        </template>
                    </b-tab-item>

                    <b-tab-item
                        value="Specification"
                        :disabled="!selectedCode('CableCode') || loadingCableSpecs">
                        <template #header>
                            <span>
                                Specification
                                <tab-badge
                                    v-if="isInitialized"
                                    :loading="loadingCableSpecs">
                                    {{ filteredCodes('CableSpec').length }}
                                </tab-badge>
                            </span>
                        </template>
                    </b-tab-item>

                    <b-tab-item
                        value="SheathColour"
                        :disabled="!selectedCode('CableCode')">
                        <template #header>
                            <span>
                                Sheath colour
                                <tab-badge
                                    v-if="isInitialized">
                                    {{ filteredCodes('CableCodeSheath').length }}
                                </tab-badge>
                            </span>
                        </template>
                        <div v-if="missingCodeSets.length === 0" />
                    </b-tab-item>

                    <b-tab-item
                        value="Type"
                        :disabled="!selectedCode('CableCode')">
                        <template #header>
                            <span>
                                Cable type
                                <tab-badge
                                    v-if="isInitialized">
                                    {{ filteredCodes('CableCodeType').length }}
                                </tab-badge>
                            </span>
                        </template>
                        <div v-if="missingCodeSets.length === 0" />
                    </b-tab-item>
                </b-tabs>
            </div>

            <div class="is-full-width mt-3">
                <div
                    v-if="scope"
                    class="">
                    <div v-if="missingCodeSets.length === 0">
                        <keep-alive v-if="!loading">
                            <cable-codes
                                v-if="selectedTab === 'Code'"
                                :header-height="headerHeight" />
                        </keep-alive>
                        <keep-alive v-if="!loading">
                            <cable-spec-container v-if="selectedTab === 'Specification'" />
                        </keep-alive>
                        <keep-alive v-if="!loading">
                            <cable-code-color-type
                                v-if="selectedTab === 'SheathColour'"
                                :type="'Color'" />
                        </keep-alive>
                        <keep-alive v-if="!loading">
                            <cable-code-color-type
                                v-if="selectedTab === 'Type'"
                                :type="'cableType'" />
                        </keep-alive>
                    </div>
                    <b-message
                        v-else
                        class="has-margin-top2"
                        title="Error"
                        has-icon
                        type="is-danger"
                        style="max-width: 100ch;"
                        :closable="false">
                        Missing code sets for facility {{ scope }} for the libraries {{ missingCodeSets.join(', ') }}.
                    </b-message>
                </div>
                <b-message
                    v-else
                    class="has-margin-top2">
                    Please select a scope
                </b-message>
            </div>
        </div>
        <cable-code-release-view
            v-else
            class="is-full-width"
            @goBack="showReleaseView = false"
            @saveCompleted="onSaveSuccess" />
        <!-- This loading overlay prevents the user from switching scope while data is being loaded,
        which prevents the page from getting into a state where the data is not loaded correctly -->
        <b-loading
            v-model="loading"
            :is-full-page="false" />
    </div>
</template>

<script>

    import { mapActions, mapState, mapStores } from 'pinia';
    import { showMixin } from '@/shared/mixins/showMixin';
    import messageDialog from '../../../shared/mixins/messageDialogMixin';
    import { getScopesForLibraries } from '@/shared/helpers/api';
    import { getCableSpecs, libNames } from '@/shared/helpers/cableEditHelpers';
    import CableCodes from './CableCodes.vue';
    import CableSpecContainer from './CableSpecContainer.vue';
    import CableCodeColorType from './CableCodeColorType.vue';
    import CableCodeReleaseView from './CableCodeReleaseView.vue';
    import ScopeDropdown from '@/shared/components/ScopeDropdown.vue';
    import { useScopeStore } from '@/stores/scopeStore.js';
    import { useCodeEditStore } from '@/stores/codeEditStore.js';
    import TabBadge from '@/shared/components/TabBadge.vue';
    import { useCableEditStore } from '@/stores/cableEditStore.js';

    const localStorageKeys = {
        cableEditScope: 'cableEditScope'
    };

    export default {
        components: {
            TabBadge,
            ScopeDropdown,
            CableCodes,
            CableSpecContainer,
            CableCodeColorType,
            CableCodeReleaseView
        },
        mixins: [
            showMixin,
            messageDialog
        ],
        async beforeRouteLeave() {
            const ok = await this.confirmIfDataIsDirty(() => true, () => false);
            if (ok) {
                this.codeEditStore.$reset();
                return true;
            } else {
                return false;
            }
        },
        props: {
            selectedTab: {
                type: String,
                default: 'Code'
            }
        },
        data() {
            return {
                loading: false,
                loadingCableSpecs: false,
                activeTab: '',
                headerHeight: 0,
                showReleaseView: false,
                missingCodeSets: [],
                loadedSpecifications: new Set(),
                specificationsLoadedPromise: null,
                scopesForLibrariesPromise: null,
                cableEditStore: useCableEditStore()
            };
        },
        computed: {
            ...mapStores(useScopeStore, useCodeEditStore),
            ...mapState(useCodeEditStore, ['changes', 'isDirty', 'scope', 'changesCount']),
            title() {
                const base = 'Cable codes';

                if (this.selectedCode('CableCode')) {
                    const name = this.selectedCode('CableCode').values.name;
                    return `${base} - ${name}`;
                }
                return base;
            },
            selectedCableCode() {
                return this.selectedCode('CableCode');
            },
            isInitialized() {
                return !this.loading && this.isFullyLoaded(libNames.cableCode);
            }
        },
        watch: {
            async selectedCableCode() {
                if (!this.selectedCableCode)
                    return;
                this.specificationsLoadedPromise = this.loadSpecificationsForCableCode();
                await this.specificationsLoadedPromise;

                const selectedCableSpec = this.cableEditStore.popCableSpec();
                if (selectedCableSpec) {
                    this.setSelectedCableSpecification(selectedCableSpec);
                }
            }
        },
        async created() {
            this.scopeStore.setScopeType('Facility');
            await this.scopeStore.init();

            this.scopesForLibrariesPromise = getScopesForLibraries(this, Object.values(libNames))
                .then(res => res.data);

            if (this.scopeStore.selectedScope) {
                await this.changeSelectedScope(this.scopeStore.selectedScope);
            }

            const selectedCableCode = this.cableEditStore.popCableCode();

            if (selectedCableCode) {
                this.setSelectedCableCode(selectedCableCode);
            }

            if (this.selectedTab) {
                this.activeTab = this.selectedTab;
            }

            this.$watch('activeTab', (value) => {
                const destination = { name: 'CableEditTab', params: { selectedTab: value } };
                this.$router.replace(destination);
            });
        },
        methods: {
            ...mapActions(
                useCodeEditStore,
                ['filteredCodes', 'selectedCode', 'isFullyLoaded']
            ),
            async changeSelectedScope(newScope) {
                localStorage.setItem(localStorageKeys.cableEditScope, this.scope);

                // Reset state
                this.scopeStore.updateSelectedScope(newScope);
                this.codeEditStore.$reset();
                this.codeEditStore.scope = newScope;
                this.loadedSpecifications.clear();
                this.activeTab = 'Code';

                this.loading = true;

                const scopesForLibraries = await this.scopesForLibrariesPromise;
                this.missingCodeSets = Object.values(libNames)
                    .filter(libName => !scopesForLibraries[libName].includes(this.scope));

                if (this.missingCodeSets.length > 0) {
                    this.loading = false;
                    return;
                }

                await Promise.all([
                    this.codeEditStore.loadCodes(libNames.cableCode),
                    this.codeEditStore.loadCodes(libNames.cableCat, { isValid: true }),
                    this.codeEditStore.loadCodes(libNames.cableScreen, { isValid: true }),

                    this.codeEditStore.loadLibrary(libNames.cableSpec),
                    this.codeEditStore.loadLibrary(libNames.cableSpecWire),
                    this.codeEditStore.loadCodes(libNames.cableCrossSectionUnit, { isValid: true }),
                    this.codeEditStore.loadCodes(libNames.cableSize, { isValid: true }),
                    this.codeEditStore.loadCodes(libNames.cableWireColour),
                    this.codeEditStore.loadCodes(libNames.cableWireGroup),

                    this.codeEditStore.loadCodes(libNames.cableCodeSheeth, { isValid: true }),
                    this.codeEditStore.loadCodes(libNames.cableSheathColor),

                    this.codeEditStore.loadCodes(libNames.cableCodeType, { isValid: true }),
                    this.codeEditStore.loadCodes(libNames.cableType)
                ]);

                this.codeEditStore.registerFilter(
                    libNames.cableSpec,
                    s => s.values.CableCode === this.codeEditStore.selectedCodes[libNames.cableCode]
                );
                this.codeEditStore.registerFilter(
                    libNames.cableSpecWire,
                    s => s.values.CableSpec === this.codeEditStore.selectedCodes[libNames.cableSpec]
                );
                this.codeEditStore.registerFilter(
                    libNames.cableCodeSheeth,
                    s => s.values.CableCode === this.codeEditStore.selectedCodes[libNames.cableCode]
                );
                this.codeEditStore.registerFilter(
                    libNames.cableCodeType,
                    s => s.values.CableCode === this.codeEditStore.selectedCodes[libNames.cableCode]
                );

                this.loading = false;
            },
            async loadSpecificationsForCableCode() {
                const cableCode = this.selectedCableCode.values;

                if (this.isFullyLoaded(libNames.cableSpec)
                    || this.loadedSpecifications.has(cableCode.identity))
                    return;

                this.loadingCableSpecs = true;
                const cableSpecs = await getCableSpecs(this, cableCode.name, cableCode.identity, this.scope);
                this.codeEditStore.addCodes(libNames.cableSpec, cableSpecs);
                this.loadingCableSpecs = false;

                this.loadedSpecifications.add(cableCode.identity);
            },
            setSelectedCableCode(cableId) {
                const cableCode = this.codeEditStore.filteredCodes(libNames.cableCode)
                    .find(c => c.values.identity === cableId);

                if (cableCode) {
                    this.codeEditStore.selectCode(libNames.cableCode, cableCode.values.identity);

                    const selectedCategory = this.codeEditStore
                        .selectedCode(libNames.cableCode)
                        ?.values.CableCategory;

                    this.codeEditStore.selectCode(libNames.cableCat, selectedCategory);
                }
            },
            setSelectedCableSpecification(cableSpecIdentity) {
                const cableSpec = this.codeEditStore.filteredCodes(libNames.cableSpec)
                    .find(c => c.values.identity === cableSpecIdentity);

                if (cableSpec) {
                    this.codeEditStore.selectCode(libNames.cableSpec, cableSpec.values.identity);
                }
            },
            switchToReleaseView() {
                this.showReleaseView = true;
            },
            clearChanges() {
                this.codeEditStore.revertChanges();
                if (!this.selectedCode('CableCode'))
                    this.activeTab = 'Code';
            },
            async onSaveSuccess() {
                this.showReleaseView = false;
                const oldSelectedTab = this.activeTab;
                const oldCableSpec = this.codeEditStore.selectedCodes.CableSpec;
                await this.changeSelectedScope(this.scope);
                await this.specificationsLoadedPromise;
                this.activeTab = oldSelectedTab;
                this.codeEditStore.selectCode(libNames.cableSpec, oldCableSpec);
            },
            async onSelectScope(scope) {
                if (this.scope === scope)
                    return;

                await this.confirmIfDataIsDirty(
                    async () => {
                        await this.changeSelectedScope(scope);
                    },
                    () => {
                        // Revert scope change
                        this.scopeStore.updateSelectedScope(this.scope);
                    }
                );
            },
            async confirmIfDataIsDirty(onConfirm, onCancel = null) {
                if (this.isDirty) {
                    try {
                        await this.messageDialog('You have done changes without creating a release. Changing scope will result in changes beeing discarded. Continue?');
                    } catch {
                        return onCancel?.();
                    }
                }
                return onConfirm();
            }
        }
    };
</script>

<style scoped>
    .has-margin-left-half {
        margin-left: 0.6em;
    }

    .b-tabs :deep(.tab-content) {
        padding: 0px;
    }
</style>
