<template>
    <section>
        <spinner
            :loading="loading">
            <div class="table-overflow">
                <div class="saveButtonWrapper">
                    <div
                        v-require-can-edit-code="{ libraryName: 'SchemaMapClass' }">
                        <b-button
                            type="is-primary"
                            :disabled="!userCanSave"
                            @click="saveForm">
                            <b-icon
                                icon="floppy"
                                size="is-small" />
                            <span>Save</span>
                        </b-button>
                        <b-button
                            :disabled="!userCanSave"
                            @click="clickCancel">
                            <b-icon
                                icon="close-octagon-outline"
                                size="is-small" />
                            <span>{{ createNew ? 'Cancel' : 'Undo' }}</span>
                        </b-button>
                        <b-tooltip
                            label="SchemaMapClass library is configured locked for deletions"
                            :active="lockedForDelete">
                            <b-button
                                type="is-danger"
                                :disabled="lockedForDelete || createNew"
                                @click="() => confirmDeleteCode()">
                                <b-icon
                                    icon="skull"
                                    size="is-small" />
                                <span>Delete</span>
                            </b-button>
                        </b-tooltip>
                    </div>
                </div>
                <div
                    v-for="(value, key, index) in mappingClassFiltered"
                    :key="`${key}_${index}`"
                    :v-if="!isHiddenField(key)">
                    <div class="field is-horizontal flex-container">
                        <div class="field-label">
                            <label class="label pad-top">
                                <b-tooltip
                                    class="tooltip"
                                    :label="getDefinitions(key)"
                                    :multilined="true"
                                    :active="getDefinitions(key) && getDefinitions(key) !== ''"
                                    position="is-top">
                                    <span>{{ getDisplayName(key) }}:</span>
                                </b-tooltip>
                            </label>
                        </div>
                        <div class="field-body has-margin-top has-margin-right">
                            <div class="field is-grouped has-addons">
                                <div class="control is-expanded">
                                    <template v-if="isInputBool(key)">
                                        <b-switch
                                            v-if="isInputBool(key)"
                                            v-model="mappingClassForm[key]" />
                                    </template>
                                    <template v-else>
                                        <b-input
                                            v-model="mappingClassForm[key]"
                                            :disabled="key === 'Name'" />
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <b-field class="has-margin-sides margin-top">
                    <template #label>
                        Add Attributes
                        <b-tooltip
                            position="is-right"
                            multilined
                            :label="addAttributeText">
                            <b-icon
                                size="is-small"
                                icon="help-circle-outline" />
                        </b-tooltip>
                    </template>
                </b-field>

                <b-field
                    v-for="(value, index) in 3"
                    :key="value"
                    class="has-margin-sides box">
                    <template #label>
                        {{ getDisplayName(`AddAttribute${value}`) }}
                        <b-tooltip
                            position="is-right"
                            multilined
                            :label="getDefinitions(`AddAttribute${value}`)">
                            <b-icon
                                size="is-small"
                                icon="help-circle-outline" />
                        </b-tooltip>
                    </template>
                    <div class="AddAttributeFieldElements">
                        <b-dropdown
                            class="dropdownForm"
                            :triggers="['hover']"
                            aria-role="list"
                            :scrollable="true"
                            :max-height="400"
                            :position="index === 2 ? 'is-top-right' : 'is-bottom-right'">
                            <template #trigger>
                                <b-button
                                    class="dropdownButton"
                                    :label="addAttributes[index] ? `${addAttributes[index].Schema} ${addAttributes[index].Name}` : 'None selected'"
                                    type=""
                                    icon-right="menu-down" />
                            </template>
                            <b-dropdown-item
                                v-for="(attribute, idx) in class2Attributes"
                                :key="attribute + idx"
                                aria-role="listitem"
                                @click="()=>{changeAddAttribute(attribute, index)}">
                                {{ attribute ? `${attribute.Schema} ${attribute.Name}` : '' }}
                            </b-dropdown-item>
                        </b-dropdown>
                        <b-button
                            v-if="addAttributes[index]"
                            type="is-text"
                            @click="()=>{changeAddAttribute(null ,index)}">
                            Clear selected
                        </b-button>

                        <b-table
                            v-if="addAttributes[index]"
                            class="margin-top-bottom"
                            :columns="[{ field: 'key', label: 'Key' }, { field: 'value', label: 'Value' }]"
                            :data="createTableAttributeFormat(addAttributes[index])" />
                        <b-field
                            v-if="addAttributes[index]"
                            :label="getDisplayName(`ValueSource${value}`)"
                            :type="!valueSource[index] && (valueConstants[index] === '' || !valueConstants[index]) ? 'is-danger' : ''">
                            <b-dropdown
                                class="dropdownForm"
                                :triggers="['hover']"
                                aria-role="list"
                                :scrollable="true"
                                :max-height="400"
                                :position="index === 2 ? 'is-top-right' : 'is-bottom-right'">
                                <template #trigger>
                                    <b-tooltip
                                        class="tooltip"
                                        position="is-right"
                                        multilined
                                        :label="getDefinitions(`ValueSource${value}`)">
                                        <b-button
                                            class="dropdownButton"
                                            :label="valueSource[index] ? valueSource[index].Name : 'None selected'"
                                            type=""
                                            icon-right="menu-down" />
                                    </b-tooltip>
                                </template>
                                <b-dropdown-item
                                    v-for="(vs, idx) in possibleValueSources[index]"
                                    :key="vs + idx"
                                    aria-role="listitem"
                                    @click="()=>{changeValueSource(vs, index)}">
                                    {{ vs ? vs.Name : '' }}
                                </b-dropdown-item>
                            </b-dropdown>
                            <b-button
                                v-if="valueSource[index]"
                                type="is-text"
                                @click="()=>{changeValueSource(null ,index)}">
                                Clear selected
                            </b-button>
                        </b-field>
                        <b-field
                            v-if="addAttributes[index]"
                            :label="getDisplayName(`ValueConstant${value}`)"
                            :type="!valueSource[index] && (valueConstants[index] === '' || !valueConstants[index]) ? 'is-danger' : ''">
                            <b-tooltip
                                class="tooltip"
                                position="is-right"
                                multilined
                                :label="getDefinitions(`ValueConstant${value}`)">
                                <b-input
                                    v-model="valueConstants[index]" />
                            </b-tooltip>
                        </b-field>
                    </div>
                </b-field>
            </div>
        </spinner>
    </section>
</template>

<script>
    import Spinner from '@/shared/components/Spinner.vue';
    import { requireCanEditCode } from '@/shared/directives/requirePermission';
    import { createChangeDocAndCommit, deleteCode, genericViewQueryAsText, getLibraryWithScopes } from '@/shared/helpers/api';
    import { generateNameByConvention } from '@/shared/helpers/schemaHelpers';
    import _ from 'lodash';

    export default {
        directives: {
            'require-can-edit-code': requireCanEditCode
        },
        components: {
            Spinner
        },
        props: {
            createNew: {
                type: Boolean,
                default: false
            },
            selectedSchema1Class: {
                type: Object,
                required: true,
                default: null
            },
            selectedSchema2Class: {
                type: Object,
                required: true,
                default: null
            },
            mappingClass: {
                type: Object,
                default() {
                    return {
                        'Id': null,
                        'Name': '',
                        'Identity': '',
                        'IsValid': false,
                        'Description': '',
                        'Schema1': '',
                        'Schema1_ID': null,
                        'Schema2': '',
                        'Schema2_ID': null,
                        'Class1': '',
                        'Class1_ID': null,
                        'Class2': '',
                        'Class2_ID': null,
                        'Comments': null,
                        'InternalComments': null,
                        'PassOnUnmappedAttributes': false,
                        'IsBidirectional': false,
                        'AddAttribute1': null,
                        'AddAttribute1_ID': null,
                        'ValueSource1': null,
                        'ValueSource1_ID': null,
                        'ValueSource2': null,
                        'ValueSource2_ID': null,
                        'AddAttribute2': null,
                        'AddAttribute2_ID': null,
                        'AddAttribute3': null,
                        'AddAttribute3_ID': null,
                        'ValueSource3': null,
                        'ValueSource3_ID': null,
                        'ValueConstant1': null,
                        'ValueConstant2': null,
                        'ValueConstant3': null
                    };
                }
            },
            schemaObjects: {
                type: Array,
                required: true,
                default: () => []
            }
        },
        emits: [
            'refresh',
            'clickCancel'
        ],
        data() {
            return {
                mappingClassForm: {},
                addAttributes: [null, null, null],
                valueSource: [null, null, null],
                valueConstants: [null, null, null],
                mappingClassFiltered: null,
                displayFilter: ['Name', 'Description', 'Comments', 'InternalComments', 'PassOnUnmappedAttributes', 'IsBidirectional'],
                class1Attributes: [],
                class2Attributes: [],
                requiredAttributes: ['Schema1', 'Schema2', 'Class1', 'Class2', 'IsBidirectional', 'PassOnUnmappedAttributes'],
                originalFormValues: {},
                schemaMapClassCodeSet: null,
                loading: false,
                libraryDefinitions: null,
                attributeDefinitions: null,
                lockedForDelete: true,
                addAttributeText: `Sometimes, in addition to translate attributes, you need to copy attributes.
                    As part of the class mapping, you are given the ability to add currently up to 3 of the target object attributes.
                    The values for these attributes may come from any of the source object attributes, or you can add them with fixed (constant) values.
                    In cases when source attribute is specified, an no such is found on the source object, the target attribute is not added to the target object.`
            };
        },
        computed: {
            possibleValueSources() {
                return [this.getValueSource(this.addAttributes[0]),
                        this.getValueSource(this.addAttributes[1]),
                        this.getValueSource(this.addAttributes[2])];
            },
            codeSetName() {
                return this.libraryDefinitions[0]?.codeSets[0]?.name;
            },
            userCanSave() {
                if (this.createNew) {
                    return true;
                }

                let valid = true;

                const editedEntries = Object.entries(this.mappingClassForm);
                if (editedEntries[0] === undefined || editedEntries[0] === null) {
                    return false;
                }

                this.addAttributes.forEach((x, index) => {
                    if (x) {
                        if (!this.valueSource[index] && (this.valueConstants[index] === '' || !this.valueConstants[index])) {
                            valid = false;
                        }
                    }
                });

                const same = _.isEqual(this.mappingClassForm, this.originalFormValues);

                if (same) {
                    valid = false;
                }
                return valid;
            }
        },
        watch: {
            mappingClass: {
                handler(newValue) {
                    this.mappingClassForm = newValue;
                    this.originalFormValues = JSON.parse(JSON.stringify(this.mappingClassForm));
                },
                deep: false
            },
            async selectedSchema1Class() {
                this.reloadForm();
            },
            async selectedSchema2Class() {
                this.reloadForm();
            },
            addAttributes: {
                handler(newVal) {
                    [1, 2, 3].forEach((value, i) => {
                        this.mappingClassForm['AddAttribute' + value] = newVal[i]?.Identity;
                        this.mappingClassForm['AddAttribute' + value + '_ID'] = newVal[i]?.Id;
                    });
                },
                deep: true
            },
            valueSource: {
                handler(newVal) {
                    [1, 2, 3].forEach((value, i) => {
                        this.mappingClassForm['ValueSource' + value] = newVal[i]?.Identity;
                        this.mappingClassForm['ValueSource' + value + '_ID'] = newVal[i]?.Id;
                    });
                },
                deep: true
            },
            valueConstants: {
                handler(newVal) {
                    [1, 2, 3].forEach((value, i) => {
                        newVal[i] !== null || newVal[i] !== undefined
                            ? this.mappingClassForm['ValueConstant' + value] = newVal[i]
                            : null;
                    });
                },
                deep: true
            }
        },
        async mounted() {
            this.libraryDefinitions = await getLibraryWithScopes(this, 'SchemaMapClass');
            this.lockedForDelete = this.libraryDefinitions[0].codeSets[0].lockedForDelete;
            this.attributeDefinitions = this.libraryDefinitions[0].attributeDefinitions.map((x) => (
                {
                    [x.name]: {
                        description: x.description,
                        displayAs: x.displayAs
                    }
                }));
            this.mappingClassForm = { ...this.mappingClass };
            await this.reloadForm();
            this.originalFormValues = { ...this.mappingClassForm };
        },
        methods: {
            changeValueSource(value, index) {
                this.valueSource[index] = value;
            },
            changeAddAttribute(value, index) {
                this.addAttributes[index] = value;
                this.selectedAddAttribute(index);
            },
            selectedAddAttribute(index) {
                this.valueSource[index] = null;
                this.valueConstants[index] = null;
                this.mappingClassForm['ValueSource' + (index + 1)] = null;
                this.mappingClassForm['ValueSource' + (index + 1) + '_ID'] = null;
                this.mappingClassForm['ValueConstant' + (index + 1)] = null;
            },
            getDefinitions(key) {
                return this.attributeDefinitions?.find(x => x[key]) ? this.attributeDefinitions?.find(x => x[key])[key].description : null;
            },
            getDisplayName(key) {
                return this.attributeDefinitions?.find(x => x[key]) ? this.attributeDefinitions?.find(x => x[key])[key].displayAs : key;
            },
            getValueSource(obj) {
                if (obj) {
                    return this.class1Attributes[0].Schema === obj.Schema ? this.class2Attributes : this.class1Attributes;
                }
            },
            isInputBool(key) {
                return ['IsBidirectional', 'PassOnUnmappedAttributes', 'IsValid'].includes(key);
            },
            isHiddenField(key) {
                const regex = new RegExp('(Schema.*|Class.*)');
                return regex.test(key);
            },
            async getFilteredMappingClass() {
                return Object.keys(this.mappingClass)
                    .filter(key => this.displayFilter.includes(key))
                    .filter(key => !key.includes('AddAttribute'))
                    .reduce((obj, key) => {
                        return {
                            ...obj,
                            [key]: this.mappingClass[key]
                        };
                    }, {});
            },
            async getSchemaClassInterface(className, schemaName) {
                return (await genericViewQueryAsText(
                    this,
                    `FROM SchemaClassInterface
                    SELECT Name, Schema, Interface
                    WHERE IsValid = true and Schema = @SchemaName
                    JOIN Class WHERE name = @ClassName`,
                    [{ name: '@SchemaName', value: schemaName }, { name: '@ClassName', value: className }])).data;
            },
            async getSchemaClassInterfaceWBaseClass(baseClass) {
                return (await genericViewQueryAsText(
                    this,
                    `FROM SchemaClassInterface
                    SELECT Name, Schema, Interface
                    WHERE IsValid = true
                    JOIN Class SELECT Name as Classname WHERE Name = @BaseClass`,
                    [{ name: '@BaseClass', value: baseClass }])).data;
            },
            async getAttributesByInterface(query, schema) {
                return (await genericViewQueryAsText(
                    this,
                    query,
                    [{ name: '@Schema', value: schema }])).data;
            },
            attributesByInterfaceQuery(interfaceArray) {
                return `FROM SchemaAttribute
                        SELECT Name, Schema, Id, Identity, Interface
                        WHERE IsValid = true AND Interface in (${interfaceArray.map(x => `"${x}"`)}) and schema = @Schema`;
            },
            async getBaseClass(className, schemaName) {
                return (await genericViewQueryAsText(
                    this,
                    `FROM SchemaClass
                    SELECT Name, Schema
                    WHERE IsValid = true AND Name = @ClassName AND Schema = @SchemaName
                    JOIN HasBaseClass SELECT Name as BaseclassName`,
                    [{ name: '@ClassName', value: className }, { name: '@SchemaName', value: schemaName }])).data;
            },
            async getValidAttributes(className, schemaName) {
                // Some issues querying db when className is a number
                const isNumber = !isNaN(parseInt(className));
                const newClassName = isNumber ? `"${className}"` : className;

                const classInterfaces = await this.getSchemaClassInterface(newClassName, schemaName);
                const schemaClass = await this.getBaseClass(newClassName, schemaName);
                const interfaces = classInterfaces.map(x => x.Interface);
                const baseClassInterfaces = await this.getSchemaClassInterfaceWBaseClass(schemaClass ? schemaClass[0].BaseclassName : null);
                if (!interfaces.length && !baseClassInterfaces.length) {
                    return [];
                }
                const query = this.attributesByInterfaceQuery(baseClassInterfaces
                    ? interfaces.concat(baseClassInterfaces.map(z => z.Interface))
                    : interfaces);
                const result = await this.getAttributesByInterface(query, schemaName);
                return result.map(x => {
                    x['Class'] = className;
                    return x;
                });
            },
            createTableAttributeFormat(addAttribute) {
                return [{ key: 'ClassName', value: addAttribute.Class },
                        { key: 'InterfaceName', value: addAttribute.Interface },
                        { key: 'AttributeName', value: addAttribute.Name }];
            },
            async reloadForm() {
                this.loading = true;
                this.mappingClassFiltered = await this.getFilteredMappingClass();
                this.class1Attributes = await this.getValidAttributes(this.selectedSchema1Class.Name, this.selectedSchema1Class.Schema);
                this.class2Attributes = await this.getValidAttributes(this.selectedSchema2Class.Name, this.selectedSchema2Class.Schema);

                [1, 2, 3].forEach((x, index) => {
                    this.addAttributes[index] = this.class2Attributes.find(a => a.Identity === this.mappingClassForm[`AddAttribute${x}`]) || null;
                });

                [1, 2, 3].forEach((x, index) => {
                    this.valueSource[index] = this.class1Attributes.find(a => a.Identity === this.mappingClassForm[`ValueSource${x}`]) || null;
                });

                this.valueConstants = [this.mappingClass['ValueConstant1'], this.mappingClass['ValueConstant2'], this.mappingClass['ValueConstant3']];

                this.mappingClassForm.Name = this.mappingClassForm.Name === '' || !this.mappingClassForm.Name
                    ? generateNameByConvention(this.schemaObjects[0], this.selectedSchema1Class.Name,
                                               this.schemaObjects[1], this.selectedSchema2Class.Name)
                    : this.mappingClassForm.Name;

                this.loading = false;
            },
            getCode() {
                const code = {
                    'Schema1': this.selectedSchema1Class.Schema,
                    'Schema2': this.selectedSchema2Class.Schema,
                    'Class1': this.selectedSchema1Class.Identity,
                    'Class2': this.selectedSchema2Class.Identity,
                    'Name': this.mappingClassForm.Name,
                    'Description': this.mappingClassForm.Description ?? '',
                    'Comments': this.mappingClassForm.Comments ?? '',
                    'InternalComments': this.mappingClassForm.InternalComments ?? '',
                    'IsBidirectional': this.mappingClassForm.IsBidirectional,
                    'PassOnUnmappedAttributes': this.mappingClassForm.PassOnUnmappedAttributes
                };

                this.addAttributes.forEach((attr, index) => {
                    if (attr) {
                        code[`AddAttribute${index + 1}`] = attr.Identity;
                        const valueSource = this.valueSource[index];
                        code[`ValueSource${index + 1}`] = valueSource ? valueSource.Identity : '{null}';
                        code[`ValueConstant${index + 1}`] = this.valueConstants[index] ?? '';
                    } else {
                        code[`AddAttribute${index + 1}`] = '{null}';
                        code[`ValueSource${index + 1}`] = '{null}';
                        code[`ValueConstant${index + 1}`] = '';
                    }
                });

                return code;
            },
            async saveForm() {
                this.loading = true;
                const code = this.getCode();
                await createChangeDocAndCommit(this, this.libraryDefinitions[0].codeSets[0].name, [code]);
                this.$emit('refresh');
                this.loading = false;
            },
            clickCancel() {
                this.$emit('clickCancel');
            },
            confirmDeleteCode() {
                this.$buefy.dialog.confirm({
                    title: 'Please confirm delete',
                    message: `Deleting items may cause issues if the item is known externally to Common Library,
                        and should normally only be done during initial setup of libraries. Continue to delete item?`,
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: async () => {
                        await this.deleteCode();
                    }
                });
            },
            async deleteCode() {
                try {
                    const codeset = this.libraryDefinitions[0].codeSets[0].name;
                    const codeIdentity = this.mappingClass.Identity;

                    await deleteCode(this, codeset, codeIdentity);

                    this.$emit('refresh');
                } catch (error) {
                    this.showError(_.get(error, 'response.data.Message', 'Unable to delete item'));
                }
            }

        }
    };
</script>

<style scoped>
.select select option:disabled {
    color: #c3c3c3;
}
.AddAttributeFieldElements{
    display: inline-block;
    flex-direction: column;
}
.saveButtonWrapper{
    margin: auto;
    margin-top: 2vh;
    width: 100%;
    text-align: center;
}
.table-overflow{
    overflow: auto;
    overflow-y: scroll;
    height: 66vh;
}
.has-margin-sides{
    margin-left: 0.5vw;
    margin-right: 1vw;
}
.margin-top-bottom {
    margin-top: 1vh;
    margin-bottom: 1vh;
}
.flex-container{
    display: flex;
    align-items:center;
}
.pad-top {
    padding-top: 15px;
}
.margin-top {
    margin-top: 20px;
}
.has-margin-right {
    margin-right: 1em;
}
</style>
