<template>
    <section class="card">
        <header class="card-header">
            <p class="card-header-title">
                {{ title }}
            </p>
        </header>
        <b-field class="card-content">
            <b-input
                v-model="searchInput"
                placeholder="search.."
                icon="magnify"
                type="search" />
        </b-field>
        <div class="card-content">
            <ul
                v-for="(item, idx) in filteredList"
                :key="item+idx">
                <li
                    class="hover padding"
                    @click="$emit('selectedItem', item)">
                    {{ item }}
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
    export default {
        props: {
            list: {
                type: Array,
                default: () => []
            },
            selected: {
                type: String,
                default: ''
            },
            title: {
                type: String,
                default: 'Select item'
            }
        },
        emits: [
            'selectedItem'
        ],
        data() {
            return {
                searchInput: ''
            };
        },
        computed: {
            filteredList() {
                const filteredForSelected = this.selected !== ''
                    ? this.list.filter(x => !x.includes(this.selected))
                    : this.list;
                return filteredForSelected.filter(y => y.toLowerCase().includes(this.searchInput.toLowerCase()));
            }
        }
    };
</script>

<style scoped>
.hover:hover {
    cursor: pointer;
    background-color: rgb(238, 238, 238);
    border-radius: 3px;
}
.padding {
    padding: 5px;
}
</style>
