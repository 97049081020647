<template>
    <div
        style="height: auto;"
        class="block">
        <p v-show="libraryName">
            <b>{{ libraryName }}</b>
        </p>
        <ul class="process flat">
            <li :class="{ active: creatingIsActive }">
                Creating
            </li>
            <li :class="{ active: verifyingIsActive }">
                Verifying
            </li>
            <li :class="{ active: approvedIsActive }">
                Approved
            </li>
            <li :class="{ active: committingIsActive }">
                Committing
            </li>
            <li :class="{ active: committedIsActive }">
                Committed
            </li>
            <li>
                ...
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        props: {
            state: {
                type: String,
                default: null
            },
            libraryName: {
                type: String,
                default: null
            }
        },
        computed: {
            creatingIsActive() {
                // Simplifying process for the user, bundling Creating and Closed states
                return ['Creating', 'Closed'].includes(this.state) || this.verifyingIsActive;
            },
            verifyingIsActive() {
                return this.state === 'Verifying' || this.approvedIsActive;
            },
            approvedIsActive() {
                return this.state === 'Approved' || this.committingIsActive;
            },
            committingIsActive() {
                return this.state === 'Committing' || this.committedIsActive;
            },
            committedIsActive() {
                // Committed or any later state
                return ['Committed', 'Publishing', 'Published'].includes(this.state);
            }
        }
    };
</script>

<style scoped>
</style>
