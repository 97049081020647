<template>
    <vue-markdown
        class="markdown">
        # Query Help

        Common Library queries should be familiar to anyone that has used SQL, but it's simpler and differs in some areas.

        Simple query to get all records from a library:

        ```
        FROM LibraryName
        ```

        All available fields will be included by default.

        A more advanced exmaple:

        ```
        FROM Library1
        TAKE 1000
        SELECT Name AS Code, Description
        WHERE IsValid = true
        AND Scope = "JSV"
        JOIN Category
        SELECT Name AS CategoryName
        ```

        ## Select fields

        ```
        SELECT Field1, Field2, Field3
        ```

        Return field by another name:

        ```
        SELECT Name AS SomethingElse
        ```

        Look at the UML to see what fields a library has.

        ## Ordering

        ```
        ORDER BY Name1, Name2 DESC
        ```

        You may order the result from any selected field. When a field has an alias, use the alias in the `order by` clause.

        ## Conditions

        Conditions has the form:

        ```
        fieldname operator value
        ```

        The supported operators are:

        - `=` (equal)
        - `!=` (not equal)
        - `>` (greater than)
        - `&lt;` (less than)
        - `>=` (greater than or equal)
        - `&lt;=` (less than or equal)
        - `IN` (equal one of)
        - `LIKE` (wildcard match, SQL syntax)
        - `is null` (ignores value)
        - `is not null` (ignores value)

        More operators will be supported in the future.

        To get all valid records (`IsValid` is an attribute of all libraries):

        ```
        WHERE IsValid = true
        ```

        Use `AND`, `OR` and parenthesis to combine conditions:

        ```
        WHERE Material = "Wood"
        AND (Length = 10 OR Height = 10)
        AND Area IN (50, 100, 150)
        ```

        ## Parameters

        The *value* part of a condition can be a parameter; a name that starts with a `@` character:

        ```
        WHERE Scope = @facility
        ```

        In this example `@facility` turns into a parameter for the query. The Query Builder interface will provide a separate field to supply a value. If the value is not specified, the condition will be ignored when the query is executed.

        ## Limit query

        Use `TAKE` to limit the number of records returned. Use `SKIP` to skip over records. Combine them to do pagination.

        ```
        FROM LibraryName TAKE 100 SKIP 0
        ```

        ## Join referred libraries

        If the library you query has references to other libraries you may join in data from the relation. Joins must be placed at the end of a query specification and has the form:

        ```
        JOIN CodeRefName
        ... sub query
        END
        ```

        `CodeRefName` is the name of the referring attribute on the library you query, and *not* the name of the related library.

        The *sub query* follows the same rules as the main query, and can contain a `SELECT` statement to select fields, a `WHERE` statement to specify conditions, and one or more `JOIN` statements to traverse further library references.

        Field names selected must be unique across the entire query (including joins). Use aliasing (`AS` keyword) to resolve naming conflicts.

        The `END` keyword is only required if you want to add more than one join on the same level.

        ## Read more..

        For more documentation related to queries and library views, see [Help &raquo; Query spec](/Help/query).
    </vue-markdown>
</template>

<script>
    import VueMarkdown from '@/shared/components/VueMarkdown.js';

    export default {
        components: {
            VueMarkdown
        }
    };
</script>

<style scoped>
    .markdown :deep(> h1) {
        font-size: 120%;
        font-weight: bold;
        margin-bottom: 5px;
    }
    .markdown :deep(> h2) {
        font-size: 110%;
        font-weight: bold;
        margin-top: 8px;
        margin-bottom: 4px;
    }
    .markdown :deep(> p) {
        font-size: 85%;
        margin-top:5px;
        margin-bottom: 2px;
    }
    .markdown :deep(> pre) {
        padding: 0.1rem 0.5rem;
        font-size: 90%;
        margin-bottom:5px;
    }
    .markdown :deep(> ul) {
        list-style-type: disc;
        list-style-position: inside;
        margin-bottom:10px;
        padding-left:20px;
    }
    .markdown :deep(> ol) {
        list-style-position: inside;
        margin-bottom:10px;
        margin-left:20px;
    }
</style>
