<template>
    <b-dropdown
        :append-to-body="true"
        :trigger-tabindex="-1"
        :position="scopeFilterPosition"
        :mobile-modal="false"
        aria-role="list">
        <template #trigger>
            <b-button
                :disabled="store.scopeFilterLoading"
                :loading="store.scopeFilterLoading"
                :icon-left="store.filterIcon"
                class="button is-info is-dropdown-trigger">
                {{ store.scopeFilterLabel }}
            </b-button>
        </template>
        <b-dropdown-item custom>
            <b-field
                class="scope-filter-header"
                position="is-centered">
                <b-button
                    @click="store.clearScopeFilter">
                    Clear selected
                </b-button>
            </b-field>
        </b-dropdown-item>
        <b-dropdown-item separator />
        <b-dropdown-item custom>
            <div class="scope-column-container">
                <div
                    v-for="(scope, idx) in store.scopes"
                    :key="idx"
                    class="scope-column-item">
                    <p class="control is-pulled-left">
                        <b-checkbox
                            :model-value="store.selectedScopeFilter.includes(scope)"
                            @input="store.updateScopeFilter(scope)">
                            {{ scope }}
                        </b-checkbox>
                    </p>
                </div>
            </div>
        </b-dropdown-item>
    </b-dropdown>
</template>

<script setup>
    import { useScopeFilterStore } from '@/stores/scopeFilterStore.js';
    import { onMounted } from 'vue';

    const props = defineProps({
        library: {
            type: Object,
            required: true
        },
        scopeFilterPosition: {
            type: String,
            default: 'is-bottom-right'
        }
    });

    const store = useScopeFilterStore();

    onMounted(async () => {
        if (props.library.scopeType) {
            await store.setScopeType(props.library.scopeType);
        }
        await store.init();
    });
</script>
<style scoped>
.scope-column-container {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(auto-fit, 20px);
    height: 520px;
    margin: 5px;
    width: max-content;
}
.dropdown-item {
    max-width: 80vw;
    overflow: auto;
}
</style>
