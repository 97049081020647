<template>
    <div class="menu">
        <template v-if="filteredItems.length > 0">
            <top-sub-menu-item
                v-for="item in filteredItems"
                :key="item.text"
                :item="item" />
        </template>
        <template v-else>
            <p class="no-content">
                <span>No content</span>
                <b-icon
                    pack="fa"
                    icon="face-frown"
                    size="is-small"
                    style="margin-left: 0.33em" />
            </p>
        </template>
    </div>
</template>

<script>
    import TopSubMenuItem from './TopSubMenuItem.vue';
    import { computePosition } from '@floating-ui/vue';

    const TopSubMenu = {
        name: 'TopSubMenu',
        components: { TopSubMenuItem },
        props: {
            items: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                permissions: new Set()
            };
        },
        async created() {
            this.permissions = new Set(await window.authService.getPermissions());
        },
        computed: {
            filteredItems() {
                return this.items.filter(this.isAccessible);
            }
        },
        methods: {
            isAccessible(item) {
                return item.permissions.some(p => this.permissions.has(p));
            },
            makeFloating(reference, trigger, options) {
                const menu = this.$el;
                async function update() {
                    const { x, y } = await computePosition(reference, menu, options);
                    Object.assign(menu.style, {
                        left: `${x}px`,
                        top: `${y}px`
                    });
                }

                function showMenu() {
                    menu.style.display = 'block';
                    void update();
                }

                function hideMenu() {
                    menu.style.display = '';
                }

                [
                    ['mouseenter', showMenu],
                    ['mouseleave', hideMenu],
                    ['focus', showMenu],
                    ['blur', hideMenu]
                ].forEach(([event, listener]) => {
                    trigger.addEventListener(event, listener);
                });

                void update();
            }
        }
    };

    TopSubMenuItem.components['TopSubMenu'] = TopSubMenu;

    export default TopSubMenu;
</script>

<style scoped>
.menu {
    /* Use same styling as Bulma */
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 8px 8px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
    padding: 0.5rem 0;

    /* Make menu float. Position will be overridden by Floating-UI */
    z-index: 20;
    width: max-content;
    position: absolute;
    top: 0;
    left: 0;

    /* Make menu hidden by default */
    display: none;
}

.no-content {
    color: #949494;
    display: flex;
    align-items: center;
    padding: 0.175rem 1.1rem;
}
</style>
