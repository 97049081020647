<template>
    <div v-require-is-administrator>
        <spinner
            v-if="loading"
            :loading="loading" />
        <div v-else>
            <h2 class="title is-3">
                Access Policy
            </h2>
            <b-tabs type="is-boxed">
                <b-tab-item label="Library Policy">
                    <library-policy-tab :systems-and-roles="systemsAndRoles" />
                </b-tab-item>
                <b-tab-item label="User Attributes">
                    <user-attributes-tab :systems-and-roles="systemsAndRoles" />
                </b-tab-item>
            </b-tabs>
        </div>
    </div>
</template>

<script>
    import Spinner from '@/shared/components/Spinner.vue';
    import { requireIsAdministrator } from '@/shared/directives/requirePermission';
    import { getAllSystemsAndRoles } from '@/shared/helpers/api';
    import LibraryPolicyTab from './LibraryPolicyTab.vue';
    import UserAttributesTab from './UserAttributesTab.vue';

    export default {
        name: 'AccessPolicy',
        directives: {
            'require-is-administrator': requireIsAdministrator
        },
        components: { UserAttributesTab, Spinner, LibraryPolicyTab },
        data: () => {
            return {
                loading: false,
                systemsAndRoles: {}
            };
        },
        async mounted() {
            this.loading = true;
            await this.loadSystemsAndRoles();
            this.loading = false;
        },
        methods: {
            async loadSystemsAndRoles() {
                this.systemsAndRoles = await getAllSystemsAndRoles(this);
            }
        }
    };
</script>
