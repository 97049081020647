<template>
    <div
        v-if="data.length"
        class="table-wrapper">
        <h4 class="subtitle is-4">
            {{ countName }}, number of: {{ data[0].MasterEntityTypeCount }}
        </h4>
        <div class="column-header">
            <div class="empty-box" />
            <div class="header-box entity-type">
                {{ headers.EntityType }}
            </div>
            <div class="header-box master">
                {{ headers.MasterEntityType }}
            </div>
            <div class="header-box entities-column">
                {{ headers.Entities }}
            </div>
        </div>
        <b-table
            :data="data"
            :striped="true">
            <b-table-column
                v-slot="props"
                sortable
                field="Description"
                label="Description">
                {{ props.row.Description }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                sortable
                numeric
                field="EntityTypesWithMasterEntityType"
                label="MappedCount">
                {{ props.row.EntityTypesWithMasterEntityType }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                sortable
                numeric
                field="EntityTypeCount"
                label="TotalCount">
                {{ props.row.EntityTypeCount }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                sortable
                centered
                field="PercentEntityTypeMapped"
                label="PercentMapped">
                <span
                    :class="
                        [
                            'tag',
                            'tag-percent',
                            'is-medium',
                            percentTagClass(props.row.PercentEntityTypeMapped)
                        ]">
                    {{ percentFormat(props.row.PercentEntityTypeMapped) }}
                </span>
            </b-table-column>
            <b-table-column
                v-slot="props"
                sortable
                numeric
                field="MasterEntitiesUsed"
                label="MappedCount">
                {{ props.row.MasterEntitiesUsed }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                sortable
                centered
                field="PercentMasterEntitiesUsed"
                label="PercentMapped">
                <span
                    :class="
                        [
                            'tag',
                            'tag-percent',
                            'is-medium',
                            percentTagClass(props.row.PercentMasterEntitiesUsed)
                        ]">
                    {{ percentFormat(props.row.PercentMasterEntitiesUsed) }}
                </span>
            </b-table-column>
            <b-table-column
                v-slot="props"
                sortable
                numeric
                field="STIDEntitiesWithMasterEntityType"
                label="MappedCount">
                {{ props.row.STIDEntitiesWithMasterEntityType }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                sortable
                numeric
                field="STIDEntityCount"
                label="TotalCount">
                {{ props.row.STIDEntityCount }}
            </b-table-column>
            <template #footer>
                <th class="is-hidden-mobile">
                    <div class="th-wrap">
                        Totals
                    </div>
                </th>
                <th class="is-hidden-mobile">
                    <div class="has-text-right">
                        {{ totals.EntityTypesWithMasterEntityType }}
                    </div>
                </th>
                <th class="is-hidden-mobile">
                    <div class="has-text-right">
                        {{ totals.EntityTypeCount }}
                    </div>
                </th>
                <th class="is-hidden-mobile" />
                <th class="is-hidden-mobile">
                    <div class="has-text-right">
                        {{ totals.MasterEntitiesUsed }}
                    </div>
                </th>
                <th class="is-hidden-mobile" />
                <th class="is-hidden-mobile">
                    <div class="has-text-right">
                        {{ totals.STIDEntitiesWithMasterEntityType }}
                    </div>
                </th>
                <th class="is-hidden-mobile">
                    <div class="has-text-right">
                        {{ totals.STIDEntityCount }}
                    </div>
                </th>
            </template>
        </b-table>
    </div>
</template>

<script>
    export default {
        props: {
            data: { type: Array, required: true },
            countName: { type: String, required: true },
            headers: { type: Object, required: true }
        },
        computed: {
            totals() {
                return {
                    EntityTypesWithMasterEntityType: this.data.reduce((total, item) => total + item['EntityTypesWithMasterEntityType'], 0),
                    EntityTypeCount: this.data.reduce((total, item) => total + item['EntityTypeCount'], 0),
                    MasterEntitiesUsed: this.data.reduce((total, item) => total + item['MasterEntitiesUsed'], 0),
                    STIDEntitiesWithMasterEntityType: this.data.reduce((total, item) => total + item['STIDEntitiesWithMasterEntityType'], 0),
                    STIDEntityCount: this.data.reduce((total, item) => total + item['STIDEntityCount'], 0)
                };
            }
        },
        methods: {
            percentFormat(percent) {
                if (percent || percent === 0) {
                    return percent + '%';
                }
            },
            percentTagClass(percent) {
                if (percent === 0) {
                    return 'none-mapped';
                } else if (percent > 0 && percent < 50) {
                    return 'some-mapped';
                } else if (percent >= 50 && percent < 100) {
                    return 'most-mapped';
                } else {
                    return 'all-mapped';
                }
            }
        }
    };
</script>

<style scoped>
.tag.tag-percent {
    font-weight: bold;
    width: 100px;
}

.tag.all-mapped {
    background-color: #A1F28E;
    color: #111;
}

.tag.most-mapped {
    background-color: #E8F27D;
    color: #111;
}

.tag.some-mapped {
    background-color: #F2C1B0;
    color: #111;
}

.tag.none-mapped {
    background-color: #F45D5D;
    color: #eee;
}

.table-wrapper {
    display: flex;
    flex-shrink: 1;
    justify-content: start;
    flex-direction: column;
}

.column-header {
    display: flex;
    align-items: center;
}

.empty-box {
    width: 15%;
    height: 27px;
}

.header-box {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #111;
    border-bottom-style: solid;
    border-bottom-color: darkgray;
    border-bottom-width: thin;
}

.entity-type {
    width: 36%;
    margin-right: 4px;
}

.master {
    width: 26%;
    margin-right: 4px;
}

.entities-column {
    width: 22%;
}
</style>
