<template>
    <div class="box">
        <h2 class="title is-5">
            Publish filter
        </h2>
        <table
            class="table is-narrow is-hoverable is-fullwidth">
            <thead>
                <tr>
                    <th>Library</th>
                    <th>Facilities</th>
                    <th>Filter</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(lib, idx) in libs"
                    :key="idx">
                    <td>{{ lib }}</td>
                    <td>{{ facilities }}</td>
                    <td>{{ filters }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        props: {
            spec: {
                type: Object,
                default: () => {}
            }
        },
        computed: {
            libs() {
                return this.spec ? this.spec.libraryNames : [];
            },
            facilities() {
                return this.spec ? this.spec.facilityNames.join() : '';
            },
            filters() {
                const filters = [];
                if (this.spec.isValid) {
                    filters.push(`IsValid = ${this.spec.isValid}`);
                }
                if (this.spec.updatedSince) {
                    const dateTimeString = this.$filters.dateFormatLong(this.spec.updatedSince);
                    filters.push(`DateUpdated ≥ ${dateTimeString}`);
                }
                return filters.join(', ');
            }
        }
    };
</script>

<style>

</style>
