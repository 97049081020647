<template>
    <div>
        <div v-if="facility">
            <h1 class="subtitle">
                Facility
            </h1>
            <h1 class="title">
                {{ facility.name }} - {{ facility.description }}
            </h1>
            <router-link :to="{ name: 'FacilityList'}">
                &laquo; Back to list
            </router-link>
            <hr>
            <span
                id="codeEditor">
                <div
                    v-if="showEditor">
                    <code-edit
                        :key="editorKey"
                        :quick-commit="true"
                        :show-cancel="true"
                        :code-set-id="editCodeSetId"
                        :code-id="editCodeId"
                        :code-template-values="codeTemplateValues"
                        :form-title="formTitle"
                        :library="editLibrary"
                        @refresh="onSave"
                        @cancel="cancelEditor" />
                    <hr>
                </div>
            </span>
            <div class="columns">
                <div class="column is-6">
                    <div class="panel">
                        <div class="panel-heading is-flex is-justify-content-space-between">
                            <p>
                                Facility attributes
                            </p>
                            <b-button
                                v-require-can-edit-code="{ libraryName: 'Facility' }"
                                class="is-primary"
                                @click="editFacility">
                                <b-icon
                                    icon="pencil"
                                    size="is-small" />
                                <span>
                                    Edit
                                </span>
                            </b-button>
                        </div>
                        <div class="panel-block">
                            <b-table
                                class="headless-table"
                                :data="attributes"
                                :striped="false"
                                :narrowed="true"
                                :hoverable="false">
                                <b-table-column
                                    v-slot="props"
                                    field="name"
                                    label="Name">
                                    <b>{{ props.row.name }}</b>
                                </b-table-column>
                                <b-table-column
                                    v-slot="props"
                                    field="value"
                                    label="Value">
                                    {{ props.row.value }}
                                </b-table-column>
                            </b-table>
                        </div>
                    </div>
                </div>
                <div class="column is-6">
                    <nav class="panel">
                        <div class="panel-heading is-flex is-justify-content-space-between">
                            <p>
                                ProCoSys
                            </p>
                            <b-button
                                v-require-can-edit-code="{ libraryName: 'ApplicationProCoSys' }"
                                class="is-primary"
                                @click="editProCoSys">
                                <b-icon
                                    icon="pencil"
                                    size="is-small" />
                                <span>
                                    Edit
                                </span>
                            </b-button>
                        </div>
                        <div class="panel-block">
                            <b-table
                                class="headless-table"
                                :data="applicationProCoSysViewModel.properties"
                                default-sort="name"
                                :striped="false"
                                :narrowed="true"
                                :hoverable="false">
                                <b-table-column
                                    v-slot="props"
                                    field="name"
                                    label="Name">
                                    {{ props.row.name }}:
                                </b-table-column>
                                <b-table-column
                                    v-slot="props"
                                    field="value"
                                    label="Value">
                                    {{ props.row.value }}
                                </b-table-column>
                            </b-table>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import CodeEdit from '@/shared/components/CodeEdit.vue';
    import { showMixin } from '@/shared/mixins/showMixin';
    import { decodeIdBase64, encodeIdBase64 } from '@/shared/helpers/utils';
    import { genericViewQueryAsText, getAccessGroupForLibrary, getCodeById, getLibrary } from '@/shared/helpers/api';
    import { requireCanEditCode } from '@/shared/directives/requirePermission';

    export default {
        directives: {
            'require-can-edit-code': requireCanEditCode
        },
        components: {
            CodeEdit
        },
        mixins: [
            showMixin
        ],
        props: {
            id: {
                default: null,
                type: String
            }
        },
        data() {
            return {
                showEditor: false,
                formTitle: null,
                editorKey: 1,
                editLibrary: null,
                editCodeSetId: null,
                editCodeId: null,
                codeTemplateValues: null,
                facility: null,
                applicationProCoSys: null,
                loading: true
            };
        },
        computed: {
            attributes() {
                if (this.facility && !this.loading) {
                    const attrs = _.sortBy(this.facility.attributes, x => x.attributeDefinition.sequenceNumber).map(function(x) {
                        return { name: x.name, value: x.value };
                    });
                    attrs.unshift({ name: 'IsValid', value: this.facility.isValid ? 'True' : 'False' });
                    return attrs;
                }
                return [];
            },
            applicationProCoSysViewModel() {
                if (this.facility && this.applicationProCoSys && !this.loading) {
                    const facilityIdentity = this.facility.identity;
                    const code = this.applicationProCoSys.codeSets[0].codes[0];

                    if (code && code.Name === facilityIdentity) {
                        const referenceName = [
                            { name: 'Reference name', value: code.Name },
                            { name: 'Facility', value: code.Facility }
                        ];
                        const projectSchema = code.ProjectSchema ? [{ name: 'ProjectSchema', value: code.ProjectSchema }] : [];
                        return {
                            id: encodeIdBase64('Code', code.Id),
                            properties: _.concat(referenceName, projectSchema)
                        };
                    }
                }
                return {
                    properties: []
                };
            }
        },
        async mounted() {
            await this.fetchFacilityCode();
            this.applicationProCoSys = await getLibrary(this, 'ApplicationProCoSys');
            await this.fetchApplicationProCoSysCode();
            this.loading = false;
        },
        methods: {
            async fetchFacilityCode() {
                const tempAccessGroups = this.facility?.codeSet.library.accessGroups;
                const codeId = decodeURIComponent(this.$route.params.id);
                const decodedId = decodeIdBase64(codeId);
                this.facility = await getCodeById(this, decodedId);
                this.facility.id = codeId;
                if (tempAccessGroups) { // Avoiding to fetch when we already have this
                    this.facility.codeSet.library.accessGroups = tempAccessGroups;
                } else {
                    this.facility.codeSet.library.accessGroups = await getAccessGroupForLibrary(this, this.facility.codeSet.library.name);
                }
                this.mapFacilityAttributes();
            },
            async fetchApplicationProCoSysCode() {
                this.applicationProCoSys.codeSets[0].codes = (await genericViewQueryAsText(
                    this,
                    `FROM ApplicationProCoSys
                        SELECT Id, Name, Facility, ProjectSchema
                        WHERE Facility = @facility`,
                    [{ name: '@facility', value: this.facility.name }]
                )).data;
            },
            mapFacilityAttributes() {
                this.facility.attributes.map(attr => {
                    const thisAttrDef = this.facility.codeSet.library.attributeDefinitions.find(attrDef => attrDef.name === attr.definitionName);
                    attr.name = attr.definitionName;
                    attr.value = attr.displayValue;
                    attr.type = thisAttrDef.attributeType.toUpperCase();
                    attr.attributeDefinition = {
                        'sequenceNumber': thisAttrDef.sequenceNumber
                    };
                });
            },
            editFacility() {
                this.formTitle = null;
                this.editCodeSetId = null;
                this.editCodeId = this.facility.id;
                this.editLibrary = 'Facility';
                this.editorKey += 1;
                this.showEditor = true;
            },
            editProCoSys() {
                this.formTitle = 'ApplicationProCoSys';
                this.editCodeSetId = this.applicationProCoSys.id;
                this.editCodeId = this.applicationProCoSysViewModel.id;
                this.codeTemplateValues = {
                    'Facility': this.facility.identity,
                    'name': this.facility.identity
                };
                this.editLibrary = 'ApplicationProCoSys';
                this.editorKey += 1;
                this.showEditor = true;
            },
            async onSave() {
                this.showEditor = false;
                if (this.editLibrary === 'Facility') {
                    await this.fetchFacilityCode();
                } else if (this.editLibrary === 'ApplicationProCoSys') {
                    await this.fetchApplicationProCoSysCode();
                }
            },
            cancelEditor() {
                this.showEditor = false;
            }
        }
    };
</script>
