<template>
    <div>
        <spinner :loading="loading">
            <div class="buttons-container">
                <div
                    v-require-can-edit-code="{ libraryName: 'SchemaMapAttribute' }">
                    <b-button
                        type="is-primary"
                        :disabled="!canSave"
                        @click="clickSaveButton">
                        <b-icon
                            icon="floppy"
                            size="is-small" />
                        <span>Save</span>
                    </b-button>
                    <b-button
                        :disabled="!canSave"
                        @click="clickCancel">
                        <b-icon
                            icon="close-octagon-outline"
                            size="is-small" />
                        <span>{{ creatingNew ? 'Cancel' : 'Undo' }}</span>
                    </b-button>
                    <b-tooltip
                        label="SchemaMapAttribute library is configured locked for deletions"
                        position="is-left"
                        :animated="true"
                        :delay="200"
                        :multilined="true"
                        :active="lockedForDelete">
                        <b-button
                            v-show="!creatingNew"
                            type="is-danger"
                            :disabled="lockedForDelete || creatingNew"
                            @click="() => confirmDeleteCode()">
                            <b-icon
                                icon="skull"
                                size="is-small" />
                            <span>Delete</span>
                        </b-button>
                    </b-tooltip>
                </div>
            </div>
            <div
                v-for="(prop, index) in mappingFiltered"
                :key="`${prop.attributeName}_${index}`">
                <div class="input-field">
                    <div class="input-field-label">
                        <label class="label pad-top">
                            <b-tooltip
                                class="tooltip"
                                :label="prop.description"
                                :multilined="true"
                                :active="prop.description !== null"
                                position="is-top">
                                <span>{{ prop.displayAs || prop.attributeName }}:</span>
                            </b-tooltip>
                        </label>
                    </div>
                    <div class="field-body">
                        <div class="field is-grouped has-addons">
                            <div class="control is-expanded">
                                <template v-if="prop.attributeName === 'IsBidirectional'">
                                    <b-switch
                                        v-model="mappingForm.IsBidirectional" />
                                </template>
                                <template v-else>
                                    <b-input
                                        v-model="mappingForm[prop.attributeName]"
                                        class="input-width"
                                        :disabled="prop.attributeName === 'Name'" />
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <b-message
                title="Mapping of attribute value (optional)"
                type="is-light"
                :closable="false">
                <schema-attribute-mapping-editor-form-value-map
                    v-if="mappingForm"
                    :mapping="mappingForm"
                    :schema1-attributes="schema1Attributes"
                    :definitions="attributeDefinitions"
                    @valueMapFormData="readValueMapFormData" />
                <br>
                Sometimes, some or all values of an attribute need to be translated to other values. The parameters for such a process you describe here. The ContextAttributes are optional, only to be specified if you need more than the source attribute value to hit uniquely into your mapping library.
            </b-message>
        </spinner>
    </div>
</template>

<script>
    import Spinner from '@/shared/components/Spinner.vue';
    import { requireCanEditCode } from '@/shared/directives/requirePermission';
    import { createChangeDocAndCommit, deleteCode } from '@/shared/helpers/api';
    import { showMixin } from '@/shared/mixins/showMixin';
    import _ from 'lodash';
    import SchemaAttributeMappingEditorFormValueMap from './SchemaAttributeMappingEditorFormValueMap.vue';

    export default {
        directives: {
            'require-can-edit-code': requireCanEditCode
        },
        components: {
            SchemaAttributeMappingEditorFormValueMap,
            Spinner
        },
        mixins: [
            showMixin
        ],
        props: {
            mapping: {
                default() {
                    return {
                        'Id': null,
                        'Name': null,
                        'Identity': null,
                        'IsValid': true,
                        'Description': null,
                        'Schema1': null,
                        'Schema1_ID': null,
                        'Attribute1': null,
                        'Attribute1_ID': null,
                        'Schema2': null,
                        'Schema2_ID': null,
                        'Attribute2': null,
                        'Attribute2_ID': null,
                        'Comments': null,
                        'InternalComments': null,
                        'IsBidirectional': false,
                        'ValueMapBehaviour': null,
                        'ValueMapBehaviour_ID': null,
                        'ValueMapLibrary': null,
                        'ValueMapSet': null,
                        'ValueMapColumn1': null,
                        'ValueMapColumn2': null,
                        'ValueMapSetColumn': null,
                        'ValueMapContextAttribute1': null,
                        'ValueMapContextAttribute1_ID': null,
                        'ValueMapContextAttribute2': null,
                        'ValueMapContextAttribute2_ID': null,
                        'ValueMapContextColumn1': null,
                        'ValueMapContextColumn2': null
                    };
                },
                type: Object
            },
            libraryDefinitions: {
                default: null,
                type: Object
            },
            schema1Attributes: {
                default: () => [],
                type: Array
            },
            creatingNew: {
                default: false,
                type: Boolean
            }
        },
        emits: [
            'refresh',
            'clickCancel'
        ],
        data() {
            return {
                mappingForm: {},
                mappingFiltered: null,
                originalMappingForm: {},
                loading: false,
                attributeDefinitions: [],
                lockedForDelete: true
            };
        },
        computed: {
            canSave() {
                if (this.creatingNew) {
                    return true;
                }
                return !_.isEqual(this.mappingForm, this.originalMappingForm);
            }

        },
        watch: {
            mapping() {
                this.mappingForm = { ... this.mapping };
            },
            'mappingForm.Comments': function(newValue) {
                if (!newValue) {
                    this.mappingForm.Comments = null;
                }
            },
            'mappingForm.InternalComments': function(newValue) {
                if (!newValue) {
                    this.mappingForm.InternalComments = null;
                }
            },
            'mappingForm.Description': function(newValue) {
                if (!newValue) {
                    this.mappingForm.Description = null;
                }
            }
        },
        async mounted() {
            this.mappingForm = { ... this.mapping };
            this.lockedForDelete = this.libraryDefinitions.codeSets[0].lockedForDelete;
            this.attributeDefinitions = this.libraryDefinitions.attributeDefinitions;
            this.mappingFiltered = Object.entries(await this.getFilteredMapping()).map(([key, value]) => {
                return {
                    attributeName: key,
                    attributeValue: value,
                    description: this.getDefinitions(key),
                    displayAs: this.getDefinitions(key, true)
                };
            });
            this.originalMappingForm = { ...this.mappingForm };
        },
        methods: {
            async getFilteredMapping() {
                const displayFilter = ['Name', 'Description', 'Comments', 'InternalComments', 'PassOnUnmappedAttributes', 'IsBidirectional'];
                return Object.keys(this.mappingForm)
                    .filter(key => displayFilter.includes(key))
                    .reduce((obj, key) => {
                        obj[key] = this.mappingForm[key];
                        return obj;
                    }, {});
            },
            async clickSaveButton() {
                this.loading = true;
                const code = this.makeCodeReadyForSave();
                try {
                    await createChangeDocAndCommit(this, this.libraryDefinitions.codeSets[0].name, [code]); //
                } catch (error) {
                    this.showError(error);
                }
                this.$emit('refresh');
                this.loading = false;
            },
            clickCancel() {
                this.$emit('clickCancel');
            },
            readValueMapFormData(formData) {
                const idAttributes = ['ValueMapContextAttribute1', 'ValueMapContextAttribute2', 'ValueMapBehaviour'];
                Object.entries(formData).forEach(([attributeName, attributeValue]) => {
                    if (idAttributes.includes(attributeName)) {
                        this.mappingForm[`${attributeName}_ID`] = attributeValue?.Id ?? null;
                        this.mappingForm[attributeName] = attributeValue?.Identity ?? null;
                    } else {
                        this.mappingForm[attributeName] = attributeValue;
                    }
                });
            },
            makeCodeReadyForSave() {
                const filteredMappingForm = {};
                Object.entries(this.mappingForm).forEach(([attrName, value]) => {
                    if ((value === null || value === undefined) && attrName !== 'Identity') {
                        filteredMappingForm[attrName] = '{null}';
                    } else if (value !== null && value !== undefined) {
                        filteredMappingForm[attrName] = value;
                    }
                });
                return filteredMappingForm;
            },
            getDefinitions(key, displayAs = false) {
                const definition = this.libraryDefinitions.attributeDefinitions?.find(x => x.name === key);
                return definition ? displayAs ? definition.displayAs : definition.description : null;
            },
            confirmDeleteCode() {
                this.$buefy.dialog.confirm({
                    title: 'Please confirm delete',
                    message: `Deleting items may cause issues if the item is known externally to Common Library,
                        and should normally only be done during initial setup of libraries. Continue to delete item?`,
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: async () => {
                        await this.deleteCode();
                    }
                });
            },
            async deleteCode() {
                try {
                    const codeset = this.libraryDefinitions.codeSets[0].name;
                    const codeIdentity = this.mapping.Identity;

                    await deleteCode(this, codeset, codeIdentity);

                    this.$emit('refresh');
                } catch (error) {
                    this.showError(_.get(error, 'response.data.Message', 'Unable to delete item'));
                }
            }

        }
    };
</script>

<style scoped>
.pad-top {
    padding-top: 15px;
}
.buttons-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: stretch;
    align-content: stretch;
}
.input-field {
    display: flex;
    height: 40px;
    margin-top: 0.5rem;
    align-items: center;
}
.input-field-label {
    height: 100%;
    width: 180px;
    text-align: right;
    margin-right: 1rem;
}
</style>
