<template>
    <b-dropdown
        :disabled="disabled"
        class="dropdownForm"
        :triggers="['click']"
        aria-role="list"
        :scrollable="isScrollable"
        :max-height="400">
        <template #trigger>
            <b-button
                :label="$filters.truncate(dropdownSelectedLabel, 30)"
                icon-right="menu-down"
                :type="buttonType"
                :loading="isLoading" />
        </template>
        <b-dropdown-item>
            <b-button
                type="is-text"
                @click="setSelected(null)">
                Clear selected
            </b-button>
        </b-dropdown-item>
        <b-dropdown-item
            v-for="(alternative, index) in alternatives"
            :key="index"
            aria-role="listitem"
            @click="setSelected(alternative)">
            <span :title="displayProperty ? alternative[displayProperty] : alternative">
                {{ $filters.truncate(displayProperty ? alternative[displayProperty] : alternative, 30) }}
            </span>
        </b-dropdown-item>
    </b-dropdown>
</template>

<script>
    export default {
        props: {
            alternatives: {
                type: Array,
                default: () => []
            },
            disabled: {
                type: Boolean,
                default: false
            },
            displayProperty: {
                type: String,
                default: null
            },
            selected: {
                type: null,
                default: null
            },
            isScrollable: {
                type: Boolean,
                default: false
            },
            isLoading: {
                type: Boolean,
                default: false
            },
            buttonType: {
                type: String,
                default: 'is-default'
            },
            noneSelectedText: {
                type: String,
                default: 'None selected'
            },
            selectedTextPrefix: {
                type: String,
                default: null
            }
        },
        emits: [
            'setSelected'
        ],
        data() {
            return {
                internalSelected: null
            };
        },
        computed: {
            dropdownSelectedLabel() {
                if (this.internalSelected) {
                    const selectedText = this.internalSelected[this.displayProperty] ?? this.internalSelected;
                    return this.selectedTextPrefix ? `${this.selectedTextPrefix} ${selectedText}` : selectedText;
                } else return this.noneSelectedText;
            }
        },
        watch: {
            selected(initSelected) {
                this.internalSelected = initSelected;
            }
        },
        mounted() {
            this.internalSelected = this.selected;
        },
        methods: {
            setSelected(newSelected) {
                this.internalSelected = newSelected;
                this.$emit('setSelected', newSelected);
            }
        }
    };
</script>

<style scoped>

</style>
