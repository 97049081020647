<template>
    <div>
        <div
            v-if="hasSelectedScope">
            <slot name="toolbar" />
            <div
                class="columns">
                <div
                    :class="columnWidthOfOneThird">
                    <slot
                        name="picker" />
                </div>
                <div
                    v-if="isFormActive"
                    class="column">
                    <div
                        v-if="existsCodeSetForScope"
                        class="box">
                        <h1 class="title center-large-text">
                            {{ codeEditTitle }}
                        </h1>
                        <slot name="form" />
                    </div>
                    <b-message
                        v-else
                        type="is-danger"
                        has-icon>
                        Missing code set
                    </b-message>
                </div>
            </div>
        </div>
        <p v-else>
            <b-message>
                Please select a scope.
            </b-message>
        </p>
    </div>
</template>

<script>
    export default {
        props: {
            hasSelectedScope: {
                type: Boolean,
                required: true
            },
            isFormActive: {
                type: Boolean,
                required: true
            },
            existsCodeSetForScope: {
                type: Boolean,
                required: true
            },
            codeEditTitle: {
                type: String,
                required: true
            },
            oneThird: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            columnWidthOfOneThird() {
                return this.oneThird ? 'column is-one-third' : 'column';
            }
        }
    };
</script>
<style scoped>

</style>
