import { genericViewQueryAsText } from './api.ts';

export const libNames = {
    cableCat: 'CableCategory',
    cableCode: 'CableCode',
    cableCodeSheeth: 'CableCodeSheath',
    cableCodeType: 'CableCodeType',
    cableCrossSectionUnit: 'CableCrossSectionUnit',
    cableScreen: 'CableScreen',
    cableSheathColor: 'CableSheathColour',
    cableSize: 'CableSize',
    cableSpec: 'CableSpec',
    cableType: 'CableType',
    cableWireColour: 'CableWireColour',
    cableWireGroup: 'CableWireGroup',
    cableSpecWire: 'CableSpecWire'
};

export const createSpecTemplate = (template, category, cableCodeId) => {
    const temp = JSON.parse(JSON.stringify(template));
    temp.meta.isNew = true;
    temp.values.isValid = true;
    temp.values.GroundFlag = 'False';
    temp.values.CableCode = cableCodeId;
    if (category.values.NumberOfGroupsFlag === false)
        temp.values.NumberOfGroups = 1;
    if (category.values.NumberOfWiresFlag === false)
        temp.values.NumberOfWires = 1;
    if (category.values.GroundFlag === 'False')
        temp.values.GroundFlag = 'False';
    return temp;
};

export const createCableCodeTableRow = (cableCode, cableCats, cableScreens) => {
    return {
        meta: cableCode.meta,
        name: cableCode.values.name,
        identity: cableCode.values.identity,
        description: cableCode.values.description,
        isValid: cableCode.values.isValid,
        Screen: cableScreens.find(s => s.values.identity === cableCode.values.Screen)?.values.description,
        CableCategory: cableCats.find(cat => cat.values.identity === cableCode.values.CableCategory)?.values.description,
        MudResistant: cableCode.values.MudResistant
    };
};

export const createSpecShortName = (spec, category) => {
    const numberOfGroups = category.values.NumberOfGroupsFlag ? spec.values.NumberOfGroups : '';
    const numberOfWires = category.values.NumberOfWiresFlag ? spec.values.NumberOfWires : '';
    const crosssec = category.values.CrossSectionFlag ? spec.values.CrossSectionSize.replace('|', ' ') : '';
    const groundE = category.values.GroundFlag && spec.values.GroundFlagValue === 'E' ? '+E' : '';
    const groundG = category.values.GroundFlag && spec.values.GroundFlagValue === 'G' ? 'G' : 'x';
    const braid = category.values.BraidCrossSectionFlag && spec.values.BraidCrossSection ? '/' + spec.values.BraidCrossSection : '';

    return `${numberOfGroups}${numberOfGroups !== '' && numberOfWires !== '' ? 'x' : ''}${numberOfWires}${groundG}${crosssec}${braid}${groundE}`;
};

export const createSpecCheckName = (spec, category) => {
    const numberOfGroups = category.values.NumberOfGroupsFlag ? spec?.values.NumberOfGroups : '';
    const numberOfWires = category.values.NumberOfWiresFlag ? spec?.values.NumberOfWires : '';
    const crosssec = category.values.CrossSectionFlag ? spec?.values.CrossSectionSize.replace('|', ' ') : '';
    const groundE = category.values.GroundFlag && spec?.values.GroundFlagValue === 'E' ? '+E' : '';
    const groundG = category.values.GroundFlag && spec?.values.GroundFlagValue === 'G' ? 'G' : 'x';
    const braid = category.values.BraidCrossSectionFlag && spec?.values.BraidCrossSection ? '/' + spec.values.BraidCrossSection : '';

    return `${numberOfGroups}${numberOfGroups !== '' && numberOfWires !== '' ? 'x' : ''}${numberOfWires}${groundG}${crosssec}${braid}${groundE}`;
};

export async function getCableSpecWire(context, selectedSpecId, scope) {
    const query = 'FROM CableSpecWire WHERE CableSpec = @CableSpec AND Scope = @Scope';
    const response = (await genericViewQueryAsText(
        context,
        query,
        [{ name: '@CableSpec', value: selectedSpecId }, { name: '@Scope', value: scope }]
    )).data;

    return response;
}

export async function getCableSpecs(context, cableCodeName, cableCodeId, scope) {
    // Name prefix matching seems to be the fastest way to get the CableSpecs. We also tried matching
    // on the `CableCode` and `CableCode_ID` columns, but they were both slower.
    const response = await genericViewQueryAsText(
        context,
        'FROM CableSpec WHERE Name LIKE @namePattern and Scope = @scope',
        [
            { name: '@namePattern', value: `${cableCodeName} %` },
            { name: '@scope', value: scope }
        ]
    );

    // Compensate for inaccuracy of prefix matching
    return response.data.filter(cableSpec => cableSpec.CableCode === cableCodeId);
}
