/**
 * @typedef {Object} MemoizeOptions
 * @property {number|undefined} timeout
 * @property {(function(...[*]): string)|undefined} cacheKeyFactory
 * @property {boolean|undefined} debug
 */

/**
 * @param {function} fn
 * @param {MemoizeOptions} [options]
 * @returns {function(...[*]): (*)}
 */
export function memoize(fn, options) {
    const cache = {};
    const cacheKeyFactory = options?.cacheKeyFactory ?? btoa;
    const debug = options?.debug ?? false;

    return (...args) => {
        const key = args.length > 0
            ? cacheKeyFactory(...args)
            : 'A' // When function arity is zero, cache will only have a single item
        ;

        if (key in cache) {
            if (debug) {
                // eslint-disable-next-line no-console
                console.log(key, 'memoize cache hit');
            }
            return cache[key];
        } else {
            if (debug) {
                // eslint-disable-next-line no-console
                console.log(key, 'memoize not cached');
            }
            const result = fn(...args);
            cache[key] = result;

            if (options?.timeout) {
                setTimeout(() => {
                    if (key in cache) {
                        if (debug) {
                            // eslint-disable-next-line no-console
                            console.log(key, 'memoize deleting cache');
                        }
                        delete cache[key];
                    }
                }, options.timeout);
            }

            return result;
        }
    };
}
