<script setup>
    import useShow from '@/shared/composables/useShow.js';
    import { ref, computed, onBeforeMount } from 'vue';
    import { featureNames } from '@/stores/disabledFeaturesStore.js';
    import { http } from '@/shared/httpWrapper.js';
    import {
        disabledFeaturesGetAll,
        disableFeaturesSave,
        disabledFeaturesDelete
    } from '@/shared/helpers/api';

    const { showError } = useShow();

    const features = ref([]);
    const selected = ref(null);

    const loadFeatures = async () => {
        features.value = await disabledFeaturesGetAll(http);
    };

    onBeforeMount(loadFeatures);

    const selectableFeatureNames = computed(() => {
        const existingFeatureNames = features.value.map(x => x.name);
        const result = Object.values(featureNames)
            .filter(x => !existingFeatureNames.includes(x))
            .toSorted();

        if (selected.value?.name) {
            result.unshift(selected.value.name);
        }

        return result;
    });

    function newClick() {
        selected.value = {
            id: 0,
            name: null,
            isDisabled: true,
            message: ''
        };
    }

    async function saveClick() {
        if (!selected.value.name) {
            showError('You have to select a feature!');
            return;
        }
        await disableFeaturesSave(http, selected.value);
        selected.value = null;
        await loadFeatures();
    }

    async function deleteClick() {
        await disabledFeaturesDelete(http, selected.value.id);
        selected.value = null;
        await loadFeatures();
    }

    function cancelClick() {
        selected.value = null;
    }
</script>

<template>
    <h1 class="title">
        Disabled features
    </h1>
    <hr>

    <div class="is-flex">
        <b-button
            v-show="!selected"
            class="ml-auto"
            label="New"
            icon-left="plus-circle-outline"
            type="is-primary"
            @click="newClick" />
    </div>

    <section
        v-if="selected"
        class="block">
        <b-field
            horizontal
            label="ID">
            {{ selected.id }}
        </b-field>
        <b-field
            horizontal
            label="Name">
            <b-select
                v-model="selected.name"
                placeholder="Select feature">
                <option
                    v-for="name in selectableFeatureNames"
                    :key="name"
                    :value="name">
                    {{ name }}
                </option>
            </b-select>
        </b-field>
        <b-field
            horizontal
            label="Feature is disabled">
            <b-switch v-model="selected.isDisabled">
                {{ selected.isDisabled }}
            </b-switch>
        </b-field>
        <b-field
            horizontal
            label="Message">
            <b-input
                v-model="selected.message"
                type="textarea" />
        </b-field>
        <b-field horizontal>
            <p class="control buttons">
                <b-button
                    label="Save"
                    icon-left="content-save"
                    type="is-primary"
                    @click="saveClick" />
                <b-button
                    label="Cancel"
                    icon-left="close-circle"
                    @click="cancelClick" />
                <b-button
                    v-show="selected.id > 0"
                    label="Delete"
                    icon-left="delete"
                    type="is-danger"
                    @click="deleteClick" />
            </p>
        </b-field>
    </section>

    <b-table
        v-model:selected="selected"
        :striped="true"
        :data="features"
        focusable>
        <b-table-column
            v-slot="props"
            field="id"
            label="ID"
            sortable>
            {{ props.row.id }}
        </b-table-column>
        <b-table-column
            v-slot="props"
            field="name"
            label="Name"
            sortable>
            {{ props.row.name }}
        </b-table-column>
        <b-table-column
            v-slot="props"
            field="isDisabled"
            label="Feature is disabled"
            sortable>
            {{ props.row.isDisabled }}
        </b-table-column>
        <b-table-column
            v-slot="props"
            field="message"
            label="Message to users"
            sortable>
            {{ props.row.message }}
        </b-table-column>
    </b-table>
</template>
