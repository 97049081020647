<template>
    <article
        class="tile is-child notification is-info flex-it"
        :class="{clickable: !remainingTags}"
        @click="!remainingTags ? redirect() : null">
        <p class="title">
            {{ !remainingTags ? tag.name : 'Others' }}
        </p>
        <template v-if="!remainingTags">
            <p class="subtitle">
                {{ tag.description }}
            </p>
            <figure
                v-if="tag.imageUrl && tag.promotionLevel == 2"
                class="image is-2by1 margin-top-auto">
                <img :src="tag.imageUrl">
            </figure>
        </template>
        <template v-else>
            <div class="content scroll-box">
                <ul
                    v-for="t in remainingTags"
                    :key="t.name">
                    <li
                        class="clickable is-size-5"
                        @click="redirect(t.name)">
                        {{ t.name }}
                    </li>
                </ul>
            </div>
        </template>
    </article>
</template>

<script>
    import { getLibraryListLink } from '@/shared/helpers/routing';
    export default {
        props: {
            tag: {
                type: Object,
                default: null
            },
            remainingTags: {
                type: Array,
                default: null
            }
        },
        methods: {
            redirect(tagName = null) {
                window.location = getLibraryListLink([tagName ?? this.tag.name]);
                return null;
            }
        }
    };
</script>

<style scoped>
.clickable:hover {
    cursor: pointer;
    opacity: 0.8;
}
.flex-it {
    display: flex;
    flex-direction: column;
}
.margin-top-auto {
    margin-top: auto;
}
.center-text {
    text-align: center;
}
.scroll-box {
    max-height: 20em;
    overflow: scroll;
    overflow-x: hidden;
}
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #ff1234 #243746;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #243746;
  border-radius: 20px;
}

*::-webkit-scrollbar-thumb {
  background-color: #ff1234;
  border-radius: 20px;
  border: 3px solid #ff1234;
}
</style>
