<template>
    <div v-if="show">
        <h2 class="title is-2 section-header">
            Create code sets for library..
        </h2>
        <p class="block">
            Create code sets for a given Library.
            Information from <code>LibraryMetadata</code> and <code>Facility</code> will be used.
        </p>
        <p class="block">
            If <code>LibraryMetadata.IsGrouped == false</code> a code set will be created for each Facility
            where <code>IsForSTID</code> is true.<br>
            Existing code sets will be skipped.
        </p>
        <p class="block">
            If <code>LibraryMetadata.IsGrouped == true</code> a code set will be created for each Facility
            where <code>IsForSTID</code> is true, but grouped by <code>STIDDB</code>.
            Facilities without STIDDB will not be used.<br>
            THIS SHOULD NOT BE USED if there are existing code sets (command may be improved to support this).
        </p>
        <div class="block">
            <b-field label="Library name">
                <b-input v-model="libraryName" />
            </b-field>
            <b-field>
                <b-switch v-model="dryRun">
                    Dry run (rollback transaction)
                </b-switch>
            </b-field>
        </div>
        <div class="buttons">
            <b-button
                type="is-primary"
                :disabled="!enabled"
                @click="run()">
                Run
            </b-button>
            <b-button
                :disabled="!enabled"
                @click="cancelCommand()">
                Cancel
            </b-button>
        </div>
    </div>
</template>

<script>
    import runMixin from './RunMixin.js';

    export default {
        mixins: [runMixin],
        data() {
            return {
                libraryName: '',
                dryRun: true
            };
        },
        methods: {
            async run() {
                await this.submitCommand([
                    'create-codesets',
                    this.libraryName,
                    this.dryRun ? 'dry-run' : ''
                ]);
            }
        }
    };
</script>
