<template>
    <div>
        <h2 class="title is-3">
            Aliases
        </h2>
        <div class="columns">
            <div class="column is-two-thirds">
                <tag-filter-list
                    :all-tags="allScopes"
                    :active-tags="scopeFilter"
                    @click-tag="toggleScopeFilter" />
            </div>
            <div class="column is-clearfix">
                <div class="field is-grouped is-pulled-right">
                    <p class="control">
                        <button
                            class="button is-info"
                            @click="newScopeClick">
                            <span class="icon is-small">
                                <i
                                    class="fa fa-plus-circle"
                                    aria-hidden="true" />
                            </span>
                            <span>New scope</span>
                        </button>
                    </p>
                    <p class="control">
                        <button
                            class="button is-info"
                            @click="newAliasClick">
                            <span class="icon is-small">
                                <i
                                    class="fa fa-plus-circle"
                                    aria-hidden="true" />
                            </span>
                            <span>New alias</span>
                        </button>
                    </p>
                </div>
            </div>
        </div>

        <b-modal v-model="isNewAliasActive">
            <form
                action=""
                @submit.prevent="saveNewAlias">
                <div
                    class="modal-card"
                    style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title title is-4">
                            Add new alias
                        </p>
                    </header>
                    <section class="modal-card-body">
                        <b-field label="Scope">
                            <b-select
                                v-model="newAlias.scope"
                                required>
                                <option
                                    v-for="scope in allScopes"
                                    :key="scope"
                                    :value="scope">
                                    {{ scope }}
                                </option>
                            </b-select>
                        </b-field>
                        <b-field label="Key">
                            <b-input
                                v-model="newAlias.key"
                                type="text"
                                placeholder="key"
                                required />
                        </b-field>
                        <b-field label="Value">
                            <b-input
                                v-model="newAlias.value"
                                type="text"
                                placeholder="value" />
                        </b-field>
                        <b-field label="Direction">
                            <b-select
                                v-model="newAlias.direction"
                                required>
                                <option
                                    v-for="direction in directions"
                                    :key="direction"
                                    :value="direction">
                                    {{ direction }}
                                </option>
                            </b-select>
                        </b-field>
                        <b-field label="Map function">
                            <b-select
                                v-model="newAlias.mapFunction"
                                required>
                                <option
                                    v-for="(mfd, mf) in mapFunctions"
                                    :key="mf"
                                    :value="mf">
                                    {{ mfd }}
                                </option>
                            </b-select>
                        </b-field>
                    </section>
                    <footer class="modal-card-foot">
                        <button class="button is-primary">
                            Save
                        </button>
                    </footer>
                </div>
            </form>
        </b-modal>

        <b-modal v-model="isEditAliasActive">
            <form
                action=""
                @submit.prevent="saveEditAlias">
                <div
                    class="modal-card"
                    style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title title is-4">
                            Edit alias
                        </p>
                    </header>
                    <section class="modal-card-body">
                        <b-field label="Scope">
                            {{ editAlias.scope }}
                        </b-field>
                        <b-field label="Key">
                            {{ editAlias.key }}
                        </b-field>
                        <b-field label="Value">
                            <b-input
                                v-model="editAlias.value"
                                type="text"
                                placeholder="value" />
                        </b-field>
                        <b-field label="Direction">
                            <b-select
                                v-model="editAlias.direction"
                                required>
                                <option
                                    v-for="direction in directions"
                                    :key="direction"
                                    :value="direction">
                                    {{ direction }}
                                </option>
                            </b-select>
                        </b-field>
                        <b-field label="Map function">
                            <b-select
                                v-model="editAlias.mapFunction"
                                required>
                                <option
                                    v-for="(mfd, mf) in mapFunctions"
                                    :key="mf"
                                    :value="mf">
                                    {{ mfd }}
                                </option>
                            </b-select>
                        </b-field>
                    </section>
                    <footer class="modal-card-foot">
                        <button class="button is-primary">
                            Save
                        </button>
                    </footer>
                </div>
            </form>
        </b-modal>

        <spinner :loading="loading">
            <b-table
                :data="filteredAliases"
                default-sort="keyWithoutPrefix"
                :striped="true"
                :narrowed="true"
                :loading="loading"
                :hoverable="true"
                class="table-height site-sticky-header">
                <b-table-column
                    v-slot="props"
                    field="scope"
                    label="Scope"
                    sortable>
                    {{ props.row.scope }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="keyPrefix"
                    label="Key prefix"
                    sortable>
                    {{ props.row.keyPrefix }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="keyWithoutPrefix"
                    label="Key"
                    sortable>
                    {{ props.row.keyWithoutPrefix }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="value"
                    label="Value"
                    sortable>
                    {{ props.row.value }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="direction"
                    label="Direction"
                    sortable>
                    {{ props.row.direction }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="mapFunction"
                    label="Map function"
                    sortable>
                    {{ mapFunctions[props.row.mapFunction] }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    label=""
                    style="text-align:right">
                    <button
                        class="button is-small"
                        @click="editAliasClick(props.row)">
                        <b-icon
                            icon="lead-pencil"
                            size="is-small" />
                    </button>
                    <button
                        class="button is-small is-primary"
                        @click="deleteAlias(props.row)">
                        <b-icon
                            icon="delete"
                            size="is-small" />
                    </button>
                </b-table-column>
                <template #empty>
                    <section class="section">
                        <div class="content has-text-grey has-text-centered title is-6">
                            <p>No Aliases found</p>
                        </div>
                    </section>
                </template>
            </b-table>
        </spinner>
    </div>
</template>

<script>
    import Spinner from '@/shared/components/Spinner.vue';
    import TagFilterList from '@/shared/components/TagFilterList.vue';
    import {
        deleteAlias,
        getAliases,
        getAliasScopes,
        saveNewAlias,
        saveNewAliasScope,
        updateAlias
    } from '@/shared/helpers/api';
    import messageDialog from '@/shared/mixins/messageDialogMixin';
    import { showMixin } from '@/shared/mixins/showMixin';
    import _ from 'lodash';

    export default {
        components: {
            TagFilterList,
            Spinner
        },
        mixins: [
            showMixin,
            messageDialog
        ],
        data() {
            return {
                mapFunctions: {
                    'None': 'None',
                    'IntegerStringFlipflop': 'Integer string flipflop',
                    'StaticContent': 'Static content',
                    'TemplateString': 'Template string',
                    'StidBoolean': 'STID boolean',
                    'StidSelectFlag': 'STID select flag (Obsolete)',
                    'StidLeftRightPadding': 'STID left/right padding',
                    'StringToStidString': 'String to STID string',
                    'SpaceEncodedStringToStidString': 'Space encoded string to STID string',
                    'IntegerToStidInteger': 'Integer to STID integer',
                    'IntegerToStidString': 'Integer to STID string',
                    'StringToStidInteger': 'String to STID integer',
                    'FloatToStidFloat': 'Float to STID float'
                },
                directions: ['Both', 'In', 'Out'],
                aliases: [],
                loading: true,
                isNewAliasActive: false,
                isEditAliasActive: false,
                allScopes: [],
                scopeFilter: [],
                newAlias: {
                    key: '',
                    value: '',
                    scope: '',
                    direction: 'Both',
                    mapFunctions: 'None'
                },
                editAlias: {}
            };
        },
        computed: {
            filteredAliases() {
                const self = this;
                let filtered = this.aliases;

                if (this.scopeFilter.length) {
                    filtered = filtered.filter(function(a) {
                        return -1 !== self.scopeFilter.indexOf(a.scope);
                    });
                }

                return filtered
                    .map(alias => ({
                        ...alias,
                        ...this.splitKey(alias)
                    }));
            }
        },
        async mounted() {
            await this.initAliases();
            await this.initScopes();
        },
        methods: {
            async initAliases() {
                try {
                    this.aliases = await getAliases(this);
                    this.loading = false;
                } catch {
                    this.showError('Unable to load aliases');
                    this.loading = false;
                }
            },
            initScopes() {
                getAliasScopes(this)
                    .then(response => {
                        this.allScopes = _.map(response, scope => scope.name);
                    })
                    .catch(() => {
                        this.showError('Unable to load scopes');
                    });
            },
            newScopeClick() {
                this.$buefy.dialog.prompt({
                    title: 'New scope',
                    message: 'Enter a unique scope name',
                    inputAttrs: {
                        maxlength: 256
                    },
                    onConfirm: async (value) => {
                        try {
                            const requestBody = {
                                name: value
                            };
                            await saveNewAliasScope(this, requestBody);
                            this.allScopes.push(value);
                        } catch (err) {
                            this.showError('Failed to add scope!');
                        }
                    }
                });
            },
            newAliasClick() {
                this.isNewAliasActive = true;
            },
            editAliasClick(alias) {
                this.editAlias = alias;
                this.isEditAliasActive = true;
            },
            async saveNewAlias() {
                const newAlias = {
                    key: this.newAlias.key.trim(),
                    scope: this.newAlias.scope,
                    value: this.newAlias.value.trim(),
                    direction: this.newAlias.direction,
                    mapFunction: this.newAlias.mapFunction
                };

                try {
                    await saveNewAlias(this, newAlias);
                    this.isNewAliasActive = false;
                    this.aliases.push(newAlias);
                } catch (err) {
                    this.isNewAliasActive = false;
                    this.showError('Failed to add alias!');
                }
            },
            async saveEditAlias() {
                try {
                    await updateAlias(this, this.editAlias);
                    this.isEditAliasActive = false;
                    await this.initAliases();
                } catch (err) {
                    this.showError('Failed to update alias!');
                }
            },
            deleteAlias(row) {
                this.messageDialog(`This will permanently delete ${row.key}`, false, 'Confirm delete')
                    .then(async () => {
                        try {
                            await deleteAlias(this, row);
                            const idx = _.findIndex(self.aliases, row);
                            this.aliases.splice(idx, 1);
                        } catch {
                            this.showError();
                        }
                    }).catch(() => { });
            },
            toggleScopeFilter(scope) {
                if (this.scopeFilter.indexOf(scope) === -1) {
                    this.scopeFilter.push(scope);
                } else {
                    this.scopeFilter.splice(this.scopeFilter.indexOf(scope), 1);
                }
            },
            splitKey(alias) {
                const keyArray = alias.key.split('.');
                if (keyArray.length === 1) {
                    return { keyPrefix: '', keyWithoutPrefix: keyArray[0] };
                } else {
                    const keyPrefix = keyArray[0];
                    const keyWithoutPrefix = keyArray.slice(1, keyArray.length).join('.');
                    return { keyPrefix, keyWithoutPrefix };
                }
            }
        }
    };
</script>

<style scoped>
.table-height {
    max-height: 75vh
}
</style>
