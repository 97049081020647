<template>
    <b-tooltip
        :label="fullDescription">
        <a
            v-if="hasEmail"
            :href="`mailto:${user.email}?subject=Common Library Code: ${pageTitle}&body=${pageUrl}`"
            style="font-weight: normal">{{ shortDescription }}</a>
        <span v-if="!hasEmail">{{ shortDescription }}</span>
    </b-tooltip>
</template>

<script>
    export default {
        props: {
            user: {
                type: Object,
                default: null
            }
        },
        computed: {
            hasName() {
                return this.user?.name && this.user.name !== '';
            },
            hasEmail() {
                return this.user?.email && this.user.email !== '';
            },
            shortDescription() {
                if (this.hasName)
                    return this.user.name;
                else if (this.hasEmail)
                    return this.user.email;
                else if (this.user)
                    return this.USER_UNKNOWN;
                else
                    return this.USER_UNDEFINED;
            },
            fullDescription() {
                if (this.hasName && this.hasEmail)
                    return `${this.user.name} (${this.user.email})`;
                else if (this.hasName)
                    return this.user.name;
                else if (this.hasEmail)
                    return this.user.email;
                else if (this.user)
                    return this.USER_UNKNOWN;
                else return this.USER_UNDEFINED;
            },
            pageUrl() {
                return window.location.href;
            },
            pageTitle() {
                return document.title;
            }
        },
        created() {
            this.USER_UNKNOWN = 'Unknown user';
            this.USER_UNDEFINED = '';
        }
    };

</script>

<style scoped>

</style>
