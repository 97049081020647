<template>
    <div>
        <h2 class="title is-3">
            User profile
        </h2>
        <b-tabs type="is-boxed">
            <b-tab-item label="Favourites">
                <h2 class="title is-4">
                    Favourites
                </h2>
                <b-table
                    v-if="favourites.length > 0"
                    :data="favourites"
                    height="55vh"
                    sticky-header
                    narrowed>
                    <b-table-column
                        v-slot="props"
                        label="Title"
                        field="title"
                        sortable>
                        <b-input v-model="props.row.title" />
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        label="Url"
                        field="url"
                        width="300">
                        <b-input v-model="props.row.url" />
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        label="Update"
                        centered>
                        <b-button
                            type="is-primary"
                            icon-left="pencil"
                            @click="updateFavourite(props.row)" />
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        label="Delete"
                        centered>
                        <b-button
                            type="is-danger"
                            icon-left="delete-outline"
                            @click="deleteFavourite(props.row)" />
                    </b-table-column>
                </b-table>
                <p v-else>
                    You have no favourites.
                    Add favourites by clicking the any star icon
                    (<span class="fav-star-inactive"><i class="fas fa-star is-small" /></span>)
                    in Common Library.
                </p>
            </b-tab-item>
            <b-tab-item label=" Access Control">
                <div>
                    <h2 class="title is-5">
                        Access Control
                    </h2>
                    <div style="width: 50%;">
                        <access-control-card
                            :card-title="'Assigned Roles'"
                            :access-groups="roles" />
                        <access-control-card
                            :card-title="'Access Groups'"
                            :access-groups="accessGroups" />
                    </div>
                    <h2 class="title is-5 mt-6">
                        UserAttributes
                    </h2>
                    <b-table
                        :data="userAttributes"
                        height="30vh"
                        sticky-header
                        narrowed>
                        <b-table-column
                            v-slot="props"
                            label="Role"
                            field="facilityRole"
                            sortable
                            searchable>
                            {{ props.row.facilityRole }}
                        </b-table-column>
                        <b-table-column
                            v-slot="props"
                            label="Scope"
                            field="scope"
                            sortable
                            searchable>
                            <span class="tag is-small is-success">
                                {{ props.row.scope }}
                            </span>
                        </b-table-column>
                        <b-table-column
                            v-slot="props"
                            label="System"
                            field="sourceSystem"
                            sortable
                            searchable>
                            {{ props.row.sourceSystem }}
                        </b-table-column>
                        <template #empty>
                            <div class="has-text-centered">
                                No user attributes
                            </div>
                        </template>
                    </b-table>
                </div>
            </b-tab-item>
        </b-tabs>
    </div>
</template>

<script>
    import AccessControlCard from '@/shared/components/AccessControlCard.vue';
    import { showMixin } from '@/shared/mixins/showMixin';
    import { getMyUserAccessAttributes } from '@/shared/helpers/api';
    import favouriteService from '@/shared/services/favouriteService';

    export default {
        components: { AccessControlCard },
        mixins: [
            showMixin
        ],
        props: {
            show: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                favourites: [],
                roles: [],
                accessGroups: [],
                userAttributes: []
            };
        },
        async mounted() {
            this.getRoles();
            await Promise.all([
                this.loadFavourites(),
                this.getAccessGroups(),
                this.getUserAttributes()
            ]);
        },
        methods: {
            async loadFavourites() {
                this.favourites = await favouriteService.get(this);
            },
            async deleteFavourite(fav) {
                await favouriteService.remove(this, fav.id);
                this.showInfo('Deleted favourite');
                await this.loadFavourites();
                await document.headerMailbox.send('update-favourites');
            },
            async updateFavourite(fav) {
                await favouriteService.update(this, fav);
                this.showInfo('Updated favourite');
                await this.loadFavourites();
                await document.headerMailbox.send('update-favourites');
            },
            getRoles() {
                this.user = window.authService.getUser();
                this.roles = this.user.idTokenClaims.roles
                    .filter(r => ['Administrator', 'CodeEditor', 'LibraryEditor', 'Viewer'].includes(r));
            },
            async getAccessGroups() {
                this.accessGroups = await window.authService.getAccessGroups();
            },
            async getUserAttributes() {
                const res = await getMyUserAccessAttributes(this);
                this.userAttributes = res.map(atr => ({
                    facilityRole: atr.split(':')[2],
                    scope: atr.split(':')[1],
                    sourceSystem: atr.split(':')[0]
                })).sort((a, b) => a.scope.localeCompare(b.scope));
            }
        }
    };
</script>
