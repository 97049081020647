<template>
    <div>
        <div style="margin-bottom: 10px">
            <div class="control has-icons-left">
                <input
                    v-model="contractsFilterText"
                    type="text"
                    class="input"
                    placeholder="Filter available contracts">
                <span class="icon is-small is-left">
                    <i class="fa fa-search" />
                </span>
            </div>
        </div>
        <set-selector
            v-if="true"
            :key="contractSetSelectorKey"
            :identity="(x) => x.identity"
            :sort-by="['element.name']"
            :initial-elements="availableContracts"
            :initial-selection="selectedContracts"
            :filter="contractFilter"
            available-elements-title="Available contracts"
            selected-elements-title="Selected contracts"
            @save="contractRelationsSave">
            <template #left-item="props">
                <div
                    class="tile notification is-light"
                    style="margin-bottom: 2px">
                    <span><b>{{ props.element.name }}</b>
                        {{ props.element.description }}</span>
                </div>
            </template>
            <template #right-item="props">
                <div
                    class="tile notification is-light"
                    style="margin-bottom: 2px">
                    <span><b>{{ props.element.name }}</b>
                        {{ props.element.description }}</span>
                </div>
            </template>
        </set-selector>
    </div>
</template>

<script>
    import _ from 'lodash';
    import SetSelector from '@/shared/components/SetSelector.vue';
    import { showMixin } from '@/shared/mixins/showMixin';
    import {
        getCodeSets,
        createChangeDocAndCommit,
        genericViewQueryAsText
    } from '@/shared/helpers/api';

    export default {
        components: {
            SetSelector
        },
        mixins: [showMixin],
        props: {
            projectMaster: {
                type: Object,
                default: null
            }
        },
        emits: [
            'refresh'
        ],
        data() {
            return {
                allContracts: {},
                contractsFilterText: '',
                contractSetSelectorKey: 1,
                contractFilter: (x) => x,
                codeSetName: ''
            };
        },
        computed: {
            contractsViewModel() {
                return this.allContracts;
            },
            availableContracts() {
                if (
                    this.allContracts
                    && this.allContracts.data
                    && this.allContracts.data.length
                )
                    return this.allContracts.data.filter(x => !x.project);
                else return [];
            },
            selectedContracts() {
                if (
                    this.allContracts
                    && this.allContracts.data
                    && this.allContracts.data.length
                )
                    return this.allContracts.data.filter(
                        (x) => x.project === this.projectMaster.Identity
                    );
                else return [];
            }
        },
        watch: {
            contractsFilterText(value) {
                if (value === '') this.contractFilter = (x) => x;

                const parts = value.toLowerCase().split(' ');
                this.contractFilter = function(x) {
                    const tmp = (x.name + x.description).toLowerCase();
                    return _.every(parts, function(p) {
                        return tmp.indexOf(p) > -1;
                    });
                };
            },
            contractsViewModel(value) {
                if (value) {
                    this.contractSetSelectorKey += 1;
                }
            }
        },
        async created() {
            this.allContracts = await this.fetchContracts();
            this.codeSetName = (await getCodeSets(this, 'Contract'))[0].name;
        },
        methods: {
            async fetchContracts() {
                return await genericViewQueryAsText(this, `
                TAKE 1000
                FROM Contract
                SELECT
                    Name as name,
                    Identity as identity,
                    Description as description,
                    IsValid as isValid,
                    ProjectMaster as project
                WHERE IsValid = true`);
            },
            async contractRelationsSave(state) {
                try {
                    const changes = _.concat(
                        state.added.map(contract => ({
                            Contract: contract.identity,
                            ProjectMaster: this.projectMaster.Identity,
                            Name: contract.name
                        })),
                        state.removed.map(contract => ({
                            Contract: contract.identity,
                            ProjectMaster: '{null}',
                            Name: contract.name
                        }))
                    );
                    const res = await createChangeDocAndCommit(
                        this,
                        this.codeSetName,
                        changes
                    );
                    this.showInfo(res.data.message);
                    this.allContracts = await this.fetchContracts();
                    this.$emit('refresh');
                } catch (err) {
                    this.showError(err);
                }
            }
        }
    };
</script>
