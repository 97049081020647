<template>
    <spinner :loading="loading">
        <b-table
            :data="structuredLog"
            :striped="true"
            :narrowed="true"
            detailed
            :hoverable="true">
            <b-table-column
                v-slot="props"
                field="releaseId"
                label="Release Id"
                sortable>
                <router-link :to="getReleaseLink(props.row.releaseId)">
                    {{ props.row.releaseId }}
                </router-link>
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="libraryName"
                label="Library"
                sortable>
                <router-link
                    v-if="props.row.libraryName"
                    :to="getLibraryLink(props.row.libraryName)">
                    {{ props.row.libraryName }}
                </router-link>
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="codeSetDescription"
                label="Set"
                sortable>
                <a
                    v-if="props.row.codeSetName"
                    :href="getCodeSetLink(props.row.codeSetName)">
                    {{ props.row.codeSetDescription }}
                </a>
            </b-table-column>
            <b-table-column
                v-slot="props"
                label="Status">
                <i
                    :class="getStatusIcon(props.row.releaseId)"
                    size="is-small" />
            </b-table-column>
            <template #detail="props">
                <b-table
                    class="box"
                    :data="props.row.jobs"
                    custom-detail-row
                    detailed>
                    <b-table-column
                        v-slot="props2"
                        field="publishJobId"
                        label="Publish Job / Log Id"
                        sortable>
                        <p>{{ props2.row.publishJobId }}</p>
                    </b-table-column>
                    <b-table-column
                        v-slot="props2"
                        field="timestamp"
                        label="Timestamp"
                        sortable>
                        <p>{{ $filters.dateFormatShort(props2.row.lastTimestamp) }}</p>
                    </b-table-column>
                    <b-table-column
                        v-slot="props2"
                        field="message"
                        label="Message"
                        sortable>
                        <p>{{ props2.row.lastMessage }}</p>
                    </b-table-column>
                </b-table>
            </template>
            <template #empty>
                <section class="section">
                    <div class="content has-text-grey has-text-centered">
                        <p>No log entries found</p>
                    </div>
                </section>
            </template>
        </b-table>
    </spinner>
</template>

<script>

    import Spinner from './Spinner.vue';
    import { getLogs } from '../helpers/api';
    import { getReleaseLink, getCodeSetLink, getLibraryLink } from '../helpers/routing';
    import { filterUnique } from '../helpers/utils';

    export default {
        components: {
            Spinner
        },
        props: {
            webhookId: {
                type: String,
                required: false,
                default: null
            }
        },

        data() {
            return {
                loading: true,
                logData: null,
                structuredLog: []
            };
        },
        watch: {
            webhookId() {
                this.loading = true;
                this.init();
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            async init() {
                this.logData = await getLogs(this, {
                    params: {
                        webhookId: this.webhookId
                    }
                });
                this.structureLogs();
                this.loading = false;
            },
            structureLogs() {
                const self = this;

                const releases = self.logData.map(l => l.releaseId)
                    .filter(filterUnique);

                releases.forEach(rId => {
                    const jobs = self.logData.filter(le => le.releaseId === rId)
                        .map(j => j.publishJobId)
                        .filter(j => j !== null)
                        .filter(filterUnique);

                    if (jobs.length === 0)
                        return;

                    const jobLog = [];

                    jobs.forEach(jId => {
                        const events = self.logData.filter(le => le.publishJobId === jId);
                        const jobLogEntry = {
                            publishJobId: jId,
                            events: events.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                                .map(e => ({
                                    id: e.id,
                                    message: e.message,
                                    data: e.data,
                                    timestamp: e.timestamp
                                }))
                        };
                        jobLogEntry.lastMessage = jobLogEntry.events[0].message;
                        jobLogEntry.lastTimestamp = jobLogEntry.events[0].timestamp;
                        jobLog.push(jobLogEntry);
                    });

                    const releaseData = self.logData.find(l => l.releaseId = rId);

                    self.structuredLog.push({
                        releaseId: rId,
                        libraryName: releaseData.libraryName,
                        codeSetName: releaseData.codeSetName,
                        codeSetDescription: releaseData.codeSetDescription,
                        jobs: jobLog
                    });
                });
            },
            getStatusIcon(releaseId) {
                const jobs = this.structuredLog.find(l => l.releaseId === releaseId).jobs;
                if (jobs.some(j => j.lastMessage === 'Job failed permanently'))
                    return 'fa fa-exclamation-circle';
                else if (jobs.some(j => j.lastMessage === 'Job delayed'))
                    return 'fa fa-hourglass';
                else if (jobs.every(j => j.lastMessage === 'Job succeeded' || j.lastMessage === 'Job delayed'))
                    return 'fa fa-fw fa-check-circle';
                else
                    return 'fa fa-fw fa-question-circle';
            },
            getReleaseLink,
            getCodeSetLink,
            getLibraryLink
        }
    };
</script>
