<template>
    <div
        class="field is-grouped"
        style="margin-top:10px;">
        <div class="control buttons has-addons">
            <button
                :class="runButtonClass"
                title="Run query (Shift+Enter)"
                @click="emitClick('click-run')">
                <b-icon
                    icon="play"
                    size="is-small" />
                <span>Run</span>
            </button>
            <button
                class="button"
                :disabled="!canSave"
                @click="emitClick('click-save')">
                <b-icon
                    icon="floppy"
                    size="is-small" />
                <span>Save</span>
            </button>
            <button
                class="button"
                :disabled="!canDelete"
                @click="emitClick('click-delete')">
                <b-icon
                    icon="delete"
                    size="is-small" />
                <span>Delete</span>
            </button>
            <button
                class="button"
                @click="emitClick('click-view-query')">
                <b-icon
                    icon="script-text-outline"
                    size="is-small" />
                <span>JSON/SQL</span>
            </button>
            <button
                :class="umlButtonClass"
                @click="emitClick('click-view-uml')">
                <b-icon
                    icon="table-multiple"
                    size="is-small" />
                <span>{{ umlVisible ? 'Hide UML' : 'Show UML' }}</span>
            </button>
            <button
                class="button"
                @click="emitClick('click-excel-export')">
                <b-icon
                    icon="download"
                    size="is-small" />
                <span>Excel export</span>
            </button>
            <button
                v-show="savedQueryLoaded"
                v-require-is-administrator
                class="button"
                @click="emitClick('click-toggle-report')">
                <b-icon
                    :icon="isReport ? 'close-octagon-outline' : 'auto-fix'"
                    size="is-small" />
                <span>{{ isReport ? 'Remove report' : 'Make report' }}</span>
            </button>
            <button
                :class="helpButtonClass"
                @click="emitClick('click-help')">
                <b-icon
                    icon="help"
                    size="is-small" />
                <span>Help</span>
            </button>
        </div>
        <div
            class="control"
            style="padding-top:0.5em;">
            <b-checkbox v-model="localIsPublic">
                Public
            </b-checkbox>
        </div>
    </div>
</template>

<script>
    import { requireIsAdministrator } from '@/shared/directives/requirePermission';
    import { getMyUserName } from '@/shared/helpers/api';

    export default {
        directives: {
            'require-is-administrator': requireIsAdministrator
        },
        props: {
            executing: {
                type: Boolean,
                default: false
            },
            savedQueryLoaded: {
                type: Boolean,
                required: true
            },
            umlVisible: {
                type: Boolean,
                default: false
            },
            helpVisible: {
                type: Boolean,
                default: false
            },
            isPublic: {
                type: Boolean,
                required: true
            },
            isReport: {
                type: Boolean,
                required: true
            },
            queryUserId: {
                type: String,
                default: null
            }
        },
        emits: [
            'is-public-changed',
            'click-run',
            'click-save',
            'click-delete',
            'click-view-query',
            'click-view-uml',
            'click-excel-export',
            'click-toggle-report',
            'click-help'
        ],
        data() {
            return {
                localIsPublic: false,
                userId: null
            };
        },
        computed: {
            runButtonClass() {
                return {
                    button: true,
                    'is-primary': true,
                    'is-loading': this.executing
                };
            },
            umlButtonClass() {
                return {
                    button: true,
                    'is-success': this.umlVisible
                };
            },
            helpButtonClass() {
                return {
                    button: true,
                    'is-success': this.helpVisible
                };
            },
            canSave() {
                return !this.savedQueryLoaded || this.queryUserId === this.userId;
            },
            canDelete() {
                return this.savedQueryLoaded && this.queryUserId === this.userId;
            }
        },
        watch: {
            isPublic(value) {
                this.localIsPublic = value;
            },
            localIsPublic(value) {
                if (this.isPublic !== value) {
                    this.$emit('is-public-changed', value);
                }
            }
        },
        async mounted() {
            this.userId = await getMyUserName(this);
        },
        methods: {
            emitClick(name) {
                this.$emit(name);
            }
        }
    };
</script>
