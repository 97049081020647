/*
    Common props and methods for admin command dialogs.
*/
const runMixin = {
    emits: {
        submit(args) {
            return Array.isArray(args) && args.length > 0;
        },
        cancel() {
            return true;
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        enabled: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        async submitCommand(args) {
            await this.$emit('submit', args);
        },
        cancelCommand() {
            this.$emit('cancel');
        }
    }
};

export default runMixin;
