<!--
    An input component for the Cable UI which allows the user to use both ',' and '.' as decimal separator.
    In order to not overwrite the value of the input field with the parsed value, the component has separate
    state for the raw value and the parsed value.

    This allows the user to type a value like ',01' or '3,14' without the input field value changing to '1' or '3.14'.
-->
<script setup lang="ts">
    import { ref, watch } from 'vue';

    // Helpers

    function tryParseNumber(input: string | number): number | string {
        if (!input)
            return input;

        const normalizedInput = input.toString().trim().replace(',', '.');

        if (!/^[+-]?(?:\d*\.\d*|\d+)$/.test(normalizedInput))
            return input;

        const value = parseFloat(normalizedInput);

        if (Number.isNaN(value))
            return input;

        return value;
    }

    // Interface

    type Props = {
        modelValue: string | number;
        disabled: boolean;
    };

    const props = withDefaults(defineProps<Props>(), {
        modelValue: '',
        disabled: false
    });

    const emit = defineEmits<{
        'update:modelValue': [string | number];
    }>();

    // Component Logic

    const rawValue = ref<string | number>(props.modelValue);

    watch(() => props.modelValue, syncRawValue);

    function syncRawValue() {
        if (tryParseNumber(props.modelValue) !== tryParseNumber(rawValue.value)) {
            rawValue.value = props.modelValue;
        }
    }

    function onInput(input: string) {
        rawValue.value = input;
        emit('update:modelValue', tryParseNumber(input));
    }
</script>

<template>
    <b-input
        :model-value="rawValue"
        :disabled="disabled"
        type="text"
        inputmode="numeric"
        @update:model-value="onInput" />
</template>
