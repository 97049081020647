<template>
    <div
        class="columns is-mobile"
        :style="itemStyle">
        <div class="column is-one-third">
            <div class="columns is-mobile">
                <div
                    class="column is-one-quarter is-clipped"
                    style="text-align:right">
                    <b>Identity</b>
                    <br>
                    <b>Name</b>
                    <br>
                    <b>Set</b>
                    <br>
                    <b>Change type</b>
                </div>
                <div class="column is-clipped">
                    <trunk-and-tip :text="change.identity" />
                    <br>
                    <b
                        v-if="change.changeType === 'Modified'">
                        <a
                            :href="getCodeLink(change.codeSet, change.identity)">
                            <trunk-and-tip :text="change.name" />
                        </a></b>
                    <span v-else>
                        <trunk-and-tip :text="change.name" />
                    </span>
                    <br>
                    <trunk-and-tip :text="change.codeSetDescription" />
                    <br>
                    <b-icon
                        v-if="change.changeType === 'Modified'"
                        type="is-danger"
                        class="fa fa-fw fa-pencil-alt" />
                    <b-icon
                        v-if="change.changeType === 'New'"
                        type="is-danger"
                        class="fa fa-fw fa-plus-square" />
                    {{ change.changeType }}
                </div>
            </div>
        </div>
        <div class="column is-two-thirds">
            <div
                v-for="(ch, index) in change.changes"
                :key="index"
                class="columns is-mobile">
                <div class="column is-one-third">
                    <trunk-and-tip :text="ch.field" />
                </div>
                <div class="column is-one-third old-value">
                    <trunk-and-tip
                        :text="ch.oldValue"
                        :length="60" />
                </div>
                <div class="column is-one-third">
                    <trunk-and-tip
                        :text="ch.newValue"
                        :length="60" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { getCodeLink } from '@/shared/helpers/routing.js';
    import TrunkAndTip from './TrunkAndTip.vue';

    export default {
        components: { TrunkAndTip },
        filters: {
            fieldValue(value) {
                if (value === null) return '{null}';
                else if (value === '') return '{empty}';
                else return value;
            }
        },
        props: {
            change: {
                type: Object,
                default() {
                    ({ id: 0, name: '', identity: '', changes: [] });
                }
            },
            height: {
                type: Number,
                default: 0
            }
        },
        computed: {
            itemStyle() {
                return {
                    'border-style': 'solid',
                    'border-bottom': 'solid 1px silver',
                    'border-left': 'solid 1px silver',
                    'border-right': 'solid 1px silver',
                    'border-top': 'unset',

                    height: `${this.height}px`
                };
            }
        },
        methods: {
            getCodeLink
        }
    };
</script>

<style scoped>
div {
    overflow: hidden;
}

.column div {
    padding-top: .1rem ;
    padding-bottom: .1rem;
    white-space: nowrap;
}

.columns div {
    margin-bottom: .15rem;
}

.old-value {
    color: gray;
}

</style>
