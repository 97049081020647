<template>
    <div>
        <breadcrumbs />
        <div class="toolbar is-flex is-gap-10 mb-3">
            <b-input
                v-model="searchFilter"
                style="flex-basis: 400px"
                type="text"
                icon="search"
                icon-pack="fa"
                placeholder="Filter libraries" />
            <tag-filter-dropdown
                :active-tags="tagFilter"
                :filter-set-operation="filterSetOperation"
                @update:tags="updateTagFilter"
                @update:filter-set-operation="updateFilterSetOperation" />
            <p class="control ml-auto">
                <router-link
                    class="button is-pulled-right"
                    :to="getLibraryNetworkLink()">
                    <b-icon
                        size="is-small"
                        icon="graph" />
                    <span>Explore</span>
                </router-link>
            </p>
            <p class="control">
                <new-library-button />
            </p>
        </div>
        <div
            v-if="tagFilter && tagFilter.length"
            class="columns">
            <div class="column is-full">
                <p v-if="filterSetOperation==='OR'">
                    Showing libraries with at least one of the tags:
                </p>
                <p v-if="filterSetOperation==='AND'">
                    Showing libraries with all of of the tags:
                </p>
                <tag-list
                    :tags="tagFilter"
                    :icon-class="'fa fa-trash'"
                    @click-tag="removeTagFromFilter" />
            </div>
        </div>

        <b-table
            v-if="!loading"
            :data="filteredLibraries"
            default-sort="name"
            :striped="true"
            :narrowed="true"
            :hoverable="true"
            class="site-sticky-header">
            <b-table-column
                v-slot="props"
                field="name"
                label="Name"
                sortable>
                <router-link :to="getLibraryLink(props.row.name)">
                    {{ props.row.name }}
                </router-link>
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="attachmentKey">
                <a>
                    <b-tooltip
                        v-if="props.row.attachmentKey"
                        label="Download attachment">
                        <b-icon
                            icon="paperclip"
                            size="is-small"
                            @click="getAttachment(props.row.attachmentKey)" />
                    </b-tooltip>
                </a>
            </b-table-column>
            <b-table-column
                v-slot="props"
                width="45"
                label="">
                <type-icon-row :library="props.row" />
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="alias"
                label="Alias"
                sortable>
                {{ props.row.alias }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="description"
                label="Description"
                sortable>
                {{ props.row.description }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                label="Groups">
                <div v-if="props.row.tags">
                    <div class="tags adjust-margin">
                        <span
                            v-for="(tag, idx) in props.row.tags.slice(0, 5)"
                            :key="idx"
                            class="tag is-info">{{ tag }}</span>
                        <b-dropdown
                            v-if="props.row.tags.length > 6"
                            hoverable>
                            <template #trigger>
                                <span

                                    class="tag is-info">
                                    <b-icon icon="menu-down" />
                                </span>
                            </template>
                            <b-taglist class="has-text-centered">
                                <b-tag
                                    v-for="(tag, idx) in props.row.tags.slice(5)"
                                    :key="idx"
                                    class="tag is-info">
                                    {{ tag }}
                                </b-tag>
                            </b-taglist>
                        </b-dropdown>
                    </div>
                </div>
            </b-table-column>
            <b-table-column
                v-slot="props"
                label="Access groups">
                <div v-if="props.row.tags">
                    <div class="tags adjust-margin">
                        <span
                            v-for="(ag, idx) in props.row.accessGroups.slice(0, 5)"
                            :key="idx"
                            class="tag is-danger">{{ ag }}</span>
                        <b-dropdown
                            v-if="props.row.accessGroups.length > 6"
                            hoverable>
                            <template #trigger>
                                <span

                                    class="tag is-info">
                                    <b-icon icon="menu-down" />
                                </span>
                            </template>
                            <b-taglist class="has-text-centered">
                                <b-tag
                                    v-for="(ag, idx) in props.row.accessGroups.slice(5)"
                                    :key="idx"
                                    class="tag is-danger">
                                    {{ ag }}
                                </b-tag>
                            </b-taglist>
                        </b-dropdown>
                    </div>
                </div>
            </b-table-column>
            <template #empty>
                <section class="section">
                    <div class="content has-text-grey has-text-centered title is-5">
                        <p>No Libraries Found</p>
                    </div>
                </section>
            </template>
        </b-table>
        <b-loading
            :is-full-page="false"
            :model-value="loading"
            :can-cancel="false" />
    </div>
</template>

<script>
    import 'moment';
    import NewLibraryButton from './NewLibraryButton.vue';
    import TagList from '@/shared/components/TagList.vue';
    import TagFilterList from '@/shared/components/TagFilterList.vue';
    import Breadcrumbs from '@/shared/components/Breadcrumbs.vue';
    import TagFilterDropdown from '@/shared/components/TagFilterDropdown.vue';
    import TypeIconRow from '@/shared/components/TypeIconRow.vue';
    import { getLibraryLink, getLibraryNetworkLink } from '@/shared/helpers/routing';
    import { getAttachment, getAttachmentName, getLibraryList } from '@/shared/helpers/api';
    import saveAs from 'file-saver';

    export default {
        components: {
            TagList,
            TagFilterList,
            Breadcrumbs,
            TagFilterDropdown,
            NewLibraryButton,
            TypeIconRow
        },
        data() {
            return {
                libraries: null,
                loading: true,
                searchFilter: '',
                tagFilter: null,
                filterSetOperation: null
            };
        },
        url: {
            tagFilter: {
                param: 'TagFilter',
                noHistory: true
            },
            searchFilter: {
                param: 'SearchFilter',
                noHistory: true
            },
            filterSetOperation: {
                param: 'FilterSetOperation',
                noHistory: true
            }
        },
        computed: {
            filteredLibraries() {
                let filtered = this.libraries;

                if (this.searchFilter && this.searchFilter.length && filtered && filtered.length) {
                    const search = this.searchFilter.toLowerCase();
                    filtered = filtered.filter(l => l.name.toLowerCase().includes(search) || (l.description ? l.description.toLowerCase().includes(search) : false));
                }

                if (this.tagFilter.length && filtered && filtered.length) {
                    if (this.filterSetOperation === 'OR')
                        filtered = filtered.filter(l => l.tags.some(t => this.tagFilter.includes(t)));
                    else if (this.filterSetOperation === 'AND')
                        filtered = filtered.filter(l => this.tagFilter.every(t => l.tags.includes(t)));
                }
                return filtered;
            }
        },
        async created() {
            this.initializeTagFilter();
            this.initializeFilterSetOperation();

            this.libraries = await getLibraryList(this);
            this.loading = false;
            this.$store.commit('SET_VIEWCONTEXT', { viewContext: 'other' });
        },
        methods: {
            initializeTagFilter() {
                const storageKey = 'LibraryList.tagFilter';
                this.tagFilter ??= JSON.parse(localStorage.getItem(storageKey)) ?? [];
                this.$watch(
                    'tagFilter',
                    newValue => localStorage.setItem(storageKey, JSON.stringify(newValue)),
                    { immediate: true, deep: true }
                );
            },
            initializeFilterSetOperation() {
                const storageKey = 'LibraryList.filterSetOperation';
                this.filterSetOperation ??= localStorage.getItem(storageKey) ?? 'OR';
                this.$watch(
                    'filterSetOperation',
                    newValue => localStorage.setItem(storageKey, newValue),
                    { immediate: true }
                );
            },
            updateTagFilter(newValue) {
                this.tagFilter = newValue;
            },
            removeTagFromFilter(tag) {
                this.tagFilter.splice(this.tagFilter.indexOf(tag), 1);
            },
            updateFilterSetOperation(newValue) {
                this.filterSetOperation = newValue;
            },
            async getAttachment(key) {
                try {
                    const [resp, fileName] = await Promise.all([getAttachment(this, key), getAttachmentName(this, key)]);
                    saveAs(resp, fileName);
                } catch (error) {
                    this.showError('Could not download attachment');
                }
            },
            getLibraryLink,
            getLibraryNetworkLink
        }
    };
</script>

<style scoped>

.adjust-margin:last-child{
    margin-bottom: -0.275rem;
}
</style>
