<template>
    <div class="panel">
        <div class="panel-heading is-flex is-justify-content-space-between">
            <p>
                Facility associations
            </p>
            <div>
                <b-button
                    v-require-can-edit-code="accessArguments"
                    @click="$emit('click-edit-associations')">
                    <b-icon
                        icon="pencil"
                        size="is-small" />
                    <span>
                        Edit associations
                    </span>
                </b-button>
                <b-button
                    v-require-can-edit-code="accessArguments"
                    @click="$emit('click-new-association')">
                    <b-icon
                        icon="plus"
                        size="is-small" />
                    <span>
                        Create new association
                    </span>
                </b-button>
            </div>
        </div>
        <b-table
            :selected="selectedProjectFacility"
            class="headless-table"
            :data="selectedProjectFacilities"
            :loading="projectFacilityLoading"
            striped
            narrowed
            hoverable
            @update:selected="$emit('update:selectedProjectFacility', $event)">
            <b-table-column
                v-slot="props"
                field="facility"
                label="Facility">
                {{ props.row.facility }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="deliveryCode"
                label="DeliveryCode"
                style="text-align:right">
                Delivery code: {{ props.row.deliveryCode }}
            </b-table-column>
            <template #empty>
                <div class="has-text-centered">
                    No facility associations
                </div>
            </template>
        </b-table>
    </div>
</template>
<script>
    import { requireCanEditCode } from '@/shared/directives/requirePermission.js';

    export default {
        directives: { requireCanEditCode },
        props: {
            selectedProjectFacilities: {
                type: Array,
                required: true
            },
            selectedProjectFacility: {
                type: Object,
                default: null
            },
            projectFacilityLoading: {
                type: Boolean,
                default: false
            },
            accessArguments: {
                type: Object,
                required: true
            }
        },
        emits: [
            'click-edit-associations',
            'click-new-association',
            'update:selectedProjectFacility'
        ]
    };
</script>
<style scoped>
.headless-table thead {
    display: none;
}
</style>
