import { getFavourites, addFavourite, updateFavourite, deleteFavourite } from '../helpers/api';

/**
 * Manages 'favourite' state in local storage
 */

const KEY_TIMESTAMP = 'favourites-timestamp';
const KEY_LIST = 'favourites';
const AGE_LIMIT_MS = 1000 * 60 * 10;

async function initialize(vue) {
    const result = await getFavourites(vue);
    localStorage.setItem(KEY_LIST, JSON.stringify(result));
    localStorage.setItem(KEY_TIMESTAMP, JSON.stringify(Date.now()));
}

const TITLE_POSTFIX = ' - Common Library';

function _stripPostfix(title) {
    if (title.endsWith(TITLE_POSTFIX)) {
        return title.substring(0, title.length - TITLE_POSTFIX.length);
    }
    return title;
}

async function _add(vue, title, url) {
    title = _stripPostfix(title);
    await addFavourite(vue, { title, url });
    await initialize(vue);
}

async function _update(vue, favourite) {
    await updateFavourite(vue, favourite.id, {
        title: favourite.title,
        url: favourite.url
    });
    await initialize(vue);
}

async function _remove(vue, id) {
    await deleteFavourite(vue, id);
    await initialize(vue);
}

async function _get(vue) {
    const timestampRaw = localStorage.getItem(KEY_TIMESTAMP);
    if (timestampRaw) {
        const timestamp = JSON.parse(timestampRaw);
        const now = Date.now();
        if (now - timestamp > AGE_LIMIT_MS) {
            await initialize(vue);
        }
    } else {
        await initialize(vue);
    }
    return JSON.parse(localStorage.getItem(KEY_LIST));
}

/*
    returns [boolean (isFavourite), int? (id)]
*/
async function _lookup(vue, url) {
    const favourites = await _get(vue);
    for (let i = 0; i < favourites.length; i++) {
        if (favourites[i].url === url) {
            return [true, favourites[i].id];
        }
    }
    return [false, undefined];
}

export default {
    add: _add,
    update: _update,
    remove: _remove,
    get: _get,
    lookup: _lookup,
    stripPostfix: _stripPostfix
};
