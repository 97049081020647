import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useCableEditStore = defineStore('cableEdit', () => {
    const cableCode = ref(null);
    const cableSpec = ref(null);

    function setCableCode(value) {
        cableCode.value = value;
    }

    function setCableSpec(value) {
        cableSpec.value = value;
    }

    function popCableCode() {
        const val = cableCode.value;
        cableCode.value = null;
        return val;
    }

    function popCableSpec() {
        const val = cableSpec.value;
        cableSpec.value = null;
        return val;
    }

    return {
        cableCode,
        cableSpec,
        setCableCode,
        setCableSpec,
        popCableCode,
        popCableSpec
    };
});
