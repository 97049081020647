<template>
    <spinner
        :loading="loading">
        <div class="table-overflow">
            <div class="saveButtonWrapper">
                <div
                    v-require-can-edit-code="{ libraryName: 'SchemaMapRelation' }">
                    <b-button
                        type="is-primary"
                        :disabled="!userCanSave"
                        @click="clickSaveButton">
                        <b-icon
                            icon="floppy"
                            size="is-small" />
                        <span>Save</span>
                    </b-button>
                    <b-button
                        :disabled="!userCanSave"
                        @click="clickCancel">
                        <b-icon
                            icon="close-octagon-outline"
                            size="is-small" />
                        <span>{{ createNew ? 'Cancel' : 'Undo' }}</span>
                    </b-button>
                </div>
            </div>
            <div class="field is-horizontal flex-container">
                <div class="field-label">
                    <label class="label pad-top">
                        Name:
                    </label>
                </div>
                <div class="field-body has-margin-top">
                    <div class="field is-grouped has-addons">
                        <div class="control is-expanded">
                            <b-input
                                v-model="mappingRelationForm['Name']"
                                class="input-width"
                                disabled />
                        </div>
                    </div>
                </div>
            </div>
            <div class="field is-horizontal flex-container">
                <div class="field-label">
                    <label class="label pad-top">
                        Description:
                    </label>
                </div>
                <div class="field-body has-margin-top">
                    <div class="field is-grouped has-addons">
                        <div class="control is-expanded">
                            <b-input
                                v-model="mappingRelationForm['Description']"
                                class="input-width" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="container is-fluid">
                <div class="card">
                    <div class="card-header-title">
                        You may map a relation to a relation or an attribute
                    </div>
                    <div class="card-content">
                        <div class="field is-horizontal flex-container">
                            <div class="field-label">
                                <label class="label pad-top">
                                    <b-tooltip
                                        :label="getDefinitions('Relation2')"
                                        :active="getDefinitions('Relation2') && getDefinitions('Relation2') !== ''"
                                        position="is-right">
                                        <span>Relation2:</span>
                                    </b-tooltip>
                                </label>
                            </div>
                            <div class="field-body has-margin-top">
                                <div class="field is-grouped has-addons">
                                    <div class="control is-expanded">
                                        <b-dropdown
                                            class="dropdownForm"
                                            :triggers="['hover']"
                                            aria-role="list"
                                            :scrollable="true"
                                            :max-height="400">
                                            <template #trigger>
                                                <b-button
                                                    class="dropdownButton"
                                                    :label="selectedRelation2 ? selectedRelation2.Name : 'None selected'"
                                                    type=""
                                                    icon-right="menu-down" />
                                            </template>
                                            <b-button
                                                type="is-text"
                                                @click="selectedRelation2 = null">
                                                Clear selected
                                            </b-button>
                                            <b-dropdown-item
                                                v-for="(relation, idx) in selectedSchema2RelationsSorted"
                                                :key="idx"
                                                aria-role="listitem"
                                                @click="()=>{selectedRelation2 = relation; selectedAttribute2 = null}">
                                                {{ relation ? relation.Name : '' }}
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="field is-horizontal flex-container">
                            <div class="field-label">
                                <label class="label pad-top">
                                    <b-tooltip
                                        :label="getDefinitions('Attribute2')"
                                        :active="getDefinitions('Attribute2') && getDefinitions('Attribute2') !== ''"
                                        position="is-right">
                                        <span>Attribute2:</span>
                                    </b-tooltip>
                                </label>
                            </div>

                            <div class="field-body has-margin-top">
                                <div class="field is-grouped has-addons">
                                    <div class="control is-expanded">
                                        <b-dropdown
                                            class="dropdownForm"
                                            :triggers="['hover']"
                                            aria-role="list"
                                            :scrollable="true"
                                            :max-height="400">
                                            <template #trigger>
                                                <b-button
                                                    class="dropdownButton"
                                                    :label="selectedAttribute2 ? selectedAttribute2.InterfaceName + '.' + selectedAttribute2.Name : 'None selected'"
                                                    type=""
                                                    icon-right="menu-down" />
                                            </template>
                                            <b-button
                                                type="is-text"
                                                @click="selectedAttribute2 = null">
                                                Clear selected
                                            </b-button>
                                            <b-dropdown-item>
                                                <div class="attributeWrapper">
                                                    <span class="interfaceTab">
                                                        <u>Interface</u>
                                                    </span>
                                                    <span class="attributeTab">
                                                        <u>Attribute</u>
                                                    </span>
                                                </div>
                                            </b-dropdown-item>
                                            <b-dropdown-item
                                                v-for="(attribute, idx) in schema2AttributesSorted"
                                                :key="idx"
                                                aria-role="listitem"
                                                @click="()=>{selectedAttribute2 = attribute; selectedRelation2 = null;}">
                                                <div class="attributeWrapper">
                                                    <span class="interfaceTab">
                                                        {{ attribute ? attribute.InterfaceName : '' }}
                                                    </span>
                                                    <span class="attributeTab">
                                                        {{ attribute ? attribute.Name : '' }}
                                                    </span>
                                                </div>
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="field is-horizontal flex-container">
                            <div class="field-label">
                                <label class="label pad-top">
                                    <b-tooltip
                                        :label="getDefinitions('Attribute2ToClass1')"
                                        :active="getDefinitions('Attribute2ToClass1') && getDefinitions('Attribute2ToClass1') !== ''"
                                        position="is-right">
                                        <span>Attribute2ToClass1:</span>
                                    </b-tooltip>
                                </label>
                            </div>
                            <div class="field-body has-margin-top">
                                <div class="field is-grouped has-addons">
                                    <div class="control is-expanded">
                                        <b-dropdown
                                            class="dropdownForm"
                                            :triggers="['hover']"
                                            aria-role="list"
                                            :scrollable="true"
                                            :max-height="400">
                                            <template #trigger>
                                                <b-button
                                                    class="dropdownButton"
                                                    :label="selectedAttribute2ToClass1 ? selectedAttribute2ToClass1.Name : 'none selected'"
                                                    type=""
                                                    icon-right="menu-down" />
                                            </template>
                                            <b-button
                                                type="is-text"
                                                @click="selectedAttribute2ToClass1 = null">
                                                Clear selected
                                            </b-button>
                                            <b-dropdown-item
                                                v-for="(attribute, idx) in selectedAttribute2ToClass1Sorted"
                                                :key="idx"
                                                aria-role="listitem"
                                                @click="selectedAttribute2ToClass1 = attribute">
                                                {{ attribute ? attribute.Name : '' }}
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </spinner>
</template>

<script>
    import Spinner from '@/shared/components/Spinner.vue';
    import { requireCanEditCode } from '@/shared/directives/requirePermission';
    import { createChangeDocAndCommit, genericViewQueryAsText, getLibraryWithScopes } from '@/shared/helpers/api';
    import { generateNameByConvention } from '@/shared/helpers/schemaHelpers';
    import messageDialog from '@/shared/mixins/messageDialogMixin';
    import _ from 'lodash';

    export default {
        directives: {
            'require-can-edit-code': requireCanEditCode
        },
        components: { Spinner },
        mixins: [
            messageDialog
        ],
        props: {
            mappingRelation: {
                type: Object,
                default() {
                    return {
                        'Name': null,
                        'Description': null,
                        'Relation2': null,
                        'Relation2_ID': null,
                        'Attribute2': null,
                        'Attribute2_ID': null,
                        'Attribute2ToClass1': null,
                        'Attribute2ToClass1_ID': null,
                        'Id': null,
                        'Identity': null,
                        'IsValid': true,
                        'Relation1': null,
                        'Relation1_ID': null,
                        'Schema1': null,
                        'Schema1_ID': null,
                        'Schema2': null,
                        'Schema2_ID': null
                    };
                }
            },
            selectedSchema1Relation: {
                type: Object,
                required: true,
                default: null
            },
            selectedSchema1: {
                type: Object,
                required: true,
                default: null
            },
            selectedSchema2: {
                type: Object,
                required: true,
                default: null
            },
            selectedSchema2Relations: {
                type: Array,
                required: false,
                default: () => []
            },
            createNew: {
                type: Boolean,
                default: false,
                required: false
            }
        },
        emits: [
            'refresh',
            'clickCancel'
        ],
        data() {
            return {
                originalFormValues: null,
                mappingRelationForm: null,
                schema2Attributes: null,
                schema1Classes: null,
                selectedRelation2: null,
                selectedAttribute2: null,
                selectedAttribute2ToClass1: null,
                libraryDefinitions: null,
                attributeDefinitions: null,
                loading: false
            };
        },
        computed: {
            schema2AttributesSorted() {
                if (this.schema2Attributes) {
                    return [...this.schema2Attributes]
                        .sort((a, b) => a.Name.localeCompare(b.Name));
                }
                return null;
            },
            selectedAttribute2ToClass1Sorted() {
                if (this.schema1Classes) {
                    return [...this.schema1Classes]
                        .sort((a, b) => a.Name.localeCompare(b.Name));
                }
                return null;
            },
            selectedSchema2RelationsSorted() {
                if (this.selectedSchema2Relations) {
                    return [...this.selectedSchema2Relations]
                        .sort((a, b) => a.Name.localeCompare(b.Name));
                }
                return null;
            },
            mappingRelationFiltered() {
                const displayFilter = ['Name', 'Description', 'Attribute2', 'Relation2', 'Attribute2ToClass1'];

                return Object.keys(this.mappingRelation)
                    .filter(key => displayFilter.includes(key))
                    .reduce((obj, key) => {
                        return {
                            ...obj,
                            [key]: this.mappingRelation[key]
                        };
                    }, {});
            },
            mappingName() {
                if (!this.mappingRelation.Name || this.isEdited()) {
                    const name = generateNameByConvention(this.selectedSchema1,
                                                          this.selectedSchema1Relation.Name, this.selectedSchema2);
                    if (this.selectedAttribute2 || this.selectedRelation2) {
                        return generateNameByConvention(this.selectedSchema1, this.selectedSchema1Relation.Name,
                                                        this.selectedSchema2,
                                                        this.selectedAttribute2 ? this.selectedAttribute2.Name : this.selectedRelation2.Name);
                    }
                    return name;
                }
                return this.mappingRelation.Name;
            },
            userCanSave() {
                if (this.createNew) {
                    return true;
                }
                return this.isEdited();
            }
        },
        watch: {
            mappingRelation() {
                this.loadForm();
            },
            mappingName() {
                this.mappingRelationForm.Name = this.mappingName;
            },
            mappingDescription() {
                this.mappingRelationForm.Description = this.mappingDescription;
            },
            selectedAttribute2(val) {
                this.mappingRelationForm.Attribute2 = val ? val.Identity : null;
                this.mappingRelationForm.Attribute2_ID = val ? val.Id : null;

                if (val) {
                    this.mappingRelationForm.Relation2 = null;
                    this.mappingRelationForm.Relation2_ID = null;
                }
            },
            selectedRelation2(val) {
                this.mappingRelationForm.Relation2 = val ? val.Identity : null;
                this.mappingRelationForm.Relation2_ID = val ? val.Id : null;

                if (val) {
                    this.mappingRelationForm.Attribute2 = null;
                    this.mappingRelationForm.Attribute2_ID = null;
                }
            },
            selectedAttribute2ToClass1(val) {
                this.mappingRelationForm.Attribute2ToClass1 = val ? val.Identity : null;
                this.mappingRelationForm.Attribute2ToClass1_ID = val ? val.Id : null;
            }
        },
        async beforeMount() {
            this.loadForm();
        },
        async mounted() {
            this.libraryDefinitions = await getLibraryWithScopes(this, 'SchemaMapRelation');
            this.attributeDefinitions = this.libraryDefinitions[0].attributeDefinitions.map((x) => ({ [x.name]: x.description }));
            this.originalFormValues = { ...this.mappingRelationForm };
        },
        methods: {
            async loadForm() {
                this.mappingRelationForm = { ...this.mappingRelation };

                this.schema2Attributes = await this.getSchema2Attributes();
                this.schema1Classes = await this.getSchema1Classes();

                this.selectedRelation2 = this.selectedSchema2Relations.find(r => r.Identity === this.mappingRelationForm.Relation2);
                this.selectedAttribute2 = this.schema2Attributes.find(a => a.Identity === this.mappingRelationForm.Attribute2);
                this.selectedAttribute2ToClass1 = this.schema1Classes.find(c => c.Identity === this.mappingRelationForm.Attribute2ToClass1);

                this.mappingRelationForm.Name = this.mappingName;
                this.mappingRelationForm.Description = this.mappingDescription();
                this.mappingRelationForm.Schema1 = this.selectedSchema1.Identity;
                this.mappingRelationForm.Schema1_ID = this.selectedSchema1.Id;
                this.mappingRelationForm.Relation1 = this.selectedSchema1Relation.Identity;
                this.mappingRelationForm.Relation1_ID = this.selectedSchema1Relation.Id;
                this.mappingRelationForm.Schema2 = this.selectedSchema2.Identity;
                this.mappingRelationForm.Schema2_ID = this.selectedSchema2.Id;
            },
            getDefinitions(key) {
                return this.attributeDefinitions?.find(x => x[key]) ? this.attributeDefinitions?.find(x => x[key])[key] : null;
            },
            mappingDescription() {
                if (!this.mappingRelation.Description) {
                    return `Mapping of ${this.selectedSchema1.Name} relation ${this.selectedSchema1Relation.Name} to `;
                }

                return this.mappingRelation.Description;
            },
            async getSchema2Attributes() {
                return (await genericViewQueryAsText(
                    this,
                    `FROM SchemaAttribute
                    SELECT Identity, Id, Name
                    WHERE IsValid = true AND Schema = @SchemaName
                    JOIN Interface SELECT Name as InterfaceName`,
                    [{ name: '@SchemaName', value: this.selectedSchema2.Identity }])).data;
            },
            async getSchema1Classes() {
                return (await genericViewQueryAsText(
                    this,
                    'FROM SchemaClass WHERE IsValid = true AND Schema = @SchemaName',
                    [{ name: '@SchemaName', value: this.selectedSchema1.Identity }])).data;
            },
            isEdited() {
                if (this.originalFormValues) {
                    const originalEntries = Object.entries(this.originalFormValues);
                    const editedEntries = Object.entries(this.mappingRelationForm);

                    return !_.isEqual(originalEntries, editedEntries);
                }
            },
            async saveForm() {
                this.loading = true;
                const code = this.makeCodeReadyForSave();
                await createChangeDocAndCommit(this, this.libraryDefinitions[0].codeSets[0].name, [code]);
                this.$emit('refresh');
                this.loading = false;
            },
            clickSaveButton() {
                if (!this.selectedRelation2 && !this.selectedAttribute2) {
                    this.messageDialog('You have not selected Relation2 or Attribute2. Continue?')
                        .then(() => {
                            this.saveForm();
                        })
                        .catch(() => {

                        });
                } else {
                    this.saveForm();
                }
            },
            clickCancel() {
                this.$emit('clickCancel');
            },
            makeCodeReadyForSave() {
                const filteredRelationForm = {};
                Object.entries(this.mappingRelationForm).forEach(([attrName, value]) => {
                    if (!value && attrName !== 'Identity') {
                        filteredRelationForm[attrName] = '{null}';
                    } else if (value) {
                        filteredRelationForm[attrName] = value;
                    }
                });
                return filteredRelationForm;
            }

        }
    };
</script>

<style scoped>
.table-overflow{
    overflow: auto;
    overflow-y: scroll;
    height: 66vh;
}
.input-width{
    width: 30vw;
}
.flex-container{
    --display: flex;
    --align-items: center;
}
.pad-top {
    padding-top: 15px;
}
.clearButton{
    background-color: rgb(243, 243, 243);
    color: red;
}
.attributeWrapper{
    display: flex;
    justify-content: space-between;
    width: 300px;
}
.interfaceTab {
    width: 50%;
}
.attributeTab {
    width: 50%;
}

.saveButtonWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: stretch;
    align-content: stretch;
}
.dropdownButton {
    --width: 100%;
    --min-width: 300px;
    --text-align: left;
}
.info-text {
    margin-right: 5px;
    color: #007079;
}
</style>
