<template>
    <div>
        <b-loading
            :is-full-page="false"
            :model-value="loading" />
        <breadcrumbs
            :library="library" />
        <b-notification
            v-if="showUnlockedForDeleteNotification"
            type="is-warning"
            :closable="false"
            has-icon
            role="alert">
            <p><b>Items may be deleted</b></p>
            <p v-if="library && library.codeSets.length === 1">
                This library is opened so that items may be deleted. This generally means the library is <u>not production ready</u>, and it must be locked for deletion when appropriate.
            </p>
            <p v-else>
                One or more scoped library sets are open so that items may be deleted. This generally means the set is <u>not production ready</u>, and it must be locked for deletion when appropriate.
            </p>
        </b-notification>
        <not-found
            v-if="!library && !loading"
            :library-name="libraryName" />

        <div v-if="!editMode && library">
            <div class="columns">
                <div class="column">
                    <h1 class="title">
                        {{ library.name }}
                    </h1>
                </div>
                <div class="column">
                    <div class="field is-grouped is-pulled-right">
                        <p
                            v-if="library.isForeignObject"
                            class="control">
                            <router-link
                                class="button is-info"
                                :to="getForeignLibrarySearch(library.name)">
                                <b-icon
                                    icon="magnify"
                                    size="is-small" />
                                <span>Open foreign object search</span>
                            </router-link>
                        </p>
                        <p class="control">
                            <button
                                v-if="!library.isForeignObject"
                                class="button is-info"
                                @click="exploreLibrary">
                                <b-icon
                                    icon="graph"
                                    size="is-small" />
                                <span>Explore</span>
                            </button>
                        </p>
                        <p class="control">
                            <button
                                v-if="!library.isForeignObject"
                                class="button is-info"
                                @click="queryLibrary">
                                <b-icon
                                    icon="magnify"
                                    size="is-small" />
                                <span>Query</span>
                            </button>
                        </p>
                        <p class="control">
                            <library-documentation-button
                                :library-name="libraryName" />
                        </p>
                        <p
                            v-require-can-edit-library="library.name"
                            class="control">
                            <button
                                class="button is-primary"
                                @click="editLibrary">
                                <b-icon
                                    icon="lead-pencil"
                                    size="is-small" />
                                <span>Edit Library</span>
                            </button>
                        </p>
                        <p
                            v-show="!library.isGlobal && !library.isForeignObject"
                            class="control">
                            <button
                                class="button is-primary"
                                @click="addCodeSet">
                                <b-icon
                                    icon="plus-circle-outline"
                                    size="is-small" />
                                <span>New set</span>
                            </button>
                        </p>
                    </div>
                </div>
            </div>
            <div class="columns is-desktop is-multiline">
                <div class="column is-one-quarter-desktop is-full-tablet is-full-mobile">
                    <library-data
                        :library="library" />
                    <library-attachment
                        v-if="library"
                        :attachment="library.attachment"
                        :library-name="library.name"
                        @updateAttachment="editDone" />
                    <library-groups-card
                        :tags="library.tags" />
                    <access-control-card
                        :access-groups="library.accessGroups"
                        :policies="policies"
                        :card-title="'Access Control'"
                        :library-access-control="true" />
                </div>
                <div class="column">
                    <b-tabs
                        v-model="selectedTab"
                        size="is-medium"
                        type="is-boxed"
                        class="block">
                        <b-tab-item
                            v-if="!library.isForeignObject"
                            label="Content"
                            value="Content">
                            <div
                                v-if="library.isVirtual"
                                style="text-align:center;padding-top:10em;">
                                <b-button
                                    type="is-info"
                                    size="is-large"
                                    @click="gotoVirtualCodeResult">
                                    Open content view
                                </b-button>
                                <p style="padding-top: 1em;">
                                    Virtual library content is viewed on a separate page.
                                </p>
                            </div>
                            <code-set-list
                                v-else
                                id="codeset-list"
                                :library="library" />
                        </b-tab-item>
                        <b-tab-item
                            v-if="!library.isForeignObject"
                            label="Attribute definitions"
                            value="Attributes">
                            <attribute-def
                                v-if="!library.isForeignObject"
                                id="attribute-def"
                                :library-name="libraryName"
                                :code-name-regex="codeNameRegex"
                                :description-regex="descriptionRegex"
                                :name-in-identity="library.nameInIdentity"
                                :description-is-required="library.descriptionIsRequired"
                                :name-alias="library.nameAlias" />
                        </b-tab-item>
                        <b-tab-item
                            v-if="!library.isForeignObject"
                            label="UML"
                            value="Relations">
                            <keep-alive>
                                <library-uml
                                    v-if="selectedTab === 'Relations'"
                                    :library-name="libraryName" />
                            </keep-alive>
                        </b-tab-item>
                        <b-tab-item
                            v-if="!library.isForeignObject"
                            label="Subscriptions"
                            value="Subscriptions">
                            <library-relations :library="library" />
                        </b-tab-item>
                        <b-tab-item
                            v-if="!library.isForeignObject"
                            label="Change log"
                            value="Changelog">
                            <library-log :library-name="library.name" />
                        </b-tab-item>
                    </b-tabs>
                </div>
            </div>
        </div>
        <div v-if="editMode">
            <library-edit
                :library-name="libraryName"
                @done="editDone" />
        </div>
    </div>
</template>

<script>
    import 'moment';
    import _ from 'lodash';
    import LibraryEdit from './LibraryEdit.vue';
    import AttributeDef from './AttributeDef.vue';
    import CodeSetList from './CodeSetList.vue';
    import LibraryData from './LibraryData.vue';
    import LibraryRelations from './LibraryRelations.vue';
    import LibraryLog from './LibraryLog.vue';
    import LibraryUml from './LibraryUml.vue';
    import LibraryAttachment from './LibraryAttachment.vue';
    import Breadcrumbs from '@/shared/components/Breadcrumbs.vue';
    import AccessControlCard from '@/shared/components/AccessControlCard.vue';
    import { showMixin } from '@/shared/mixins/showMixin.js';
    import { getCodeSetLink, queryLinkLibrary, explorerLinkLibrary, getForeignLibrarySearch, getSqlResultLink } from '@/shared/helpers/routing.js';
    import { getAccessPolicesForLib, getLibrary, newCodeSet } from '@/shared/helpers/api.ts';
    import { requireCanEditLibrary } from '@/shared/directives/requirePermission';
    import LibraryGroupsCard from './LibraryGroupsCard.vue';
    import LibraryDocumentationButton from './LibraryDocumentationButton.vue';
    import NotFound from '@/shared/NotFound.vue';

    export default {
        directives: {
            'require-can-edit-library': requireCanEditLibrary
        },
        components: {
            LibraryGroupsCard,
            Breadcrumbs,
            LibraryLog,
            LibraryUml,
            LibraryEdit,
            AttributeDef,
            CodeSetList,
            LibraryData,
            LibraryRelations,
            LibraryAttachment,
            AccessControlCard,
            LibraryDocumentationButton,
            NotFound
        },
        mixins: [showMixin],
        data() {
            return {
                libraryName: '',
                editMode: false,
                libraryEditRole: 'CanEditLibrary',
                library: null,
                loading: true,
                selectedTab: 'Content',
                policies: []
            };
        },
        computed: {
            showUnlockedForDeleteNotification() {
                if (this.library) {
                    return _.some(this.library.codeSets, x => !x.lockedForDelete);
                }
                return false;
            },
            codeNameRegex() {
                return this.library.codeNameRegex;
            },
            descriptionRegex() {
                return this.library.descriptionRegex;
            }
        },
        async created() {
            this.libraryName = decodeURIComponent(this.$route.params.libraryName);

            try {
                await this.getLibrary(this.libraryName);
            } catch {
                this.loading = false;
            }

            if (this.library) {
                this.$store.commit('SET_VIEWCONTEXT', { viewContext: 'library' });
            }

            if (this.library.isForeignObject) {
                this.selectedTab = 'Information';
            }
        },
        methods: {
            async getLibrary(libraryName) {
                const response = await getLibrary(this, libraryName);
                if (response !== '') {
                    this.library = response;
                    this.policies = await getAccessPolicesForLib(this, libraryName)
                        .then(data => data.policies.map(e => e.facilityRole))
                        .catch(() => []);
                }
                this.loading = false;
            },
            addCodeSet() {
                const self = this;
                self.$buefy.dialog.prompt({
                    message: 'New library set:',
                    inputAttrs: {
                        placeholder: 'Set description',
                        minlength: 1,
                        maxlength: 256,
                        required: true
                    },
                    onConfirm: async description => {
                        try {
                            await newCodeSet(self, self.library.name, description);
                            await self.getLibrary(self.library.name);
                        } catch {
                            self.showError('Cannot Create Set');
                        }
                    }
                });
            },
            editLibrary() {
                this.editMode = true;
            },
            editDone() {
                this.editMode = false;
                window.location.reload(true);
            },
            queryLibrary() {
                this.$router.push(queryLinkLibrary(this.libraryName));
            },
            exploreLibrary() {
                this.$router.push(explorerLinkLibrary(this.libraryName));
            },
            gotoVirtualCodeResult: function() {
                this.$router.push(getSqlResultLink(
                    `SELECT * FROM ${this.library.name}`,
                    this.library.name
                ));
            },
            getCodeSetLink,
            getForeignLibrarySearch
        }
    };
</script>
