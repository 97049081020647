import { getAttributeDefinitions, getCode, getCodeSets, getLibrary, getLibraryFromCodeSet } from '../../helpers/api';
import { createCodeDefArray, createCodeObject } from '../../helpers/dataHelpers';

const viewContexts = {
    other: 0,
    library: 1,
    codeSet: 2,
    code: 3
};

const getDefaultState = () => {
    return {
        codeSet: null,
        library: null,
        code: null,
        viewContext: null,
        scopes: null
    };
};

export const codeLibraryModule = {
    state: getDefaultState(),
    getters: {
    },
    mutations: {
        SET_SCOPES: (state, payload) => {
            state.scopes = payload;
        },
        SET_CODE: (state, payload) => {
            if (state.library && state.library.attributeDefinitions)
                state.code = createCodeObject(payload, state.library.attributeDefinitions.map(a => a.field));
            else
                throw 'cannot set code without setting library first';
        },
        SET_CODESET: (state, payload) => {
            state.codeSet = payload;
        },
        SET_LIBRARY: (state, payload) => {
            state.library = payload;
            state.library.attributeDefinitions = createCodeDefArray(payload.attributeDefinitions, {
                nameInIdentity: payload.data.nameInIdentity,
                descriptionIsRequired: payload.data.descriptionIsRequired
            });
        },
        SET_VIEWCONTEXT: (state, payload) => {
            state.viewContext = viewContexts[payload.viewContext];
        }
    },
    actions: {
        INIT_FROM_LIB: async (context, payload) => {
            const library = await getLibrary(payload.vm, payload.library);
            library.attributeDefinitions = await getAttributeDefinitions(payload.vm, payload.library);
            library.codeSets = await getCodeSets(payload.vm, payload.library);
            context.commit('SET_LIBRARY', library);
        },
        INIT_FROM_CODESET: async (context, payload) => {
            const cs = await getLibraryFromCodeSet(payload.vm, payload.codeSet);
            context.commit('SET_LIBRARY', cs.library);
            context.commit('SET_SCOPES', cs.scopes);

            cs.accessGroups = []; // Is this expected anywhere or can it be removed now that scoped access groups are removed?

            delete cs.library;
            delete cs.scopes;
            context.commit('SET_CODESET', cs);
        },
        INIT_FROM_CODE: async (context, payload) => {
            if (!context.state.codeSet || context.state.codeSet.name !== payload.codeSet)
                await context.dispatch('INIT_FROM_CODESET', { vm: payload.vm, codeSet: payload.codeSet });

            context.commit('SET_CODE', await getCode(payload.vm, context.state.codeSet.name, payload.identity));
        }
    }
};
