<template>
    <b-tag
        :class="{'is-loading': loading}"
        class="button is-static px-3"
        rounded>
        <slot v-if="!loading" />
    </b-tag>
</template>
<script>
    export default {
        name: 'TabBadge',
        props: {
            loading: {
                type: Boolean,
                default: false
            }
        }
    };
</script>
<style scoped>
.button.is-static {
    bottom: -0.5ex;
    /* Increase contrast over static button's default. Color taken from tab text */
    color: hsl(0, 0%, 29%);
}
</style>
