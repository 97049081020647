<template>
    <b-tooltip
        :label="tooltipLabel"
        :close-delay="5000"
        type="is-light"
        animated
        multilined
        @open="refreshTooltipLabel">
        <b-field>
            <p class="control">
                <b-button
                    @click="generateAndCopyLookupUrlUnencoded">
                    <b-icon
                        icon="content-copy"
                        size="is-small" />
                </b-button>
            </p>
            <p class="control">
                <b-button
                    class="is-info"
                    @click="generateAndCopyLookupUrlEncoded">
                    <span>Copy Lookup URL</span>
                </b-button>
            </p>
        </b-field>
    </b-tooltip>
</template>

<script>
    import { showMixin } from '@/shared/mixins/showMixin';
    import copyToClipboard from '@/shared/mixins/copyToClipboard';

    export default {
        mixins: [
            showMixin,
            copyToClipboard
        ],
        props: {
            libraryName: {
                type: String,
                required: true
            },
            codeSetDescription: {
                type: String,
                required: false,
                default: null
            },
            isGlobal: {
                type: Boolean,
                required: false,
                default: false
            },
            scope: {
                type: String,
                required: false,
                default: null
            },
            queries: {
                type: Array,
                required: false,
                default: () => []
            },
            regEx: {
                type: Array,
                required: false,
                default: () => []
            }
        },
        data() {
            return {
                tooltipLabel: ''
            };
        },
        methods: {
            generateLookupURL() {
                const url = new URL(`${window.location.origin}/Lookup/`);
                const selectedColumns = this.getSelectedColumns();

                url.searchParams.set('library', this.libraryName);
                url.searchParams.set('scope', !this.isGlobal ? this.scope : '');

                if (this.queries.length > 0)
                    url.searchParams.set('queries', JSON.stringify(this.queries));
                if (this.regEx.length > 0)
                    url.searchParams.set('regex', `"${this.regEx.join('","')}"`);
                if (selectedColumns.length > 0) {
                    url.searchParams.set('show', `${JSON.stringify(selectedColumns)}`);
                }

                return url;
            },
            getSelectedColumns() {
                const selectedColumns = localStorage.getItem('Library.' + this.libraryName + '.SelectedTableColumns');
                return selectedColumns ? selectedColumns.split(',') : [];
            },
            generateLinkTitle() {
                return this.codeSetDescription ?? document.title;
            },
            async generateAndCopyLookupUrlUnencoded() {
                const url = this.generateLookupURL();
                await this.copyTextToClipboard(decodeURIComponent(url.toString()));
            },
            async generateAndCopyLookupUrlEncoded() {
                const url = this.generateLookupURL();
                await this.copyUrlToClipboard(url, this.generateLinkTitle());
            },
            refreshTooltipLabel() {
                this.tooltipLabel = decodeURIComponent(this.generateLookupURL().toString());
            }
        }
    };
</script>

<style scoped>
.b-tooltip :deep(.tooltip-content) {
    font-size: 12px !important;
    width: auto !important;
}
</style>
