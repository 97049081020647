<template>
    <div v-if="subscription">
        <div class="columns">
            <div class="column">
                <h2 class="title is-3">
                    {{ subscription.application.name }} {{ subscription.library.name }} subscription
                    <b-tooltip
                        :label="`Go to ${ subscription.library.name } library page`"
                        type="is-light">
                        <router-link
                            :to="getLibraryLink(subscription.library.name)">
                            <b-icon icon="open-in-new" />
                        </router-link>
                    </b-tooltip>
                </h2>
            </div>
            <div class="column is-clearfix">
                <b-field
                    grouped
                    group-multiline
                    class="pull-right">
                    <b-switch
                        v-model="subscription.enabled"
                        v-require-can-edit-library="subscription.library.name"
                        style="margin-right:5px">
                        <span style="margin-right:5px">{{ subscription.enabled ? 'Enabled' : 'Disabled' }}</span>
                    </b-switch>
                    <b-button
                        style="margin-right:5px"
                        tag="router-link"
                        :to="getReleaseLogLink({applicationName: subscription.application.name, libraryName: subscription.library.name })"
                        class="button is-info">
                        <b-icon
                            icon="format-list-numbered"
                            size="is-small" />
                        <span>Release log</span>
                    </b-button>
                </b-field>
            </div>
        </div>
        <div class="columns">
            <div class="column is-half">
                <div class="box">
                    <div class="columns">
                        <div class="column">
                            <div class="title is-4">
                                Aliasing
                            </div>
                        </div>
                    </div>
                    <b-table
                        :data="filteredAliases"
                        :striped="true">
                        <b-table-column
                            v-slot="props"
                            field="key"
                            label="Key">
                            {{ props.row.key }}
                        </b-table-column>
                        <b-table-column>
                            <b-icon
                                size="is-small"
                                icon="arrow-right-bold" />
                        </b-table-column>
                        <b-table-column
                            v-slot="props"
                            field="value"
                            label="Value">
                            {{ props.row.value }}
                        </b-table-column>
                    </b-table>
                </div>
            </div>
            <div class="column is-half">
                <div class="box">
                    <div class="title is-4">
                        Health status
                    </div>
                    <b-table
                        :data="status"
                        :stiped="true"
                        class="table-height site-sticky-header">
                        <b-table-column
                            v-slot="props"
                            field="value"
                            label="Status"
                            sortable>
                            <span class="icon is-small">
                                <i
                                    v-if="props.row.value == 'Ok'"
                                    class="fa fa-thumbs-up"
                                    style="color:green;" />
                                <i
                                    v-else
                                    class="fa fa-thumbs-down"
                                    style="color:red;" />
                            </span>
                            {{ props.row.value }}
                        </b-table-column>
                        <b-table-column
                            v-slot="props"
                            field="summary"
                            label="Summary">
                            {{ props.row.summary }}
                        </b-table-column>
                        <b-table-column
                            v-slot="props"
                            field="scopeName"
                            label="Scope"
                            sortable>
                            {{ props.row.scopeName }}
                        </b-table-column>
                        <b-table-column
                            v-slot="props"
                            field="type"
                            label="Type"
                            sortable>
                            {{ props.row.type }}
                        </b-table-column>
                        <b-table-column
                            v-slot="props"
                            field="timestamp"
                            label="Timestamp"
                            sortable>
                            {{ $filters.dateFormatLong(props.row.timestamp) }}
                        </b-table-column>
                    </b-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import { requireCanEditLibrary } from '@/shared/directives/requirePermission';
    import {
        disableSubscriptionForApplication,
        enableSubscriptionForApplication,
        getAliases,
        getSubscription,
        getSubscriptionStatus
    } from '@/shared/helpers/api';
    import { getLibraryLink, getReleaseLink, getReleaseLogLink } from '@/shared/helpers/routing';
    import { showMixin } from '@/shared/mixins/showMixin';
    import _ from 'lodash';

    export default {
        directives: {
            'require-can-edit-library': requireCanEditLibrary
        },
        mixins: [
            showMixin
        ],
        data() {
            return {
                subscription: null,
                status: [],
                aliases: []
            };
        },
        computed: {
            filteredAliases() {
                const self = this;
                const keyFilter = `Lib.${self.subscription.library.name}`;
                return self.aliases.filter(a => a.scope === self.subscription.application.name && a.key.startsWith(keyFilter));
            }
        },
        watch: {
            '$route': 'loadData',
            subscription: {
                handler(val, old) {
                    if (old === null) return;

                    if (val.enabled) {
                        this.enableSubscription(val.id);
                    } else {
                        this.disableSubscription(val.id);
                    }
                },
                deep: true
            }
        },
        async mounted() {
            await this.loadData();
        },
        methods: {
            getLibraryLink,
            getReleaseLogLink,
            getReleaseLink,
            async loadData() {
                [this.subscription, this.status, this.aliases] = await Promise.all([
                    getSubscription(this, this.$route.params.subscriptionId),
                    getSubscriptionStatus(this, this.$route.params.subscriptionId),
                    getAliases(this)
                ]);
            },
            getTagType(status) {
                switch (status) {
                    case 'Verified':
                        return 'is-info';
                    default:
                        return '';
                }
            },
            async disableSubscription(subscriptionId) {
                try {
                    await disableSubscriptionForApplication(this, subscriptionId);
                } catch (err) {
                    this.showError(err);
                }
            },
            async enableSubscription(subscriptionId) {
                try {
                    await enableSubscriptionForApplication(this, subscriptionId);
                } catch (err) {
                    this.showError(err);
                }
            }
        }

    };
</script>

<style scoped>
    .table-height {
        max-height: 60vh;
    }
</style>
