import { defineStore, storeToRefs } from 'pinia';
import { getRecentlySelectedScope, setRecentlySelectedScope } from '../shared/helpers/scopefilterHelpers.js';
import { computed, ref, watch } from 'vue';
import { useScopeFilterStore } from './scopeFilterStore.js';
import { getScopesWithDescription } from '@/shared/helpers/api.ts';
import { http } from '../shared/httpWrapper.js';

export const useScopeStore = defineStore('scope', () => {
    const selectedScope = ref(null);
    const scopes = ref([]);
    const scopesLoading = ref(true);
    const lastUpdated = ref(null);

    const scopeFilterStore = useScopeFilterStore();
    const { selectedScopeFilter, scopeType } = storeToRefs(scopeFilterStore);

    const getScopes = computed(() => {
        if (scopeFilterStore.selectedScopeFilter === null || scopeFilterStore.selectedScopeFilter.length === 0) {
            return scopes.value;
        } else {
            return scopes.value.filter(scp => scopeFilterStore.selectedScopeFilter.includes(scp.identity));
        }
    });

    const selectedScopeLabel = computed(() => {
        const defaultLabel = `Select ${scopeType.value}`;

        return selectedScope.value ? selectedScope.value : defaultLabel;
    });

    watch(selectedScopeFilter, () => {
        if (scopeFilterStore.selectedScopeFilter?.length === 1) {
            const scope = scopeFilterStore.selectedScopeFilter[0];
            if (!selectedScope.value) {
                updateSelectedScope(scope);
            }
        } else if (scopeFilterStore.selectedScopeFilter?.length === 0) {
            selectedScope.value = null;
        }
    });

    function updateSelectedScope(scope) {
        setRecentlySelectedScope(scopeType.value, scope);
        selectedScope.value = scope;
    }

    function updateScopes(scp) {
        scopes.value = scp;
    }

    function getSelectedScope() {
        return selectedScope.value;
    }

    function setScopeType(st) {
        scopeFilterStore.setScopeType(st);
    }

    async function init() {
        scopesLoading.value = true;

        const timediff = (new Date() - lastUpdated?.value) / 1000;
        const cacheAgeInSeconds = 30;
        const scopeType = scopeFilterStore.scopeType;

        if (timediff > cacheAgeInSeconds) {
            const recentlySelectedScope = getRecentlySelectedScope(scopeType);
            if (recentlySelectedScope !== null) {
                selectedScope.value = recentlySelectedScope;
            }

            const scopeWithDesc = await getScopesWithDescription(http, scopeType);
            if (scopeWithDesc !== '') {
                updateScopes(scopeWithDesc);
            }
        }
        scopesLoading.value = false;
    }

    return {
        selectedScope,
        scopes,
        scopesLoading,
        lastUpdated,
        scopeType,
        selectedScopeLabel,
        updateSelectedScope,
        updateScopes,
        getScopes,
        getSelectedScope,
        setScopeType,
        init
    };
});
