<script setup>
    import { ref, computed } from 'vue';
    import { http } from '@/shared/httpWrapper.js';
    import { getLibraryDocumentation, getAttachment } from '@/shared/helpers/api.ts';
    import saveAs from 'file-saver';
    import useShow from '@/shared/composables/useShow.js';
    import VueMarkdown from '@/shared/components/VueMarkdown.js';

    const { showError } = useShow();

    const isModalActive = ref(false);
    const documentation = ref(null);
    const isLoading = ref(false);

    const props = defineProps({
        libraryName: {
            type: String,
            required: true
        }
    });

    const libraryHasAttachment = computed(() => {
        return !!documentation.value?.attachmentKey;
    });

    function hasValidElements(elements) {
        return elements.filter(e => e.name && e.value).length > 0;
    }

    const tags = computed(() => {
        if (!documentation.value) return [];
        return documentation.value.tags.filter(x => x.attachmentKey || hasValidElements(x.elements));
    });

    const libraryInformationElements = computed(() => {
        if (!documentation.value) return [];
        return elementsWithValue(documentation.value.elements);
    });

    const noDocumentationContent = computed(() => {
        if (isLoading.value) {
            return false;
        }

        return !libraryHasAttachment.value
            && tags.value.length === 0
            && libraryInformationElements.value.length === 0;
    });

    function generalDescriptionElement(elm) {
        return elm.name === 'General'
            || elm.name === 'Description';
    }

    function elementsWithValue(elements) {
        return elements.filter(e => e.value);
    }

    function generalDescriptionElements(elements) {
        return elementsWithValue(elements)
            .filter(generalDescriptionElement);
    }

    function otherElements(elements) {
        return elementsWithValue(elements)
            .filter(e => !generalDescriptionElement(e));
    }

    async function downloadAttachment(key, fileName) {
        try {
            const resp = await getAttachment(http, key);
            saveAs(resp, fileName);
        } catch (error) {
            showError('Could not download attachment');
        }
    }

    async function openModal() {
        isModalActive.value = true;

        if (!documentation.value) {
            isLoading.value = true;
            documentation.value = await getLibraryDocumentation(http, props.libraryName);
            isLoading.value = false;
        }
    }

</script>

<template>
    <b-button
        type="is-info"
        icon-left="file-document-outline"
        @click="openModal">
        Governance & Documentation
    </b-button>

    <b-modal
        v-model="isModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-label="Governance & Documentation"
        close-button-aria-label="Close"
        aria-modal>
        <template #default="defaultProps">
            <div
                class="modal-card"
                style="width: auto">
                <header class="modal-card-head">
                    <div class="modal-card-title">
                        <h1 class="title is-3">
                            {{ libraryName }}
                        </h1>
                        <h2 class="subtitle is-5">
                            Governance & Documentation
                        </h2>
                    </div>
                </header>
                <section
                    v-if="noDocumentationContent"
                    class="modal-card-body">
                    <p>
                        No documentation available for <b>{{ libraryName }}</b>.
                    </p>
                </section>
                <section
                    v-else
                    class="modal-card-body">
                    <div class="block">
                        <div
                            v-for="elm in generalDescriptionElements(libraryInformationElements)"
                            :key="elm.name"
                            style="margin-bottom: 1em;">
                            <vue-markdown
                                class="markdown"
                                :source="elm.value" />
                        </div>
                        <div
                            v-for="elm in otherElements(libraryInformationElements)"
                            :key="elm.name"
                            style="margin-bottom: 1em;">
                            <h4 class="is-6">
                                <b>{{ elm.name }}</b>
                            </h4>
                            <vue-markdown
                                class="markdown"
                                :source="elm.value" />
                        </div>
                    </div>
                    <div
                        v-if="libraryHasAttachment"
                        class="block">
                        Download
                        <a @click="downloadAttachment(documentation.attachmentKey, documentation.fileName)">
                            {{ documentation.fileName }}
                        </a>
                        for more in-depth documentation on <b>{{ libraryName }}</b>.
                    </div>
                    <div
                        v-for="tag in tags"
                        :key="tag.name"
                        class="block">
                        <hr>
                        <header class="block">
                            <h3 class="title is-4">
                                {{ tag.name }}
                            </h3>
                            <h4 class="subtitle is-5">
                                Library group
                            </h4>
                        </header>

                        <section class="block">
                            <div
                                v-for="elm in generalDescriptionElements(tag.elements)"
                                :key="elm.name"
                                style="margin-bottom: 1em;">
                                <vue-markdown
                                    class="markdown"
                                    :source="elm.value" />
                            </div>
                            <div
                                v-for="elm in otherElements(tag.elements)"
                                :key="elm.name"
                                style="margin-bottom: 1em;">
                                <h4 class="is-6">
                                    <b>{{ elm.name }}</b>
                                </h4>
                                <vue-markdown
                                    class="markdown"
                                    :source="elm.value" />
                            </div>
                        </section>
                        <div
                            v-if="tag.attachmentKey">
                            Download
                            <a @click="downloadAttachment(tag.attachmentKey, tag.fileName)">
                                {{ tag.fileName }}
                            </a>
                            for more in-depth documentation on <b>{{ tag.name }}</b>.
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot is-justify-content-end">
                    <b-button
                        label="Close"
                        @click="defaultProps.close" />
                </footer>
            </div>
        </template>
    </b-modal>
</template>
