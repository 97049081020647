<template>
    <div class="container">
        <div class="columns">
            <div class="column is-one-quarter">
                <b-menu v-if="index">
                    <b-menu-list
                        v-for="ml in index.menu"
                        :key="ml.label"
                        :label="ml.label">
                        <b-menu-item
                            v-for="x in ml.items"
                            :key="x.slug"
                            icon="file"
                            :active="slug == x.slug"
                            :label="x.label"
                            :tag="'router-link'"
                            :to="x.slug" />
                    </b-menu-list>
                </b-menu>
            </div>
            <div class="column">
                <div class="notification is-warning">
                    <i>Please note, the help content is currently <b>under construction!</b></i>
                </div>
                <vue-markdown
                    class="markdown"
                    :source="topicContent" />
            </div>
        </div>
    </div>
</template>

<script>
    import { showMixin } from '@/shared/mixins/showMixin';
    import VueMarkdown from '../../shared/components/VueMarkdown.js';
    import * as index from './index.json';

    export default {
        components: {
            VueMarkdown
        },
        mixins: [
            showMixin
        ],
        props: {
            slug: {
                type: String,
                required: false,
                default: 'index'
            }
        },
        data() {
            return {
                index: index,
                topicContent: '',
                errorMessage: '*Sorry, the content you are looking for does not exist (yet).*'
            };
        },
        watch: {
            async $route() {
                try {
                    await this.loadTopic(this.slug);
                } catch {
                    this.topicContent = this.errorMessage;
                }
            }
        },
        async mounted() {
            await this.loadTopic(this.slug);
        },
        methods: {
            async loadTopic(topic) {
                this.topicContent = await this.getSlug(topic);
            },
            async getSlug(topic) {
                const markdownPath = `../../../../Help/${topic}.md`;
                const slugUrl = new URL(markdownPath, import.meta.url).href;

                try {
                    const response = await this.$http.get(slugUrl);
                    return response.data;
                } catch (err) {
                    this.showError(err);
                    return this.errorMessage;
                }
            }
        }
    };
</script>

<style>

    .markdown > h1 {
        font-size: 250%;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .markdown > h2 {
        font-size: 200%;
        font-weight: bold;
        margin-bottom: 5px;
    }
    .markdown > h3 {
        font-size: 150%;
        font-weight: bold;
        margin-bottom: 5px;
    }
    .markdown > p {
        margin-bottom: 10px;
    }
    .markdown > ul {
        list-style-type: disc;
        list-style-position: inside;
        margin-bottom:10px;
        padding-left:20px;
    }
    .markdown > ol {
        list-style-position: inside;
        margin-bottom:10px;
        margin-left:20px;
    }

.markdown > blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 0px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
}
.markdown > blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
.markdown > blockquote p {
  display: inline;
}

.markdown > pre {
    padding: 0.75rem 0.75rem;
    font-size: 1rem;
    border: solid 1px silver;
    margin-bottom:10px;
}

.markdown a {
    text-decoration: underline;
    color: blue;
}
.markdown a:hover {
    text-decoration: underline;
    color: red;
}

.markdown > table {
    background-color: #fff;
    color: #363636;
}
.markdown > table thead {
    background-color: rgba(0,0,0,0);
}
.markdown > table td, table th {
    border: 1px solid #dbdbdb;
    border-width: 0 0 1px;
    padding: .5em .75em;
    vertical-align: top;
}
</style>
