<template>
    <div>
        <div
            v-if="loading"
            :class="containerClass">
            <span class="spinner" />
        </div>
        <div v-if="hideonly">
            <div v-show="!loading">
                <slot />
            </div>
        </div>
        <div v-if="!hideonly && !loading">
            <slot />
        </div>
    </div>
</template>

<script>

    export default {
        props: {
            loading: {
                type: Boolean,
                required: true
            },
            fullscreen: {
                type: Boolean,
                required: false,
                default: true
            },
            hideonly: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        computed: {
            containerClass() {
                return this.fullscreen ? 'loading-container-full' : 'loading-container-part';
            }
        }
    };
</script>

<style scoped>
.loading-container-full {
    text-align: center;
    margin-top: 40vh;
    height: 80vh;
    width: 100%;
}

.loading-container-part {
    text-align: center;
    height: 100%;
    width: 100%;
}

.spinner {
    animation: spinAround .5s linear infinite;
    border-radius: 9999px;
    border-width: 2px;
    border-color: hsl(0,0%,86%) hsl(0,0%,86%) transparent transparent ;
    border-style: solid;
    width: 39px;
    height: 39px;
    display: inline-block;
}
</style>
