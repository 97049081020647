<template>
    <section class="center-large-text section">
        <b-icon
            pack="fas"
            size="is-large"
            icon="building-lock" />
        <h1
            class="title">
            Access Denied
        </h1>
        <h1
            v-if="!isEmpty">
            To page: {{ page }}
        </h1>
    </section>
</template>
<script>
    export default {
        name: 'Unauthorized',
        props: {
            page: {
                type: String,
                default: ''
            }
        },
        computed: {
            isEmpty() {
                return this.page === '';
            }
        }
    };
</script>

<style scoped>

</style>
