<template>
    <div>
        <b-loading
            :is-full-page="false"
            :model-value="loading" />
        <div v-if="!loading">
            <breadcrumbs
                :code="code"
                :library="library"
                :code-set="codeSet" />

            <div class="columns">
                <div class="column">
                    <h1 class="title">
                        {{ code.name }}
                    </h1>
                </div>
                <div class="column">
                    <div class="is-pulled-right">
                        <div class="buttons">
                            <b-button
                                class="is-info"
                                icon-left="cog"
                                @click="toggleShowUserInfo">
                                {{ showUserInfo ? "Hide user info" : "Show user info" }}
                            </b-button>
                            <b-button
                                v-if="code && !codeSet.lockedForDelete"
                                v-require-can-edit-code="{ libraryName: library.name, scopes: codeSet?.scopes }"
                                style="margin-right: 6.4px"
                                type="is-danger"
                                icon-left="skull"
                                @click="confirmDeleteCode">
                                Delete item
                            </b-button>
                            <library-documentation-button
                                :library-name="library.name" />
                            <specialized-ui-button
                                v-if="!editCodeMode"
                                button-icon="pencil"
                                button-label="Edit item"
                                dropdown-text="Edit item using code editor"
                                :code-set="codeSet"
                                :code="code"
                                :library="library"
                                @clickEdit="editCodeMode = true" />
                            <b-button
                                v-else
                                label="Cancel"
                                icon-left="close-circle"
                                @click="editCodeMode = false" />
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="!editCodeMode"
                class="columns is-desktop is-multiline">
                <div class="column is-one-third-desktop is-full-tablet is-full-mobile">
                    <div
                        v-if="code"
                        class="box">
                        <table class="table is-striped is-narrow is-fullwidth">
                            <tbody>
                                <tr>
                                    <th>{{ getNameLabel(library.nameAlias) }}</th>
                                    <td>
                                        {{ code.name }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Identity</th>
                                    <td>
                                        {{ code.identity }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Description</th>
                                    <td>
                                        {{ code.description }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Is Valid
                                    </th>
                                    <td><bool-element :value="code.isValid" /></td>
                                </tr>
                                <tr>
                                    <th>IRI</th>
                                    <td>
                                        <b-button
                                            icon-left="content-copy"
                                            size="is-small"
                                            type="is-ghost"
                                            title="copy"
                                            class="is-pulled-right"
                                            @click="copyIri()" />
                                        <b-tooltip
                                            :label="code.iri"
                                            position="is-right">
                                            <a
                                                :href="code.iri"
                                                style="font-weight: normal">{{
                                                    $filters.truncate(code.iri, 45)
                                                }}</a>
                                        </b-tooltip>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Date created</th>
                                    <td>
                                        <b-tooltip
                                            :label="code.dateCreated"
                                            position="is-right">
                                            {{ $filters.dateFormatShort(codeUpdateInfo.dateCreated) }}
                                        </b-tooltip>
                                    </td>
                                </tr>
                                <tr v-if="showUserInfo">
                                    <th>Created by</th>
                                    <td>
                                        <name-with-email :user="codeUpdateInfo.createdBy" />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Date updated</th>
                                    <td>
                                        <b-tooltip
                                            :label="code.dateUpdated"
                                            position="is-right">
                                            {{ $filters.dateFormatShort(codeUpdateInfo.dateUpdated) }}
                                        </b-tooltip>
                                    </td>
                                </tr>
                                <tr v-if="showUserInfo">
                                    <th>
                                        Updated by
                                    </th>
                                    <td>
                                        <name-with-email :user="codeUpdateInfo.updatedBy" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <code-attachment
                        v-if="code"
                        :attachment="code.attachment"
                        :code-id="codeId"
                        :scopes="codeSet.scopes"
                        :library-name="library.name"
                        @updateAttachment="loadCode" />
                    <access-control-card
                        :access-groups="accessGroupsOnScope"
                        :policies="policies"
                        :card-title="'Access Control'"
                        :library-access-control="true" />
                </div>
                <div class="column is-two-thirds-desktop">
                    <b-tabs
                        v-model="activeTab"
                        size="is-medium"
                        type="is-boxed"
                        class="block">
                        <b-tab-item label="Details">
                            <div class="box">
                                <h2 class="title is-5">
                                    Attribute Details
                                </h2>
                                <code-attribute-details
                                    :code="code"
                                    :attribute-definitions="library.attributeDefinitions"
                                    :referable-codes="referableCodes"
                                    :code-update-info="codeUpdateInfo"
                                    :show-user-info="showUserInfo" />
                            </div>
                            <div class="box">
                                <ref-codes
                                    :code-identity="code.identity"
                                    :code-set-name="codeSet.name" />
                            </div>
                        </b-tab-item>
                        <b-tab-item label="History">
                            <div class="box">
                                <h2 class="title is-5">
                                    Code history
                                </h2>
                                <code-history
                                    :code-id="codeId"
                                    :show-user-info="showUserInfo"
                                    :attribute-definitions="library.attributeDefinitions"
                                    :referable-codes="referableCodes" />
                            </div>
                        </b-tab-item>
                    </b-tabs>
                </div>
            </div>
            <template v-else>
                <div
                    v-if="editCodeMode"
                    class="columns is-desktop">
                    <div class="column is-hidden-desktop-only is-one-fifth" />
                    <div class="column flex">
                        <code-edit
                            :show-cancel="true"
                            :code-id="code.id"
                            :library="library.name"
                            :scopes="codeSet?.scopes"
                            @refresh="refreshCode"
                            @cancel="toggleEditMode" />
                    </div>
                    <div class="column is-hidden-desktop-only is-one-fifth" />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import 'moment';
    import _ from 'lodash';
    import RefCodes from './RefCodes.vue';
    import CodeAttributeDetails from './CodeAttributeDetails.vue';
    import CodeHistory from './CodeHistory.vue';
    import BoolElement from '@/shared/components/BoolElement.vue';
    import CodeEdit from '@/shared/components/CodeEdit.vue';
    import ToggleButton from '@/shared/components/ToggleButton.vue';
    import Breadcrumbs from '@/shared/components/Breadcrumbs.vue';
    import { showMixin } from '@/shared/mixins/showMixin';
    import {
        deleteCode,
        getAccessGroupForLibrary,
        getAccessPolicesForLib,
        fetchCodeEditContext,
        getCodeSet,
        getCodeUpdateInfo
    } from '@/shared/helpers/api';
    import { getCodeSetLink } from '@/shared/helpers/routing';
    import { decodeIdBase64 } from '@/shared/helpers/utils.js';
    import { getNameLabel } from '@/shared/helpers/nameWithAliasHelper.js';
    import { requireCanEditCode } from '@/shared/directives/requirePermission';
    import NameWithEmail from './NameWithEmail.vue';
    import AccessControlCard from '@/shared/components/AccessControlCard.vue';
    import SpecializedUiButton from '@/shared/components/SpecializedUiButton.vue';
    import LibraryDocumentationButton from './LibraryDocumentationButton.vue';
    import CodeAttachment from './CodeAttachment.vue';

    export default {
        directives: {
            'require-can-edit-code': requireCanEditCode
        },
        components: {
            SpecializedUiButton,
            LibraryDocumentationButton,
            AccessControlCard,
            RefCodes,
            CodeEdit,
            BoolElement,
            ToggleButton,
            Breadcrumbs,
            CodeAttributeDetails,
            CodeHistory,
            CodeAttachment,
            NameWithEmail
        },
        mixins: [showMixin],
        data() {
            return {
                editCodeMode: false,
                activeTab: 0,
                loading: true,
                truncLimit: 30,
                code: null,
                codeUpdateInfo: null,
                library: null,
                referableCodes: null,
                codeSet: null,
                accessGroupsOnScope: null,
                permissionAgObject: null,
                showUserInfo: false,
                policies: []
            };
        },
        computed: {
            codeId() {
                return this.code ? parseInt(decodeIdBase64(this.code.id)) : null;
            }
        },
        async mounted() {
            await this.loadCode();
        },
        methods: {
            async loadCode() {
                this.loading = true;
                const codeId = decodeURIComponent(this.$route.params.id);
                const resultItem = (await fetchCodeEditContext(this, {
                    codeId: decodeIdBase64(codeId)
                })).data;
                resultItem.code.id = codeId;
                this.library = resultItem.library;
                this.referableCodes = resultItem.referableCodes;
                document.title = `${this.library.name}: ${resultItem.code.name}`;
                this.codeSet = await getCodeSet(
                    this,
                    this.library.name,
                    resultItem.codeSet
                );
                this.code = resultItem.code;
                await this.loadCodeUpdateInfo();
                await this.getAccessGroups();
                await this.getLibraryPolicies();
                this.loading = false;
            },
            async loadCodeUpdateInfo() {
                this.codeUpdateInfo = await getCodeUpdateInfo(this, this.codeId);
            },
            refreshCode() {
                this.toggleEditMode();
                this.loadCode();
            },
            toggleEditMode() {
                this.editCodeMode = !this.editCodeMode;
            },
            toggleShowUserInfo() {
                this.showUserInfo = !this.showUserInfo;
            },
            async copyIri() {
                await navigator.clipboard.writeText(this.code.iri).then(
                    () => {
                        this.showInfo('IRI copied to clipboard');
                    },
                    () => {
                        this.showError('Copy to clipboard failed!');
                    }
                );
            },
            confirmDeleteCode() {
                const self = this;
                this.$buefy.dialog.confirm({
                    title: 'Please confirm delete',
                    message:
                        'Deleting items may cause issues if the item is known externally to Common Library, and should normally only be done during initial setup of libraries. Continue to delete item?',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: async () => {
                        try {
                            await deleteCode(self, self.codeSet.name, self.code.identity);
                            document.location.href = getCodeSetLink(self.codeSet.name);
                        } catch (error) {
                            this.showError(
                                _.get(error, 'response.data.Message', 'Unable to delete item')
                            );
                        }
                    }
                });
            },
            async getAccessGroups() {
                this.accessGroupsOnScope = await getAccessGroupForLibrary(this, this.library.name)
                    .then(data => data.map(a => a.name));
            },
            async getLibraryPolicies() {
                this.policies = await getAccessPolicesForLib(this, this.library.name)
                    .then(data => data.policies.map(e => e.facilityRole))
                    .catch(() => []);
            },
            getNameLabel
        }
    };
</script>
