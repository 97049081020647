<template>
    <router-link
        v-if="release"
        :to="getReleaseLink(release.id)"
        style=""
        class="columns">
        <div class="column is-one-fifth has-text-centered">
            <p>
                Release
            </p>
            <p class="has-text-weight-bold">
                {{ release.id }}
            </p>
        </div>
        <div class="column is-one-fifth has-text-centered">
            <p>
                {{ getTypeHeader() }}
            </p>
            <p class="has-text-weight-bold">
                {{ getTypeValue() }}
            </p>
        </div>
        <div class="column is-one-fifth has-text-centered">
            <p>
                Date
            </p>
            <p class="has-text-weight-bold">
                {{ $filters.dateFormatShort(release.dateCreated) }}
            </p>
        </div>
        <div class="column is-one-fifth has-text-centered">
            <p>
                Library
            </p>
            <p
                v-if="release.specification.libraryName"
                class="has-text-weight-bold">
                {{ release.specification.libraryName }}
            </p>
            <b v-else-if="release.specification.isComposite && release.specification.libraries.length===1">
                {{ release.specification.libraries[0] }}
            </b>
            <b v-else>
                {{ release.specification.isComposite ? 'Multiple' : 'None' }}
            </b>
        </div>
        <div class="column is-one-fifth has-text-centered">
            <p>
                Set
            </p>
            <p
                v-if="release.specification.codeSetDescription"
                class="has-text-weight-bold">
                {{ release.specification.codeSetDescription }}
            </p>
            <b v-else>
                {{ release.specification.isComposite ? 'Multiple' : 'None' }}
            </b>
        </div>
    </router-link>
</template>

<script>

    import { getReleaseLink } from '@/shared/helpers/routing.js';

    export default {
        props: {
            release: {
                type: Object,
                required: true,
                default: null
            }
        },
        methods: {
            getTypeHeader() {
                switch (this.release.specification.specificationType) {
                    case 'ChangeDocument': return 'Items Changed';
                    case 'ScopeCodeSetRelease': return 'Type';
                    default: '';
                }
            },
            getTypeValue() {
                switch (this.release.specification.specificationType) {
                    case 'ScopeCodeSetRelease': return 'Set Scope Change';
                    default: '';
                }
            },
            getReleaseLink
        }
    };
</script>
