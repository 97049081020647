<template>
    <div
        v-if="releases.length > 0">
        <h3 class="title is-3">
            Subreleases:
        </h3>
        <div
            v-for="rel in sortedReleases"
            :key="rel.releaseId"
            class="box is-shadowless has-border has-background-white-ter">
            <h5 class="title is-5">
                Release id: <router-link :to="{ name: 'ReleaseById', params: { release: rel.id } }">
                    {{ rel.id }}
                </router-link>
                ({{ releaseLibraryName(rel) }})
            </h5>
            <release-errors-and-warnings :release="rel" />
            <release-state-changer
                :release="rel"
                :log-button="true"
                @transition="loadRelease($event)"
                @changeState="rel.state = $event" />
            <b-collapse
                class="box"
                :model-value="false"
                animation="slide">
                <template #trigger="props">
                    <div>
                        <p class="is-size-5">
                            {{ props.open ? 'Hide' : 'Show more info' }}
                            <span class="icon is-small">
                                <i
                                    v-if="props.open"
                                    class="fa fa-caret-up" />
                                <i
                                    v-else
                                    class="fa fa-caret-down" />
                            </span>
                        </p>
                    </div>
                </template>
                <br>

                <div class="columns">
                    <div class="column is-one-third-desktop is-full-tablet is-full-mobile">
                        <div class="box">
                            <table class="table is-striped is-narrow is-fullwidth">
                                <tbody>
                                    <tr>
                                        <th>State</th>
                                        <td>{{ rel.state }}</td>
                                    </tr>
                                    <tr>
                                        <th>Type</th>
                                        <td>{{ rel.specification.specificationType }}</td>
                                    </tr>
                                    <tr>
                                        <th>Library</th>
                                        <td>
                                            <p v-if="rel.specification.isComposite">
                                                {{ rel.specification.libraries.join(', ') }}
                                            </p>
                                            <router-link
                                                v-if="rel.specification.libraryName"
                                                :to="getLibraryLink(rel.specification.libraryName)">
                                                {{ rel.specification.libraryName }}
                                            </router-link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Library set</th>
                                        <td>
                                            <p v-if="rel.specification.isComposite">
                                                {{ rel.specification.changeDocuments.map(doc => doc.codeSetDescription).join(', ') }}
                                            </p>
                                            <a
                                                v-if="rel.specification.codeSetName"
                                                :href="getCodeSetLink(rel.specification.codeSetName)">
                                                {{ rel.specification.codeSetDescription }}
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Application</th>
                                        <td>
                                            <router-link
                                                v-if="rel.specification.applicationName"
                                                :to="getApplicationLink(rel.specification.applicationName)">
                                                {{ rel.specification.applicationName }}
                                            </router-link>
                                            <router-link
                                                v-for="app in rel.specification.applicationNames"
                                                v-else-if="rel.specification.applicationNames"
                                                :key="app"
                                                :to="getApplicationLink(app)">
                                                {{ app }}
                                            </router-link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Created</th>
                                        <td>{{ $filters.dateFormatLong(rel.dateCreated) }}</td>
                                    </tr>
                                    <tr>
                                        <th>Updated</th>
                                        <td>{{ $filters.dateFormatLong(rel.dateUpdated) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="column">
                        <div
                            v-if="rel.jobs.length"
                            class="box">
                            <h2 class="title is-5">
                                Jobs
                            </h2>
                            <table class="table is-narrow is-hoverable is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Type</th>
                                        <th>Application</th>
                                        <th>&nbsp;</th>
                                        <th>State</th>
                                        <th>Retry count</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="job in rel.jobs"
                                        :key="job.id">
                                        <td>{{ job.id }}</td>
                                        <td>{{ job.type }}</td>
                                        <td>
                                            <router-link
                                                v-if="job.subscription"
                                                :to="getApplicationLink(job.subscription.application.name)">
                                                {{ job.subscription.application.name }}
                                            </router-link>
                                        </td>
                                        <td style="vertical-align: middle">
                                            <progress
                                                v-show="job.state === 'InProgress'"
                                                class="progress is-info"
                                                max="100" />
                                        </td>
                                        <td>{{ job.state }}</td>
                                        <td>{{ job.retryCount }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </b-collapse>
        </div>
    </div>
</template>

<script>
    import { getRelease } from '@/shared/helpers/api';
    import { getApplicationLink, getCodeSetLink, getLibraryLink } from '@/shared/helpers/routing.js';
    import * as signalR from '@microsoft/signalr';
    import ReleaseErrorsAndWarnings from './ReleaseErrorsAndWarnings.vue';
    import ReleaseStateChanger from './ReleaseStateChanger.vue';

    export default {
        components: {
            ReleaseStateChanger,
            ReleaseErrorsAndWarnings
        },
        props: {
            subreleases: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                releases: []
            };
        },
        computed: {
            sortedReleases() {
                return this.releases.toSorted((a, b) => a.id - b.id);
            }
        },
        created() {
            this.connection = new signalR.HubConnectionBuilder()
                .withUrl(window.config.signalRUrlRelease, {
                    accessTokenFactory: async () => await window.authService.acquireToken()
                })
                .configureLogging(signalR.LogLevel.None)
                .build();
        },
        async mounted() {
            await this.reLoad();
            this.connection.on('Change', () => this.reLoad());
            try {
                await this.connection.start();
                this.releases.forEach(rel => this.connection.invoke('ReleaseId', rel.id));
                this.connection.onclose(() => this.connectionLost = true);
            } catch {
                this.connectionLost = true;
            }
        },
        beforeUnmount() {
            this.connection.stop();
        },
        methods: {
            async reLoad() {
                this.subreleases.forEach(async rel => {
                    const updatedRelease = await getRelease(this, rel.releaseId);
                    const index = this.releases.findIndex(r => r.id === rel.releaseId);
                    if (index > -1) {
                        this.releases[index] = updatedRelease;
                    } else {
                        this.releases.push(updatedRelease);
                    }
                });
            },
            releaseLibraryName(release) {
                const spec = release.specification;
                if (spec.isComposite) {
                    return spec.libraries.join(', ');
                }
                if (spec.libraryName) {
                    return spec.libraryName;
                }
                return '';
            },
            getCodeSetLink,
            getLibraryLink,
            getApplicationLink
        }

    };
</script>

<style>

</style>
