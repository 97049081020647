<template>
    <button
        class="button is-info"
        :class="{'is-loading': exportLoading}"
        :disabled="disabled"
        @click="exportCodeSet">
        <i class="fa fa-upload" />
        &nbsp;Export
    </button>
</template>

<script>

    import saveAs from 'file-saver';
    import { excelExportCodeSet } from '@/shared/helpers/api';

    export default {

        props: {
            codeSetName: {
                type: String,
                required: true
            },
            codeSetDescription: {
                type: String,
                required: true
            },
            codeFilter: {
                type: Object,
                required: true
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                exportLoading: false
            };
        },
        methods: {
            async exportCodeSet() {
                this.exportLoading = true;
                const response = await excelExportCodeSet(this, this.codeSetName, this.codeFilter);

                if (response.data) {
                    const filename = this.codeSetDescription ? this.codeSetDescription.replace(/\s/g, '-') + '.xlsx' : 'export.xlsx';
                    const blob = new Blob([response.data], { type: response.headers['Content-Type'] });
                    saveAs(blob, filename);
                }

                this.exportLoading = false;
            }
        }
    };
</script>
