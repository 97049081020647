<template>
    <div class="box">
        <p class="title is-5">
            Attachment
        </p>
        <img
            v-if="showImage"
            :src="imageUrl"
            :alt="attachment.fileName">
        <table
            v-if="attachment"
            class="table is-striped is-narrow is-fullwidth">
            <tbody>
                <tr>
                    <th>File name</th>
                    <td>{{ attachment.fileName }}</td>
                </tr>
                <tr>
                    <th>File size</th>
                    <td>{{ prettySize }}</td>
                </tr>
                <tr>
                    <th>File date</th>
                    <td>{{ $filters.dateFormatShort(attachment.dateUpdated) }}</td>
                </tr>
            </tbody>
        </table>
        <div>
            <b-button
                v-if="attachment"
                class="is-info"
                icon-left="download is-large"
                @click="downloadAttachment">
                Download
            </b-button>
            <slot name="upload-button" />
        </div>
    </div>
</template>

<script>
    import saveAs from 'file-saver';
    import { prettyBytes } from '@/shared/helpers/prettyBytes.js';
    import { getAttachment } from '@/shared/helpers/api.ts';

    export default {
        props: {
            attachment: {
                type: Object,
                default: () => null
            }
        },
        data() {
            return {
                imageUrl: null
            };
        },
        computed: {
            prettySize() {
                if (this.attachment) {
                    return prettyBytes(this.attachment.blobSizeBytes);
                }
                return '';
            },
            showImage() {
                const imageTypes = [
                    'image/gif',
                    'image/png',
                    'image/jpeg',
                    'image/svg+xml'
                ];
                if (this.attachment && this.attachment.blobSizeBytes < 20000000) {
                    return imageTypes.includes(this.attachment.mimeType);
                }
                return false;
            }
        },
        async mounted() {
            if (this.showImage) {
                await this.getImage();
            }
        },
        methods: {
            async getImage() {
                try {
                    const response = await getAttachment(this, this.attachment.key);
                    const urlCreator = window.URL || window.webkitURL;
                    this.imageUrl = urlCreator.createObjectURL(response);
                } catch (error) {
                    this.showError('Could not load image attachment');
                }
            },
            async downloadAttachment() {
                try {
                    const blob = await getAttachment(this, this.attachment.key);
                    if (blob) {
                        saveAs(blob, this.attachment.fileName);
                    }
                } catch (error) {
                    this.showError('Could not download attachment');
                }
            }
        }
    };
</script>
