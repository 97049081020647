<template>
    <div>
        <div>
            <b-button
                v-require-is-administrator
                class="is-info mt-2 mb-3"
                :model-value="createNew"
                @click="createNew = !createNew">
                <span class="icon is-small">
                    <i class="fa fa-plus" />
                </span>
                <span>
                    Create new policy
                </span>
            </b-button>
        </div>
        <div
            v-if="createNew"
            v-require-is-administrator
            style="width: 50%; margin-bottom: 35px;">
            <b-select
                v-model="newLibraryName"
                placeholder="Select a library"
                class="mb-3">
                <option
                    v-for="option in libraryNames"
                    :key="option"
                    :value="option">
                    {{ option }}
                </option>
            </b-select>
            <div v-if="newLibraryName">
                <access-policy-card
                    :systems-and-roles="systemsAndRoles"
                    @savePolicy="savePolicy"
                    @cancel="onCancel" />
            </div>
        </div>
        <div>
            <b-table
                :data="libraryPolicyList"
                :loading="loading"
                default-sort="libraryName"
                striped
                narrowed
                hoverable>
                <b-table-column
                    label="Library"
                    field="libraryName"
                    sortable
                    searchable>
                    <template #searchable="props">
                        <b-autocomplete
                            v-model="props.filters.libraryName"
                            placeholder="Filter by library"
                            :data="libraryNames"
                            open-on-focus
                            clearable>
                            <template #default="{ option }">
                                {{ option }}
                            </template>
                        </b-autocomplete>
                    </template>
                    <template #default="props">
                        {{ props.row.libraryName }}
                    </template>
                </b-table-column>
                <b-table-column
                    field="role"
                    label="Role"
                    sortable
                    searchable>
                    <template #searchable="props">
                        <b-autocomplete
                            v-model="props.filters.role"
                            placeholder="Filter by role"
                            :data="systemsAndRoles.facilityRoles"
                            open-on-focus
                            clearable>
                            <template #default="{ option }">
                                {{ option }}
                            </template>
                        </b-autocomplete>
                    </template>
                    <template #default="props">
                        {{ props.row.role }}
                    </template>
                </b-table-column>
                <b-table-column
                    field="system"
                    label="System"
                    sortable
                    searchable>
                    <template #searchable="props">
                        <b-autocomplete
                            v-model="props.filters.system"
                            placeholder="Filter by role"
                            :data="systemsAndRoles.sourceSystems"
                            open-on-focus
                            clearable>
                            <template #default="{ option }">
                                {{ option }}
                            </template>
                        </b-autocomplete>
                    </template>
                    <template #default="props">
                        {{ props.row.system }}
                    </template>
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    label="Explanation">
                    User(s) with role <b>{{ props.row.role }}</b>
                    from system <b>{{ props.row.system }}</b> is GRANTED ACCESS
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    label="Remove"
                    sortable>
                    <a @click="removePolicy(props.row)">
                        <b-icon
                            pack="fas"
                            icon="trash"
                            size="is-medium" />
                    </a>
                </b-table-column>
            </b-table>
        </div>
    </div>
</template>

<script>
    import { requireIsAdministrator } from '@/shared/directives/requirePermission';
    import { deleteLibraryPolicy, getAllAccessPolicies, getLibraries, saveLibraryPolicy } from '@/shared/helpers/api';
    import { SnackbarProgrammatic as Snackbar } from 'buefy';
    import AccessPolicyCard from './AccessPolicyCard.vue';

    export default {
        components: { AccessPolicyCard },
        directives: {
            'require-is-administrator': requireIsAdministrator
        },
        props: {
            systemsAndRoles: {
                type: Object,
                required: true
            }
        },
        data: () => {
            return {
                scopes: [],
                libraryNames: [],
                createNew: false,
                newLibraryName: null,
                libraryPolicyList: [],
                loading: false
            };
        },
        async mounted() {
            this.loading = true;
            await Promise.all([
                this.loadLibraryPolicyList(),
                this.loadLibraryNames()
            ]);
            this.loading = false;
        },
        methods: {
            async loadLibraryPolicyList() {
                const res = await getAllAccessPolicies(this);
                this.libraryPolicyList = res.flatMap(library =>
                    library.policies.map(policy => ({
                        libraryName: library.libraryName,
                        id: library.id,
                        policyId: policy.id,
                        role: policy.facilityRole,
                        system: policy.sourceSystem
                    }))
                );
            },
            async loadLibraryNames() {
                const options = {
                    scope: '',
                    tag: '',
                    includeAttributeDefinitions: false,
                    includeCodeSets: false,
                    includeAttachment: false
                };
                const res = await this.getLibraries(options);
                this.libraryNames = res.map(r => r.name).sort();
            },
            async removePolicy(library) {
                const libraryPolicy = {
                    id: library.policyId,
                    facilityRole: library.role,
                    sourceSystem: library.system
                };
                await deleteLibraryPolicy(this, library.libraryName, libraryPolicy);
                await this.loadLibraryPolicyList();
            },
            async savePolicy(policy) {
                if (this.libraryPolicyExists(policy)) {
                    this.showErrorMessage('Library policy already exists');
                } else {
                    await saveLibraryPolicy(this, this.newLibraryName, policy);
                    await this.loadLibraryPolicyList();
                    this.onCancel();
                }
            },
            libraryPolicyExists(policy) {
                return this.libraryPolicyList.some(p => p.libraryName === this.newLibraryName
                    && p.role === policy.facilityRole && p.system === policy.sourceSystem);
            },
            showErrorMessage(message) {
                Snackbar.open({
                    message: message,
                    type: 'is-danger',
                    position: 'is-top',
                    duration: 5000
                });
            },
            async getLibraries(options) {
                return await getLibraries(this, options.scope, options.tag, options.includeAttributeDefinitions,
                                          options.includeCodeSets, options.includeAttachment, options.isGlobal);
            },
            onCancel() {
                this.createNew = !this.createNew;
                this.newLibraryName = null;
            }
        }
    };
</script>
