<template>
    <b-field class="status-indicator">
        <template v-if="validationLoading">
            <b-button
                style="margin-right: -0.25ex"
                loading
                class="is-ghost" />
            Validating…
        </template>
        <span
            v-else-if="hasValidationError"
            class=" is-danger">
            <b-icon
                icon="alert-circle-outline"
                pack="mdi"
                size="is-small" />
            Validation error
        </span>
        <span
            v-else-if="hasUserChanges"
            class=" is-danger">
            <b-icon
                icon="alert-outline"
                pack="mdi"
                size="is-small" />
            Unsaved changes
        </span>
    </b-field>
</template>

<script lang="ts">
    export default {
        props: {
            hasUserChanges: {
                type: Boolean,
                default: false
            },
            validationLoading: {
                type: Boolean,
                default: false
            },
            hasValidationError: {
                type: Boolean,
                default: false
            }
        }
    };
</script>

<style scoped>
.status-indicator {
    display: flex;
    align-items: center;
    color: #aaa;

    & .is-danger {
        color: #FF1234;
    }
}
</style>
