<template>
    <div class="tile is-child notification is-secondary">
        <div class="title">
            <router-link :to="{ name: 'LibraryList' }">
                <b-image
                    src="/images/Original Logo Full.svg" />
            </router-link>
        </div>
        <div class="content">
            <p>
                <b>Common Library</b> is the home of reference data! It's the database that defines the
                set of permissible values to be used by data fields
                in many systems within Equinor.
            </p>
            <global-search />
        </div>
    </div>
</template>

<script>
    import GlobalSearch from '@/shared/components/GlobalSearch.vue';
    export default {
        components: {
            GlobalSearch
        }
    };
</script>

<style>

</style>
