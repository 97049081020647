<template>
    <div class="level">
        <ul class="process flat">
            <li
                v-for="state in states"
                role="link"
                :class="activeState(state)"
                @click="click(state)">
                {{ state }}
            </li><li />
        </ul>
    </div>
</template>

<script>
    // TODO figure out the v-for-key issue
    export default {
        props: {
            states: {
                type: Array,
                required: true
            },
            activeStates: {
                type: Array,
                required: true
            }
        },
        emits: [
            'click'
        ],
        methods: {
            activeState(state) {
                return { active: this.activeStates.includes(state), 'is-clickable': true };
            },
            click(state) {
                this.$emit('click', state);
            }
        }
    };
</script>

<style scoped>
        .process {
            /*centering*/
            overflow: hidden;
            /*Lets add the numbers for each link using CSS counters. flag is the name of the counter. to be defined using counter-reset in the parent element of the links*/
            counter-reset: flag;
            background-color: white;
            border-radius: 6px;
            box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
            color: #4a4a4a;
            display: block;
        }

            .process li {
                text-decoration: none;
                outline: none;
                display: block;
                float: left;
                font-size: 11px;
                line-height: 28px;
                color: white;
                /*need more margin on the left of links to accomodate the numbers*/
                padding: 0 10px 0 26px;
                background: #666;
                background: linear-gradient(#666, #333);
                position: relative;
            }
                /*since the first link does not have a triangle before it we can reduce the left padding to make it look consistent with other links*/
                .process li:first-child {
                    padding-left: 14px;
                    border-radius: 5px 0 0 5px; /*to match with the parent's radius*/
                }

                .process li:last-child {
                    border-radius: 0 5px 5px 0; /*this was to prevent glitches on hover*/
                    padding-right: 14px;
                }

                /*adding the arrows for the processs using rotated pseudo elements*/
                .process li:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: -14px; /*half of square's length*/
                    /*same dimension as the line-height of .process a */
                    width: 28px;
                    height: 28px;
                    /*as you see the rotated square takes a larger height. which makes it tough to position it properly. So we are going to scale it down so that the diagonals become equal to the line-height of the link. We scale it to 70.7% because if square's:
    length = 1; diagonal = (1^2 + 1^2)^0.5 = 1.414 (pythagoras theorem)
    if diagonal required = 1; length = 1/1.414 = 0.707*/
                    transform: scale(0.707) rotate(45deg);
                    /*we need to prevent the arrows from getting buried under the next link*/
                    z-index: 1;
                    /*background same as links but the gradient will be rotated to compensate with the transform applied*/
                    background: #666;
                    background: linear-gradient(135deg, #666, #333);
                    /*stylish arrow design using box shadow*/
                    /* box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1); */
                    box-shadow: 1px -1px 0 1px rgba(10, 10, 10, 0.1), 1px -1px 0 1px rgba(10, 10, 10, 0.1);
                    /*
    5px - for rounded arrows and
    50px - to prevent hover glitches on the border created using shadows*/
                    border-radius: 0 5px 0 50px;
                }
                /*we dont need an arrow after the last link*/
                .process li:last-child:after {
                    content: none;
                }

                .process li.active:after {
                    box-shadow: 1px -1px 0 1px rgba(200, 200, 200, 0.7), 1px -1px 0 1px rgba(255, 255, 255, 0.1);
                }

        .flat li, .flat li:after {
            background: white;
            color: black;
            transition: all 0.2s;
        }

            .flat li:before {
                background: white;
                box-shadow: 0 0 0 1px #ccc;
            }

            .flat li.active,
            .flat li.active:after {
                color: white;
                background: #007079;
            }
</style>
