<template>
    <div class="is-multiline is-full columns">
        <div
            class="column spec-list">
            <spinner
                :loading="loading"
                :fullscreen="false">
                <nav class="is-flex is-gap-10 mb-2">
                    <b-field class="mb-0">
                        <b-input
                            v-model="filters.search"
                            icon="magnify"
                            placeholder="Name" />
                    </b-field>
                    <b-field
                        class="mb-0"
                        style="display: flex">
                        <b-checkbox
                            v-model="filters.valid">
                            Valid only
                        </b-checkbox>
                    </b-field>
                    <b-field class="ml-auto">
                        <b-button
                            v-require-can-edit-code="{ libraryName: 'CableSpec', scopes: [scope] }"
                            :disabled="editMode || creatingMode"
                            type="is-info"
                            icon-left="plus-circle-outline"
                            @click="toggleCreateNew(true)">
                            New
                        </b-button>
                    </b-field>
                </nav>
                <b-table
                    ref="specTable"
                    v-model:selected="selectedSpec"
                    :striped="true"
                    :hoverable="true"
                    :bordered="true"
                    :mobile-cards="false"
                    custom-row-key="id"
                    :data="cableSpecSizeNames"
                    class="site-sticky-header long-list">
                    <b-table-column
                        v-slot="props"
                        centered
                        width="3ex">
                        <b-icon
                            v-if="props.row.meta.edited && !props.row.meta.isNew"
                            type="is-danger"
                            class="fa fa-fw fa-pencil-alt" />
                        <b-icon
                            v-if="props.row.meta.isNew"
                            type="is-danger"
                            class="fa fa-fw fa-plus-square" />
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        sortable
                        field="name"
                        label="Name">
                        {{ props.row.name }}
                    </b-table-column>
                    <b-table-column
                        v-if="!filters.valid"
                        v-slot="props"
                        field="isValid"
                        label="Valid"
                        centered
                        width="0%">
                        <bool-element
                            :size="'is-medium'"
                            :custom-size="'mdi-18px'"
                            :value="props.row.isValid" />
                    </b-table-column>
                    <template #empty>
                        <p class="has-text-centered">
                            No {{ filters.valid ? 'valid' : '' }} cable specs for this cable code
                        </p>
                    </template>
                </b-table>
            </spinner>
        </div>

        <div
            class="column spec-editor">
            <cable-spec-editor
                v-if="selectedSpec || editMode || creatingMode"
                :key="updateKey"
                :selected-spec="selectedSpec"
                :edit-mode="editMode"
                :creating-mode="creatingMode"
                :wire-color-edit-mode="wireColorEditMode"
                :composite="showComposite"
                :spec-list="cableSpecSizeNames"
                @done="doneEditingSpec($event)"
                @edited="specEdited = $event"
                @toggleEditMode="editMode = $event; creatingMode = false"
                @toggleCreateNew="toggleCreateNew($event)"
                @eraseChange="eraseChange" />
        </div>

        <div
            v-show="selectedSpec"
            class="column wire-colour-editor">
            <wire-color
                ref="wireColorRef"
                v-slot="props"
                :selected-spec="selectedSpec"
                :edit-mode="wireColorEditMode"
                @edited="wireColorEdited = $event"
                @done="wireColorEditMode = false">
                <nav
                    class="is-flex is-align-items-center is-gap-10 mb-2">
                    <p
                        class="title is-5 mb-0 mr-auto"
                        style="text-wrap: nowrap">
                        Wire Colours
                    </p>
                    <button
                        v-if="wireColorEdited || wireColorEditMode"
                        class="button is-secondary"
                        style="margin-left: auto"
                        :disabled="!(selectedSpec && !editMode) || !wireColorEdited"
                        @click="clickRemoveChange">
                        <span class="icon is-small">
                            <i class="fa fa-eraser" />
                        </span>
                        <span>Remove changes</span>
                    </button>
                    <toggle-button
                        v-require-can-edit-code="{ libraryName: 'CableSpecWire', scopes: [scope] }"
                        :disabled="!(selectedSpec && !editMode && !props.loading)"
                        :toggle="wireColorEditMode"
                        standard-text="Edit wire colours"
                        :icon="'pencil'"
                        :color="'is-info'"
                        @toggle-button="toggleWireColor"
                        @toggle-button-cancel="toggleWireColor" />
                    <button
                        v-if="wireColorEditMode"
                        class="button is-primary"
                        @click="$refs.wireColorRef.editDone">
                        <span class="icon is-small">
                            <i
                                class="fa fa-check-circle"
                                aria-hidden="true" />
                        </span>
                        <span>Done editing</span>
                    </button>
                </nav>
            </wire-color>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState, mapStores } from 'pinia';
    import CableSpecEditor from './CableSpecEditor.vue';
    import WireColor from './WireColor.vue';
    import BoolElement from '@/shared/components/BoolElement.vue';
    import BoolSelector from '@/shared/components/BoolSelector.vue';
    import ToggleButton from '@/shared/components/ToggleButton.vue';
    import Spinner from '@/shared/components/Spinner.vue';
    import CodeRefSelector from '@/shared/components/CodeRefSelector.vue';
    import messageDialogMixin from '@/shared/mixins/messageDialogMixin.js';
    import { libNames } from '@/shared/helpers/cableEditHelpers.js';
    import { requireCanEditCode } from '@/shared/directives/requirePermission';
    import { useCodeEditStore } from '@/stores/codeEditStore.js';

    const compositCableTypes = ['VENDOR'];

    export default {
        components: {
            BoolElement,
            BoolSelector,
            WireColor,
            ToggleButton,
            Spinner,
            CodeRefSelector,
            CableSpecEditor
        },
        directives: {
            requireCanEditCode
        },
        mixins: [
            messageDialogMixin
        ],
        beforeRouteLeave(to, from, next) {
            this.wireColorEditMode = false;
            this.wireColorEdited = false;
            this.selectedSpec = null;
            next();
        },
        data() {
            return {
                cableCodeLibName: libNames.cableCode,
                wireColorEditMode: false,
                wireColorEdited: false,
                loading: true,
                editMode: false,
                creatingMode: false,
                editId: null,
                filters: {
                    valid: false,
                    search: ''
                },
                specEdited: false,
                updateKey: 1
            };
        },
        computed: {
            ...mapStores(useCodeEditStore),
            ...mapState(useCodeEditStore, ['scope']),
            showComposite() {
                return this.filteredCodes(libNames.cableCodeType)
                    .map(c => c.values.CableType).some(type => compositCableTypes.includes(type));
            },
            selectedCableCode() {
                return this.codeEditStore.selectedCodes[this.cableCodeLibName];
            },
            selectedSpec: {
                get() {
                    return this.cableSpecSizeNames.find(c => c.id === this.codeEditStore.selectedCodes[libNames.cableSpec]);
                },
                set(spec) {
                    const changeSpec = () => {
                        if (spec) {
                            this.editId = spec.id;
                            this.codeEditStore.selectCode(libNames.cableSpec, spec.id);
                        } else {
                            this.editId = null;
                            this.codeEditStore.selectCode(libNames.cableSpec, null);
                        }
                        this.goToTop();
                    };

                    if (this.wireColorEditMode && this.wireColorEdited) {
                        this.messageDialog('Changing selected spesification will result in wire colour changes being discarded', false, 'Discard')
                            .then(() => {
                                this.wireColorEditMode = !this.wireColorEditMode;
                                this.wireColorEdited = false;
                                changeSpec();
                            })
                            .catch(() => {});
                    } else {
                        this.editMode = false;
                        this.creatingMode = false;
                        this.wireColorEditMode = false;
                        changeSpec();
                    }
                }
            },
            isChanged() {
                if (this.selectedSpec && this.selectedSpec.meta)
                    return this.selectedSpec.meta.isNew || this.selectedSpec.meta.edited;
                else
                    return false;
            },
            cableSpecSizeNames() {
                if (this.filteredCodes(libNames.cableSpec).length) {
                    let search = null;
                    if (this.filters.search !== '')
                        search = this.filters.search.toLowerCase();

                    const selectedCodesLength = this.codeEditStore.selectedCodes[libNames.cableCode] === undefined
                        ? undefined
                        : this.codeEditStore.selectedCodes[libNames.cableCode].length + 1;

                    return this.filteredCodes(libNames.cableSpec)
                        .map(spec => (
                            {
                                name: spec.values.name.slice(selectedCodesLength),
                                id: spec.values.identity,
                                groups: spec.values.NumberOfGroups,
                                wires: spec.values.NumberOfWires,
                                crosss: spec.values.CrossSectionSize,
                                isValid: spec.values.isValid,
                                meta: spec.meta
                            }
                        ))
                        .filter(s => this.filters.valid ? s.isValid : true)
                        .filter(s => search ? s.name.toLowerCase().includes(search) : true)
                        .sort((a, b) => {
                            const g = parseInt(a.groups, 10) - parseInt(b.groups, 10);
                            if (g === 0) {
                                const w = parseInt(a.wires, 10) - parseInt(b.wires, 10);
                                if (w === 0)
                                    return parseFloat(a.crosss.split('|')[0]) - parseFloat(b.crosss.split('|')[0]);
                                else
                                    return w;
                            } else {
                                return g;
                            }
                        });
                }
                return [];
            }
        },
        watch: {
            selectedCableCode() {
                this.editMode = false;
            }
        },
        mounted() {
            this.filters.valid = true;
            this.loading = false;
        },
        async activated() {
            if (!this.selectedSpec) {
                this.selectedSpec = this.cableSpecSizeNames[0];
            }
            this.filters.valid = true;
            if (!this.editId && this.selectedSpec)
                this.editId = this.selectedSpec.id;

            this.goToTop();
        },
        async deactivated() {
            if (this.wireColorEditMode) {
                this.wireColorEditMode = !this.wireColorEditMode;
                this.wireColorEdited = false;
            }
        },
        methods: {
            ...mapActions(useCodeEditStore, ['filteredCodes']),
            async toggleWireColor() {
                if (this.wireColorEdited && this.wireColorEditMode)
                    this.messageDialog('Exiting will cause you to lose changes you have done in wire colour codes', false, 'Continue')
                        .then(() => {
                            this.wireColorEditMode = !this.wireColorEditMode;
                        })
                        .catch(() => {});
                else
                    this.wireColorEditMode = !this.wireColorEditMode;
            },
            doneEditingSpec(code) {
                this.selectedSpec = this.cableSpecSizeNames.find(c => c.id === code.values.identity);
                this.creatingMode = false;
                this.specEdited = false;
                this.editMode = false;
            },
            eraseChange() {
                if (this.selectedSpec?.meta.isNew) {
                    this.removeRelatedColorsChanges();
                    this.codeEditStore.removeChange(libNames.cableSpec, this.selectedSpec.id);
                    this.selectedSpec = this.cableSpecSizeNames[0];
                } else if (this.selectedSpec) {
                    const cableSpecChanges = this.codeEditStore.changes[libNames.cableSpec]?.find(c => c.values.identity === this.selectedSpec.id);
                    if (cableSpecChanges) {
                        this.codeEditStore.removeChange(libNames.cableSpec, this.selectedSpec.id);
                    }
                }
                this.specEdited = false;
            },
            toggleCreateNew(edit) {
                this.selectedSpec = !this.editMode ? null : this.cableSpecSizeNames[0];
                this.editMode = edit;
                this.specEdited = false;
                this.creatingMode = edit;
            },
            removeRelatedColorsChanges() {
                const changes = this.codeEditStore.changes[libNames.cableSpecWire]
                    ?.filter(c => c.values.CableSpec === this.selectedSpec.id);
                changes?.forEach(c => {
                    this.codeEditStore.removeChange(libNames.cableSpecWire, c.values.identity);
                });
            },
            clickRemoveChange() {
                this.removeRelatedColorsChanges();
                this.$refs.wireColorRef.eraseChanges();
                this.wireColorEdited = false;
            },
            goToTop() {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            },
            onSelectSpec(spec) {
                this.selectedSpec = spec;
            }
        }
    };

</script>

<style scoped>
.long-list {
    max-height: 60vh;
    overflow-x: hidden;
}
.b-table :deep(td) {
    vertical-align: middle;
}
.spec-list {
    min-width: 300px;
}
.spec-editor {
    @media (width > 600px) {
        min-width: 600px;
    }
}
@media (width > 1200px) {
    .spec-list {
        max-width: 400px;
    }
}
@media (width > 800px) {
    .spec-editor {
        max-width: 800px;
    }
    .wire-colour-editor {
        max-width: 800px;
    }
}
</style>
