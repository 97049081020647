import { comparing, createBTableComparator } from '@/shared/helpers/utils.js';
import _ from 'lodash';

// The code is a simplified version of BTable's sortBy method, with locale sensitive string comparison added.
function customSortBy(array, key, fn, isAsc) {
    if (typeof fn !== 'function') {
        fn = createBTableComparator(
            comparing(x => _.get(x, key, undefined))
        );
    }
    return array.toSorted((a, b) => fn(a, b, isAsc));
}

export function monkeyPatchBTableSort(app) {
    app.component('BTable').methods.sortBy = customSortBy;
}
