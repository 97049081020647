import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { getScopeFilter, setScopeFilter } from '../shared/helpers/scopefilterHelpers.js';
import { getScopes, getScopesTypeRegex } from '@/shared/helpers/api.ts';
import { http } from '../shared/httpWrapper.js';
import _ from 'lodash';

export const useScopeFilterStore = defineStore('scopeFilter', () => {
    const scopes = ref([]);
    const selectedScopeFilter = ref([]);
    const scopeFilterLoading = ref(true);
    const scopeType = ref(null);

    const lastUpdated = ref(null);
    const scopeFilterRegex = ref(null);

    const scopeFilterLabel = computed(() => {
        let defaultLabel = 'Scope filter';

        if (scopeType.value) {
            defaultLabel = `${scopeType.value} filter`;
        }
        const count = selectedScopeFilter.value.length;
        return count > 0 ? `${defaultLabel} (${count})` : defaultLabel;
    });

    const filterIcon = computed(() => {
        return selectedScopeFilter.value.length > 0 ? 'filter' : 'filter-off';
    });

    function updateScopes(scps) {
        lastUpdated.value = new Date();
        scopes.value = scps.toSorted();
    }

    function setScopeType(type) {
        if (scopeType.value !== type) {
            scopeType.value = type;
            lastUpdated.value = null;
            scopeFilterRegex.value = null;
            selectedScopeFilter.value = [];
        }
    }

    function updateScopeFilter(scope, saveToLocalStorage = true) {
        let scopeArray;
        if (typeof scope === 'string') {
            scopeArray = [scope];
        } else {
            scopeArray = scope;
        }

        if (scopeFilterRegex.value !== null) {
            scopeArray = scopeArray.filter(scp => scopeFilterRegex.value.test(scp));
        }

        scopeArray.forEach(scp => {
            if (selectedScopeFilter.value.includes(scp)) {
                selectedScopeFilter.value = selectedScopeFilter.value.filter(s => s !== scp);
            } else {
                selectedScopeFilter.value = selectedScopeFilter.value.concat([scp]).toSorted();
            }
        });
        if (saveToLocalStorage) {
            setScopeFilter(scopeType.value, selectedScopeFilter.value);
        }
    }

    function setRegEx(regEx) {
        if (regEx !== null) {
            scopeFilterRegex.value = new RegExp(regEx);
        }
    }

    async function init() {
        scopeFilterLoading.value = true;

        const timediff = (new Date() - lastUpdated?.value) / 1000;
        const cacheAgeInSeconds = 30;

        if (timediff > cacheAgeInSeconds) {
            const response = await getScopes(http, scopeType.value);

            updateScopes(response);
            const scopeTypeRegExRes = await getScopesTypeRegex(http, scopeType.value);

            if (scopeTypeRegExRes !== '') {
                setRegEx(scopeTypeRegExRes.regEx);
            }
            const localStorageScopeFilter = getScopeFilter(scopeType.value);
            const combined = _.concat(selectedScopeFilter.value, localStorageScopeFilter);
            if (localStorageScopeFilter.length > 0) {
                updateScopeFilter(combined, false);
            }
        }

        scopeFilterLoading.value = false;
    }

    function clearScopeFilter() {
        selectedScopeFilter.value = [];
        setScopeFilter(scopeType.value, selectedScopeFilter.value);
    }

    return {
        scopes,
        scopeType,
        selectedScopeFilter,
        scopeFilterLoading,
        scopeFilterLabel,
        filterIcon,
        lastUpdated,
        scopeFilterRegex,
        setScopeType,
        setRegEx,
        updateScopes,
        updateScopeFilter,
        clearScopeFilter,
        init
    };
});
