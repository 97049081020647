<template>
    <div
        v-if="release"
        class="modal-card big-modal">
        <header class="modal-card-head">
            <p class="modal-card-title">
                Review Changes Done
            </p>

            <button
                class="button is-info"
                @click="verify">
                <span class="icon is-small">
                    <b-icon
                        icon="play-circle-outline"
                        size="is-small"
                        aria-hidden="true" />
                </span>
                <span>Continue</span>
            </button>
        </header>
        <section class="modal-card-body">
            <release-info-header :release="release" />

            <release-change-table
                v-if="isCodeChangeDocument"
                :change-document="release.specification"
                :editable="release.state === 'Open'" />
        </section>
    </div>
</template>

<script>

    import ReleaseChangeTable from './ReleaseChangeTable.vue';
    import ReleaseInfoHeader from './ReleaseInfoHeader.vue';

    export default {
        components: {
            ReleaseInfoHeader,
            ReleaseChangeTable
        },
        props: {
            release: {
                type: Object,
                required: true
            }
        },
        emits: [
            'verify'
        ],
        computed: {
            isCodeChangeDocument() {
                return (
                    this.release.specification.specificationType === 'CompositeChangeDocument'
                    || this.release.specification.specificationType === 'ChangeDocument');
            }
        },
        mounted() {
        },
        methods: {
            verify() {
                this.$emit('verify');
            }
        }
    };
</script>

<style scoped>
    .big-modal {
        height: auto;
        width: 90vw;
        margin-left: 5vw;
        margin-top: 5vh;
    }

    .has-margin-left4 {
        margin-left: 4vw;
    }
</style>
