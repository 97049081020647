<script setup lang="ts">
    import { computed } from 'vue';

    type RuleViolation = {
        name: string;
        message: string;
        description: string;
        type: 'ERROR' | 'WARNING';
    };

    type ValidationResult = {
        isValid: boolean;
        ruleViolations: RuleViolation[];
        syntax: string;
    };

    type Props = {
        result: ValidationResult | null;
        loading: boolean;
        error: string | null;
    };

    const props = defineProps<Props>();

    const isValid = computed(() =>
        props.result?.isValid
    );
    const hasWarning = computed(() =>
        isValid.value && props.result?.ruleViolations.some((v) => v.type === 'WARNING')
    );
    const isUnique = computed(() =>
        isValid.value && !hasWarning.value
    );
    const hasConflict = computed(() =>
        !isValid.value && props.result
    );
</script>

<template>
    <div>
        <p v-if="loading">
            <i>Analysing format...</i><br>
            <b-progress size="is-small" />
        </p>
        <div
            v-else-if="isUnique">
            <p class="title is-5">
                Syntax preview: {{ result!.syntax }}
            </p>
            <p>
                <b-icon
                    icon="check-circle"
                    size="is-small"
                    style="color: green;" /> Verified for uniqueness
            </p>
        </div>
        <div
            v-else-if="hasConflict">
            <p class="title is-5">
                Syntax preview: {{ result!.syntax }}
            </p>
            <p>
                <b-icon
                    icon="close-circle"
                    size="is-small"
                    style="color: red;" /> Format verification failed
            </p>
            <p v-for="conflict in result!.ruleViolations">
                <b>{{ conflict.description }}:</b> {{ conflict.message }}
            </p>
            <p style="color: red; font-weight: bold;">
                You will not be able to add these changes without modification!
            </p>
        </div>
        <div
            v-else-if="hasWarning">
            <p class="title is-5">
                Syntax preview: {{ result!.syntax }}
            </p>
            <p>
                <b-icon
                    icon="alert-circle"
                    size="is-small"
                    style="color: red;" />
                Syntax verification warning
            </p>
            <p
                v-for="warning in result!.ruleViolations"
                style="color: orange; font-weight: bold;">
                <b>{{ warning.description }}:</b> {{ warning.message }}
            </p>
        </div>
        <p
            v-else-if="error"
            class="validation-error title is-5">
            Validation failed: {{ error }}
        </p>
        <p
            v-else>
            ...
        </p>
    </div>
</template>

<style scoped>
    .validation-error {
        max-width: 60ch;
    }

    .title {
        margin-bottom: 0 !important;
    }
</style>
