<template>
    <pre
        class="mermaid" />
</template>
<script>
    import mermaid from 'mermaid';
    import { nanoid } from 'nanoid';

    export default {
        name: 'MermaidDiagram',
        props: {
            umlSource: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                id: null
            };
        },
        watch: {
            async umlSource() {
                await this.renderMermaid();
            }
        },
        async mounted() {
            this.id = `mermaid-${nanoid()}`;
            await mermaid.initialize({
                securityLevel: 'loose',
                startOnLoad: false,
                theme: 'base',
                themeVariables: {
                    primaryColor: '#ECECFF',
                    primaryTextColor: '#000000',
                    primaryBorderColor: '#9370DB',
                    lineColor: '#9370DB'
                }
            });

            await this.renderMermaid();
        },
        methods: {
            isUnmounted() {
                return !document.body.contains(this.$el);
            },
            async renderMermaid() {
                const { svg, bindFunctions } = await mermaid.render(`${this.id}`, this.umlSource);
                if (this.isUnmounted())
                    return;
                this.$el.innerHTML = svg;
                bindFunctions?.(this.$el);
            }
        }
    };
</script>
<style scoped>
.mermaid {
    background-color: white
}
</style>
