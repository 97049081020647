<template>
    <div>
        <div>
            <h4 class="title is-4 block">
                Downloads
            </h4>
            <div class="button-group is-grouped">
                <button
                    title="Export to DOCX (Word)"
                    class="button is-info margin-right"
                    @click="exportDocForm">
                    <b-icon
                        icon="file-word"
                        size="is-small" />
                    <span>Specification document</span>
                </button>
                <button
                    class="button is-info margin-right"
                    @click="exportCSharp">
                    <b-icon
                        icon="download"
                        size="is-small" />
                    <span>C# type library</span>
                </button>
            </div>
        </div>
        <div>
            <h4 class="title is-4 block">
                Schema mapping
            </h4>
            <div class="button-group is-grouped">
                <router-link
                    :to="{ name: 'SchemaDTO', params: { identity: identity}}"
                    class="button is-info margin-right">
                    Review / test mapping
                </router-link>
            </div>
        </div>
        <div v-require-can-edit-code="{ libraryName: 'SchemaClass', scopes: [] }">
            <h4 class="title is-4 block">
                Edit schema definition
            </h4>
            <div
                class="button-group is-grouped">
                <router-link
                    :to="{ name: 'SchemaClass', params: { identity: identity}}"
                    class="button is-primary margin-right">
                    <b-icon
                        icon="pencil"
                        size="is-small" />
                    <span>Classes</span>
                </router-link>
                <router-link
                    :to="{name: 'SchemaInterfaceEditor', params: { identity: identity }}"
                    class="button is-primary margin-right">
                    <b-icon
                        icon="pencil"
                        size="is-small" />
                    <span>Interfaces</span>
                </router-link>
                <router-link
                    :to="{ name: 'SchemaAttribute', params: { identity: identity }}"
                    class="button is-primary margin-right">
                    <b-icon
                        icon="pencil"
                        size="is-small" />
                    <span>Attributes</span>
                </router-link>
                <router-link
                    :to="{ name: 'SchemaRelation', params: {identity: identity}}"
                    class="button is-primary margin-right">
                    <b-icon
                        icon="pencil"
                        size="is-small" />
                    <span>Relations</span>
                </router-link>
            </div>
        </div>
        <div v-require-can-edit-code="{ libraryName: 'SchemaMapClass', scopes: [] }">
            <h4 class="title is-4 block">
                Edit mapping
            </h4>
            <div class="button-group is-grouped">
                <router-link
                    :to="{name: 'SchemaMapClass', params: { identity: identity }}"
                    class="button is-primary margin-right">
                    <b-icon
                        icon="pencil"
                        size="is-small" />
                    <span>Classes</span>
                </router-link>
                <router-link
                    :to="{ name: 'SchemaMapAttribute', params: { identity: identity }}"
                    class="button is-primary margin-right">
                    <b-icon
                        icon="pencil"
                        size="is-small" />
                    <span>Attributes</span>
                </router-link>
                <router-link
                    :to="{ name: 'SchemaMapRelation', params: { identity: identity }}"
                    class="button is-primary margin-right">
                    <b-icon
                        icon="pencil"
                        size="is-small" />
                    <span>Relations</span>
                </router-link>
            </div>
        </div>
        <div>
            <b-modal
                v-model="exportDocModal"
                trap-focus
                has-modal-card>
                <schema-export-doc-form
                    :identity="identity"
                    @close="onClose" />
            </b-modal>
        </div>
    </div>
</template>

<script>
    import { requireCanEditCode } from '@/shared/directives/requirePermission';
    import { getCompileCSharp } from '@/shared/helpers/api';
    import { showMixin } from '@/shared/mixins/showMixin';
    import saveAs from 'file-saver';
    import SchemaExportDocForm from './SchemaExportDocModal.vue';

    export default {
        directives: {
            'require-can-edit-code': requireCanEditCode
        },
        components: {
            SchemaExportDocForm
        },
        mixins: [
            showMixin
        ],
        props: {
            identity: {
                default: null,
                type: String
            }
        },
        data() {
            return {
                exportDocModal: false
            };
        },
        methods: {
            async exportCSharp() {
                try {
                    const response = await getCompileCSharp(this, this.identity);
                    if (response.data) {
                        const filename = this.identity + '.zip';
                        const blob = new Blob([response.data], { type: response.headers['Content-Type'] });
                        saveAs(blob, filename);
                    }
                } catch (ex) {
                    this.showError(ex);
                }
            },
            exportDocForm() {
                this.exportDocModal = true;
            },
            onClose() {
                this.exportDocModal = false;
            }
        }
    };
</script>
<style scoped>
.margin-right {
    margin-right: 9.6px;
}
.button-group {
    margin-bottom: 10px;
    margin-left: 5px;
}
</style>
