<template>
    <div class="field is-grouped is-grouped-multiline">
        <div
            v-for="(tag, idx) in sortedTags"
            v-cloak
            :key="idx"
            class="control">
            <div class="tags are-medium has-addons">
                <span
                    class="tag"
                    :class="type">
                    {{ tagMap(tag) }}
                </span>
                <a
                    v-if="filterFunction(tag)"
                    class="tag is-dark"
                    @click="$emit('click-tag', tag)">
                    <i
                        :class="iconClass"
                        aria-hidden="true" />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            tagMap: {
                type: Function,
                required: false,
                default: x => x
            },
            tags: {
                type: Array,
                default: () => []
            },
            iconClass: {
                type: String,
                default: () => 'fa fa-filter'
            },
            filterFunction: {
                type: Function,
                default: () => true
            },
            type: {
                type: String,
                default: 'is-info'
            }
        },
        emits: [
            'click-tag'
        ],
        computed: {
            sortedTags() {
                return [...this.tags].sort();
            }
        }
    };
</script>
