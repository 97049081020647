<template>
    <div id="editor">
        <b-field
            grouped
            position="is-right">
            <b-button
                v-if="!loading"
                v-require-can-edit-code="{ libraryName: 'OrganizationalUnit' }"
                class="is-primary"
                @click="newOrgUnit">
                <b-icon
                    icon="pencil"
                    size="is-small" />
                <span>Create new unit</span>
            </b-button>
        </b-field>

        <div
            v-if="showNewDialog">
            <h2
                v-show="codeId"
                class="title">
                Edit Organizational Unit:
            </h2>
            <h2
                v-show="!codeId"
                class="title">
                Create a new Organizational Unit:
            </h2>
            <code-edit
                :key="orgUnitCodeEditKey"
                :quick-commit="true"
                :show-cancel="true"
                :code-id="codeId"
                :code-set-name="codeSetName"
                library="OrganizationalUnit"
                @refresh="refreshOrgUnits"
                @cancel="cancelNew" />
            <hr>
        </div>
        <b-table
            :loading="loading"
            :data="orgUnitList"
            default-sort="name"
            :striped="true"
            :narrowed="true"
            :hoverable="true"
            class="site-sticky-header">
            <b-table-column
                v-slot="props"
                field="name"
                label="Name"
                sortable>
                {{ props.row.Name }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="description"
                label="Description"
                sortable>
                {{ props.row.Description }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="isValid"
                label="Is valid"
                centered
                sortable>
                <bool-element
                    :value="props.row.IsValid" />
            </b-table-column>
            <b-table-column
                v-slot="props"
                v-require-can-edit-code="{ libraryName: 'OrganizationalUnit' }"
                field="id"
                style="justify-content:flex-end"
                label="Edit"
                numeric>
                <a
                    v-scroll-to="'#editor'"
                    class="button"
                    @click="edit(props.row.Id)"><b-icon
                        icon="pencil"
                        size="is-small" /></a>
            </b-table-column>
        </b-table>
    </div>
</template>

<script>
    import _ from 'lodash';
    import CodeEdit from '@/shared/components/CodeEdit.vue';
    import BoolElement from '@/shared/components/BoolElement.vue';
    import { requireCanEditCode } from '@/shared/directives/requirePermission.js';
    import { genericViewQueryAsText, getCodeSets } from '@/shared/helpers/api.ts';
    import { encodeIdBase64 } from '@/shared/helpers/utils.js';

    export default {
        directives: {
            'require-can-edit-code': requireCanEditCode
        },
        components: {
            CodeEdit,
            BoolElement
        },
        data() {
            return {
                loading: true,
                codeId: null,
                orgUnitCodeEditKey: 1,
                showNewDialog: false,
                orgUnitList: [],
                orgUnitQuery: null,
                codeSetName: null
            };
        },
        watch: {
            orgUnitQuery(value) {
                this.orgUnitList = value;
                this.filterValid();
            }
        },
        async mounted() {
            await Promise.all([
                this.fetchOrgUnitList(),
                this.fetchCodeSetName()
            ]);
            this.loading = false;
        },
        methods: {
            async fetchOrgUnitList() {
                const list = (await genericViewQueryAsText(
                    this,
                    `FROM OrganizationalUnit
                    SELECT Id, Name, Description, IsValid`
                )).data;
                this.orgUnitQuery = _.sortBy(list, x => x.Name);
            },
            async fetchCodeSetName() {
                const codeSets = await getCodeSets(this, 'OrganizationalUnit');
                this.codeSetName = codeSets[0].name;
            },
            filterValid() {
                this.orgUnitList = this.orgUnitList.filter(x => x.isValid !== false);
            },
            newOrgUnit() {
                this.codeId = null;
                this.showNewDialog = true;
                this.orgUnitCodeEditKey += 1;
            },
            cancelNew() {
                this.codeId = null;
                this.showNewDialog = false;
            },
            async refreshOrgUnits() {
                this.showNewDialog = false;
                await this.fetchOrgUnitList();
            },
            edit(id) {
                this.codeId = encodeIdBase64('Code', id);
                this.showNewDialog = true;
                this.orgUnitCodeEditKey += 1;
            }
        }
    };
</script>
