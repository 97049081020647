import _ from 'lodash';

const isBlobWithJsonError = function(error) {
    return _.has(error, 'response.config.responseType')
        && error.response.config.responseType === 'blob'
        && error.response.data.type === 'application/json';
};

export const showMixin = {
    methods: {
        showError(error, callback) {
            let message = 'Unknown Error';

            if (typeof error === 'string') {
                message = error;
            } else if (isBlobWithJsonError(error)) {
                message = new Response(error.response.data).json().then(e => e.error ?? e.message);
            } else if (error?.response?.status === 403) {
                message = error.response.statusText;
            } else if (_.has(error, 'response.data.error')) {
                message = error.response.data.error;
            } else if (_.has(error, 'response.body.error')) {
                message = error.response.body.error;
            } else if (_.has(error, 'response.data') && typeof error.response.data === 'object') {
                const validationResult = error.response.data;
                message = '';
                Object.keys(validationResult).forEach(vr => {
                    if (validationResult[vr] !== null) {
                        message += validationResult[vr];
                    }
                });
            } else if (_.has(error, 'response.data') && typeof error.response.data === 'string') {
                message = error.response.data;
            } else if (error instanceof Error) {
                message = error.message;
            }

            Promise.resolve(message).then(m => {
                if (callback) {
                    callback(m);
                } else {
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: m,
                        position: 'is-top',
                        type: 'is-danger'
                    });
                }
            });
        },
        showInfo(message) {
            this.$buefy.toast.open({
                duration: 5000,
                message: message,
                position: 'is-top',
                type: 'is-info'
            });
        },
        showAlert(_ignored, title, message, callback) {
            this.$buefy.dialog.alert({
                title: title,
                message: message,
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    if (callback) {
                        callback();
                    }
                }
            });
        }
    }
};
