/**
 * Takes a string and converts it to a data URL
 * @param svg
 * @param maxSvgHeight
 * @param maxSvgWidth
 * @returns {string}
 */
export function getImageDataURL(svg, maxSvgHeight, maxSvgWidth) {
    if (!svg) return;
    const resizedSvg = resizeSvg(svg, maxSvgHeight, maxSvgWidth);
    return 'data:image/svg+xml;base64,' + btoa(resizedSvg);
}

function resizeSvg(svg, maxHeight, maxWidth) {
    const parser = new DOMParser();
    const originalSvg = parser.parseFromString(svg, 'image/svg+xml');
    const originalWidth = parseFloat(originalSvg.documentElement.getAttribute('width'));
    const originalHeight = parseFloat(originalSvg.documentElement.getAttribute('height'));

    let newWidth, newHeight;

    if (originalWidth / maxWidth > originalHeight / maxHeight) {
        newWidth = maxWidth;
        newHeight = originalHeight / originalWidth * maxWidth;
    } else {
        newHeight = maxHeight;
        newWidth = originalWidth / originalHeight * maxHeight;
    }

    if (newHeight > maxHeight) {
        newHeight = maxHeight;
        newWidth = originalWidth / originalHeight * maxHeight;
    }

    originalSvg.documentElement.setAttribute('width', newWidth);
    originalSvg.documentElement.setAttribute('height', newHeight);

    return new XMLSerializer().serializeToString(originalSvg);
}
