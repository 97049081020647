<template>
    <div>
        <h1 class="title">
            {{ pageTitle }}
        </h1>
        <b-tabs
            :model-value="tabNumber"
            type="is-boxed"
            class="block"
            @update:model-value="onChangeTabNumber">
            <b-tab-item label="Master Projects">
                <project-master-list-container />
            </b-tab-item>
            <b-tab-item label="Facilities">
                <facility-list-container />
            </b-tab-item>
            <b-tab-item label="Org. units">
                <org-unit-list-container />
            </b-tab-item>
            <b-tab-item label="Projects">
                <project-list-container />
            </b-tab-item>
            <b-tab-item label="Contracts">
                <contract-list-container />
            </b-tab-item>
        </b-tabs>
    </div>
</template>

<script>
    import ProjectMasterListContainer from './ProjectMasterListContainer.vue';
    import FacilityListContainer from './FacilityListContainer.vue';
    import OrgUnitListContainer from './OrgUnitListContainer.vue';
    import ProjectListContainer from './ProjectListContainer.vue';
    import ContractListContainer from './ContractListContainer.vue';

    const tabs = [
        {
            routeName: 'ProjectMasterList',
            title: 'Master Project admin'
        },
        {
            routeName: 'FacilityList',
            title: 'Facility admin'
        },
        {
            routeName: 'OrgUnitList',
            title: 'Org. unit admin'
        },
        {
            routeName: 'ProjectList',
            title: 'Project admin'
        },
        {
            routeName: 'ContractList',
            title: 'Contract admin'
        }
    ];

    export default {
        components: {
            ProjectMasterListContainer,
            FacilityListContainer,
            OrgUnitListContainer,
            ProjectListContainer,
            ContractListContainer
        },
        computed: {
            pageTitle() {
                return tabs[this.tabNumber].title;
            },
            tabNumber() {
                return tabs.findIndex(tab => tab.routeName === this.$route.name);
            }
        },
        methods: {
            async onChangeTabNumber(newTabNumber) {
                const name = tabs[newTabNumber].routeName;
                await this.$router.push({ name });
            }
        }
    };
</script>
