<template>
    <div
        v-if="ready">
        <div class="columns">
            <div class="column">
                <div class="panel is-success">
                    <div class="panel-heading">
                        Schema model
                    </div>
                    <network-visualization
                        ref="network"
                        class="network"
                        :nodes="nodes"
                        :edges="edges"
                        :options="options"
                        @click="networkClick" />
                </div>
            </div>
            <div class="column is-3">
                <div class="panel is-success">
                    <div class="panel-heading">
                        ...
                    </div>
                    <div class="panel-block">
                        <div class="control">
                            <b-checkbox v-model="showClasses">
                                Show classes
                            </b-checkbox>
                            <b-checkbox v-model="showInterfaces">
                                Show interfaces
                            </b-checkbox>
                            <b-checkbox v-model="showAttributes">
                                Show attributes
                            </b-checkbox>
                            <b-checkbox v-model="focusSelected">
                                Focused mode
                            </b-checkbox>
                        </div>
                    </div>
                </div>
                <div
                    v-if="selectedNode"
                    class="panel is-success">
                    <div class="panel-heading">
                        Details
                    </div>
                    <div class="panel-block">
                        <table class="table is-striped is-narrow is-fullwidth">
                            <tr
                                v-for="(value, propName) in selectedObject"
                                :key="propName">
                                <td>{{ propName }}</td>
                                <td>
                                    {{ value }}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        Loading..
    </div>
</template>

<script>
    import { genericViewQuery } from '@/shared/helpers/api';
    import NetworkVisualization from '@/shared/vis/NetworkVisualization.vue';
    import _ from 'lodash';

    export default {
        components: {
            NetworkVisualization
        },
        props: {
            identity: {
                default: null,
                type: String
            }
        },
        data() {
            return {
                options: {
                    nodes: {
                        shape: 'box'
                    },
                    physics: {
                        enabled: true,
                        solver: 'forceAtlas2Based'
                    }
                },
                schema: null,
                classes: null,
                interfaces: null,
                classInterfaces: null,
                attributes: null,
                showClasses: true,
                showInterfaces: false,
                showAttributes: false,
                selectedNode: null,
                focusSelected: false
            };
        },
        computed: {
            ready() {
                return this.classes
                    && this.interfaces;
            },
            nodes() {
                const nodes = [];

                if (this.focusSelected && this.selectedObject) {
                    const selected = this.selectedObject;
                    switch (this.selectedObjectType) {
                        case 'class':
                            nodes.push(this.classToNode(selected));
                            if (selected.HasBaseClass) {
                                nodes.push(this.classToNode(_.find(this.classes, { Identity: selected.HasBaseClass })));
                            }
                            this.classes.forEach(c => {
                                if (c.HasBaseClass === selected.Identity) {
                                    nodes.push(this.classToNode(c));
                                }
                            });
                            if (this.interfaces && this.classInterfaces && this.showInterfaces) {
                                this.classInterfaces.forEach(ci => {
                                    if (ci.Class === selected.Identity) {
                                        nodes.push(this.interfaceToNode(_.find(this.interfaces, { Identity: ci.Interface })));
                                        if (this.attributes && this.showAttributes) {
                                            this.attributes.forEach(a => {
                                                if (a.Interface === ci.Interface) {
                                                    nodes.push(this.attributeToNode(a));
                                                }
                                            });
                                        }
                                    }
                                });
                            }
                            return nodes;

                        case 'interface':
                            nodes.push(this.interfaceToNode(selected));
                            if (this.attributes && this.showAttributes) {
                                this.attributes.forEach(a => {
                                    if (a.Interface === selected.Identity) {
                                        nodes.push(this.attributeToNode(a));
                                    }
                                });
                            }
                            if (this.classes && this.classInterfaces && this.showClasses) {
                                this.classInterfaces.forEach(ci => {
                                    if (ci.Interface === selected.Identity) {
                                        nodes.push(this.classToNode(_.find(this.classes, { Identity: ci.Class })));
                                    }
                                });
                            }
                            return nodes;

                        case 'attribute':
                            nodes.push(this.attributeToNode(selected));
                            if (this.interfaces && this.showInterfaces) {
                                nodes.push(this.interfaceToNode(_.find(this.interfaces, { Identity: selected.Interface })));
                                if (this.classInterfaces && this.classes && this.showClasses) {
                                    this.classInterfaces.forEach(ci => {
                                        if (ci.Interface === selected.Interface) {
                                            nodes.push(this.classToNode(_.find(this.classes, { Identity: ci.Class })));
                                        }
                                    });
                                }
                            }
                            return nodes;
                    }
                }

                /* If we get here, load everything */

                if (this.interfaces && this.showInterfaces) {
                    this.interfaces.forEach(c => { nodes.push(this.interfaceToNode(c)); });
                }
                if (this.classes && this.showClasses) {
                    this.classes.forEach(c => { nodes.push(this.classToNode(c)); });
                }
                if (this.attributes && this.showAttributes) {
                    this.attributes.forEach(c => { nodes.push(this.attributeToNode(c)); });
                }

                return nodes;
            },
            edges() {
                const edges = [];
                if (this.classes && this.showClasses) {
                    this.classes.forEach(c => {
                        if (c.HasBaseClass) {
                            edges.push({
                                from: 'class:' + c.Identity,
                                to: 'class:' + c.HasBaseClass,
                                arrows: 'to'
                            });
                        }
                    });
                }
                if (this.classInterfaces && this.showClasses && this.showInterfaces) {
                    this.classInterfaces.forEach(c => {
                        edges.push({
                            from: 'class:' + c.Class,
                            to: 'interface:' + c.Interface,
                            arrows: 'to'
                        });
                    });
                }
                if (this.interfaces && this.attributes && this.showAttributes) {
                    this.attributes.forEach(c => {
                        edges.push({
                            from: 'attribute:' + c.Identity,
                            to: 'interface:' + c.Interface,
                            arrows: 'to'
                        });
                    });
                }
                return edges;
            },
            selectedObjectType() {
                if (!this.selectedNode)
                    return null;
                if (this.selectedNode.startsWith('class:')) {
                    return 'class';
                } else if (this.selectedNode.startsWith('interface:')) {
                    return 'interface';
                } else if (this.selectedNode.startsWith('attribute:')) {
                    return 'attribute';
                } else {
                    // say what ?
                    return null;
                }
            },
            selectedObject() {
                switch (this.selectedObjectType) {
                    case 'class':
                        return _.find(this.classes, { Identity: this.selectedNode.substring(6) });
                    case 'interface':
                        return _.find(this.interfaces, { Identity: this.selectedNode.substring(10) });
                    case 'attribute':
                        return _.find(this.attributes, { Identity: this.selectedNode.substring(10) });
                    default:
                        return null;
                }
            }
        },
        async mounted() {
            const schemaCondition = {
                where: [
                    {
                        field: 'Schema',
                        operator: '=',
                        value: this.identity
                    }
                ]
            };
            const classRes = await genericViewQuery(this, {
                version: 1,
                name: 'SchemaClass',
                ...schemaCondition
            });
            const interfaceRes = await genericViewQuery(this, {
                version: 1,
                name: 'SchemaInterface',
                ...schemaCondition
            });
            const classInterfaceRes = await genericViewQuery(this, {
                version: 1,
                name: 'SchemaClassInterface',
                ...schemaCondition
            });
            const attributeRes = await genericViewQuery(this, {
                version: 1,
                name: 'SchemaAttribute',
                ...schemaCondition
            });
            this.interfaces = interfaceRes.data;
            this.classes = classRes.data;
            this.classInterfaces = classInterfaceRes.data;
            this.attributes = attributeRes.data;
        },
        methods: {
            networkClick(e) {
                if (e.nodes.length === 1) {
                    this.selectedNode = e.nodes[0];
                }
            },
            attributeToNode(c) {
                return {
                    id: 'attribute:' + c.Identity,
                    label: c.Name
                };
            },
            interfaceToNode(c) {
                return {
                    id: 'interface:' + c.Identity,
                    label: c.Name,
                    color: '#FF1243'
                };
            },
            classToNode(c) {
                return {
                    id: 'class:' + c.Identity,
                    label: c.Name,
                    color: '#73B0B5'
                };
            }
        }
    };
</script>
<style scoped>
.network {
  height: 800px;
  width: 100%;
  border: none;
  margin: 5px 0;
}
</style>
