export const getWebClientConfig = async () => {
    const response = await fetch('/Configuration/FrontEnd');
    if (response.ok) {
        const config = await response.json();
        return {
            azureDashboardUrl: config.azureDashboardUrl,
            commonLibraryApiUrl: config.commonLibraryApiUrl,
            reportErrorUrl: config.reportErrorUrl,
            signalRUrlMyReleases: config.signalRUrlMyReleases,
            signalRUrlNotification: config.signalRUrlNotification,
            signalRUrlRelease: config.signalRUrlRelease,
            technicalDocUrl: config.technicalDocUrl
        };
    }
};

export const getAzureConfiguration = async () => {
    const response = await fetch('/Configuration/Azure');
    if (response.ok) {
        const config = await response.json();
        return {
            instance: config.instance,
            tenant: config.tenantId,
            clientId: config.clientId,
            redirectUri: config.redirectUri,
            postLogoutRedirectUri: window.location.origin
        };
    }
};
