<template>
    <div class="container">
        <h1 class="title is-1">
            Common Library Changelog
        </h1>
        <hr>
        <div
            v-for="release in releases"
            :key="release.ReleaseName"
            class="block">
            <h2 class="title is-3">
                {{ release.ReleaseName }}
            </h2>
            <div
                v-show="release.Build"
                class="subtitle is-6">
                Build: {{ release.Build }}
            </div>
            <div
                v-show="release.Summary"
                class="block">
                {{ release.Summary }}
            </div>
            <ul>
                <li
                    v-for="item in release.Items"
                    :key="item.Name">
                    <b>{{ item.ChangeTitle }}</b>
                    <a
                        v-for="prNo in split(item.PRNo)"
                        :href="'https://github.com/equinor/ti-commonlibrary/pull/' + prNo">
                        <span class="number-tag tag is-default is-rounded">PR #{{ prNo }}</span>
                    </a>
                    <a
                        v-for="devOpsNo in split(item.DevOpsNo)"
                        :href="'https://dev.azure.com/EquinorASA/Spine/_boards/board/t/CommonLibrary/Stories/?workitem=' + devOpsNo">
                        <span class="number-tag tag is-primary is-rounded">DevOps #{{ devOpsNo }}</span>
                    </a>
                    <template v-if="item.ChangeDescription">
                        <br>{{ item.ChangeDescription }}
                    </template>
                </li>
            </ul>
            <hr>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import { genericViewQueryAsText } from '@/shared/helpers/api';

    export default {
        data() {
            return {
                releases: []
            };
        },
        async mounted() {
            await this.loadDefault();
        },
        methods: {
            async loadDefault() {
                const rows = (await genericViewQueryAsText(
                    this,
                    `FROM CommonLibraryChangeLogItem
                     SELECT
                        Name,
                        Description AS ChangeTitle,
                        ChangeDescription,
                        PRNo,
                        DevOpsNo
                     WHERE IsValid = true
                     JOIN Release
                        SELECT
                           Name AS ReleaseName,
                           ReleaseDescription AS Summary,
                           Build
                     END`
                )).data;

                let grouped = Object.entries(_.groupBy(rows, r => r.ReleaseName)).map(([, rows]) => {
                    const first = rows[0];
                    return {
                        ReleaseName: first.ReleaseName,
                        Summary: first.Summary,
                        Build: first.Build,
                        Items: rows
                    };
                });

                grouped = _.sortBy(grouped, 'ReleaseName');
                _.reverse(grouped);
                this.releases = grouped;
            },
            split(string) {
                return string
                    ?.split(',')
                    .map(x => x.trim())
                    .filter(x => x)
                    ?? [];
            }
        }
    };
</script>

<style scoped>
ul {
  list-style-type: square;
  margin-left: 20px;
}
.number-tag {
    padding: 0 0.8em;
    margin-left: 1ch;
}
</style>
