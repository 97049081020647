<template>
    <b-dropdown
        aria-role="list">
        <b-field
            class="library-group-container"
            position="is-centered">
            <p class="control">
                <b-button
                    @click="clearFilter()">
                    Clear selected
                </b-button>
            </p>
        </b-field>
        <template #trigger>
            <b-button
                :disabled="loading"
                :loading="loading"
                class="button is-info is-dropdown-trigger">
                <span>Library group filter</span>
            </b-button>
        </template>
        <b-field class="library-group-container">
            <b-radio
                v-model="currentFilterSetOperation"
                native-value="OR"
                @update:model-value="setFilterSetOperation()">
                OR
            </b-radio>
            <b-radio
                v-model="currentFilterSetOperation"
                native-value="AND"
                @update:model-value="setFilterSetOperation()">
                AND
            </b-radio>
        </b-field>
        <hr>
        <tag-filter-columns
            class="tag-filter-columns"
            :active-tags="activeTags"
            @update:tags="updateTagFilter" />
    </b-dropdown>
</template>

<script>
    import { getAllTags } from '../helpers/api';
    import Spinner from './Spinner.vue';
    import TagFilterColumns from './TagFilterColumns.vue';

    export default {
        components: { Spinner, TagFilterColumns },
        props: {
            activeTags: {
                type: Array,
                default: () => [],
                required: false
            },
            filterSetOperation: {
                type: String,
                default: 'OR',
                required: false
            }
        },
        emits: [
            'update:tags',
            'update:filter-set-operation'
        ],
        data() {
            return {
                allTags: null,
                loading: true,
                selected: [],
                currentFilterSetOperation: null
            };
        },
        watch: {
            filterSetOperation() {
                this.currentFilterSetOperation = this.filterSetOperation;
            },
            activeTags() {
                this.selected = this.activeTags;
            }
        },
        async mounted() {
            this.allTags = (await getAllTags(this)).sort();
            this.loading = false;
            this.selected = this.activeTags;
            this.currentFilterSetOperation = this.filterSetOperation;
            this.setFilterSetOperation();
        },
        methods: {
            clearFilter() {
                this.selected = [];
                this.$emit('update:tags', this.selected);
            },
            setFilterSetOperation() {
                this.$emit('update:filter-set-operation', this.currentFilterSetOperation);
            },
            updateTagFilter(tags) {
                this.selected = tags;
                this.$emit('update:tags', this.selected);
            }
        }
    };
</script>
<style scoped>
.library-group-container {
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    overflow-x: auto;
    width:400px;
}
.tag-filter-columns {
    height:420px;
    margin: 5px;
}
</style>
