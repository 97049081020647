<template>
    <div>
        <h2 class="title is-3 has-margin-bottom2">
            Export Code Tables
        </h2>
        <spinner :loading="loading">
            <form
                class="card section"
                @submit.prevent>
                <b-field grouped>
                    <b-field label="Tag/Group:">
                        <b-select
                            id="group"
                            v-model="groupFilter"
                            :placeholder="groups.length + ' groups'"
                            @update:model-value="didChangeGroup($event)">
                            <option
                                v-for="s in groups"
                                :key="s"
                                :value="s">
                                {{ s }}
                            </option>
                        </b-select>
                    </b-field>
                    <b-field label="Scope type:">
                        <b-select
                            id="scopeType"
                            v-model="selectedScopeType"
                            :placeholder="scopeTypes.length + ' scope types'"
                            name="Scope"
                            @update:model-value="loadScopes($event)">
                            <option
                                v-for="st in scopeTypes"
                                :key="st"
                                :value="st">
                                {{ st }}
                            </option>
                        </b-select>
                    </b-field>
                    <b-field :label="selectedScopeType ? selectedScopeType + ':' : 'Scopes:'">
                        <b-select
                            id="scope"
                            v-model="selectedScope"
                            :placeholder="scopes.length + ' ' + (selectedScopeType ? selectedScopeType + 's' : 'scopes')"
                            name="Scope"
                            @update:model-value="didChangeScope($event)">
                            <option
                                v-for="s in scopes"
                                :key="s"
                                :value="s">
                                {{ s }}
                            </option>
                        </b-select>
                    </b-field>
                    <b-field label="Filter valid codes:">
                        <b-switch
                            v-model="getValidOnly"
                            style="top: 10%">
                            {{ getValidOnly ? 'Only valid codes will be exported' : 'All codes will be exported' }}
                        </b-switch>
                    </b-field>
                </b-field>
                <b-field grouped>
                    <b-field>
                        <b-button
                            @click="selectAllLibraries()">
                            <i
                                class="mdi mdi-select-all"
                                aria-hidden="true" />
                            Select all
                        </b-button>
                    </b-field>
                    <b-field>
                        <b-button
                            @click="deselectAllLibraries()">
                            <i
                                class="mdi mdi-select-off"
                                aria-hidden="true" />
                            Deselect all
                        </b-button>
                    </b-field>
                    <b-field>
                        <b-button
                            @click="inverseSelectedLibraries()">
                            <i
                                class="mdi mdi-select-inverse"
                                aria-hidden="true" />
                            Inverse selected
                        </b-button>
                    </b-field>
                </b-field>
                <b-field label="Libraries:">
                    <fieldset class="is-grouped">
                        <div
                            v-for="lib in libraries"
                            :key="lib.name"
                            class="checkbox-container">
                            <b-checkbox
                                v-model="selectedLibraries"
                                :native-value="lib.name">
                                {{ !lib.alias ? lib.name : `${lib.name} (${lib.alias})` }}
                            </b-checkbox>
                        </div>
                    </fieldset>
                </b-field>
                <button
                    class="button is-primary"
                    type="submit"
                    @click="downloadReport">
                    Export
                </button>
            </form>
            <b-loading
                v-model="generating"
                :is-full-page="false"
                :can-cancel="false" />
        </spinner>
    </div>
</template>
<script>
    import Spinner from '@/shared/components/Spinner.vue';
    import { getAllTagsAsEntities, getCodeTables, getLibraries, getScopes } from '@/shared/helpers/api';
    import { showMixin } from '@/shared/mixins/showMixin';
    import saveAs from 'file-saver';
    import _ from 'lodash';

    export default {
        components: {
            Spinner
        },
        mixins: [showMixin],
        url: {
            selectedScopeType: {
                param: 'scopeType',
                noHistory: true
            },
            selectedScope: {
                param: 'scope',
                noHistory: true
            },
            groupFilter: {
                param: 'group',
                noHistory: true
            },
            groupFilterAlias: {
                param: 'tag',
                noHistory: true
            },
            selectedLibrariesSerialized: {
                param: 'library',
                noHistory: true
            }
        },
        data() {
            return {
                loading: true,
                generating: false,
                scopes: [],
                libraries: [],
                groups: [],
                selectedLibrariesSerialized: null,
                selectedScope: null,
                selectedScopeType: null,
                groupFilter: null,
                groupFilterAlias: null,
                scopeTypes: [],
                getValidOnly: true
            };
        },
        computed: {
            selectedLibraries: {
                get() {
                    if (this.selectedLibrariesSerialized && this.selectedLibrariesSerialized.length > 0) {
                        return this.selectedLibrariesSerialized.split(',');
                    }
                    return [];
                },
                set(val) {
                    this.selectedLibrariesSerialized = _.intersection(val, this.libraries.map(lib => lib.name))
                        .join(',');
                }
            }
        },
        async mounted() {
            if (this.groupFilterAlias && !this.groupFilter) {
                this.groupFilter = this.groupFilterAlias;
                this.groupFilterAlias = null;
            } else if (this.groupFilter) {
                this.groupFilterAlias = null;
            }
            await this.loadGroups();
            await this.loadLibraries();
            await this.loadScopeTypes();

            this.loading = false;
        },
        methods: {
            async loadScopes(scopeType) {
                if (!scopeType) {
                    this.scopes = [];
                    this.selectedScope = null;
                    return;
                }
                this.scopes = (await getScopes(this, scopeType)).sort();
                this.selectedScope = this.scopes.length > 0 ? this.scopes[0] : null;
            },
            async didChangeGroup() {
                this.groupFilterAlias = null;
                this.loading = true;
                this.scopes = [];
                this.scopeTypes = [];
                const tempScope = this.selectedScope;
                this.selectedScope = null;
                this.selectedScopeType = null;
                await this.loadLibraries();
                await this.loadScopeTypes();

                if (this.scopes.includes(tempScope)) {
                    this.selectedScope = tempScope;
                }

                this.loading = false;
            },
            async didChangeScope(scope) {
                this.loading = true;

                await this.loadLibraries(scope);

                this.loading = false;
            },
            async loadGroups() {
                this.groups = (await getAllTagsAsEntities(this)).map(g => g.name).sort();
            },
            async loadLibraries(scope = '') {
                const temp = _.sortBy(await getLibraries(this, scope, this.groupFilter), 'name');
                this.libraries = temp;
            },
            async loadScopeTypes() {
                this.scopeTypes = _.chain(this.libraries)
                    .map(lib => lib.scopeType)
                    .filter(lib => lib)
                    .uniq()
                    .value();
                if (!this.selectedScopeType) {
                    this.selectedScopeType = this.scopeTypes.length > 0 ? this.scopeTypes[0] : null;
                }
                await this.loadScopes(this.selectedScopeType);
            },
            async downloadReport() {
                this.generating = true;

                // Removes libs that are not available for this scope or group
                // This looks strange, but was probably done intentionally. See computed implementation..
                // Should be refactored for clarity.
                // eslint-disable-next-line no-self-assign
                this.selectedLibraries = this.selectedLibraries;

                try {
                    const blob = await getCodeTables(this, this.selectedScope, this.selectedLibraries, this.getValidOnly);

                    if (blob) {
                        saveAs(blob, 'code_tables.xlsx');
                        return true;
                    }
                } catch (err) {
                    this.showError(err);
                // handled in transformResponse :<
                } finally {
                    this.generating = false;
                }
            },
            selectAllLibraries() {
                this.selectedLibraries = this.libraries.map(l => l.name);
            },
            deselectAllLibraries() {
                this.selectedLibraries = [];
            },
            inverseSelectedLibraries() {
                this.selectedLibraries = this.libraries.map(l => l.name).filter(e => !this.selectedLibraries.includes(e));
            }

        }
    };
</script>
<style>
.checkbox-container {
    width: 270px;
    display: inline-block;
}
</style>
