// @ts-nocheck

// *******************************************************
// Helper functions to transform a result from a generic
// view based on attribute defintions.
//
// Options (all false by default):
//   includeNullAttributes
//   includeIdentity
//   skipName
//   skipDescription
// *******************************************************

import moment from 'moment';

function mapResultToDisplaySingle(queryResultObject, attributeDefintions, options = {}) {
    const ret = [];

    const includeNullAttributes = options.includeNullAttributes;
    const includeIdentity = options.includeIdentity;
    const skipName = options.skipName;
    const skipDescription = options.skipDescription;
    const skipIsValid = options.skipIsValid;

    if (!skipName && queryResultObject['Name'] !== undefined) {
        ret.push({ name: 'Name', value: queryResultObject.Name });
    }

    if (includeIdentity && queryResultObject['Identity'] !== undefined) {
        ret.push({ name: 'Identity', value: queryResultObject.Identity });
    }

    if (!skipDescription && queryResultObject['Description'] !== undefined) {
        ret.push({ name: 'Description', value: queryResultObject.Description });
    }

    if (!skipIsValid && queryResultObject['IsValid'] !== undefined) {
        ret.push({ name: 'IsValid', value: queryResultObject.IsValid });
    }

    attributeDefintions.forEach(a => {
        let v = queryResultObject[a.name];
        if (v !== null || includeNullAttributes) {
            if (a.attributeType === 'Date') {
                v = moment(v).format('YYYY-MM-DD');
            } else if (a.attributeType === 'DateTime') {
                v = moment(v).format('YYYY-MM-DD HH:mm:ss');
            }
            ret.push({ name: a.displayAs, value: v });
        }
    });

    return ret;
}

function mapResultToDisplay(queryResult, attributeDefintions, options = {}) {
    return queryResult.map(o => mapResultToDisplaySingle(o, attributeDefintions, options));
}

export {
    mapResultToDisplaySingle,
    mapResultToDisplay
};
