<template>
    <div class="column is-half">
        <b-table
            :selected="selectedProject"
            :loading="loading"
            :data="filteredProjects"
            default-sort="Name"
            striped
            narrowed
            hoverable
            paginated
            per-page="20"
            @update:selected="$emit('update:selectedProject', $event)">
            <b-table-column
                v-slot="props"
                sortable
                searchable
                field="Name"
                label="Name">
                <b>{{ props.row.Name }}</b>
            </b-table-column>
            <b-table-column
                v-slot="props"
                sortable
                searchable
                field="Description"
                label="Description">
                {{ props.row.Description }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                sortable
                searchable
                field="ProjectMasterName"
                label="Project master">
                {{ props.row.ProjectMasterName }}
            </b-table-column>
            <b-table-column
                searchable
                field="IsClosed"
                label="Closed"
                width="65"
                centered>
                <template
                    #searchable>
                    <b-select
                        v-model="projectIsClosedFilter"
                        expanded>
                        <option :value="'all'">
                            All
                        </option>
                        <option
                            :value="true"
                            selected>
                            Yes
                        </option>
                        <option :value="false">
                            No
                        </option>
                    </b-select>
                </template>
                <template
                    #default="props">
                    <bool-element
                        custom-size="mdi-18px"
                        :value="props.row.IsClosed" />
                </template>
            </b-table-column>
            <b-table-column
                searchable
                field="IsValid"
                label="Valid"
                width="65"
                centered>
                <template
                    #searchable>
                    <b-select
                        v-model="projectIsValidFilter"
                        expanded>
                        <option :value="'all'">
                            All
                        </option>
                        <option
                            :value="true"
                            selected>
                            Yes
                        </option>
                        <option :value="false">
                            No
                        </option>
                    </b-select>
                </template>
                <template
                    #default="props">
                    <bool-element
                        custom-size="mdi-18px"
                        :value="props.row.IsValid" />
                </template>
            </b-table-column>
            <template #empty>
                <div class="has-text-centered">
                    No projects
                </div>
            </template>
        </b-table>
    </div>
</template>
<script>
    import BoolElement from '@/shared/components/BoolElement.vue';

    export default {
        name: 'ProjectList',
        components: { BoolElement },
        props: {
            loading: {
                type: Boolean,
                default: false
            },
            projects: {
                type: Array,
                default: () => []
            },
            selectedProject: {
                type: Object,
                default: () => null
            }
        },
        emits: ['update:selectedProject'],
        data() {
            return {
                projectIsValidFilter: true,
                projectIsClosedFilter: false
            };
        },
        computed: {
            filteredProjects() {
                let filteredProjects = this.projects;

                if (this.projectIsValidFilter !== 'all') {
                    filteredProjects = filteredProjects.filter(project => {
                        return project.IsValid === this.projectIsValidFilter;
                    });
                }

                if (this.projectIsClosedFilter !== 'all') {
                    filteredProjects = filteredProjects.filter(project => {
                        if (this.projectIsClosedFilter === false)
                            return !project.IsClosed;
                        return project.IsClosed;
                    });
                }
                return filteredProjects;
            }
        }
    };
</script>
<style scoped>
.headless-table thead {
    display: none;
}
</style>
