import { defineStore } from 'pinia';
import { disabledFeaturesGetDisabled } from '@/shared/helpers/api.ts';
import { http } from '@/shared/httpWrapper.js';

export const featureNames = Object.freeze({
    ENS_UI: 'ENS UI (TAG  and Doc)',
    ELECTRIC_COMPONENT_TYPE_UI: 'ElectricComponentType UI',
    ENS_NUMBER_VALIDATION_UI: 'ENS number validation UI'
});

const routesToFeatureNames = {
    'DocumentNumberingFormat': featureNames.ENS_UI,
    'ElectricComponentType': featureNames.ELECTRIC_COMPONENT_TYPE_UI,
    'EnsNumber': featureNames.ENS_NUMBER_VALIDATION_UI,
    'TagFormat': featureNames.ENS_UI
};

/**
 * This store shares information about disabled features across components,
 * providing a short-lived caching mechanism.
 */
export const useDisabledFeaturesStore = defineStore('disabledFeatures', () => {
    let disabledFeatures = [];
    let dataFetchedTime = null;

    async function loadState() {
        disabledFeatures = await disabledFeaturesGetDisabled(http);
        dataFetchedTime = Date.now();
    }

    /**
     * Check if a named feature is disabled.
     * @param {string} featureName - Available names defined in DisabledFeatures.vue
     * @returns {undefined|object} Falsy if not disabled. Else, returns object with message for user.
     */
    async function isDisabled(featureName) {
        // To avoid too many API calls, this store caches disabledFeatures
        // for one minute. If the user refreshes their browser the store will reset
        // and changes will take affect immediately.
        if (Date.now() - dataFetchedTime > 60000) {
            await loadState();
        }

        return disabledFeatures.find(x => x.name === featureName);
    }

    async function isRouteDisabled(routeName) {
        const featureName = routesToFeatureNames[routeName];
        return featureName ? isDisabled(featureName) : undefined;
    }

    return {
        isDisabled,
        isRouteDisabled
    };
});
