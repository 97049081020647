const getScopeFilterStorageKey = (scopeType) => `scopefilter.${scopeType}`;

const getRecentlySelectedScopeStorageKey = (scopeType) => `recentlySelectedScope.${scopeType}`;

/**
 * Takes a String or Array of strings and saves it to localStorage.
 *
 * @param {string} scopeType - Name of the scopeType, usually `Facility`
 * @param {string | string[]} newScopeFilter - A string for an scope/facility or an array of strings describing the scopes.
 */
export function setScopeFilter(scopeType, newScopeFilter) {
    if (typeof newScopeFilter === 'string') {
        newScopeFilter = [newScopeFilter];
    }

    const storageKey = getScopeFilterStorageKey(scopeType);
    writeLocalStorage(storageKey, newScopeFilter);
}

/**
 * Takes a String or Array of strings and saves it to localStorage.
 *
 * @param {string} scopeType - Name of the scopeType, usually `Facility`
 * @returns {string[]} - An array of strings (scope/facility)
 */
export function getScopeFilter(scopeType) {
    const storageKey = getScopeFilterStorageKey(scopeType);
    const scopeFilter = readLocalStorage(storageKey, []);

    if (scopeFilter.length !== 0) {
        scopeFilter.sort();
    }

    return scopeFilter;
}

/**
 * Fetches a string from sessionStorage stored under the key `recentlySelectedScope.Facility`.
 *
 * @param {string} scopeType - Name of the scopeType, usually `Facility`
 * @returns {string | null} - Name of the scope, e.g.`AHA`
 */
export function getRecentlySelectedScope(scopeType) {
    const storageKey = getRecentlySelectedScopeStorageKey(scopeType);
    const value = readSessionStorage(storageKey, null, true);
    return value === 'null' ? null : value;
}

/**
 * Takes a ScopeName String and saves it to sessionStorage.
 *
 * @param {string} scopeType - Name of the scopeType, usually `Facility`
 * @param {string} scope - Name of the scope, e.g.`AHA`
 */
export function setRecentlySelectedScope(scopeType, scope) {
    const storageKey = getRecentlySelectedScopeStorageKey(scopeType);
    writeSessionStorage(storageKey, scope, true);
}

function readLocalStorage(storageKey, defaultReturn) {
    return JSON.parse(localStorage.getItem(storageKey)) ?? defaultReturn;
}

function writeLocalStorage(storageKey, storageValue) {
    localStorage.setItem(storageKey, JSON.stringify(storageValue));
}

function readSessionStorage(storageKey, defaultReturn, raw = false) {
    return raw
        ? sessionStorage.getItem(storageKey) ?? defaultReturn
        : JSON.parse(sessionStorage.getItem(storageKey)) ?? defaultReturn;
}

function writeSessionStorage(storageKey, storageValue, raw = false) {
    raw
        ? sessionStorage.setItem(storageKey, storageValue)
        : sessionStorage.setItem(storageKey, JSON.stringify(storageValue));
}
