<script setup>
    import { genericViewQueryAsText } from '@/shared/helpers/api.ts';
    import { assert } from '@/shared/helpers/assert.ts';
    import { cancelPreviousWhenCalledWithDebouncing } from '@/shared/helpers/utils.js';
    import { http } from '@/shared/httpWrapper.js';
    import { ref, watch } from 'vue';

    const MAX_RESULTS = 10;

    const emit = defineEmits(['selected']);

    const searchText = ref('');
    const searchResults = ref([]);
    const isSearchResultPartial = ref(false);
    const isFetching = ref(false);

    const finishSearch = (results) => {
        if (results.length === MAX_RESULTS + 1) {
            results.pop();
            isSearchResultPartial.value = true;
        } else {
            isSearchResultPartial.value = false;
        }
        searchResults.value = results;
        isFetching.value = false;
    };

    const onSelect = async (option) => {
        // Get the same data as in the parent component
        const row = await genericViewQueryAsText(
            http,
            `TAKE 1
            FROM ProjectFacility
                WHERE Id = @id
                JOIN Project
                  SELECT Id, Name, Description, ProjectMaster, IsValid, InternalComment
                  JOIN ProjectMaster
                    SELECT Name AS ProjectMasterName
                  END
                END`,
            [{ name: '@id', value: option.id }]
        ).then(response => response.data[0]);
        assert(row, 'Query should always succeed');
        // Using TAKE adds a _row property to the object
        delete row._row;
        emit('selected', row);
    };

    watch(searchText, cancelPreviousWhenCalledWithDebouncing(
        async (abortSignal, readyPromise) => {
            if (!searchText.value) {
                finishSearch([]);
                return;
            }

            isFetching.value = true;

            await readyPromise;

            const results = await genericViewQueryAsText(
                http,
                `SELECT Id as id, Name as name, Description as description
                TAKE ${MAX_RESULTS + 1}
                FROM ProjectFacility
                WHERE IsValid = true AND name LIKE @wildcardSearch
                OR name = @search
                ORDER BY name`,
                [
                    { name: '@search', value: searchText.value },
                    { name: '@wildcardSearch', value: `${searchText.value}%` }
                ],
                { signal: abortSignal }
            ).then(response => response.data);

            if (abortSignal.aborted)
                return;

            finishSearch(results);
        },
        { delay: 500 }
    ));
</script>

<template>
    <b-field class="searchField">
        <b-autocomplete
            v-model="searchText"
            :data="searchResults"
            placeholder="Find existing project to link"
            field="title"
            :loading="isFetching"
            icon-right="magnify"
            @select="onSelect">
            <template #default="{option}">
                <div class="media">
                    <div class="media-content">
                        <div class="content">
                            <p>
                                <strong>{{ option.name }}</strong>
                                <br>
                                <small>{{ option.description }}</small>
                            </p>
                        </div>
                    </div>
                </div>
            </template>
            <template
                v-if="isSearchResultPartial"
                #footer>
                Displaying the first {{ MAX_RESULTS }} results. Keep typing to narrow down the list.
            </template>
        </b-autocomplete>
    </b-field>
</template>

<style scoped>
.headless-table thead {
  display: none;
}

.searchField {
  width: 30ch;
  transition: width 0.25s ease-in-out;
}

.searchField:focus-within {
  width: 100%;
}
</style>
