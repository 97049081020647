<template>
    <div class="is-flex is-flex-direction-column is-align-items-center is-full-width">
        <nav class="row">
            <div class="mb-2 is-flex is-gap-10 is-justify-content-right">
                <button
                    class="button is-secondary"
                    :disabled="isUnchanged"
                    @click="eraseChange">
                    <span class="icon is-small">
                        <i class="fa fa-eraser" />
                    </span>
                    <span>Remove change</span>
                </button>
                <toggle-button
                    v-require-can-edit-code="{ libraryName: libraryName, scopes: [scope] }"
                    :toggle="editMode"
                    :disabled="filteredCodes(refLibraryName).length === 0"
                    :color="'is-info'"
                    :icon="'pencil'"
                    :standard-text="`Edit ${typeText.toLowerCase()} validity`"
                    @toggle-button="editMode = true"
                    @toggle-button-cancel="editMode = false" />

                <button
                    v-if="editMode"
                    class="button is-primary"
                    @click="doneEditing">
                    <span class="icon is-small">
                        <i
                            class="fa fa-check-circle"
                            aria-hidden="true" />
                    </span>
                    <span>
                        Done Editing
                    </span>
                </button>
                <button
                    v-if="!editMode"
                    v-require-can-edit-code="{ libraryName: libraryName, scopes: [scope] }"
                    class="level-item button is-info is-pulled-right"
                    @click="changeModal(1, true)">
                    <span class="icon is-small">
                        <i
                            class="fa fa-plus-circle"
                            aria-hidden="true" />
                    </span>
                    <span>Add {{ typeText.toLowerCase() }}</span>
                </button>
            </div>
        </nav>

        <div class="row">
            <b-table
                v-if="filteredCodes(refLibraryName).length"
                class="site-sticky-header"
                :striped="true"
                :hoverable="true"
                :bordered="true"
                :mobile-cards="false"
                :data="colorTypes">
                <b-table-column
                    v-slot="props"
                    centered
                    width="3ex">
                    <b-icon
                        v-if="props.row.meta.edited && !props.row.meta.isNew"
                        type="is-danger"
                        class="fa fa-fw fa-pencil-alt" />
                    <b-icon
                        v-if="props.row.meta.isNew"
                        type="is-danger"
                        class="fa fa-fw fa-plus-square" />
                </b-table-column>
                <b-table-column
                    v-if="isType"
                    v-slot="props"
                    field="values.CableType"
                    label="Type"
                    sortable>
                    {{ props.row.values.CableType }}
                </b-table-column>
                <b-table-column
                    v-else
                    v-slot="props"
                    field="values.SheathColour"
                    label="Colour"
                    sortable>
                    {{ props.row.values.SheathColour }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="values.description"
                    label="Description">
                    {{ findRefCode(props.row).values.description }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="values.isValid"
                    label="Valid"
                    centered
                    width="0%"
                    sortable>
                    <div
                        v-if="!editMode"
                        class="is-flex is-justify-content-center">
                        <bool-element
                            :size="'is-medium'"
                            :custom-size="'mdi-18px'"
                            :value="props.row.values.isValid" />
                    </div>
                    <div
                        v-else
                        class="is-flex is-justify-content-center">
                        <a
                            :class="{'button': true, 'trash-icon': true, 'in-valid': !props.row.values.isValid}"
                            @click="editValidity(props.row)">
                            <b-icon
                                pack="fas"
                                icon="trash"
                                :type="props.row.values.isValid ? '' : 'is-white '"
                                size="is-medium" />
                        </a>
                    </div>
                </b-table-column>
            </b-table>
        </div>
    </div>

    <b-modal
        v-if="template"
        v-model="modalActive[1]"
        has-modal-card>
        <div
            class="modal-card"
            style="width: 500px">
            <header class="modal-card-head">
                <div class="modal-card-title">
                    <p>Add {{ typeText }} relation for Cable Code:</p>
                    <br>
                    <small
                        v-if="selectedCode('CableCode')"
                        class="has-margin-top">
                        {{ selectedCode('CableCode').values.name }}
                    </small>
                </div>
            </header>
            <section
                class="modal-card-body"
                style="padding-bottom: 250px;">
                <b-field
                    horizontal
                    label="Relation is valid:">
                    <bool-selector
                        :value="template.values.isValid"
                        @bool-select="template.values.isValid = $event" />
                </b-field>
                <b-field
                    v-if="isType"
                    horizontal
                    label="Cable type:">
                    <code-ref-selector
                        :code-refs="originalCodes(libraryName).map(c => c.values).filter(c => c.isValid)"
                        :required="true"
                        :complex="false"
                        @ref-selected="template.values.CableType = $event" />
                </b-field>
                <b-field
                    v-else
                    horizontal
                    label="Colour:">
                    <code-ref-selector
                        :code-refs="originalCodes(libraryName).map(c => c.values).filter(c => c.isValid)"
                        :required="true"
                        :complex="false"
                        @ref-selected="template.values.SheathColour = $event" />
                </b-field>
            </section>
            <footer class="modal-card-foot">
                <button
                    class="button"
                    type="button"
                    @click="changeModal(1, false)">
                    <b-icon
                        icon="close-circle"
                        size="is-small" />
                    <span>
                        Cancel
                    </span>
                </button>
                <button
                    class="button is-primary"
                    :disabled="(isType ? !template.values.CableType.length : !template.values.SheathColour.length) || loadingCreateRelation"
                    @click="addRelation">
                    <span class="icon is-small">
                        <i
                            class="fa fa-check-circle"
                            aria-hidden="true" />
                    </span>
                    <span>
                        Create
                    </span>
                    <b-loading
                        :is-full-page="false"
                        :model-value="loadingCreateRelation" />
                </button>
            </footer>
        </div>
    </b-modal>
</template>

<script>

    import _ from 'lodash';
    import { showMixin } from '@/shared/mixins/showMixin.js';
    import { libNames } from '@/shared/helpers/cableEditHelpers.js';
    import BoolElement from '../../../shared/components/BoolElement.vue';
    import BoolSelector from '../../../shared/components/BoolSelector.vue';
    import CodeRefSelector from '../../../shared/components/CodeRefSelector.vue';
    import Spinner from '../../../shared/components/Spinner.vue';
    import ToggleButton from '../../../shared/components/ToggleButton.vue';
    import { requireCanEditCode } from '@/shared/directives/requirePermission';
    import { mapActions, mapState, mapStores } from 'pinia';
    import { useCodeEditStore } from '@/stores/codeEditStore.js';

    export default {
        components: {
            BoolElement,
            BoolSelector,
            CodeRefSelector,
            Spinner,
            ToggleButton
        },
        directives: {
            requireCanEditCode
        },
        mixins: [
            showMixin
        ],
        props: {
            type: {
                type: String,
                default: '',
                required: true
            }
        },
        data() {
            return {
                libraryName: '',
                refLibraryName: '',
                modalActive: [false, false],
                template: null,
                isType: null,
                loadingCreateRelation: false,
                editMode: false,
                colorTypesCopy: []
            };
        },
        computed: {
            ...mapStores(useCodeEditStore),
            ...mapState(useCodeEditStore, ['scope']),
            isUnchanged() {
                if (this.colorTypes.length > 0)
                    return !this.colorTypes.some(ct => ct.meta.isNew || ct.meta.edited);
                else
                    return true;
            },
            typeText() {
                return this.isType ? 'Type' : 'Sheath colour';
            },
            colorTypes() {
                if (this.editMode) {
                    return this.colorTypesCopy?.slice()
                        .sort((a, b) => a.values.CableType?.localeCompare(b.values.CableType));
                } else {
                    return [...this.filteredCodes(this.refLibraryName)].slice()
                        .sort((a, b) => a.values.CableType?.localeCompare(b.values.CableType));
                }
            }
        },
        watch: {
            editMode(newValue) {
                if (newValue)
                    this.colorTypesCopy = JSON.parse(JSON.stringify(this.filteredCodes(this.refLibraryName)));
            }
        },
        created() {
            this.isType = this.type === 'cableType' ? true : false;
            this.libraryName = this.isType ? libNames.cableType : libNames.cableSheathColor;
            this.refLibraryName = this.isType ? libNames.cableCodeType : libNames.cableCodeSheeth;
        },
        methods: {
            ...mapActions(useCodeEditStore, ['originalCodes', 'filteredCodes', 'selectedCode', 'isFullyLoaded']),
            findRefCode(code) {
                if (this.isType)
                    return this.originalCodes(this.libraryName).find(c => c.values.identity === code.values.CableType);
                else
                    return this.originalCodes(this.libraryName).find(c => c.values.identity === code.values.SheathColour);
            },
            changeModal(num, val) {
                if (val) {
                    this.template = JSON.parse(JSON.stringify(this.codeEditStore.templates[this.refLibraryName]));
                    this.template.values.isValid = 'True';
                }
                this.modalActive[num] = val;
            },
            editValidity(row) {
                row.values.isValid = !row.values.isValid;
                row.meta.edited = this.checkEdited(row);
                this.colorTypesCopy = this.colorTypesCopy.slice();
            },
            async addRelation() {
                this.loadingCreateRelation = true;
                const code = _.cloneDeep(this.template);
                code.meta.isNew = true;
                code.values.CableCode = this.codeEditStore.selectedCodes.CableCode;

                if (this.isType) {
                    code.values.name = `${this.codeEditStore.selectedCodes.CableCode}-${code.values.CableType}`;
                } else {
                    code.values.name = `${this.codeEditStore.selectedCodes.CableCode}-${code.values.SheathColour}`;
                }

                try {
                    this.codeEditStore.changeCode(this.refLibraryName, code, 'create');
                } catch (error) {
                    this.showAlert(this, _.get(error, 'title', 'Error'), _.get(error, 'message', 'Something went wrong'));
                }
                this.loadingCreateRelation = false;
                this.changeModal(1, false);
            },
            eraseChange() {
                const changes = this.colorTypes.filter(ct => ct.meta.isNew || ct.meta.edited);
                if (this.codeEditStore.changes[this.refLibraryName]) {
                    changes.forEach(c => {
                        this.codeEditStore.removeChange(this.refLibraryName, c.values.identity);
                    });
                }
                this.colorTypesCopy = JSON.parse(JSON.stringify(this.filteredCodes(this.refLibraryName)));
            },
            doneEditing() {
                this.colorTypesCopy.forEach(ct => {
                    this.codeEditStore.changeCode(this.refLibraryName, ct, 'update');
                });
                this.editMode = false;
            },
            checkEdited(row) {
                const rows = this.originalCodes(this.refLibraryName)
                    .filter(s => s.values.CableCode === this.codeEditStore.selectedCodes[libNames.cableCode]);
                const originalRow = rows.find(ctc => ctc.identity === row.identity);
                return !_.isEqual(row.values, originalRow?.values);
            }
        }
    };
</script>
<style scoped>
.b-table :deep(td) {
    vertical-align: middle;
}
.row {
    max-width: 800px;
    width: 100%;
}
.modal-card-foot {
    justify-content: flex-end;
}
</style>
