import MarkdownIt from 'markdown-it';
import dedent from 'dedent';
import { h } from 'vue';

function childrenAsText(children) {
    return typeof children === 'string'
        ? children
        : children
            .map(node => node.children ? childrenAsText(node.children) : node.text)
            .join('');
}

const VueMarkdown = {
    name: 'VueMarkdown',
    props: {
        source: {
            type: String,
            required: false,
            default: ''
        }
    },
    data() {
        return {
            md: null,
            slotSource: null
        };
    },
    computed: {
        content() {
            let _a;
            const src = this.slotSource ?? this.source;
            return (_a = this.md) === null || _a === void 0 ? void 0 : _a.render(src);
        }
    },
    created() {
        this.md = new MarkdownIt().set({
            html: true,
            breaks: true,
            langPrefix: true,
            typographer: true
        });
    },
    beforeMount() {
        if (this.$slots.default) {
            const rawText = childrenAsText(this.$slots.default());
            const dedented = dedent(rawText);
            this.slotSource = dedented;
        }
    },
    render() {
        return h('div', { innerHTML: this.content });
    }
};
export default VueMarkdown;
