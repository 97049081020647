<template>
    <div
        class="columns library-group-container">
        <div
            v-for="(tag, idx) in allTags"
            :key="idx"
            class="library-group-item">
            <p class="control is-pulled-left">
                <b-checkbox
                    :model-value="selected.includes(tag)"
                    @update:model-value="selectTag(tag)">
                    {{ tag }}
                </b-checkbox>
            </p>
        </div>
        <div v-if="displayShowOtherOption">
            <b-checkbox
                v-model="currentShowOther"
                @update:model-value="selectShowOther">
                Ungrouped libraries
            </b-checkbox>
        </div>
    </div>
</template>

<script>
    import { getAllTags } from '../helpers/api';

    export default {
        props: {
            activeTags: {
                type: Array,
                required: true
            },
            displayShowOtherOption: {
                type: Boolean,
                default: false
            },
            showOther: {
                type: Boolean,
                default: false
            }
        },
        emits: [
            'update:tags',
            'update:show-other'
        ],
        data() {
            return {
                allTags: null,
                selected: [],
                currentShowOther: false
            };
        },
        watch: {
            activeTags() {
                this.selected = this.activeTags;
            },
            showOther() {
                this.currentShowOther = this.showOther;
            }
        },
        async mounted() {
            this.allTags = (await getAllTags(this)).sort();
            this.selected = this.activeTags;
            this.currentShowOther = this.showOther;
        },
        methods: {
            selectTag(tag) {
                this.selected = this.selected.includes(tag)
                    ? this.selected.filter(t => t !== tag)
                    : this.selected.concat([tag]);
                this.$emit('update:tags', this.selected);
            },
            selectShowOther() {
                this.$emit('update:show-other', this.currentShowOther);
            }
        } };
</script>
<style scoped>
    .library-group-container {
        padding: 5px;
        display: flex;
        flex-flow: column wrap;
        justify-content: flex-start;
        overflow-x: auto;
        width:100%;
        height:100%;
    }
    .library-group-item {
        flex-basis: 20px;
        flex-grow: 0;
        flex-shrink: 0;
        height:20px;
    }
</style>
