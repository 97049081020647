<template>
    <div>
        <div class="is-flex is-gap-10 is-align-items-center">
            <b-button
                v-require-is-administrator
                class="is-info mt-2 mb-3"
                :model-value="createNew"
                @click="createNew = !createNew">
                <span class="icon is-small">
                    <i class="fa fa-plus" />
                </span>
                <span>
                    Create new attribute
                </span>
            </b-button>
            <b-switch
                v-model="showExpired"
                class="ml-auto">
                Show expired
            </b-switch>
            <b-button
                :disabled="expandedRows.length === 0"
                title="Collapses all expanded rows"
                icon-left="unfold-less-horizontal"
                @click="onClickCollapseAll">
                Collapse all
            </b-button>
        </div>
        <div
            v-if="createNew"
            style="width: 50%; margin-bottom: 35px;">
            <b-input
                v-model="newUserEmail"
                v-require-is-administrator
                type="email"
                placeholder="Enter user email"
                @keyup.enter="validateEmail" />
            <b-button
                class="is-primary mt-2"
                :disabled="!newUserEmailHasValue"
                @click="validateEmail">
                Add
            </b-button>
            <b-button
                class="mt-2"
                @click="onCancel">
                Cancel
            </b-button>
            <div
                v-if="emailValid"
                class="mt-2">
                <access-policy-card
                    :systems-and-roles="systemsAndRoles"
                    :scopes="scopes"
                    :is-user-policy="true"
                    @savePolicy="savePolicy"
                    @cancel="onCancel" />
            </div>
        </div>
        <div>
            <b-table
                :key="JSON.stringify(roles) /* Needed to maintain correct order of dynamic columns */"
                :data="groupedUsers"
                :loading="loading"
                :opened-detailed="expandedRows"
                :debounce-search="500"
                detailed
                detail-key="groupKey"
                default-sort="email"
                striped
                narrowed
                hoverable
                paginated
                per-page="50"
                @update:opened-detailed="expandedRows = $event">
                <b-table-column
                    label="User"
                    field="email"
                    sortable
                    searchable>
                    <template #searchable="props">
                        <b-input
                            v-model="props.filters.email"
                            placeholder="Filter by email" />
                    </template>
                    <template #default="props">
                        {{ props.row.email }}
                    </template>
                </b-table-column>
                <b-table-column
                    label="Name"
                    field="name"
                    sortable
                    searchable>
                    <template #searchable="props">
                        <b-input
                            v-model="props.filters.name"
                            placeholder="Filter by name" />
                    </template>
                    <template #default="props">
                        {{ props.row.name }}
                    </template>
                </b-table-column>
                <b-table-column
                    v-for="role in roles"
                    :key="role"
                    :label="role"
                    width="2ch"
                    header-class="rotate"
                    sortable
                    :custom-sort="createHasRoleComparator(role)">
                    <template #header>
                        {{ role }}
                    </template>
                    <template #default="{row: group}">
                        <bool-element
                            v-if="hasRole(group, role)"
                            :value="!hasExpiredRole(group, role)"
                            size="is-small"
                            custom-size="is-small" />
                    </template>
                </b-table-column>
                <b-table-column
                    field="scope"
                    label="Facility"
                    sortable
                    searchable>
                    <template #searchable="props">
                        <b-autocomplete
                            v-model="props.filters.scope"
                            placeholder="Filter by facility"
                            :data="scopes"
                            open-on-focus
                            clearable>
                            <template #default="{ option }">
                                {{ option }}
                            </template>
                        </b-autocomplete>
                    </template>
                    <template #default="props">
                        {{ props.row.scope }}
                    </template>
                </b-table-column>
                <b-table-column
                    field="system"
                    label="System"
                    sortable
                    searchable>
                    <template #searchable="props">
                        <b-autocomplete
                            v-model="props.filters.system"
                            placeholder="Filter by role"
                            :data="systemsAndRoles.sourceSystems"
                            open-on-focus
                            clearable>
                            <template #default="{ option }">
                                {{ option }}
                            </template>
                        </b-autocomplete>
                    </template>
                    <template #default="props">
                        {{ props.row.system }}
                    </template>
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="earliestDateTimeExpire"
                    label="Expiration date (earliest)"
                    sortable>
                    {{ formatDate(props.row.earliestDateTimeExpire, props.row.allFromCommonlib) }}
                    <span
                        v-if="props.row.isAnyExpired"
                        class="tag is-small is-danger">
                        Expired
                    </span>
                </b-table-column>
                <template #detail="{row: detailRow}">
                    <b-table
                        :data="detailRow.users">
                        <b-table-column
                            label="Role"
                            field="role"
                            sortable>
                            <template #default="{row}">
                                {{ row.role }}
                            </template>
                        </b-table-column>
                        <b-table-column
                            v-slot="{row}"
                            label="Source"
                            :custom-sort="sourceComparator"
                            sortable>
                            <span class="tag is-small is-success">
                                {{ showSource(row.fromCommonLib) }}
                            </span>
                        </b-table-column>
                        <b-table-column
                            label="Expiration date"
                            field="dateTimeExpire"
                            sortable>
                            <template #default="{row}">
                                {{ formatDate(row.dateTimeExpire, row.fromCommonLib) }}
                                <span
                                    v-if="row.isExpired"
                                    class="tag is-small is-danger">
                                    Expired
                                </span>
                            </template>
                        </b-table-column>
                        <b-table-column
                            label="Expire">
                            <template #default="{row}">
                                <a @click="expirePolicy(row)">
                                    <b-icon
                                        pack="fas"
                                        icon="trash"
                                        size="is-medium" />
                                </a>
                            </template>
                        </b-table-column>
                    </b-table>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
    import BoolElement from '@/shared/components/BoolElement.vue';
    import { requireIsAdministrator } from '@/shared/directives/requirePermission';
    import { expireUserAttribute, getAllUserAttributes, getScopes, saveUserAttribute } from '@/shared/helpers/api';
    import { comparing } from '@/shared/helpers/utils';
    import { SnackbarProgrammatic as Snackbar } from 'buefy';
    import _ from 'lodash';
    import AccessPolicyCard from './AccessPolicyCard.vue';

    export default {
        components: { BoolElement, AccessPolicyCard },
        directives: {
            'require-is-administrator': requireIsAdministrator
        },
        props: {
            systemsAndRoles: {
                type: Object,
                required: true
            }
        },
        data: () => {
            return {
                userList: [],
                expandedRows: [],
                scopes: [],
                createNew: false,
                newUserEmail: null,
                emailValid: false,
                loading: false,
                showExpired: false
            };
        },
        computed: {
            newUserEmailHasValue() {
                return this.newUserEmail && this.newUserEmail.trim().length > 0;
            },
            filteredUserList() {
                if (this.showExpired)
                    return this.userList;
                return this.userList.filter(u => !u.isExpired);
            },
            groupedUsers() {
                return _.chain(this.filteredUserList)
                    .groupBy(x => [x.email, x.scope, x.system])
                    .map(users => {
                        const { email, name, scope, system } = users[0];
                        return {
                            groupKey: `${email}|${scope}|${system}`,
                            email: email,
                            name: name,
                            scope: scope,
                            system: system,
                            allFromCommonlib: users.every(x => x.fromCommonLib),
                            earliestDateTimeExpire: _.min(users.map(x => x.dateTimeExpire)),
                            isAnyExpired: users.some(x => x.isExpired),
                            roles: new Map(users.map(x => [x.role, { isExpired: x.isExpired }])),
                            users
                        };
                    })
                    .value();
            },
            roles() {
                return _.chain(this.userList)
                    .flatMap(u => u.role)
                    .uniq()
                    .sort()
                    .value();
            },
            sourceComparator() {
                return this.createBTableComparator(comparing(x => !x.fromCommonLib));
            }
        },
        async mounted() {
            this.loading = true;
            await Promise.all([
                this.loadUserList(),
                this.loadScopes()
            ]);
            this.loading = false;
        },
        methods: {
            async loadUserList() {
                const res = await getAllUserAttributes(this);
                this.userList = res.flatMap(user =>
                    user.policies.map(policy => ({
                        email: user.email,
                        id: user.id,
                        name: user.fullName,
                        fromCommonLib: policy.commonLibrary,
                        policyId: policy.id,
                        role: policy.facilityRole,
                        scope: policy.scope,
                        system: policy.sourceSystem,
                        dateTimeExpire: policy.dateTimeExpire,
                        isExpired: this.checkIfExpired(policy.dateTimeExpire, policy.commonLibrary)
                    }))
                );
            },
            async loadScopes() {
                this.scopes = (await getScopes(this, 'Facility')).sort();
            },
            async savePolicy(policy) {
                if (this.userAttributeExists(policy)) {
                    this.showErrorMessage('User attribute already exists');
                } else {
                    await saveUserAttribute(this, this.newUserEmail, policy);
                    await this.loadUserList();
                    this.onCancel();
                }
            },
            async expirePolicy(user) {
                const userPolicy = {
                    id: user.policyId,
                    facilityRole: user.role,
                    sourceSystem: user.system,
                    scope: user.scope,
                    commonLibrary: user.fromCommonLib
                };
                await expireUserAttribute(this, user.email, userPolicy);
                await this.loadUserList();
            },
            userAttributeExists(policy) {
                return this.userList.some(p => p.email === this.newUserEmail
                    && p.role === policy.facilityRole && p.system === policy.sourceSystem
                    && p.scope === policy.scope);
            },
            hasRole(group, role) {
                return group.roles.has(role);
            },
            hasExpiredRole(group, role) {
                return group.roles.get(role)?.isExpired;
            },
            showErrorMessage(message) {
                Snackbar.open({
                    message: message,
                    type: 'is-danger',
                    position: 'is-top',
                    duration: 5000
                });
            },
            validateEmail() {
                if (!this.newUserEmail) return;
                if (this.validateEmailAndModify(this.newUserEmail)) {
                    this.emailValid = true;
                } else {
                    this.showErrorMessage('Invalid email address, must be of format USERNAME@equinor.com');
                    this.newUserEmail = null;
                }
            },
            validateEmailAndModify(email) {
                if (!this.newUserEmailHasValue) return false;

                if (!email.includes('@')) return false;

                const split = email.split('@', 2);
                const mailToTest = `${split[0].toUpperCase()}@${split[1].toLowerCase()}`;

                const mailFormat = /^[^@\sa-z]{2,64}@equinor.com$/;
                if (!mailToTest.match(mailFormat)) return false;

                this.newUserEmail = mailToTest;
                return true;
            },
            onClickCollapseAll() {
                this.expandedRows = [];
            },
            onCancel() {
                this.createNew = !this.createNew;
                this.newUserEmail = null;
                this.emailValid = false;
            },
            showSource(fromCommonLib) {
                return fromCommonLib ? 'Common Library' : 'STID';
            },
            checkIfExpired(date, fromCommonLib) {
                if (fromCommonLib && date === '0001-01-01T00:00:00')
                    return false;
                return new Date(date) < new Date();
            },
            formatDate(date, fromCommonLib) {
                if (date === '0001-01-01T00:00:00')
                    return fromCommonLib ? 'No expiration date' : 'Unknown';
                const dateObj = new Date(date);
                return `${dateObj.getDate()}/${dateObj.getMonth() + 1}/${dateObj.getFullYear()}`;
            },
            createHasRoleComparator(role) {
                return this.createBTableComparator(comparing(user => {
                    const userRole = user.roles.get(role);
                    // First valid roles
                    if (userRole?.isExpired === false)
                        return 1;
                    // Then expired roles
                    else if (userRole?.isExpired === true)
                        return 2;
                    // Then users without the role
                    else
                        return 3;
                }));
            },
            createBTableComparator(comparator) {
                return (a, b, isAsc) => (isAsc ? comparator(a, b) : comparator(b, a));
            }
        }
    };
</script>

<style scoped>
:deep(.table th) {
    vertical-align: bottom;
}
:deep(th.rotate) span {
    width: 0px;
    text-orientation: mixed;
    writing-mode: vertical-rl;
    rotate: 180deg;
}
</style>
