<script setup>
    import { useRegisterSW } from 'virtual:pwa-register/vue';

    const UPDATE_CHECK_INTERVAL = 60000; // Check for updates every 1 minute

    const { needRefresh, updateServiceWorker } = useRegisterSW({
        immediate: true,
        onRegisteredSW(swUrl, registration) {
            registration && setInterval(async () => {
                if (registration.installing || !navigator)
                    return;

                if (('connection' in navigator) && !navigator.onLine)
                    return;

                const resp = await fetch(swUrl, {
                    cache: 'no-store',
                    headers: {
                        'cache': 'no-store',
                        'cache-control': 'no-cache'
                    }
                });

                if (resp?.status === 200)
                    await registration.update();
            }, UPDATE_CHECK_INTERVAL);
        }
    });

</script>

<template>
    <div
        v-if="needRefresh">
        <b-notification
            class="reload-notification"
            type="is-warning is-light"
            aria-close-label="Close notification"
            has-icon
            role="alert">
            <span>A new version of CommonLibrary is available!
                Please save your work in all the open tabs and click update.</span>
            <b-button
                class="reload-button"
                type="is-info"
                label="Update"
                @click="updateServiceWorker()" />
        </b-notification>
    </div>
</template>

<style>

.reload-notification {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80%;
    font-size: 1.3rem;

}

.reload-button {
    margin-left: 10px;

}

</style>
