import { codeLibraryModule } from './modules/codeLibraryModule';
import { codeTableModule } from './modules/codeTableModule';

export const storeConfig = {

    modules: {
        codeLibraryModule,
        codeTableModule
    },
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
    },
    // All state mutations can be explicitly tracked by debugging tools
    // Dont use in production ->    quite expensive
    strict: import.meta.env.MODE === 'development'
};
