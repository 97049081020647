import { AxiosError } from 'axios';
import _ from 'lodash';

export function setCookie(name, value, days) {
    let expires = '';
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

export function getCookie(name) {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}
export function eraseCookie(name) {
    document.cookie = name + '=; Max-Age=-99999999;';
}

export function attributeTypeToInputType(attributeType) {
    attributeType = attributeType === undefined ? '' : attributeType;
    switch (attributeType.toLowerCase()) {
        case 'int': return 'number';
        case 'float': return 'number';
        case 'string': return 'text';
        case 'date': return 'date';
        default: 'text';
    }
}

export function filterUnique(value, index, self) {
    return self.indexOf(value) === index;
}

export function encodeIdBase64(type, Id) {
    return btoa(type + '\ni' + Id);
}
export function decodeIdBase64(encoded) {
    return atob(encoded).split('\n')[1].substring(1);
}

export function compressImage(img) {
    const width = img.width;
    const height = img.height;
    const canvas = document.createElement('canvas');
    canvas.style.display = 'none';
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0, width, height);
    return canvas.toDataURL('image/jpeg', 0.7);
}

export function dataURItoBlob(dataURI) {
    const binary = atob(dataURI.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
}

// Make sure this is in sync with CodeRefDisplayMode.cs
export function referenceDisplayMode(referenceDisplayMode) {
    switch (referenceDisplayMode) {
        case 0: default:
            return 'Identity';
        case 1:
            return 'Name';
        case 2:
            return 'Description';
        case 3:
            return 'Name and Description';
    }
}

export function levenshtein(s, t) {
    const track = Array(t.length + 1).fill(null).map(() =>
        Array(s.length + 1).fill(null));
    for (let i = 0; i <= s.length; i += 1) {
        track[0][i] = i;
    }

    for (let j = 0; j <= t.length; j += 1) {
        track[j][0] = j;
    }

    for (let j = 1; j <= t.length; j += 1) {
        for (let i = 1; i <= s.length; i += 1) {
            const indicator = s[i - 1] === t[j - 1] ? 0 : 1;
            track[j][i] = Math.min(
                track[j][i - 1] + 1, // deletion
                track[j - 1][i] + 1, // insertion
                track[j - 1][i - 1] + indicator // substitution
            );
        }
    }

    return track[t.length][s.length];
}

export function getElementHeight(htmlElement) {
    const height = htmlElement?.offsetHeight;
    const styles = window.getComputedStyle(htmlElement);
    return height + parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom']);
}

export function scrollToSelectedTableRow(tableList, value, tableRef) {
    let index;
    tableList.find((element, i) => {
        if (element && value) {
            if (element === value) {
                index = i;
                return;
            }
        }
    });
    if (index) {
        const row = tableRef.querySelectorAll('tr')[index];
        row.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
}

export const codeRefDisplayModes = [
    { value: 0, label: referenceDisplayMode(0) },
    { value: 1, label: referenceDisplayMode(1) },
    { value: 2, label: referenceDisplayMode(2) },
    { value: 3, label: referenceDisplayMode(3) }
];

export const attributeTypes = [
    'String',
    'Int',
    'Float',
    'Bool',
    'Date',
    'DateTime',
    'CodeRef',
    'LibraryRef',
    'Uri',
    'Svg'
];

export function convertToStringAndIsEquals(a, b) {
    a = a.toString().toLowerCase();
    b = b.toString().toLowerCase();
    return a === b;
}

/**
 * Wraps an asynchronous function to maintain only one active operation at a time.
 *
 * When invoked, the wrapper cancels any ongoing asynchronous operation from the last call (if any) and initiates a new
 * one. The supplied asynchronous function, `func`, should be capable of handling an `AbortSignal` for cancellation
 * purposes.
 *
 * @template {Array<unknown>} Args - The argument types for the function.
 * @template Result - The return type of the function.
 * @template Self - The type of the `this` context for the function.
 * @param {function(this: Self, AbortSignal, ...Args): Promise<Result>} func - The function to wrap
 * @returns {function(this: Self, ...Args): Promise<Result>}
 */
export function cancelPreviousWhenCalled(func) {
    let abortController = null;
    return function() {
        abortController?.abort();
        abortController = new AbortController();
        return func.apply(this, [abortController.signal, ...arguments]);
    };
}

/**
 * Wraps an asynchronous operation to both debounce it and maintain only one active operation at a time.
 *
 * When invoked, the wrapper cancels any ongoing asynchronous operation from the last call (if any) and starts a new
 * one immediately
 *
 *
 * @template {Array<unknown>} Args
 * @template Self
 *
 * @param {function(this: Self, abortSignal: AbortSignal, readyPromise: Promise<void>, ...Args): Promise<void> | void} callback The function to wrap
 * @param {object} options
 * @param {number} [options.delay] The delay in milliseconds for the debounce
 * @param {_.DebounceSettings} [options.debounceSettings] Options for the debounce function
 */
export function cancelPreviousWhenCalledWithDebouncing(callback, { delay, debounceSettings } = {}) {
    // Schedules an effect on the "debounce queue" that may or may not be executed.
    const scheduleDebounced = _.debounce(action => action(), delay, debounceSettings);

    // Creates a promise that resolves when the operation is ready to proceed.
    function createReadyPromise(abortSignal) {
        return new Promise((resolve, reject) => {
            scheduleDebounced(() => {
                if (abortSignal.aborted) {
                    reject(abortSignal.reason);
                } else {
                    resolve();
                }
            });
        });
    }

    function rethrowUnlessCancel(ex) {
        if (isAbortError(ex) || isCancelAxiosError(ex))
            return;
        throw ex;
    }

    async function wrapper(abortSignal, ...args) {
        try {
            const readyPromise = createReadyPromise(abortSignal);
            await callback.call(this, abortSignal, readyPromise, ...args);
        } catch (e) {
            rethrowUnlessCancel(e);
        }
    }

    return cancelPreviousWhenCalled(wrapper);
}

export function isAbortError(error) {
    return error instanceof DOMException && error.name === 'AbortError';
}

export function isCancelAxiosError(error) {
    return error instanceof AxiosError && error.name === 'CanceledError';
}

/**
 * Returns the stepSize to be used with input fields.
 * Default step size is 1.
 * To allow float digits step size, set noDigits to the number of decimals wanted after 0.
 *
 * MDN docs: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/number
 *
 * @param {string} attributeType
 * @param {number} noDigits - Number of digits
 * @returns {string}
 */
export function attributeTypeToStepSize(attributeType, noDigits = 2) {
    if (attributeType === undefined) return '';
    switch (attributeType.toLowerCase()) {
        case 'int': return '1';
        case 'float': {
            const zero = '0';
            return `0.${zero.repeat(noDigits - 1)}1`;
        }
        default: return '1';
    }
}

/**
 * A locale-aware collator for sorting strings.
 */
const defaultCollator = new Intl.Collator(undefined, {
    usage: 'sort',
    caseFirst: 'upper'
});

/**
 * @template T
 * @typedef {(a: T, b: T) => number} Comparator
 */

/**
 * @template T
 * @type {Comparator<T>}
 */
export const defaultComparer = (a, b) => {
    if (typeof a === 'string' && typeof b === 'string')
        return defaultCollator.compare(a, b);

    // Sort nullish values last
    if (isNullish(a))
        return isNullish(b) ? 0 : 1;
    if (isNullish(b))
        return -1;

    if (a < b)
        return -1;
    else if (a > b)
        return 1;
    else
        return 0;
};

/**
 * @template T, U
 * @param {(a: T) => U} valueSelector
 * @param {Comparator<U>} [valueComparator]
 * @returns {Comparator<T>}
 */
export function comparing(valueSelector, valueComparator = undefined) {
    valueComparator ??= defaultComparer;
    return (a, b) =>
        valueComparator(
            valueSelector(a),
            valueSelector(b)
        );
}

export function chainComparers(...comparers) {
    return comparers.reduce((acc, next) => (a, b) => acc(a, b) || next(a, b));
}

export function flipComparer(comparer) {
    return (a, b) => comparer(b, a);
}

/**
 * Returns a function to be used as a compareFn when sorting an array of objects.
 * The objects will be compared by the contents of the property specifyed by parameter propertyName.
 *
 * @template T
 * @param {keyof T} propertyName
 * @returns {Comparator<T>}
 */
export function compareByProperty(propertyName) {
    return comparing(obj => obj[propertyName]);
}

/**
 * @template T
 *
 * @param comparator<T>
 * @returns {function(T, T, boolean): number}
 */
export function createBTableComparator(comparator) {
    return (a, b, isAsc) => (isAsc ? comparator(a, b) : comparator(b, a));
}

// Collator for checking equality of strings, ignoring case.
const caseInsensitiveEqualityCollator = new Intl.Collator(undefined, {
    // Return value indicates equality only.
    //
    // From the ECMAScript specification:
    // > The collation associated with the "search" value for the "usage" option should only be used to find matching
    // > strings, since this collation is not guaranteed to be in any particular order."
    usage: 'search',
    // Ignore casing, but not accents and other diacritics.
    sensitivity: 'accent'
});

/**
 * Checks if two values are equal with respect to `===`, ignoring case for strings.
 * @param {any} a
 * @param {any} b
 * @returns {boolean}
 */
export function equalsIgnoreCase(a, b) {
    if (typeof a === 'string' && typeof b === 'string') {
        return caseInsensitiveEqualityCollator.compare(a, b) === 0;
    } else {
        return a === b;
    }
}

/**
 * Checks if a path is a prefix of another path.
 *
 * @param {string} path
 * @param {string} prefix
 * @returns {boolean}
 */
export function isPrefixOfPath(path, prefix) {
    const normalizedPath = path.replace(/\/$/, '').toLowerCase();
    const normalizedPrefix = prefix.replace(/\/$/, '').toLowerCase();
    return normalizedPath.startsWith(normalizedPrefix);
}

export function isNullish(obj) {
    return obj === null || obj === undefined;
}
