<template>
    <div>
        <div class="columns">
            <div class="column">
                <h2 class="title is-3">
                    Releases
                </h2>
            </div>

            <div class="column is-clearfix">
                <div class="field is-grouped is-pulled-right">
                    <p class="control">
                        <a
                            href="/Log"
                            class="button is-info">
                            <b-icon
                                icon="format-list-numbered"
                                size="is-small" />
                            <span>Log</span>
                        </a>
                    </p>
                </div>
            </div>
        </div>

        <div class="buttons">
            <sequential-process
                :states="allStates"
                :active-states="activeStates"
                @click="toggleState" />
            <div class="pb-4 ml-3">
                <b-checkbox
                    v-model="allStatesSelected"
                    @update:model-value="toggleSelectAllStates">
                    Select all states
                </b-checkbox>
            </div>
        </div>

        <b-loading
            v-model="loading"
            :is-full-page="false" />
        <div class="toolbar block">
            <searchable-dropdown-checkbox
                :options="allLibraries"
                :is-loading="allLibrariesLoading"
                :is-disabled="!allLibraries.length"
                selector="name"
                title="Libraries"
                @update="onSelectedLibrariesChanged" />
            <searchable-dropdown-checkbox
                :options="codeSets"
                :is-loading="codeSetsLoading"
                :is-disabled="!codeSets.length"
                selector="description"
                title="Code sets"
                message-if-empty="Selected libraries do not have code sets."
                @update="onSelectedCodeSetsChanged" />
            <searchable-dropdown-checkbox
                :options="scopes"
                :is-loading="scopesLoading"
                :is-disabled="!scopes.length"
                title="Scopes"
                message-if-empty="Selected libraries do not have scopes."
                @update="onSelectedScopesChanged" />
            <b-dropdown>
                <template #trigger>
                    <b-button
                        :label="`Number of results: ${selectedNumOfResults}`"
                        type="is-info"
                        icon-right="menu-down" />
                </template>
                <b-dropdown-item
                    v-for="num in numOfResults"
                    :key="num"
                    :value="num"
                    @click="onSelectedNumberOfResultsChanged(num)">
                    {{ num }}
                </b-dropdown-item>
            </b-dropdown>
        </div>
        <release-table
            :releases="releases" />
    </div>
</template>

<script>

    import SearchableDropdownCheckbox from '@/shared/components/SearchableDropdownCheckbox.vue';
    import SequentialProcess from '@/shared/components/SequentialProcess.vue';
    import Spinner from '@/shared/components/Spinner.vue';
    import { getCodeSetsByLibraries, getFilteredReleases, getLibraries, getScopesByScopeTypes } from '@/shared/helpers/api';
    import ReleaseTable from './ReleaseTable.vue';

    export default {
        components: {
            Spinner,
            SequentialProcess,
            ReleaseTable,
            SearchableDropdownCheckbox
        },
        data() {
            return {
                releases: [],
                loading: true,
                activeStates: ['Open', 'Verified'],
                allStates: ['Planned', 'Creating', 'Open', 'Closed', 'Verifying', 'Verified', 'Approved', 'Committing', 'Committed', 'Publishing', 'Published', 'Discarded'],
                allLibraries: [],
                codeSets: [],
                scopes: [],
                selectedLibraries: [],
                selectedCodeSets: [],
                selectedScopes: [],
                numOfResults: [10, 50, 100, 500, 1000, 5000],
                selectedNumOfResults: 100,
                allLibrariesLoading: true,
                codeSetsLoading: false,
                scopesLoading: false,
                allStatesSelected: false
            };
        },
        url: {
            activeStates: {
                param: 'state',
                noHistory: true
            }
        },
        computed: {
            pagingParameters() {
                return {
                    Take: this.selectedNumOfResults,
                    Direction: 'Decending'
                };
            }
        },
        async mounted() {
            await Promise.all([
                this.loadReleases(),
                this.loadAllLibraries()
            ]);
        },
        methods: {
            async toggleState(state) {
                if (this.activeStates.includes(state))
                    this.activeStates = this.activeStates.filter(s => s !== state);
                else
                    this.activeStates.push(state);
                this.allStatesSelected = this.allStates.every((s) => this.activeStates.includes(s));
                await this.loadReleases();
            },
            async toggleSelectAllStates() {
                if (this.allStatesSelected) {
                    this.activeStates = [...this.allStates];
                } else {
                    this.activeStates = [];
                }
                await this.loadReleases();
            },
            async loadReleases() {
                this.loading = true;
                if (this.activeStates.length > 0)
                    await this.loadFilteredReleases();
                else
                    this.releases = [];
                this.loading = false;
            },
            async loadFilteredReleases() {
                const res = await getFilteredReleases(this, {
                    states: this.activeStates,
                    libraries: this.selectedLibraries.map(l => l.name),
                    codeSets: this.selectedCodeSets,
                    scopes: this.selectedScopes,
                    pagingParameters: this.pagingParameters
                });
                this.releases = res.data;
            },
            async loadAllLibraries() {
                this.allLibrariesLoading = true;
                this.allLibraries = await getLibraries(this);
                this.allLibraries.sort((a, b) => a.name > b.name ? 1 : -1);
                this.allLibrariesLoading = false;
            },
            async fetchCodeSets() {
                this.codeSetsLoading = true;
                const res = await getCodeSetsByLibraries(this, this.selectedLibraries.map(l => l.name));
                this.codeSets = res.data.sort((a, b) => a.description > b.description ? 1 : -1);
                this.codeSetsLoading = false;
            },
            async fetchScopes() {
                this.scopesLoading = true;
                const res = await getScopesByScopeTypes(this, this.selectedLibraries.map(l => l.scopeType));
                this.scopes = res.data;
                this.scopesLoading = false;
            },
            async onSelectedLibrariesChanged(selectedLibraries) {
                this.selectedLibraries = selectedLibraries;
                if (this.selectedLibraries.length > 0) {
                    await Promise.all([
                        this.fetchCodeSets(),
                        this.fetchScopes()
                    ]);
                } else {
                    this.codeSets = [];
                    this.selectedCodeSets = [];
                    this.scopes = [];
                    this.selectedScopes = [];
                }
                await this.loadReleases();
            },
            async onSelectedCodeSetsChanged(selectedCodeSets) {
                this.selectedCodeSets = selectedCodeSets.map(cs => cs.name);
                await this.loadReleases();
            },
            async onSelectedScopesChanged(selectedScopes) {
                this.selectedScopes = selectedScopes;
                await this.loadReleases();
            },
            async onSelectedNumberOfResultsChanged(number) {
                this.selectedNumOfResults = number;
                await this.loadReleases();
            }
        }
    };

</script>

<style scoped>
  .toolbar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0;
  }
</style>
