<template>
    <div class="tile is-ancestor wrap">
        <div class="tile is-parent is-3">
            <search-tile />
        </div>
        <div
            v-for="(tag, idx) in primaryTags"
            :key="idx+tag.name"
            class="tile is-parent is-3">
            <library-group-tile :tag="tag" />
        </div>
        <div
            v-for="(arr, idx) in secondaryTags"
            :key="idx"
            class="tile is-vertical is-3 is-parent">
            <template
                v-for="tag in arr"
                :key="idx+tag.name">
                <library-group-tile
                    :tag="tag" />
            </template>
        </div>
        <template v-if="remainingTags.length > 0">
            <div class="tile is-parent is-3">
                <library-group-tile :remaining-tags="remainingTags" />
            </div>
        </template>
    </div>
</template>

<script>

    import LibraryGroupTile from '@/shared/components/LibraryGroupTile.vue';
    import SearchTile from './SearchTile.vue';
    import { getAllTagsAsEntities } from '@/shared/helpers/api';
    export default {
        components: {
            SearchTile,
            LibraryGroupTile
        },
        data() {
            return {
                tags: []
            };
        },
        computed: {
            primaryTags() {
                return this.tags.filter(t => t.promotionLevel === 2).sort((a, b) => a.name.localeCompare(b.name));
            },
            secondaryTags() {
                const secTags = this.tags.filter(t => t.promotionLevel === 1).sort((a, b) => a.name.localeCompare(b.name));
                const matrix = [];
                for (let i = 0, k = -1; i < secTags.length; i++) {
                    if (i % 2 === 0) {
                        k++;
                        matrix[k] = [];
                    }
                    matrix[k].push(secTags[i]);
                }
                return matrix;
            },
            remainingTags() {
                return this.tags.filter(t => t.promotionLevel === 0).sort((a, b) => a.name.localeCompare(b.name));
            }
        },
        mounted() {
            this.loadTags();
        },
        methods: {
            async loadTags() {
                this.tags = await getAllTagsAsEntities(this);
            }
        }

    };
</script>

<style scoped>
.wrap {
    flex-wrap: wrap;
}
</style>
