import moment from 'moment';
import cronstrue from 'cronstrue';

export function truncateFilter(text, length, clamp) {
    if (length === 0) return text;

    text = text || '';
    clamp = clamp || '...';
    length = length || 30;

    if (text.length <= length) return text;

    let tcText = text.slice(0, length - clamp.length);
    let last = tcText.length - 1;

    while (last > 0 && tcText[last] !== ' ' && tcText[last] !== clamp[0]) last -= 1;

    // Fix for case when text dont have any `space`
    last = last || length - clamp.length;

    tcText = tcText.slice(0, last);

    return tcText + clamp;
}

export function dateFormatShortFilter(date) {
    if (!date) {
        return ';';
    } else if (!moment(date).isValid()) {
        return date;
    } else {
        return moment(date).format('YYYY-MM-DD');
    }
}

export function dateFormatLongFilter(date) {
    if (!date)
        return '';
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
}

export function thousandSpaceSeparatorFilter(n) {
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export function formatCronstrueString(cronstrueExpression) {
    const cronstrueOptions = {
        use24HourTimeFormat: true,
        throwExceptionOnParseError: false
    };
    return cronstrue.toString(cronstrueExpression, cronstrueOptions);
}

export function changeValueFilter(value) {
    if (value === null)
        return '{null}';
    else if (value === '')
        return '{empty}';
    else
        return value;
}

export const legacyFiltersPlugin = {
    install(app) {
        app.mixin({
            created() {
                const localFilters = this.$options.filters ?? {};
                this.$filters = {
                    'truncate': truncateFilter,
                    'dateFormatShort': dateFormatShortFilter,
                    'dateFormatLong': dateFormatLongFilter,
                    'thousandSpaceSeparator': thousandSpaceSeparatorFilter,
                    ...localFilters
                };
            }
        });
    }
};
