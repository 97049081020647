<template>
    <div>
        <div class="columns">
            <div class="column">
                <h2 class="title is-3">
                    <b-icon
                        icon="table" />
                    Library statistics
                </h2>
            </div>
            <div class="column">
                <div class="is-pulled-right field is-grouped">
                    <div class="control buttons has-addons">
                        <button
                            v-show="selectedLibrary"
                            class="button"
                            @click="deselectLibrary">
                            <b-icon
                                icon="arrow-left"
                                size="is-small" />
                            <span>Back to libraries</span>
                        </button>
                        <button
                            v-show="selectedLibrary"
                            class="button is-danger"
                            @click="recompute">
                            <b-icon
                                icon="auto-fix"
                                size="is-small" />
                            <span>Recompute</span>
                        </button>
                        <button
                            class="button"
                            @click="refresh">
                            <b-icon
                                icon="refresh"
                                size="is-small" />
                            <span>Refresh</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-show="!selectedLibrary"
            class="block">
            <b-field grouped>
                <b-field
                    label="Facility"
                    label-position="on-border">
                    <b-select
                        v-model="selectedFacility"
                        :disabled="loading">
                        <option
                            v-for="facility in facilities"
                            :key="facility"
                            :value="facility">
                            {{ facility }}
                        </option>
                    </b-select>
                    <p class="control">
                        <b-button
                            icon-right="eraser"
                            :disabled="!selectedFacility"
                            @click="selectedFacility = null" />
                    </p>
                </b-field>
                <b-switch v-model="showGlobalLibraries">
                    Include global
                </b-switch>
                <b-switch v-model="showGroupedLibraries">
                    Include grouped
                </b-switch>
                <b-switch v-model="showUngroupedLibraries">
                    Include ungrouped
                </b-switch>
                <b-switch v-model="showUnknownGroupingLibraries">
                    Include no grouping info
                </b-switch>
                <b-switch v-model="showLibrariesWithoutDiff">
                    Include libraries without differences
                </b-switch>
                <button
                    v-show="!showLibrariesWithoutDiff"
                    class="button"
                    @click="toggleAllDetails">
                    <span>{{ activeDetails.length > 0 ? 'Hide' : 'Open' }} details</span>
                </button>
            </b-field>
        </div>
        <p
            v-show="selectedFacility"
            class="block">
            <i>Only showing data for <b>{{ selectedFacility }}</b>. Deselect facility to show everything.</i>
        </p>
        <div class="block">
            <div class="legend-box not-release-ready">
                &nbsp;
            </div>: Some sets are not release ready
        </div>
        <hr>

        <div class="block">
            <b-table
                v-show="!selectedLibrary"
                :detailed="!showLibrariesWithoutDiff"
                :opened-detailed="activeDetails"
                detail-key="libraryName"
                :data="filteredLibraries"
                :default-sort="['libraryName', 'asc']"
                :striped="false"
                :narrowed="true"
                :hoverable="false"
                :loading="loading"
                :sticky-header="true"
                height="70vh"
                :row-class="rowCssClass">
                <b-table-column
                    v-slot="props"
                    field="libraryName"
                    label="Library"
                    sortable
                    searchable>
                    <a
                        v-if="!selectedFacility"
                        style="white-space: nowrap"
                        @click="loadDetails(props.row)">
                        {{ props.row.libraryName }}
                    </a>
                    <span v-else>
                        {{ props.row.libraryName }}</span>
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="allLockedForDelete"
                    label=""
                    sortable>
                    <b-tooltip :label="props.row.allLockedForDelete ? 'Locked for delete' : 'Some sets open for delete'">
                        <b-icon
                            size="is-small"
                            type="is-info"
                            :icon="props.row.allLockedForDelete ? 'lock' : 'lock-off'" />
                    </b-tooltip>
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="allIsReleaseReady"
                    label=""
                    sortable>
                    <b-tooltip :label="props.row.allIsReleaseReady ? 'Released' : 'Some sets not release ready'">
                        <b-icon
                            size="is-small"
                            type="is-info"
                            :icon="props.row.allIsReleaseReady ? 'cloud' : 'alert'" />
                    </b-tooltip>
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="maxStatisticsUpdatedDate"
                    label="Updated"
                    sortable>
                    <b-tooltip
                        multilined
                        :label="'Max:' + props.row.maxStatisticsUpdatedDate + ' Min:' + props.row.minStatisticsUpdatedDate"
                        position="is-right"
                        style="white-space: nowrap">
                        {{ $filters.dateFormatShort(props.row.maxStatisticsUpdatedDate) }}
                    </b-tooltip>
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="codeSetCount"
                    label="#sets"
                    numeric
                    sortable>
                    {{ props.row.codeSetCount }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="codeCount"
                    label="#codes"
                    numeric
                    sortable>
                    {{ props.row.codeCount }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="validCodeCount"
                    label="#valid"
                    numeric
                    sortable>
                    {{ props.row.validCodeCount }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    label="#invalid"
                    numeric>
                    {{ props.row.codeCount - props.row.validCodeCount }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="stidCodeCount"
                    label="#codes STID"
                    numeric
                    sortable>
                    {{ props.row.stidCodeCount }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="stidValidCodeCount"
                    label="#valid STID"
                    numeric
                    sortable>
                    {{ props.row.stidValidCodeCount }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="stidMissingInStidCodeCount"
                    label="#missing STID"
                    numeric
                    sortable>
                    {{ props.row.stidMissingInStidCodeCount }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="stidMissingInCommonLibCodeCount"
                    label="#missing CL"
                    numeric
                    sortable>
                    {{ props.row.stidMissingInCommonLibCodeCount }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="stidInBothButDifferentCodeCount"
                    label="#diff w/STID"
                    numeric
                    sortable>
                    {{ props.row.stidInBothButDifferentCodeCount }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="maxStidSyncDate"
                    label="STID sync"
                    style="white-space: nowrap"
                    sortable>
                    {{ $filters.dateFormatShort(props.row.maxStidSyncDate) }}
                </b-table-column>
                <template #detail="props">
                    <details-table
                        table-height="10vh"
                        :loading-details="loadingAllDetails"
                        :details="allLibraryDetails[props.row.libraryName]"
                        :is-searchable="false" />
                </template>
            </b-table>
        </div>

        <div
            v-show="!selectedLibrary"
            class="block">
            To include statistics for a library, make sure it is registered in <b>LibraryMetadata</b> and that <b>IsForStatisticReports</b> is set to <b>true</b>.
        </div>

        <div v-if="selectedLibrary">
            <h3 class="title is-4">
                <span>Statistics for {{ selectedLibrary.libraryName }}</span>
            </h3>
            <div
                class="columns is-desktop is-multiline">
                <div class="column is-one-third-desktop is-full-tablet is-full-mobile">
                    <div
                        class="box">
                        <table class="table is-striped is-narrow is-fullwidth">
                            <tbody>
                                <tr>
                                    <th>Code count</th>
                                    <td>{{ selectedLibrary.codeCount }} total, {{ selectedLibrary.validCodeCount }} valid, {{ selectedLibrary.codeCount - selectedLibrary.validCodeCount }} not valid</td>
                                </tr>
                                <tr>
                                    <th>Code count STID</th>
                                    <td>{{ selectedLibrary.stidCodeCount }} total, {{ selectedLibrary.stidValidCodeCount }} valid, {{ selectedLibrary.stidCodeCount - selectedLibrary.stidValidCodeCount }} not valid</td>
                                </tr>
                                <tr>
                                    <th>STID sync</th>
                                    <td>
                                        {{ selectedLibrary.stidMissingInStidCodeCount }} missing in STID<br>
                                        {{ selectedLibrary.stidMissingInCommonLibCodeCount }} missing in Common Library<br>
                                        {{ selectedLibrary.stidInBothButDifferentCodeCount }} in both but different<br>
                                    </td>
                                </tr>
                                <tr>
                                    <th>STID sync date</th>
                                    <td v-if="selectedLibrary.maxStidSyncDate">
                                        {{ $filters.dateFormatLong(selectedLibrary.minStidSyncDate) }} (oldest current)<br>
                                        {{ $filters.dateFormatLong(selectedLibrary.maxStidSyncDate) }} (newest current)<br>
                                    </td>
                                    <td v-else>
                                        <i>Never</i>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Statistics update date</th>
                                    <td>
                                        {{ $filters.dateFormatLong(selectedLibrary.minStatisticsUpdatedDate) }} (oldest current)<br>
                                        {{ $filters.dateFormatLong(selectedLibrary.maxStatisticsUpdatedDate) }} (newest current)<br>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="column is-two-thirds-desktop is-full-tablet is-full-mobile">
                    <div
                        class="box">
                        <table class="table is-striped is-narrow is-fullwidth">
                            <tbody>
                                <tr>
                                    <th>LibraryMetadata.IsGrouped</th>
                                    <td>{{ selectedLibraryMetadata?.IsGrouped }}</td>
                                </tr>
                                <tr>
                                    <th>LibraryMetadata.MessagesFromDevOps</th>
                                    <td>{{ selectedLibraryMetadata?.MessagesFromDevOps }}</td>
                                </tr>
                                <tr>
                                    <th>LibraryMetadata.MessagesFromPO</th>
                                    <td>{{ selectedLibraryMetadata?.MessagesFromPO }}</td>
                                </tr>
                                <tr>
                                    <th>LibraryMetadata.StatusLibrary</th>
                                    <td>{{ selectedLibraryMetadata?.StatusLibrary }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <h3 class="title is-4">
                <span>Library sets ({{ selectedLibrary.codeSetCount }})</span>
            </h3>
            <details-table
                table-height="70vh"
                :loading-details="loadingDetails"
                :details="selectedLibraryDetails" />
        </div>
    </div>
</template>

<script>
    import DetailsTable from '@/apps/report/DetailsTable.vue';
    import { genericViewQueryAsText } from '@/shared/helpers/api';
    import {
        getLibrariesStatistics,
        getLibrariesStatisticsWithScopeFilter,
        getLibraryStatisticDetails,
        getScopes,
        recomputeLibraryStatistics
    } from '@/shared/helpers/api.ts';
    import { getCodeSetLink } from '@/shared/helpers/routing.js';
    import { showMixin } from '@/shared/mixins/showMixin';

    export default {
        components: { DetailsTable },
        mixins: [
            showMixin
        ],
        data() {
            return {
                facilities: [],
                selectedFacility: null,
                libraryStatsList: [],
                selectedLibrary: null,
                selectedLibraryDetails: [],
                allLibraryDetails: [],
                libraryMetadata: null,
                showGlobalLibraries: true,
                showGroupedLibraries: true,
                showUngroupedLibraries: true,
                showUnknownGroupingLibraries: true,
                showLibrariesWithoutDiff: true,
                loading: false,
                loadingDetails: false,
                loadingAllDetails: false,
                activeDetails: []
            };
        },
        computed: {
            selectedLibraryMetadata() {
                return this.selectedLibrary ? this.libraryMetadata[this.selectedLibrary.libraryName] : null;
            },
            filteredLibraries() {
                if (!this.libraryMetadata)
                    return []; // not loaded yet

                return this.libraryStatsList.filter(row => {
                    const meta = this.libraryMetadata[row.libraryName];
                    if (meta?.IsForStatisticReports !== true) {
                        return false;
                    }
                    if (!this.showLibrariesWithoutDiff
                        && (row.stidMissingInStidCodeCount === 0
                            && row.stidMissingInCommonLibCodeCount === 0
                            && row.stidInBothButDifferentCodeCount === 0)) {
                        return false;
                    }
                    if (this.showGlobalLibraries && row.isGlobal) {
                        return true;
                    }
                    if (!this.showGroupedLibraries && meta?.IsGrouped === true) {
                        return false;
                    }
                    if (!this.showUngroupedLibraries && meta?.IsGrouped === false) {
                        return false;
                    }
                    if (!this.showUnknownGroupingLibraries && (meta === undefined || meta.IsGrouped === null)) {
                        return false;
                    }
                    return true; // default catch-all
                });
            }
        },
        watch: {
            async selectedFacility() {
                await this.loadLibrariesStatistics();
            },
            async showLibrariesWithoutDiff() {
                if (!this.showLibrariesWithoutDiff) {
                    await this.loadDetailsAllLibraries();
                }
            }
        },
        async mounted() {
            await Promise.all([
                this.loadFacilities(),
                this.loadLibraryMetadata(),
                this.loadLibrariesStatistics()
            ]);
        },
        methods: {
            async loadFacilities() {
                this.facilities = (await getScopes(this, 'Facility')).sort();
            },
            async refresh() {
                await Promise.all([
                    this.loadLibraryMetadata(),
                    this.loadLibrariesStatistics(),
                    this.loadDetailsForSelectedLibrary()
                ]);
            },
            async loadLibraryMetadata() {
                try {
                    const res = await genericViewQueryAsText(this, 'FROM LibraryMetadata WHERE IsValid = true');
                    this.libraryMetadata = res.data.reduce((acc, row) => {
                        acc[row.Name] = row;
                        return acc;
                    }, {});
                } catch (error) {
                    this.showError(error);
                }
            },
            async loadLibrariesStatistics() {
                try {
                    this.loading = true;
                    this.libraryStatsList = this.selectedFacility
                        ? await getLibrariesStatisticsWithScopeFilter(this, this.selectedFacility)
                        : await getLibrariesStatistics(this);
                } catch (error) {
                    this.showError(error);
                } finally {
                    this.loading = false;
                }
            },
            async loadDetailsForSelectedLibrary() {
                if (this.selectedLibrary !== null) {
                    await this.loadDetails(this.selectedLibrary);
                }
            },
            async loadDetails(library) {
                try {
                    this.loadingDetails = true;
                    this.selectedLibrary = library;
                    this.selectedLibraryDetails = await getLibraryStatisticDetails(this, this.selectedLibrary.libraryName);
                } catch (error) {
                    this.showError(error);
                } finally {
                    this.loadingDetails = false;
                }
            },
            async loadDetailsAllLibraries() {
                try {
                    this.loadingAllDetails = true;
                    this.allLibraryDetails = {};
                    const promises = this.filteredLibraries.map(library =>
                        getLibraryStatisticDetails(this, library.libraryName, true)
                            .then(result => [library.libraryName, result])
                    );
                    const results = await Promise.all(promises);
                    this.allLibraryDetails = Object.fromEntries(results);
                } catch (error) {
                    this.showError(error);
                } finally {
                    this.loadingAllDetails = false;
                }
            },
            deselectLibrary() {
                this.selectedLibrary = null;
                this.selectedLibraryDetails = [];
            },
            async recompute() {
                try {
                    this.selectedLibraryDetails = [];
                    this.loadingDetails = true;
                    await recomputeLibraryStatistics(this, this.selectedLibrary.libraryName);
                    await this.loadDetails(this.selectedLibrary);
                    this.showInfo('Statistics re-computed');
                } catch (error) {
                    this.showError(error);
                }
            },
            toggleAllDetails() {
                if (this.activeDetails.length === 0) {
                    this.activeDetails = this.filteredLibraries.map(obj => obj.libraryName);
                } else {
                    this.activeDetails = [];
                }
            },
            rowCssClass(row) {
                return !row.isReleaseReady && !row.allIsReleaseReady && 'not-release-ready';
            },
            getCodeSetLink
        }
    };
</script>

<!--suppress CssUnusedSymbol -->
<style scoped>
    :deep(.legend-box) {
        display: inline-block;
        width: 1.5em;
        height: 1.5em;
        margin-right: 0.5em;
        border: 1px solid #efefef;
    }
    :deep(tr.not-release-ready), :deep(.not-release-ready) {
        background-color: #ff8080;
        color: white;
    }
</style>
