<template>
    <p>
        {{ prefix }}<span
            v-if="decoratedText"
            :style="styleObject"> {{ decoratedText }} </span>{{ suffix }}
    </p>
</template>

<script>
    export default {
        props: {
            prefix: {
                type: String,
                default: ''
            },
            suffix: {
                type: String,
                default: ''
            },
            decoratedText: {
                type: String,
                default: undefined
            },
            styleObject: {
                type: Object,
                default: () => ({})
            }
        }
    };
</script>
