<template>
    <span
        class="keep-spaces"
        :title="text"
        v-text="displayText" />
</template>
<script>
    import { changeValueFilter, truncateFilter } from '@/filters.js';

    export default {
        props: {
            text: {
                type: [String, Boolean],
                default: () => ''
            },
            length: {
                type: Number,
                default: () => 40
            }
        },
        computed: {
            displayText() {
                return truncateFilter(changeValueFilter(this.text), length);
            }
        },
        methods: {}
    };
</script>

<style scoped>
    .keep-spaces {
        white-space: pre-wrap;
    }
</style>
