import { showMixin } from '@/shared/mixins/showMixin';
import { getCurrentInstance } from 'vue';

// It's not possible for mixins to use setup(), so we need to create
// the composable from the mixin and not the other way around
export default function() {
    const self = getCurrentInstance().proxy;

    return {
        showError: showMixin.methods.showError.bind(self),
        showInfo: showMixin.methods.showInfo.bind(self),
        showAlert: showMixin.methods.showAlert.bind(self)
    };
}
