// Note: When changing this module, consider updating the corresponding C# version in SpaceEncoding.cs

export function spaceEncode(value) {
    const encodeRegex = /^( *)(.*?)( *)$/s;

    const match = encodeRegex.exec(value);

    const leading = match[1];
    const middle = match[2];
    const trailing = match[3];

    return '{space}'.repeat(leading.length)
        + middle.replaceAll(/{space}/gis, m => '{' + m + '}')
        + '{space}'.repeat(trailing.length);
}

export function spaceDecode(value) {
    return value.replaceAll(/{({space})}|{space}/gis, (_, escaped) => escaped || ' ');
}
