import _ from 'lodash';
import { doQuery } from '../../helpers/clqlHelpers';

export const codeTableModule = {
    state: {
        filters: [],
        queries: [],
        regex: [],
        columns: [],
        libraryName: ''
    },
    getters: {
    },
    mutations: {
        UPDATE_FILTERS: (state) => {
            state.filters = [];
            const createRegexFilterFunc = function(regex) {
                if (state.columns.length === 0) return;
                const nameIdx = state.columns.find(c => c.field === 'name').valueIdx;
                const descIdx = state.columns.find(c => c.field === 'description').valueIdx;
                return row =>
                    row[nameIdx].value
                        .toLowerCase()
                        .includes(regex.toLowerCase())
                        || row[descIdx].value.toLowerCase().includes(regex.toLowerCase())
                        || row[nameIdx].value.match(new RegExp(regex));
            };

            const createQueryFilterFunc = function(queryFrags) {
                if (state.columns.length === 0) return;
                // For some reason, this sometimes ends up as undefined, so we check for it
                const col = state.columns.find(c => c.field === queryFrags[0]);
                if (!col) return;
                const attributeType = col.type;
                const valueIdx = state.columns.find(c => c.field === queryFrags[0]).valueIdx;
                return row =>
                    doQuery(
                        attributeType,
                        queryFrags[1],
                        queryFrags[2],
                        attributeType === 'CODEREF' && queryFrags[1] === 'like' ? row[valueIdx].name : row[valueIdx].value
                    );
            };

            for (let i = 0; i < state.regex.length; i++) {
                const f = createRegexFilterFunc(state.regex[i]);
                if (f) {
                    state.filters.push(f);
                }
            }
            for (let i = 0; i < state.queries.length; i++) {
                const f = createQueryFilterFunc(state.queries[i]);
                if (f)
                    state.filters.push(f);
            }
        },
        SET_COLUMNS: (state, payload) => {
            if (payload.length)
                state.columns = payload;
        },
        SET_REGEX: (state, payload) => {
            if (state.regex.find(q => q === payload)) return;
            state.regex.push(payload);
        },
        UNSET_REGEX: (state, payload) => {
            state.regex = _.without(state.regex, payload);
        },
        SET_QUERY: (state, payload) => {
            if (state.queries.find(q => _.isEqual(q, payload))) return;
            state.queries.push(payload);
        },
        UNSET_QUERY: (state, payload) => {
            state.queries = _.without(
                state.queries,
                state.queries.find(q => _.isEqual(q, payload))
            );
        },
        SET_LIBRARYNAME: (state, payload) => {
            state.libraryName = payload;
        },
        CLEAR_QUERIES: (state) => {
            state.queries = [];
        },
        CLEAR_REGEX: (state) => {
            state.regex = [];
        }

    },
    actions: {
        ADD_QUERY: ({ commit }, payload) => {
            commit('SET_QUERY', payload);
            commit('UPDATE_FILTERS');
        },
        ADD_QUERIES: ({ commit, state }, payload) => {
            if (!Array.isArray(payload)) return;
            if (_.isEqual(state.queries, payload)) return;

            commit('CLEAR_QUERIES');
            payload.forEach(element => {
                commit('SET_QUERY', element);
            });
            commit('UPDATE_FILTERS');
        },
        ADD_REGEXES: ({ commit, state }, payload) => {
            if (!Array.isArray(payload)) return;
            if (_.isEqual(state.regex, payload)) return;

            commit('CLEAR_REGEX');
            payload.forEach(element => {
                commit('SET_REGEX', element);
            });
            commit('UPDATE_FILTERS');
        },
        ADD_REGEX: ({ commit }, payload) => {
            commit('SET_REGEX', payload);
            commit('UPDATE_FILTERS');
        },
        REMOVE_QUERY: ({ commit }, payload) => {
            commit('UNSET_QUERY', payload);
            commit('UPDATE_FILTERS');
        },
        REMOVE_REGEX: ({ commit }, payload) => {
            commit('UNSET_REGEX', payload);
            commit('UPDATE_FILTERS');
        },
        ADD_COLUMNS: ({ commit }, payload) => {
            commit('SET_COLUMNS', payload);
            commit('UPDATE_FILTERS');
        },
        RESET_FILTERS: ({ commit }) => {
            commit('CLEAR_QUERIES');
            commit('CLEAR_REGEX');
            // we need to clear this, so that if you navigate to
            // the same lib, the watchers will trigger
            commit('SET_LIBRARYNAME', '');
        }
    }
};
