<template>
    <div v-if="hasErrors || hasWarnings || hasLogErrors">
        <b-message
            v-if="hasErrors"
            title="Errors in import"
            type="is-danger"
            :closable="false">
            <ul style="list-style-type: square; list-style-position: inside; margin-bottom: 1em;">
                <li
                    v-for="(error, idx) in cappedErrors"
                    :key="idx">
                    {{ error }}
                </li>
            </ul>
            <p><strong>Errors must be corrected before import can be executed.</strong></p>
            <div v-show="hasMoreErrors">
                <strong>Only first 10 errors are shown here! Total error count is {{ release.specification.errors.length }}</strong>
                <br>
                <button
                    class="button is-info is-small"
                    @click="paginate.maxErrors = undefined">
                    Show all errors
                </button>
            </div>
        </b-message>
        <b-message
            v-if="hasWarnings"
            title="Warnings in import"
            type="is-warning"
            :closable="false">
            <ul style="list-style-type: square; list-style-position: inside; margin-bottom: 1em;">
                <li
                    v-for="(warning, idx) in cappedWarnings"
                    :key="idx">
                    {{ warning }}
                </li>
            </ul>
            <div v-show="hasMoreWarnings">
                <strong>Only first 10 warnings are shown here!</strong>
                <br>
                <button
                    class="button is-info is-small"
                    @click="paginate.maxWarnings = undefined">
                    Show all warnings
                </button>
            </div>
        </b-message>
        <b-message
            v-if="hasLogErrors"
            title="Errors in log"
            type="is-danger">
            <b-table
                :data="logErrors"
                :striped="true"
                :narrowed="true"
                :hoverable="true"
                :detailed="logErrors.some(errorHasCodeResults)"
                :has-detailed-visible="errorHasCodeResults"
                detail-key="id"
                :opened-detailed="logErrors.filter(errorHasCodeResults).map(x => x.id)"
                :mobile-cards="false">
                <b-table-column
                    v-slot="props"
                    field="publishJobId"
                    label="Job"
                    sortable>
                    {{ props.row.publishJobId }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="timestamp"
                    label="Timestamp"
                    sortable>
                    {{ $filters.dateFormatLong(props.row.timestamp) }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="libraryName"
                    label="Library"
                    sortable>
                    <router-link
                        v-if="props.row.libraryName"
                        :to="getLibraryLink(props.row.libraryName)">
                        {{ props.row.libraryName }}
                    </router-link>
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="codeSetDescription"
                    label="Set"
                    sortable>
                    <router-link
                        v-if="props.row.codeSetName"
                        :to="getCodeSetLink(props.row.codeSetName)">
                        {{ props.row.codeSetDescription }}
                    </router-link>
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="message"
                    label="Message"
                    sortable>
                    {{ props.row.message }}
                </b-table-column>
                <template
                    #detail="props">
                    <div>
                        <pre>{{ props.row.data.codeResults }}</pre>
                    </div>
                </template>
            </b-table>
        </b-message>
    </div>
</template>

<script>
    import { getCodeSetLink, getLibraryLink } from '@/shared/helpers/routing.js';
    import _ from 'lodash';

    export default {
        props: {
            release: {
                type: Object,
                required: true
            },
            logErrors: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                paginate: {
                    maxErrors: 10,
                    maxWarnings: 10
                }
            };
        },
        computed: {
            cappedErrors() {
                return this.release.specification.errors.slice(0, this.paginate.maxErrors);
            },
            hasErrors() {
                return _.get(this.release, 'specification.errors.length', false);
            },
            hasMoreErrors() {
                return _.get(this.release, 'specification.errors.length', 0) > this.paginate.maxErrors;
            },
            cappedWarnings() {
                return this.release.specification.warnings.slice(0, this.paginate.maxWarnings);
            },
            hasWarnings() {
                return _.get(this.release, 'specification.warnings.length', false);
            },
            hasMoreWarnings() {
                return _.get(this.release, 'specification.warnings.length', 0) > this.paginate.maxWarnings;
            },
            hasLogErrors() {
                return this.logErrors.length > 0;
            }
        },
        methods: {
            getCodeSetLink,
            getLibraryLink,
            errorHasCodeResults(error) {
                return error.data
                    && error.data.codeResults
                    && error.data.codeResults.length > 0;
            }
        }
    };
</script>

<style scoped>
    .b-table:deep() > div > table {
        border: #e8beca 1px solid;

        & > tbody > tr.detail > td {
            /*
             * Trick to limit the width of the pre element. This somehow makes the size information flow down from parent element
             * to pre element instead of up from pre element to parent element.
             */
            max-width: 0;
        }
    }
</style>
