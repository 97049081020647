import _ from 'lodash';
import { encodeIdBase64 } from './utils.js';

export function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function createCodeDefArray(attributesDefs, options = {}) {
    const nameInIdentity = options.nameInIdentity ?? true;
    const filterFunc = options.filterFunc ?? (() => true);
    const descriptionIsRequired = options.descriptionIsRequired ?? false;
    const descriptionRegex = options.descriptionRegex ?? null;

    const constDefs = [
        { field: 'identity', label: 'Identity', required: true, includeInIdentity: true, type: 'STRING' },
        { field: 'name', label: 'Name', required: true, includeInIdentity: nameInIdentity, type: 'STRING' },
        { field: 'isValid', label: 'Is valid', required: true, includeInIdentity: false, type: 'BOOL' },
        { field: 'description', label: 'Description', required: descriptionIsRequired, includeInIdentity: false, type: 'STRING', regex: descriptionRegex },
        { field: 'dateCreated', label: 'Date created', required: true, includeInIdentity: false, type: 'DATETIME' },
        { field: 'dateUpdated', label: 'Date modified', required: true, includeInIdentity: false, type: 'DATETIME' },
        { field: 'attachmentKey', label: 'AttachmentKey', required: false, includeInIdentity: false, type: 'STRING' },
        { field: 'commonLibraryIRI', label: 'CommonLibraryIRI', required: false, includeInIdentity: false, type: 'STRING' }
    ];

    const defs = constDefs.concat(attributesDefs
        .sort((a, b) => a.sequenceNumber - b.sequenceNumber)
        .map(ad => ({
            attribute: true,
            field: ad.name,
            description: ad.description,
            label: ad.displayAs,
            sequenceNumber: ad.sequenceNumber,
            required: ad.required,
            includeInIdentity: ad.includeInIdentity,
            type: ad.attributeType,
            refLibraryName: ad.referenceLibraryName,
            referenceDisplayMode: ad.referenceDisplayMode,
            regex: ad.regex
        })))
        .filter(filterFunc);

    for (let i = 0; i < defs.length; i++) {
        defs[i].valueIdx = i;
    }

    return defs;
}

export function createCodeDefObject(attributesDefs, nameInIdentity = true, filterFunc = () => true) {
    const codeDefObj = {};
    createCodeDefArray(attributesDefs, {
        nameInIdentity: nameInIdentity,
        filterFunc: filterFunc
    }).forEach(e => {
        codeDefObj[e.field] = e;
    });
    return codeDefObj;
}

export function createCodeObject4(code, codeFields) {
    const codeObj = {
        values: {
            identity: code.Identity,
            name: code.Name,
            description: code.Description,
            isValid: code.IsValid
        },
        meta: {
            dateCreated: '',
            dateUpdated: ''
        }
    };

    codeFields.forEach(field => {
        if (field.attribute) {
            const hasField = code[field.field] !== null && code[field.field] !== undefined;
            codeObj.values[field.field] = hasField ? code[field.field] : '';
        }
    });

    return codeObj;
}

export function createCodeObject(code, codeFields) {
    const codeObj = {
        values: {
            identity: code.identity,
            name: code.name,
            description: code.description,
            isValid: code.isValid ? 'True' : 'False'
        },
        meta: {
            dateCreated: code.dateCreated,
            dateUpdated: code.dateUpdated
        }
    };
    code.attributes.forEach(a => {
        codeObj.values[a.definitionName] = a.displayValue;
    });
    codeFields.forEach(field => {
        !(field in codeObj.values) && (codeObj.values[field] = '');
    });
    return codeObj;
}

export function createCodeObject2(code, codeFields) {
    const codeObj = {
        id: code.id,
        data: {
            identity: { name: 'identity', value: code.identity },
            name: { name: 'name', value: code.name },
            description: { name: 'description', value: code.description },
            isValid: { name: 'isValid', value: code.isValid ? 'true' : 'false' },
            dateCreated: { name: 'dateCreated', value: code.dateCreated },
            dateUpdated: { name: 'dateUpdated', value: code.dateUpdated }
        }
    };
    code.attributes.forEach(a => codeObj.data[a.name] = a);
    codeFields.forEach(f => !(f in codeObj.data) && (codeObj.data[f] = { value: '' }));
    return codeObj;
}

export function createCodeObject3(code, codeFields) {
    const codeObj = {
        id: code.id,
        data: {
            identity: { name: 'identity', value: code.identity },
            name: { name: 'name', value: code.name },
            description: { name: 'description', value: code.description },
            isValid: { name: 'isValid', value: code.isValid ? 'true' : 'false' },
            dateCreated: { name: 'dateCreated', value: code.dateCreated },
            dateUpdated: { name: 'dateUpdated', value: code.dateUpdated }
        }
    };
    code.attributes.forEach(a => {
        const convertedAttr = {
            name: a.definitionName,
            identity: a.displayValue,
            value: a.displayValue
        };
        if (a.referenceCode?.id) {
            convertedAttr.id = encodeIdBase64('Code', a.referenceCode.id);
        }
        codeObj.data[a.definitionName] = convertedAttr;
    });
    codeFields.forEach(f => !(f in codeObj.data) && (codeObj.data[f] = { value: '' }));
    return codeObj;
}

export function isTruncated(val, truncLimit, def = null) {
    if (def) {
        return getCodeVal(val, def).length > truncLimit;
    } else {
        return val.length > truncLimit;
    }
}

export function getCodeVal(code, def) {
    return code.values[def.field];
}

export function getReferenceCodeLabel(attr, refCode) {
    switch (attr.referenceDisplayMode) {
        case 0: return refCode.identity;
        case 1: return refCode.name;
        case 2: return refCode.description;
        case 3: return `${refCode.name} - ${refCode.description}`;
        default:
            return refCode.identity;
    }
}

export function transformToChangeDocCode(newCode, originalCode = null) {
    const code = {};
    for (const prop in newCode) {
        if (['identity', 'referenceCodes'].includes(prop))
            continue;
        if (['name', 'description', 'isValid'].includes(prop))
            code[_.upperFirst(prop)] = newCode[prop];
        else if (originalCode && newCode[prop] === '' && originalCode[prop] !== '')
            code[prop] = '{null}';
        else if (originalCode && newCode[prop] === '' && originalCode[prop] === '')
            continue;
        else if (newCode[prop] === '')
            continue;
        else
            code[prop] = newCode[prop];
    }
    return code;
}

export function transformToChangeDocCode2(newCode, originalCode = null) {
    const code = {};
    for (const prop in newCode) {
        if (['identity', 'referenceCodes'].includes(prop))
            continue;
        if (['name', 'description', 'isValid'].includes(prop))
            code[capitalize(prop)] = newCode[prop].value;
        else if (originalCode && newCode[prop].value === '' && originalCode[prop].value !== '')
            code[prop] = '{null}';
        else if (originalCode && newCode[prop].value === '' && originalCode[prop].value === '')
            continue;
        else if (newCode[prop].value === '')
            continue;
        else
            code[prop] = newCode[prop].value;
    }
    return code;
}

export function transformToChangeDocCodeIdentityFieldsOnly(newCode, attributes) {
    const code = {};
    for (const attr of attributes) {
        const fieldValue = newCode[attr.field].value;
        if (attr.includeInIdentity && attr.field !== 'identity') {
            if (['name', 'description', 'isValid'].includes(attr.field))
                code[capitalize(attr.field)] = fieldValue;
            else if (fieldValue === '')
                continue;
            else
                code[attr.field] = fieldValue;
        }
    }
    return code;
}
