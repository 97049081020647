<template>
    <div
        v-if="isVisible">
        <b-field
            horizontal>
            <div class="control buttons is-right">
                <div
                    v-require-can-edit-code="{ libraryName: 'SchemaRelation' }">
                    <b-button
                        type="is-primary"
                        :loading="saving"
                        @click="clickSave">
                        <b-icon
                            icon="floppy"
                            size="is-small" />
                        <span>Save</span>
                    </b-button>
                    <b-button @click="clickCancel">
                        <b-icon
                            icon="close-octagon-outline"
                            size="is-small" />
                        <span>Cancel</span>
                    </b-button>
                </div>
            </div>
        </b-field>

        <b-notification
            v-for="(issue, i) in validationIssues"
            :key="i"
            type="is-danger"
            indefinite
            :closable="false"
            role="alert">
            {{ issue }}
        </b-notification>

        <b-field
            horizontal
            label="Name">
            <b-input
                v-model="name"
                :disabled="!newRelationMode" />
        </b-field>
        <b-field
            horizontal
            label="Description">
            <b-input v-model="description" />
        </b-field>
        <b-field
            horizontal
            label="Comments">
            <b-input
                v-model="comments"
                rows="2"
                type="textarea" />
        </b-field>
        <b-field
            horizontal
            label="Interface"
            message="The interface carrying eventual attributes for the relation itself"
            expanded>
            <b-select
                v-model="interfaceIdentity"
                expanded>
                <option
                    v-for="option in selectableInterfaces"
                    :key="option.Identity"
                    :value="option.Identity">
                    {{ option.Name }}
                </option>
            </b-select>
            <div class="control">
                <b-tooltip
                    label="Clear field"
                    position="is-left">
                    <a
                        class="button"
                        :disabled="interfaceIdentity == null || null"
                        @click="interfaceIdentity = null">
                        <b-icon
                            icon="eraser"
                            size="is-small" />
                    </a>
                </b-tooltip>
            </div>
        </b-field>
        <h6
            class="subtitle is-6 section-header">
            Left side
        </h6>
        <b-field
            horizontal
            label="Interface"
            expanded>
            <b-select
                v-model="leftInterface"
                expanded>
                <option
                    v-for="option in selectableInterfaces"
                    :key="option.Identity"
                    :value="option.Identity">
                    {{ option.Name }}
                </option>
            </b-select>
            <div class="control">
                <b-tooltip
                    label="Find interface from a different schema"
                    position="is-left">
                    <a
                        class="button"
                        @click="findOtherInterface('leftInterface')">
                        <b-icon
                            icon="magnify"
                            size="is-small" />
                        <span>Find other..</span>
                    </a>
                </b-tooltip>
            </div>
        </b-field>
        <b-field
            horizontal
            label="Role">
            <b-input
                v-model="leftRole" />
        </b-field>
        <b-field
            horizontal
            label="Cardinality">
            <b-numberinput
                v-model="leftCardinality"
                min="0" />
            <div class="control">
                <b-tooltip
                    label="Clear field"
                    position="is-top">
                    <a
                        class="button"
                        :disabled="leftCardinality == null || null"
                        @click="leftCardinality = null">
                        <b-icon
                            icon="eraser"
                            size="is-small" />
                    </a>
                </b-tooltip>
            </div>
        </b-field>
        <h6
            class="subtitle is-6 section-header">
            Right side
        </h6>
        <b-field
            horizontal
            label="Interface"
            expanded>
            <b-select
                v-model="rightInterface"
                expanded>
                <option
                    v-for="option in selectableInterfaces"
                    :key="option.Identity"
                    :value="option.Identity">
                    {{ option.Name }}
                </option>
            </b-select>
            <div class="control">
                <b-tooltip
                    label="Find interface from a different schema"
                    position="is-left">
                    <a
                        class="button"
                        @click="findOtherInterface('rightInterface')">
                        <b-icon
                            icon="magnify"
                            size="is-small" />
                        <span>Find other..</span>
                    </a>
                </b-tooltip>
            </div>
        </b-field>
        <b-field
            horizontal
            label="Role">
            <b-input
                v-model="rightRole" />
        </b-field>
        <b-field
            horizontal
            label="Cardinality">
            <b-numberinput
                v-model="rightCardinality"
                min="0" />
            <div class="control">
                <b-tooltip
                    label="Clear field"
                    position="is-top">
                    <a
                        class="button"
                        :disabled="rightCardinality == null || null"
                        @click="rightCardinality = null">
                        <b-icon
                            icon="eraser"
                            size="is-small" />
                    </a>
                </b-tooltip>
            </div>
        </b-field>
        <h6
            class="subtitle is-6 section-header">
            &nbsp;
        </h6>
        <b-field
            horizontal
            label="References">
            <b-input
                v-model="references"
                rows="2"
                type="textarea" />
        </b-field>
        <b-field
            horizontal
            label="Internal comments">
            <b-input
                v-model="internalComments"
                rows="2"
                type="textarea" />
        </b-field>
        <two-step-generic-code-selector
            :active="showInterfaceSelector"
            :title="'Find interface from another schema'"
            :step-one-label="'1. Select schema'"
            :step-one-query="'FROM Schema SELECT Id, Name'"
            :step-one-sort="(a, b) => a.Name.localeCompare(b.Name)"
            :step-one-display-template="x => x.Name"
            :step-two-label="'2. Select interface'"
            :step-two-query="x => `FROM SchemaInterface WHERE Schema_ID = ${x.Id}`"
            :step-two-sort="(a, b) => a.Name.localeCompare(b.Name)"
            :step-two-display-template="x => x.Name"
            @close="showInterfaceSelector = false"
            @selected="handleOtherInterfaceSelected" />
    </div>
</template>

<script>
    import TwoStepGenericCodeSelector from '@/shared/components/TwoStepGenericCodeSelector.vue';
    import { requireCanEditCode } from '@/shared/directives/requirePermission';
    import { createChangeDocAndCommit, genericViewQueryAsText } from '@/shared/helpers/api';
    import { showMixin } from '@/shared/mixins/showMixin';
    import _ from 'lodash';

    function deserializeInterfaceIdentity(identity) {
        const [name, schema] = identity.split('|');
        return {
            Name: `${name} (${schema})`,
            Identity: identity,
            Id: -1
        };
    }

    export default {
        directives: {
            'require-can-edit-code': requireCanEditCode
        },
        components: {
            TwoStepGenericCodeSelector
        },
        mixins: [
            showMixin
        ],
        props: {
            relationId: {
                default: 0,
                type: Number
            },
            interfaces: {
                default() {
                    return [];
                },
                type: Array
            },
            schemaIdentity: {
                type: String,
                required: true
            },
            newRelationMode: {
                type: Boolean,
                default: false
            },
            relationCodeSet: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                isVisible: false,
                id: 0,
                name: undefined,
                description: undefined,
                interfaceIdentity: undefined,
                leftInterface: undefined,
                leftRole: undefined,
                leftCardinality: undefined,
                rightInterface: undefined,
                rightRole: undefined,
                rightCardinality: undefined,
                comments: undefined,
                references: undefined,
                internalComments: undefined,
                originalRelation: null,
                saving: false,
                additionalInterfaces: [],
                showInterfaceSelector: false,
                tempInterfaceFieldToSet: undefined,
                validationIssues: []
            };
        },
        computed: {
            selectableInterfaces() {
                return [...this.interfaces, ...this.additionalInterfaces];
            }
        },
        watch: {
            async relationId(value) {
                if (value && value !== 0 && value !== this.id) {
                    await this.loadRelation(value);
                    this.isVisible = true;
                } else {
                    this.isVisible = false;
                }
            },
            newRelationMode(value) {
                if (value) {
                    this.resetRelationState();
                    this.isVisible = true;
                } else {
                    this.isVisible = false;
                }
            }
        },
        methods: {
            resetRelationState() {
                this.id = 0;
                this.name = '';
                this.description = '';
                this.interfaceIdentity = null;
                this.leftInterface = null;
                this.leftRole = '';
                this.leftCardinality = null;
                this.rightInterface = null;
                this.rightRole = '';
                this.rightCardinality = null;
                this.comments = '';
                this.references = '';
                this.internalComments = '';
                this.additionalInterfaces = [];
                this.originalRelation = null;
                this.validationIssues = [];
            },
            async loadRelation(id) {
                const response = await genericViewQueryAsText(
                    this,
                    'FROM SchemaRelation WHERE Id = @id',
                    [{ name: '@id', value: id }]);

                const r = response.data[0];

                this.originalRelation = r;
                this.validationIssues = [];

                this.id = r.Id;
                this.name = r.Name;
                this.description = r.Description;
                this.interfaceIdentity = r.Interface;
                this.leftInterface = r.LeftInterface;
                this.leftRole = r.LeftRole ?? '';
                this.leftCardinality = r.LeftCardinality;
                this.rightInterface = r.RightInterface;
                this.rightRole = r.RightRole ?? '';
                this.rightCardinality = r.RightCardinality;
                this.comments = r.Comments;
                this.references = r.References;
                this.internalComments = r.InternalComments;

                this.additionalInterfaces = [];
                if (!_.find(this.interfaces, { 'Identity': r.LeftInterface })) {
                    this.additionalInterfaces.push(deserializeInterfaceIdentity(r.LeftInterface));
                }
                if (r.LeftInterface !== r.RightInterface && !_.find(this.interfaces, { 'Identity': r.RightInterface })) {
                    this.additionalInterfaces.push(deserializeInterfaceIdentity(r.RightInterface));
                }
            },
            async clickSave() {
                this.saving = true;
                const isNewAttribute = this.id === 0;

                try {
                    if (!this.validate())
                        return;

                    const changeDoc = {
                        Name: this.name,
                        Description: this.description,
                        Schema: this.schemaIdentity,
                        LeftInterface: this.leftInterface,
                        RightInterface: this.rightInterface,
                        Comments: this.comments,
                        References: this.references,
                        InternalComments: this.internalComments
                    };

                    if (this.interfaceIdentity === null && this.originalRelation?.Interface) {
                        changeDoc.Interface = '{null}';
                    } else if (this.interfaceIdentity) {
                        changeDoc.Interface = this.interfaceIdentity;
                    }

                    if (this.leftCardinality !== null) {
                        changeDoc.LeftCardinality = this.leftCardinality;
                    } else if (this.originalRelation?.LeftCardinality !== null) {
                        changeDoc.LeftCardinality = '{null}';
                    }

                    if (this.rightCardinality !== null) {
                        changeDoc.RightCardinality = this.rightCardinality;
                    } else if (this.originalRelation?.RightCardinality !== null) {
                        changeDoc.RightCardinality = '{null}';
                    }

                    if (this.leftRole === '' && this.originalRelation?.LeftRole) {
                        changeDoc.LeftRole = '{null}';
                    } else {
                        changeDoc.LeftRole = this.leftRole;
                    }

                    if (this.rightRole === '' && this.originalRelation?.RightRole) {
                        changeDoc.RightRole = '{null}';
                    } else {
                        changeDoc.RightRole = this.rightRole;
                    }

                    const res = await createChangeDocAndCommit(this, this.relationCodeSet.name, [changeDoc]);
                    this.showInfo(res.data.message);
                    if (res.data.releaseId > 0) {
                        this.resetRelationState();
                        this.$emit(
                            isNewAttribute ? 'new-relation-saved' : 'modified-relation-saved',
                            changeDoc);
                    }
                } catch (generalError) {
                    this.showError(generalError);
                } finally {
                    this.saving = false;
                }
            },
            validate() {
                const issues = [];

                if (this.name === '') {
                    issues.push('Missing required field: Name');
                }

                if (!this.leftInterface || !this.rightInterface) {
                    issues.push('Missing left and/or right interface');
                }

                this.validationIssues = issues;
                return this.validationIssues.length === 0;
            },
            clickCancel() {
                this.resetRelationState();
                this.$emit('cancel-edit', null);
            },
            findOtherInterface(dataFieldName) {
                this.tempInterfaceFieldToSet = dataFieldName;
                this.showInterfaceSelector = true;
            },
            handleOtherInterfaceSelected(element) {
                this.showInterfaceSelector = false;
                const elementFormatted = {
                    Id: element.Id,
                    Identity: element.Identity,
                    Name: `${element.Name} (${element.Schema})`
                };
                if (!_.find(this.additionalInterfaces, { 'Identity': element.Identity })) {
                    this.additionalInterfaces.push(elementFormatted);
                }
                this[this.tempInterfaceFieldToSet] = element.Identity;
                this.tempInterfaceFieldToSet = undefined;
            }
        }
    };
</script>

<style scoped>
.section-header {
    font-weight: bold !important;
    border-bottom: solid 1px silver;
}
</style>
