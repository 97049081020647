import { decodeIdBase64 } from '@/shared/helpers/utils.js';
import { useSchemaEditStore } from '@/stores/schemaEditStore.js';
import { getCodeById } from '@/shared/helpers/api';
import { http } from '@/shared/httpWrapper.js';
import { useCableEditStore } from '@/stores/cableEditStore.js';

export function getCodeAttribute(code, attributeDefinitionName) {
    return code
        ?.attributes
        ?.find(attribute => attribute.definitionName === attributeDefinitionName);
}

export function getCodeAttributeValue(code, attributeDefinitionName) {
    return getCodeAttribute(code, attributeDefinitionName)
        ?.displayValue;
}

export function getCodeAttributeIdentity(code, attributeDefinitionName) {
    return getCodeAttribute(code, attributeDefinitionName)
        ?.referenceCode
        ?.identity;
}

function getCodeAttributeReferenceId(code, attributeDefinitionName) {
    const idValue = getCodeAttribute(code, attributeDefinitionName)
        ?.referenceCode
        ?.id;

    const value = decodeIdBase64(idValue);
    return parseInt(value, 10);
}

export function getRoute(routeName) {
    return {
        name: routeName
    };
}

export const schemaRouteDictionary = {
    'SchemaMapClass': 'SchemaMapClass',
    'SchemaMapAttribute': 'SchemaMapAttribute',
    'SchemaMapRelation': 'SchemaMapRelation',
    'SchemaAttribute': 'SchemaAttribute',
    'SchemaClass': 'SchemaClass',
    'SchemaClassInterface': 'SchemaClass',
    'SchemaRelation': 'SchemaRelation',
    'SchemaInterface': 'SchemaInterfaceEditor'
};

export function getSchemaRoute(library, code, schema) {
    const attributeDefinitionName = 'Schema';

    const route = {
        name: schemaRouteDictionary[library.name],
        params: {
            identity: null
        }
    };

    if (schema) {
        route.params.identity = schema.Identity;
    }

    if (code !== null && code !== undefined) {
        route.params.identity = getCodeAttributeValue(code, attributeDefinitionName);

        const store = useSchemaEditStore();

        if (library.name === 'SchemaAttribute') {
            const attributeId = parseInt(decodeIdBase64(code.id));
            const interfaceId = getCodeAttributeReferenceId(code, 'Interface');

            store.setAttributeId(attributeId);
            store.setInterfaceId(interfaceId);
        } else if (library.name === 'SchemaClass') {
            const codeId = parseInt(decodeIdBase64(code.id));
            store.setClassId(codeId);
        } else if (library.name === 'SchemaClassInterface') {
            const classId = getCodeAttributeReferenceId(code, 'Class');
            const interfaceId = code.identity;

            store.setClassId(classId);
            store.setClassInterfaceId(interfaceId);
        } else if (library.name === 'SchemaInterface') {
            const interfaceId = parseInt(decodeIdBase64(code.id));
            store.setInterfaceId(interfaceId);
        } else if (library.name.startsWith('SchemaMap')) {
            const schemaSource = getCodeAttributeValue(code, 'Schema1');
            const schemaTarget = getCodeAttributeReferenceId(code, 'Schema2');

            route.params.identity = schemaSource;

            if (library.name === 'SchemaMapClass') {
                const selectedClassOne = getCodeAttributeReferenceId(code, 'Class1');
                const selectedClassTwo = getCodeAttributeReferenceId(code, 'Class2');

                store.setMapSchemaId(schemaTarget);
                store.setMapSchemaObject({
                    class1: selectedClassOne,
                    class2: selectedClassTwo
                });
            }

            if (library.name === 'SchemaMapAttribute') {
                const selectedAttributeOne = getCodeAttributeReferenceId(code, 'Attribute1');
                const selectedAttributeTwo = getCodeAttributeReferenceId(code, 'Attribute2');

                store.setMapSchemaId(schemaTarget);
                store.setMapSchemaObject({
                    attribute1: selectedAttributeOne,
                    attribute2: selectedAttributeTwo
                });
            }

            if (library.name === 'SchemaMapRelation') {
                const schemaTarget = getCodeAttributeReferenceId(code, 'Schema2');
                const selectedRelation = getCodeAttributeReferenceId(code, 'Relation1');

                store.setMapSchemaId(schemaTarget);
                store.setRelationId(selectedRelation);
            }
        } else if (library.name === 'SchemaRelation') {
            const relationId = parseInt(decodeIdBase64(code.id));
            const interfaceId = getCodeAttributeReferenceId(code, 'LeftInterface');

            store.setRelationId(relationId);
            store.setInterfaceId(interfaceId);
        }
    }

    return route;
}

export function getEnsFormatRoute(specializedUI, library, codeSet, code) {
    const route = getRoute(specializedUI);
    route.query = {
        facility: codeSet.scopes[0],
        formatIdentity: null
    };

    if (library.name === 'DocumentNumberingFormatElement') {
        route.query.formatIdentity = getCodeAttributeIdentity(code, 'DocNoFormat');
    } else if (library.name === 'TagFormatElement') {
        route.query.formatIdentity = getCodeAttributeIdentity(code, 'TagFormat');
    } else {
        route.query.formatIdentity = code?.identity;
    }

    return route;
}

export function getLCIRoute(tab, codeSet, code) {
    const route = {
        name: 'LCITab',
        params: {
            tab: tab
        },
        query: null
    };

    if (tab === 'Projects') {
        route.query = {
            facility: getCodeAttributeValue(code, 'Facility'),
            project: encodeURI(code?.name)
        };
    } else if (tab === 'Contractors') {
        route.query = {
            facility: codeSet.scopes[0],
            contractor: code?.name
        };
    } else if (tab === 'PurchaseOrders') {
        route.query = {
            facility: codeSet.scopes[0],
            purchaseOrder: code?.name
        };
    }
    return route;
}

const cableEditTabs = {
    'CableCode': 'Code',
    'CableSpec': 'Specification',
    'CableCodeSheath': 'SheathColour',
    'CableCodeType': 'Type',
    'CableSpecWire': 'Specification'
};

export async function getCableEditRoute(library, code) {
    const tab = cableEditTabs[library.name];
    const cableEditStore = useCableEditStore();

    if (code !== null && code !== undefined) {
        if (library.name === 'CableCode') {
            cableEditStore.setCableCode(code.identity);
        } else {
            const codeIdentity = getCodeAttributeIdentity(code, 'CableCode');
            cableEditStore.setCableCode(codeIdentity);
        }

        if (library.name === 'CableSpec') {
            cableEditStore.setCableSpec(code.identity);
        }

        if (library.name === 'CableSpecWire') {
            const specIdentity = getCodeAttributeIdentity(code, 'CableSpec');

            const referenceId = getCodeAttributeReferenceId(code, 'CableSpec');
            const referenceCode = await getCodeById(http, referenceId);
            const codeIdentity = getCodeAttributeValue(referenceCode, 'CableCode');

            cableEditStore.setCableCode(codeIdentity);
            cableEditStore.setCableSpec(specIdentity);
        }
    }

    return {
        name: 'CableEditTab',
        params: {
            selectedTab: tab
        }
    };
}

const pipingClassEditorRouteDictionary = {
    'PipingClass': 'Class',
    'PipingClassSize': 'ClassSize',
    'LineSize': 'LineSize'
};

export function getPipeClassEditorRoute(pipeClassStore, library, codeSet, code) {
    const tab = pipingClassEditorRouteDictionary[library.name];

    if (code !== null && code !== undefined) {
        const codeId = parseInt(decodeIdBase64(code.id));

        if (tab === 'Class') {
            pipeClassStore.setPipingClass(codeId);
        } else if (tab === 'ClassSize') {
            const classRefCode = getCodeAttribute(code, 'PipingClass')
                .referenceCode
                .id;

            const pipingClassId = parseInt(decodeIdBase64(classRefCode));

            pipeClassStore.setPipingClass(pipingClassId);
            pipeClassStore.setPipingClassSize(codeId);
        } else {
            pipeClassStore.setLineSize(codeId);
        }
    }

    return {
        name: 'PipeClassEditor',
        params: {
            selectedTab: tab
        }
    };
}

export function getElectricComponentRoute(electricalComponentStore, codeSet, code) {
    const route = {
        name: 'ElectricComponentType'
    };

    if (code !== null && code !== undefined) {
        const electricalComponentId = parseInt(decodeIdBase64(code.id));
        electricalComponentStore.setElectricalComponent(electricalComponentId);
    }

    return route;
}
