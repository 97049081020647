<script setup>
    import ScopeDropdown from '@/shared/components/ScopeDropdown.vue';
    import useShow from '@/shared/composables/useShow.js';
    import { requireCanEditCode as vRequireCanEditCode } from '@/shared/directives/requirePermission';
    import { genericViewQueryAsText } from '@/shared/helpers/api.ts';
    import { http } from '@/shared/httpWrapper.js';
    import { useScopeStore } from '@/stores/scopeStore.js';
    import { computed, ref, watchEffect } from 'vue';

    const scopeStore = useScopeStore();

    const { showError } = useShow();

    const props = defineProps({
        categories: {
            type: Array,
            required: true
        },
        ensType: {
            type: String,
            required: true
        },
        originalFormat: {
            type: Object,
            required: true
        }
    });

    const emit = defineEmits(['close', 'submit']);

    const description = ref(props.originalFormat.description);
    const selectedFacility = ref(props.originalFormat.facility);
    const selectedCategory = ref(props.originalFormat.category);
    const loadingExistingFormats = ref(false);
    const existingFormatDescriptions = ref([]);

    const filteredCategories = computed(() => {
        return props.categories.filter(x => !x.facility || x.facility === selectedFacility.value);
    });

    const isDescriptionUnique = computed(() => {
        const normalizedDescription = description.value.toLowerCase().trim();
        return !existingFormatDescriptions.value.some(x => x.toLowerCase().trim() === normalizedDescription);
    });

    const canSubmit = computed(() => {
        return !!selectedFacility.value
            && !!selectedCategory.value
            && !!description.value.trim()
            && !loadingExistingFormats.value
            && isDescriptionUnique.value;
    });

    const loadFormats = async () => {
        existingFormatDescriptions.value = [];

        if (!selectedFacility.value || !selectedCategory.value)
            return;

        loadingExistingFormats.value = true;

        const query = props.ensType === 'TagFormat'
            ? `FROM TagFormat
                            SELECT description
                            WHERE isValid = true AND scope = @scope AND tagCategory = @category
                            ORDER BY description`
            : `FROM DocumentNumberingFormat
                            SELECT description
                            WHERE isValid = true AND scope = @scope AND docCategory = @category
                            ORDER BY description`;

        try {
            const response = await genericViewQueryAsText(http, query, [
                { name: '@scope', value: selectedFacility.value },
                { name: '@category', value: selectedCategory.value }
            ]);
            existingFormatDescriptions.value = response.data.map(x => x.description);
        } catch (ex) {
            showError(ex);
        }

        loadingExistingFormats.value = false;
    };

    const onClose = () => {
        emit('close');
    };

    const onClickSubmit = () => {
        emit('submit', {
            facility: selectedFacility.value,
            category: selectedCategory.value,
            description: description.value
        });
        emit('close');
    };

    const onSelectFacility = facility => {
        selectedFacility.value = facility;
    };

    // Watch for external changes
    watchEffect(() => {
        selectedFacility.value = scopeStore.selectedScope;
    });

    watchEffect(() => {
        selectedCategory.value = filteredCategories.value.find(x => x.identity === selectedCategory.value)?.identity ?? null;
    });

    watchEffect(async () => {
        await loadFormats();
    });
</script>

<template>
    <b-modal
        has-modal-card
        model-value
        @close="onClose">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">
                    <b>Clone format from {{ originalFormat.facility }}</b>
                </p>
            </header>
            <section class="modal-card-body">
                <b-field
                    label="Clone to facility"
                    horizontal>
                    <scope-dropdown
                        scope-type="Facility"
                        @update:selected-scope="onSelectFacility" />
                </b-field>
                <b-field
                    label="Category"
                    horizontal>
                    <b-select
                        v-model="selectedCategory"
                        placeholder="Select category"
                        expanded>
                        <option
                            v-for="category in filteredCategories"
                            :key="category.identity"
                            :value="category.identity">
                            {{ category.description }}
                        </option>
                    </b-select>
                </b-field>
                <b-field
                    label="Description"
                    :type="isDescriptionUnique ? '' : 'is-danger'"
                    :message="isDescriptionUnique ? '' : 'A format with the same description already exists'"
                    horizontal>
                    <b-input
                        v-model="description"
                        :loading="loadingExistingFormats"
                        required />
                </b-field>
            </section>
            <footer class="modal-card-foot">
                <b-field class="is-grouped-right">
                    <b-button
                        label="Cancel"
                        @click="onClose" />
                    <b-button
                        v-require-can-edit-code="{ libraryName: ensType, scopes: selectedFacility ? [selectedFacility] : [] }"
                        label="Clone"
                        type="is-primary"
                        :disabled="!canSubmit"
                        @click="onClickSubmit" />
                </b-field>
            </footer>
        </div>
    </b-modal>
</template>

<style scoped>
.modal-card {
    max-width: 700px;
}

.modal-card-foot {
    /* Right-justify buttons to match .dialog button placement */
    justify-content: flex-end;
}
</style>
