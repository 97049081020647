<template>
    <div>
        <h2 class="title is-3">
            Release {{ release.id }}
        </h2>
        <release-state-changer
            :release="mutableRelease"
            :log-button="true"
            @transition="loadRelease($event)"
            @changeState="mutableRelease.state = $event" />
        <div class="columns">
            <div class="column is-narrow">
                <div class="box">
                    <table class="table is-striped">
                        <tbody>
                            <tr>
                                <th>State</th>
                                <td>{{ release.state }}</td>
                            </tr>
                            <tr>
                                <th>Type</th>
                                <td>{{ release.specification.specificationType }}</td>
                            </tr>
                            <tr>
                                <th>Number of sub-releases</th>
                                <td>{{ release.specification.subReleases.length }}</td>
                            </tr>
                            <tr>
                                <th>Created</th>
                                <td>{{ $filters.dateFormatLong(release.dateCreated) }}</td>
                            </tr>
                            <tr>
                                <th>Updated</th>
                                <td>{{ $filters.dateFormatLong(release.dateUpdated) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="column is-narrow">
                <b-message>
                    This release is of the type <b>ReleaseChain</b>.<br>
                    That means that it contains several sub-releases that will automatically run in sequence.<br>
                    If one of the sub-releases fails, the whole ReleaseChain will fail.
                </b-message>
            </div>
        </div>
        <chain-state-changer
            v-if="hasSubreleases"
            :subreleases="release.specification.subReleases" />
    </div>
</template>

<script>
    import { getRelease } from '@/shared/helpers/api.ts';
    import ChainStateChanger from './ChainStateChanger.vue';
    import ReleaseStateChanger from './ReleaseStateChanger.vue';

    export default {
        components: {
            ReleaseStateChanger,
            ChainStateChanger
        },
        props: {
            release: {
                type: Object,
                default: () => {}
            }
        },
        data() {
            return {
                mutableRelease: this.release
            };
        },
        computed: {
            hasSubreleases() {
                return this.release?.specification?.subReleases?.length > 0;
            }
        },
        methods: {
            async reLoad() {
                this.mutableRelease = await getRelease(this, this.release.releaseId);
            }
        }

    };
</script>

<style>

</style>
