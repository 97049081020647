<template>
    <div>
        <b-modal
            :model-value="scheduleSelectedForConfigurationDisplay !== null"
            has-modal-card
            @close="scheduleSelectedForConfigurationDisplay = null">
            <div
                v-if="scheduleSelectedForConfigurationDisplay"
                class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">
                        {{ scheduleSelectedForConfigurationDisplay.description }} configuration
                    </p>
                </header>
                <section class="modal-card-body">
                    <pre>{{ scheduleSelectedForConfigurationDisplay.configuration }}</pre>
                </section>
                <footer class="modal-card-foot is-justify-content-end">
                    <button
                        class="button"
                        @click="scheduleSelectedForConfigurationDisplay = null">
                        <b-icon
                            icon="close-circle"
                            size="is-small" />
                        <span>
                            Close
                        </span>
                    </button>
                </footer>
            </div>
        </b-modal>

        <h2 class="title is-3">
            <b-icon
                icon="clock" />
            Schedules report
        </h2>
        <div class="columns">
            <div class="column">
                <p class="block">
                    View details about all scheduled jobs configured in Common Library.
                    Expand schedule to view details.
                    Logs are available for 30 days.
                </p>
            </div>
            <div class="column">
                <div class="is-pulled-right">
                    <button
                        class="button"
                        @click="loadSchedules">
                        <b-icon
                            icon="refresh"
                            size="is-small" />
                        <span>Refresh</span>
                    </button>
                </div>
            </div>
        </div>
        <b-table
            :data="schedules"
            :loading="loading"
            :default-sort="['category', 'asc']"
            :striped="false"
            :narrowed="true"
            :hoverable="true"
            class="site-sticky-header"
            detailed
            :show-detail-icon="true"
            @details-open="ensureScheduleExecutions">
            <b-table-column
                v-slot="props"
                field="icons"
                label="Icons"
                sortable>
                {{ props.row.icons }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="lastKnownState"
                label="State"
                sortable>
                <i
                    v-if="props.row.lastKnownState === 'Failed'"
                    class="fa fa-times-circle"
                    style="color:red;" />
                <i
                    v-else-if="props.row.lastKnownState === 'Running'"
                    class="fa fa-spinner"
                    style="color:blue;" />
                <i
                    v-else
                    class="fa fa-check-circle"
                    style="color:green;" />
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="enabled"
                label="Enabled"
                sortable>
                <bool-element :value="props.row.enabled" />
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="category"
                label="Category"
                sortable
                searchable>
                {{ props.row.category }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="description"
                label="Description"
                sortable
                searchable>
                {{ props.row.description }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                label="Schedule (UTC based)">
                {{ $filters.formatCronstrueString(props.row.scheduleSpec) }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="lastExecutionTime"
                label="Last run (UTC)"
                sortable>
                {{ $filters.dateFormatLong(props.row.lastExecutionTime) }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="configuration"
                label="Configuration">
                <button
                    class="button is-small"
                    @click="scheduleSelectedForConfigurationDisplay = props.row">
                    <b-icon
                        icon="script"
                        size="is-small" />
                    <span>Configuration</span>
                </button>
            </b-table-column>

            <template #detail="props">
                <b-table
                    :loading="!scheduleExecutions[props.row.id]"
                    :data="scheduleExecutions[props.row.id]"
                    :default-sort="['startDate', 'desc']"
                    detailed
                    :show-detail-icon="true"
                    @details-open="ensureScheduleExecutionLog">
                    <b-table-column
                        v-slot="props2"
                        label="State">
                        <i
                            v-if="props2.row.failed"
                            class="fa fa-times-circle"
                            style="color:red;" />
                        <i
                            v-else-if="props2.row.running"
                            class="fa fa-spinner"
                            style="color:blue;" />
                        <i
                            v-else
                            class="fa fa-check-circle"
                            style="color:green;" />
                    </b-table-column>
                    <b-table-column
                        v-slot="props2"
                        field="startDate"
                        label="Start time"
                        sortable>
                        {{ $filters.dateFormatLong(props2.row.startDate) }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props2"
                        field="endDate"
                        label="End time"
                        sortable>
                        {{ $filters.dateFormatLong(props2.row.endDate) }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props2"
                        label="Duration">
                        {{ executionDuration(props2.row) }}
                    </b-table-column>
                    <template #detail="props2">
                        <pre>{{ executionLog(props2.row) }}</pre>
                    </template>
                </b-table>
            </template>
        </b-table>
    </div>
</template>

<script>
    import { formatCronstrueString } from '@/filters.js';
    import BoolElement from '@/shared/components/BoolElement.vue';
    import { adminPageScheduleOutput, getScheduleExecutions, getSchedules } from '@/shared/helpers/api.ts';
    import _ from 'lodash';
    import moment from 'moment';

    export default {
        name: 'ScheduleReport',
        components: { BoolElement },
        filters: {
            formatCronstrueString
        },
        data() {
            return {
                schedules: [],
                scheduleExecutions: {},
                scheduleExecutionLogs: {},
                scheduleSelectedForConfigurationDisplay: null,
                loading: true
            };
        },
        mounted() {
            // Schedules are loaded first. The next two levels (executions and execution logs) are loaded on demand.
            this.loadSchedules();
        },
        methods: {
            async loadSchedules() {
                this.loading = true;
                // When sorting data by description and then sorting table by category,
                // the default view will be sorted by category then description.
                this.schedules = _.sortBy(await getSchedules(this), 'description');
                this.loading = false;
            },
            async ensureScheduleExecutions(schedule) {
                if (this.scheduleExecutions[schedule.id]) {
                    return;
                }

                const tmp = await getScheduleExecutions(this, schedule.id);
                this.scheduleExecutions[schedule.id] = tmp;
            },
            async ensureScheduleExecutionLog(execution) {
                if (this.scheduleExecutionLogs[execution.id]) {
                    return;
                }

                this.scheduleExecutionLogs[execution.id] = { output: 'loading...' };

                const tmp = await adminPageScheduleOutput(this, execution.id);
                this.scheduleExecutionLogs[execution.id] = tmp;
            },
            executionDuration(execution) {
                const start = execution.startDate;
                const end = execution.endDate;
                if (start && end) {
                    return moment.duration(moment(start).diff(end)).humanize();
                }
                return '';
            },
            executionLog(execution) {
                const log = this.scheduleExecutionLogs[execution.id];
                return log && log.output ? log.output : 'empty log';
            }
        }
    };
</script>

<style scoped>

</style>
