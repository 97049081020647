<template>
    <div
        v-if="showAccessControl || !libraryAccessControl"
        class="box">
        <p class="title is-5">
            {{ cardTitle }}
        </p>
        <div
            v-if="showAccessGroups"
            class="tags are-medium">
            <span
                v-for="(tag, idx) in accessGroups"
                :key="idx"
                class="tag is-danger">{{ tag }}</span>
        </div>
        <div
            v-if="showAccessPolicies"
            class="tags are-medium">
            <span
                v-for="(role, idx) in policies"
                :key="idx"
                class="tag is-danger">
                Policy: {{ role }}
            </span>
        </div>
        <p v-if="!showAccessControl">
            No {{ cardTitle.toLowerCase() }}
        </p>
    </div>
</template>

<script>
    export default {
        props: {
            accessGroups: {
                type: Array,
                default: () => []
            },
            policies: {
                type: Array,
                default: () => []
            },
            cardTitle: {
                type: String,
                default: ''
            },
            libraryAccessControl: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            showAccessGroups() {
                return this.accessGroups.length > 0;
            },
            showAccessPolicies() {
                return this.policies.length > 0;
            },
            showAccessControl() {
                return this.showAccessGroups || this.showAccessPolicies;
            }
        }
    };
</script>
