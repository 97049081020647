<template>
    <div :class="containerClass">
        <type-icon
            v-if="library.isVirtual"
            :size="size"
            :tooltip-position="tooltipPosition"
            type="virtual" />
        <type-icon
            v-if="library.isGlobal"
            :size="size"
            :tooltip-position="tooltipPosition"
            type="global" />
        <type-icon
            v-if="library.scopeType == 'Facility'"
            :size="size"
            :tooltip-position="tooltipPosition"
            type="facility" />
        <type-icon
            v-if="library.isForeignObject"
            :size="size"
            :tooltip-position="tooltipPosition"
            type="foreign" />
        <type-icon
            v-if="library.areNamesUpperCase"
            :size="size"
            :tooltip-position="tooltipPosition"
            type="uppercase" />
        <type-icon
            v-if="library.isReadOnly"
            :size="size"
            :tooltip-position="tooltipPosition"
            type="readonly" />
    </div>
</template>

<script>
    import TypeIcon from '@/shared/components/TypeIcon.vue';

    export default {
        name: 'TypeIconRow',
        components: {
            TypeIcon
        },
        props: {
            library: {
                type: Object,
                required: true
            },
            size: {
                type: String,
                default: 'is-small'
            },
            tooltipPosition: {
                type: String,
                default: 'is-left'
            }
        },
        computed: {
            containerClass() {
                return {
                    'type-icon-row': true,
                    'type-icon-row-small': this.size === 'is-small',
                    'type-icon-row-medium': this.size === 'is-medium'
                };
            }
        }
    };
</script>

<style>
.type.icon-row {
    display: flex;
    flex-wrap: nowrap;
}
.type-icon-row-small * {
    padding-left: 0.1em;
}
.type-icon-row-medium * {
    padding-left: 0.4em;
}
.type-icon-row *:first-child {
    padding: 0;
}
</style>
