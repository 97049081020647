<template>
    <div class="panel">
        <div class="panel-heading is-flex is-justify-content-space-between">
            <p>
                Project attributes
            </p>
            <div>
                <b-button
                    v-require-can-edit-code="accessArguments"
                    @click="$emit('click-edit-project')">
                    <b-icon
                        icon="pencil"
                        size="is-small" />
                    <span>Edit project</span>
                </b-button>
            </div>
        </div>
        <b-table
            class="headless-table"
            :data="selectedProjectAttributes"
            narrowed
            striped>
            <b-table-column
                v-slot="props"
                field="property">
                <b>{{ props.row.property }}</b>
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="value">
                <template v-if="props.row.property === 'IsValid'">
                    <bool-element :value="props.row.value" />
                </template>
                <template v-else>
                    {{ props.row.value }}
                </template>
            </b-table-column>
            <template #empty>
                <div class="has-text-centered">
                    No attributes
                </div>
            </template>
        </b-table>
    </div>
</template>
<script>
    import BoolElement from '@/shared/components/BoolElement.vue';
    import { requireCanEditCode } from '@/shared/directives/requirePermission.js';

    export default {
        name: 'ProjectAttributesPanel',
        directives: { requireCanEditCode },
        components: { BoolElement },
        props: {
            selectedProjectAttributes: {
                type: Array,
                required: true
            },
            accessArguments: {
                type: Object,
                required: true
            }
        },
        emits: ['click-edit-project']
    };
</script>
<style scoped>
.headless-table thead {
    display: none;
}
</style>
