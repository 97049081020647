<template>
    <div
        class="modal-card"
        style="height: 400px">
        <header class="modal-card-head">
            <p class="modal-card-title">
                Add Library
            </p>
        </header>
        <section class="modal-card-body">
            <library-selector
                :existing-libraries="existingLibraries"
                @lib-selected="(e) => selected = e" />
        </section>
        <footer class="modal-card-foot">
            <button
                class="button is-primary"
                @click="$emit('selected', selected)">
                <b-icon
                    icon="plus-circle-outline"
                    size="is-small" />
                <span>Add</span>
            </button>
        </footer>
    </div>
</template>

<script>
    import LibrarySelector from '@/shared/components/LibrarySelector.vue';

    export default {
        components: {
            LibrarySelector
        },
        props: {
            existingLibraries: {
                type: Array,
                required: false,
                default: () => []
            }
        },
        emits: [
            'selected'
        ],
        data: () => {
            return {
                selected: ''
            };
        }
    };
</script>
