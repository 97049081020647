<template>
    <div>
        <h1 class="title">
            ENS number validation
        </h1>
        <h1 class="subtitle">
            Validate a TAG number or document number according to the TR0052 ENS formats stored in Common Library.
        </h1>
        <hr>

        <scope-dropdown
            scope-type="Facility" />

        <b-field grouped>
            <b-field label="ENS format type">
                <b-select
                    v-model="formatType"
                    placeholder="Select format type..">
                    <option value="TagFormat">
                        TAG numbering
                    </option>
                    <option value="DocumentNumberingFormat">
                        Document numbering
                    </option>
                </b-select>
            </b-field>

            <b-field :label="formatCategoryLabel">
                <b-select
                    v-model="selectedCategory"
                    placeholder="Select category.."
                    :loading="categoriesLoading"
                    :disabled="categories.length === 0">
                    <option
                        v-for="option in categories"
                        :value="option.name">
                        {{ option.description }}
                    </option>
                </b-select>
            </b-field>
        </b-field>

        <b-field
            label="ENS number"
            :type="ensNumberWarning ? 'is-warning' : ''"
            :message="ensNumberWarning">
            <b-input v-model="ensNumber" />
        </b-field>

        <b-button
            type="is-primary"
            :loading="validationLoading"
            :disabled="!validationEnabled"
            @click="validateClick">
            Validate
        </b-button>

        <hr>
        <ens-number-validation-result :result="validationResult" />
    </div>
</template>

<script>
    import { ensParse, genericViewQueryAsText } from '@/shared/helpers/api';
    import { showMixin } from '@/shared/mixins/showMixin';
    import EnsNumberValidationResult from './EnsNumberValidationResult.vue';
    import ScopeDropdown from '@/shared/components/ScopeDropdown.vue';
    import { useScopeStore } from '@/stores/scopeStore.js';

    export default {
        name: 'EnsNumberValidation',
        components: {
            ScopeDropdown,
            EnsNumberValidationResult
        },
        mixins: [
            showMixin
        ],
        data() {
            return {
                formatType: null,
                categories: [],
                categoriesLoading: false,
                selectedCategory: null,
                facilities: [],
                facilitiesLoading: false,
                ensNumber: '',
                validationLoading: false,
                validationResult: null,
                scopeStore: useScopeStore()
            };
        },
        computed: {
            selectedFacility: {
                get() {
                    return this.scopeStore.getSelectedScope();
                },
                set(value) {
                    this.scopeStore.updateSelectedScope(value);
                }
            },
            formatCategoryLabel() {
                if (!this.formatType) {
                    return 'Format category';
                }
                return this.formatType === 'TagFormat' ? 'Tag category' : 'Document category';
            },
            validationEnabled() {
                return this.formatType
                    && this.selectedCategory
                    && this.selectedFacility
                    && this.ensNumber;
            },
            ensNumberWarning() {
                const whitespaceStartAndEnd = /^\s+.*\S.*\s+$/;
                if (whitespaceStartAndEnd.test(this.ensNumber)) {
                    return 'The ENS number begins and ends with whitespace';
                }

                const whitespaceStart = /^\s+\S/;
                if (whitespaceStart.test(this.ensNumber)) {
                    return 'The ENS number starts with whitespace';
                }

                const whitespaceEnd = /^\S.*\s+$/;
                if (whitespaceEnd.test(this.ensNumber)) {
                    return 'The ENS number ends with whitespace';
                }

                return null;
            }
        },
        watch: {
            async formatType() {
                await this.loadCategories();
            },
            async selectedFacility() {
                await this.loadCategories();
            }
        },
        mounted() {
            this.loadFacilities();
        },
        methods: {
            async loadCategories() {
                this.selectedCategory = null;
                this.categories = [];

                if (this.formatType && this.selectedFacility) {
                    this.categoriesLoading = true;

                    try {
                        if (this.formatType === 'TagFormat') {
                            this.categories = (await genericViewQueryAsText(this, `
                                SELECT name, description
                                FROM TagCategory
                                WHERE IsValid = true
                                  AND Scope = ${this.selectedFacility}
                                ORDER BY description
                            `)).data;
                        } else {
                            this.categories = (await genericViewQueryAsText(this, `
                                SELECT name, description
                                FROM DocCategory
                                WHERE IsValid = true
                                  AND Scope = ${this.selectedFacility}
                                ORDER BY name
                            `)).data.map(x => ({
                                name: x.name,
                                description: `${x.name} - ${x.description}`
                            }));
                        }
                    } catch (ex) {
                        this.showError(ex);
                    }

                    this.categoriesLoading = false;
                }
            },
            async loadFacilities() {
                this.selectedFacility = null;
                this.facilities = [];
                this.facilitiesLoading = true;
                const query = `
                    SELECT name, description
                    FROM Facility
                    WHERE IsValid = true
                    ORDER BY name
                `;
                try {
                    this.facilities = (await genericViewQueryAsText(this, query)).data;
                } catch (ex) {
                    this.showError(ex);
                }
                this.facilitiesLoading = false;
            },
            async validateClick() {
                this.validationResult = null;
                this.validationLoading = true;
                try {
                    this.validationResult = await ensParse(this, this.formatType, this.selectedCategory, this.selectedFacility, this.ensNumber);
                } catch (ex) {
                    this.showError(ex);
                }
                this.validationLoading = false;
            }
        }
    };
</script>

<style scoped>

</style>
