import { http } from '@/shared/httpWrapper';
import {
    deleteTagMetadataElement,
    getTagMetadata,
    getTagUniqueKeys,
    saveTagMetadata,
    deleteLibraryInformationElement,
    getLibraryInformationElement,
    getLibraryInformationElementUniqueKeys,
    saveLibraryInformationElement,
    ResponsePromise
} from '@/shared/helpers/api';

import {
    canEditTag,
    canEditLibrary
} from '@/shared/directives/requirePermission';

export interface IMetaDataService {
    canEdit(item: string): Promise<boolean>;
    get(item: string): Promise<Record<string, string>>;
    save(item: string, elements: string): ResponsePromise<void>;
    delete(item: string, element: string): ResponsePromise<void>;
    uniqueKeys(): Promise<string[]>;
}

export class TagMetadataService implements IMetaDataService {
    canEdit(item: string): Promise<boolean> {
        return canEditTag(item);
    }

    get(type: string): Promise<Record<string, string>> {
        return getTagMetadata(http, type);
    }

    save(tag: string, elements: string): ResponsePromise<void> {
        return saveTagMetadata(http, tag, elements);
    }

    delete(tag: string, element: string): ResponsePromise<void> {
        return deleteTagMetadataElement(http, tag, element);
    }

    uniqueKeys(): Promise<string[]> {
        return getTagUniqueKeys(http);
    }
}

export class LibraryInformationElementService implements IMetaDataService {
    canEdit(item: string): Promise<boolean> {
        return canEditLibrary(item);
    }

    get(type: string): Promise<Record<string, string>> {
        return getLibraryInformationElement(http, type);
    }

    save(libraryName: string, elements: string): ResponsePromise<void> {
        return saveLibraryInformationElement(http, libraryName, elements);
    }

    delete(libraryName: string, element: string): ResponsePromise<void> {
        return deleteLibraryInformationElement(http, libraryName, element);
    }

    uniqueKeys(): Promise<string[]> {
        return getLibraryInformationElementUniqueKeys(http);
    }
}

export function metadataServiceProvider(type: string): IMetaDataService {
    switch (type.toLowerCase()) {
        case 'tag':
            return new TagMetadataService();
        case 'libraryinformationelement':
            return new LibraryInformationElementService();
        default:
            throw new Error('Invalid type');
    }
}
