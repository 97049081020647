<template>
    <div
        v-if="isVisible">
        <b-field
            horizontal>
            <div class="control buttons is-right">
                <div
                    v-require-can-edit-code="{ libraryName: 'SchemaAttribute' }">
                    <b-button
                        type="is-primary"
                        :loading="saving"
                        @click="clickSave">
                        <b-icon
                            icon="floppy"
                            size="is-small" />
                        <span>Save</span>
                    </b-button>
                    <b-button @click="clickCancel">
                        <b-icon
                            icon="close-octagon-outline"
                            size="is-small" />
                        <span>Cancel</span>
                    </b-button>
                </div>
            </div>
        </b-field>

        <b-notification
            v-for="(issue, i) in validationIssues"
            :key="i"
            type="is-danger"
            indefinite
            :closable="false"
            role="alert">
            {{ issue }}
        </b-notification>

        <b-field
            horizontal
            label="Name">
            <b-input
                v-model="name"
                :disabled="!newAttributeMode" />
        </b-field>
        <b-field
            horizontal
            label="Description">
            <b-input v-model="description" />
        </b-field>
        <b-field
            horizontal
            label="Comments">
            <b-input
                v-model="comments"
                rows="2"
                type="textarea" />
        </b-field>
        <b-field
            horizontal
            label="Master Attribute">
            <b-input
                disabled
                :model-value="masterAttribute" />
            <div class="button-container">
                <b-button
                    class="is-primary margin-right"
                    icon="edit"
                    @click="masterAttrModalActive = true">
                    <b-icon
                        icon="pencil"
                        size="is-small" />
                </b-button>
                <div class="control">
                    <b-tooltip
                        label="Clear field"
                        position="is-top">
                        <a
                            class="button"
                            :disabled="masterAttribute === null || null"
                            @click="masterAttribute = null">
                            <b-icon
                                icon="eraser"
                                size="is-small" />
                        </a>
                    </b-tooltip>
                </div>
            </div>
            <b-modal
                v-model="masterAttrModalActive">
                <generic-search-and-select
                    :list="masterAttrChoices.map(x => x.Name)"
                    :selected="masterAttribute ? masterAttribute : ''"
                    title="Select Master Attribute"
                    @selectedItem="setMasterAttribute($event)" />
            </b-modal>
        </b-field>
        <b-field
            grouped
            horizontal
            label="Interface">
            <b-field>
                <b-select
                    v-model="interfaceIdentity"
                    :disabled="!newAttributeMode">
                    <option
                        v-for="option in interfaces"
                        :key="option.Id"
                        :value="option.Identity">
                        {{ option.Name }}
                    </option>
                </b-select>
            </b-field>
        </b-field>
        <b-field
            horizontal
            label="Display Sequence Number">
            <b-field>
                <b-input
                    v-model="displaySequenceNo"
                    class="displaySequenceNo-width"
                    type="number"
                    step="1" />
            </b-field>
        </b-field>
        <b-field
            horizontal
            label="Data type">
            <b-select v-model="datatype">
                <option
                    v-for="option in availableDataTypes"
                    :key="option.Identity"
                    :value="option.Identity">
                    {{ option.Name }}
                </option>
            </b-select>
        </b-field>
        <b-field
            v-show="datatype === 'QUANTITY'"
            grouped
            horizontal
            label="Quantity">
            <b-field>
                <b-select
                    v-model="quantity"
                    expanded>
                    <option
                        v-for="option in quantities"
                        :key="option.Identity"
                        :value="option.Identity">
                        {{ option.Name }}
                    </option>
                </b-select>
            </b-field>
            <b-field
                :type="{ 'is-danger': !(quantity && quantity !== '') }"
                :message="{ 'Select Quantity to populate unit of measures': !(quantity && quantity !== '') }"
                horizontal
                label="Default Unit">
                <b-select
                    v-model="defaultUoM"
                    expanded>
                    <option
                        v-for="option in filteredUnitOfMeasures"
                        :key="option.Identity"
                        :value="option.Identity">
                        {{ `${option.Description} (${option.Name})` }}
                    </option>
                </b-select>
            </b-field>
        </b-field>
        <b-field
            v-show="datatype === 'QUANTITY'"
            horizontal
            label="Has Unit string"
            message="Specifies that the attribute value should be assigned the UoM string. If FALSE, never add the UoM string part to the attribute value and relie on that is the one defined in DefaultUoM.">
            <b-switch v-model="hasUoMString">
                {{ hasUoMString ? 'Yes' : 'No' }}
            </b-switch>
        </b-field>
        <b-field
            v-show="datatype === 'ENUM'"
            horizontal
            label="Enum library">
            <b-field>
                <b-select v-model="enumLibrary">
                    <option
                        v-for="option in enumLibraries"
                        :key="option"
                        :value="option">
                        {{ option }}
                    </option>
                </b-select>
                <div class="control">
                    <b-tooltip
                        label="Clear field"
                        position="is-top">
                        <a
                            class="button"
                            :disabled="enumLibrary === null || null"
                            @click="enumLibrary = null">
                            <b-icon
                                icon="eraser"
                                size="is-small" />
                        </a>
                    </b-tooltip>
                </div>
            </b-field>
        </b-field>
        <b-field
            v-show="datatype === 'ENUM'"
            horizontal
            label="Enum values"
            message="Comma-separated list of valid values, where a library is overkill/not needed. As YES, NO, N/A etc.">
            <b-input v-model="enumValues" />
            <div class="control">
                <b-tooltip
                    label="Clear field"
                    position="is-top">
                    <a
                        class="button"
                        :disabled="enumValues === null || null"
                        @click="enumValues = null">
                        <b-icon
                            icon="eraser"
                            size="is-small" />
                    </a>
                </b-tooltip>
            </div>
        </b-field>
        <b-field
            v-show="showMin || showMax"
            label="Value range"
            horizontal
            grouped>
            <b-field grouped>
                <b-field
                    v-if="showMin"
                    label="Minimum"
                    label-position="on-border">
                    <b-input
                        v-model.number="min"
                        type="number"
                        step="any" />
                </b-field>
                <b-field
                    v-show="showMax"
                    label="Maximum"
                    label-position="on-border">
                    <b-input
                        v-model.number="max"
                        type="number"
                        step="any" />
                </b-field>
            </b-field>
        </b-field>
        <b-field
            v-show="showMaxAndMinPrecision"
            label="Data delivery precision"
            horizontal
            grouped>
            <b-field grouped>
                <b-field
                    label="Minimum no of decimal digits"
                    label-position="on-border">
                    <b-input
                        v-model="minPrecision"
                        type="number"
                        step="any"
                        min="0" />
                </b-field>
                <b-field
                    label="Maximum no of decimal digits"
                    label-position="on-border">
                    <b-input
                        v-model="maxPrecision"
                        type="number"
                        step="any"
                        min="1" />
                </b-field>
            </b-field>
        </b-field>
        <b-field
            v-show="showDecimalPrecisionAndScale"
            horizontal
            label="Decimal storage requirement"
            grouped>
            <b-field grouped>
                <b-field
                    label="Precision (total no of digits)"
                    label-position="on-border">
                    <b-input
                        v-model="decimalPrecision"
                        type="number"
                        step="any"
                        min="1" />
                </b-field>
                <b-field
                    label="Scale (total no of decimal digits)"
                    label-position="on-border">
                    <b-input
                        v-model="decimalScale"
                        type="number"
                        step="any"
                        min="1" />
                </b-field>
            </b-field>
        </b-field>
        <b-field
            horizontal
            :type="{ 'is-primary': regexSuggestion }"
            :message="{ 'A suggested regular expression was added': regexSuggestion }"
            label="Regex">
            <b-input v-model="regex" />
            <div class="control">
                <b-tooltip
                    label="Clear field"
                    position="is-top">
                    <a
                        class="button"
                        :disabled="regex === null || null"
                        @click="regex = null">
                        <b-icon
                            icon="eraser"
                            size="is-small" />
                    </a>
                </b-tooltip>
            </div>
        </b-field>
        <b-field
            horizontal
            label="Default value">
            <b-input v-model="defaultValue" />
            <div class="control">
                <b-tooltip
                    label="Clear field"
                    position="is-top">
                    <a
                        class="button"
                        :disabled="defaultValue === null || null"
                        @click="defaultValue = null">
                        <b-icon
                            icon="eraser"
                            size="is-small" />
                    </a>
                </b-tooltip>
            </div>
        </b-field>
        <b-field
            horizontal
            label="Is in identity"
            message="Flags whether this attribute is part of the information needed to uniquely identify the object. Is mandatory both in create and update transactions.">
            <b-switch v-model="isInIdentity">
                {{ isInIdentity ? 'Yes' : 'No' }}
            </b-switch>
        </b-field>
        <b-field
            horizontal
            label="Is mandatory"
            message="Flags whether this attribute is mandatory for the interface. A Mandatory attribute for a Mandatory interface, is mandatory for an object's existence. As must be supplied in the object create transaction.">
            <b-switch v-model="isMandatory">
                {{ isMandatory ? 'Yes' : 'No' }}
            </b-switch>
        </b-field>
        <b-field
            horizontal
            label="Is public"
            message="If FALSE, the attribute should not be exposed outside Company. Assume TRUE if not present.">
            <b-switch v-model="isPublic">
                {{ isPublic ? 'Yes' : 'No' }}
            </b-switch>
        </b-field>
        <b-field
            horizontal
            label="Is deliverable"
            message="If FALSE, the attribute is not to be delivered by Contractor. Example is TR3111 TagStatus. Assume TRUE if not present. ">
            <b-switch v-model="isDeliverable">
                {{ isDeliverable ? 'Yes' : 'No' }}
            </b-switch>
        </b-field>
        <b-field
            horizontal
            label="Is local"
            message="If TRUE, the attribute value varies with the publishing application. An example is DateUpdated. Assume FALSE if not present.">
            <b-switch v-model="isLocal">
                {{ isLocal ? 'Yes' : 'No' }}
            </b-switch>
        </b-field>
        <b-field
            horizontal
            label="Is official"
            message="If TRUE, it is part of the official schema that may be contracted. If FALSE, it may just be needed for mapping purposes, ease-of-use, aliasing etc.">
            <b-switch v-model="isOfficial">
                {{ isOfficial ? 'Yes' : 'No' }}
            </b-switch>
        </b-field>
        <b-field
            horizontal
            label="Is list"
            message="For enum and string. Indicates that the value may be a list of values separated by a separator character (as a row in a CSV-file). False is default.">
            <b-switch v-model="isList">
                {{ isList ? 'Yes' : 'No' }}
            </b-switch>
        </b-field>
        <b-field
            v-show="isList === true"
            horizontal
            label="Separator character"
            message="If list, this is the character that separates the values">
            <b-input v-model="listSeparatorCharacter" />
        </b-field>
        <b-field
            horizontal
            label="TIE Name"
            message="TIE name in message framing. Only supply when attribute needs to be mapped to TIE message framing attributes. As INST_CODE <-> Site in TIE 1.">
            <b-input v-model="tieName" />
        </b-field>
        <b-field
            horizontal
            grouped
            label="Version">
            <b-field
                label="Introduced"
                label-position="inside">
                <b-select
                    v-model="versionIn"
                    expanded>
                    <option
                        v-for="option in schemaVersions"
                        :key="option.Identity"
                        :value="option.Identity">
                        {{ option.Name }}
                    </option>
                </b-select>
                <div class="control">
                    <b-tooltip
                        label="Clear field"
                        position="is-left">
                        <a
                            class="button"
                            :disabled="versionIn === null || null"
                            @click="versionIn = null">
                            <b-icon
                                icon="eraser"
                                size="is-small" />
                        </a>
                    </b-tooltip>
                </div>
            </b-field>
            <b-field
                label="Terminated"
                label-position="inside">
                <b-select
                    v-model="versionOut"
                    expanded>
                    <option
                        v-for="option in schemaVersions"
                        :key="option.Identity"
                        :value="option.Identity">
                        {{ option.Name }}
                    </option>
                </b-select>
                <div class="control">
                    <b-tooltip
                        label="Clear field"
                        position="is-left">
                        <a
                            class="button"
                            :disabled="versionOut === null || null"
                            @click="versionOut = null">
                            <b-icon
                                icon="eraser"
                                size="is-small" />
                        </a>
                    </b-tooltip>
                </div>
            </b-field>
        </b-field>
        <b-field
            horizontal
            label="References">
            <b-input
                v-model="references"
                rows="2"
                type="textarea" />
        </b-field>
        <b-field
            horizontal
            label="Internal comments">
            <b-input
                v-model="internalComments"
                rows="2"
                type="textarea" />
        </b-field>
    </div>
</template>

<script>
    import GenericSearchAndSelect from '@/shared/components/GenericSearchAndSelect.vue';
    import { requireCanEditCode } from '@/shared/directives/requirePermission';
    import { createChangeDocAndCommit, genericViewQueryAsText, getLibraryNames } from '@/shared/helpers/api';
    import { showMixin } from '@/shared/mixins/showMixin';

    function stringIsInteger(s) {
        return /^[+-]?(0|[1-9]\d*)$/.test(s);
    }

    export default {
        directives: {
            'require-can-edit-code': requireCanEditCode
        },
        components: {
            GenericSearchAndSelect
        },
        mixins: [
            showMixin
        ],
        props: {
            attributeId: {
                default: 0,
                type: Number
            },
            interfaces: {
                default() {
                    return [];
                },
                type: Array
            },
            schemaIdentity: {
                type: String,
                required: true
            },
            newAttributeMode: {
                type: Boolean,
                default: false
            },
            selectedInterface: {
                type: String,
                default: null
            },
            attributeCodeSet: {
                type: Object,
                default: null
            }
        },
        emits: [
            'cancel-edit',
            'new-attribute-saved',
            'modified-attribute-saved'
        ],
        data() {
            return {
                isVisible: false,
                id: undefined,
                name: undefined,
                description: undefined,
                masterAttribute: undefined,
                isValid: undefined,
                interfaceIdentity: undefined,
                displaySequenceNo: undefined,
                datatype: undefined,
                enumLibrary: undefined,
                enumValues: undefined,
                regex: undefined,
                quantity: undefined,
                defaultUoM: undefined,
                hasUoMString: undefined,
                min: undefined,
                max: undefined,
                minPrecision: undefined,
                maxPrecision: undefined,
                decimalPrecision: undefined,
                decimalScale: undefined,
                defaultValue: undefined,
                isInIdentity: undefined,
                isMandatory: undefined,
                isPublic: undefined,
                isDeliverable: undefined,
                isLocal: undefined,
                isOfficial: undefined,
                isList: undefined,
                listSeparatorCharacter: undefined,
                tieName: undefined,
                versionIn: undefined,
                versionOut: undefined,
                comments: undefined,
                references: undefined,
                internalComments: undefined,
                originalAttribute: null,
                availableDataTypes: [ /* Hardcoding this list, but it is also a library */
                    { Name: 'bool', Identity: 'BOOL' },
                    { Name: 'date', Identity: 'DATE' },
                    { Name: 'datetime', Identity: 'DATETIME' },
                    { Name: 'enum', Identity: 'ENUM' },
                    { Name: 'float', Identity: 'FLOAT' },
                    { Name: 'integer', Identity: 'INTEGER' },
                    { Name: 'quantity', Identity: 'QUANTITY' },
                    { Name: 'string', Identity: 'STRING' },
                    { Name: 'decimal', Identity: 'DECIMAL' }
                ],
                availableRegex: {
                    BOOL: '^(true|false|1|0|[yYnN+-])$',
                    DATE: '^\\d{4}-\\d{2}-\\d{2}$',
                    DATETIME: '^\\d{4}-\\d{2}-\\d{2}\\s\\d{2}:\\d{2}:\\d{2}$',
                    ENUM: null,
                    FLOAT: '^[+-]?([0-9]*[.])?[0-9]+$',
                    INTEGER: '^[+-]?([0-9])+$',
                    QUANTITY: '^[+-]?([0-9]*[.])?[0-9]+$',
                    STRING: null
                },
                enumLibraries: [],
                quantities: [],
                unitOfMeasures: [],
                schemaVersions: [],
                validationIssues: [],
                saving: false,
                regexSuggestion: false,
                masterAttrModalActive: false,
                masterAttrChoices: [],
                intRegexPattern: '^[0-9]*$',
                intRegex: null
            };
        },
        computed: {
            filteredUnitOfMeasures() {
                if (this.quantity && this.quantity !== '') {
                    return this.unitOfMeasures.filter(u => u.Quantity === this.quantity);
                } else {
                    return [];
                }
            },
            showDecimalPrecisionAndScale() {
                return this.datatype === 'DECIMAL' || this.datatype === 'QUANTITY';
            },
            showMaxAndMinPrecision() {
                return this.datatype === 'FLOAT'
                    || this.datatype === 'DECIMAL'
                    || this.datatype === 'QUANTITY';
            },
            showMin() {
                return this.datatype === 'INTEGER'
                    || this.datatype === 'FLOAT'
                    || this.datatype === 'DECIMAL'
                    || this.datatype === 'QUANTITY';
            },
            showMax() {
                return this.datatype === 'STRING'
                    || this.datatype === 'INTEGER'
                    || this.datatype === 'FLOAT'
                    || this.datatype === 'DECIMAL'
                    || this.datatype === 'QUANTITY';
            }
        },
        watch: {
            async attributeId(value) {
                if (value && value !== 0 && value !== this.id) {
                    await this.loadAttribute(value);
                    this.isVisible = true;
                } else {
                    this.isVisible = false;
                }
            },
            newAttributeMode(value) {
                if (value) {
                    this.resetAttributeState();
                    this.isVisible = true;
                } else {
                    this.isVisible = false;
                }
            },
            datatype(value, oldValue) {
                const isInSuggested = this.regex && Object.values(this.availableRegex).includes(this.regex);
                if ((!this.regex || isInSuggested) && oldValue !== undefined && value) {
                    this.regex = this.availableRegex[value];
                    if (this.regex) {
                        this.regexSuggestion = true;
                        setTimeout(() => {
                            this.regexSuggestion = false;
                        }, 6000);
                    }
                }
            },
            maxPrecision() {
                if (this.datatype === 'DECIMAL'
                    && this.maxPrecision !== this.originalAttribute?.MaxPrecision) {
                    this.decimalScale = this.maxPrecision;
                }
            }
        },
        async mounted() {
            await Promise.all([
                this.loadEnumLibraries(),
                this.loadQuantities(),
                this.loadUnitOfMeasures(),
                this.loadSchemaVersions(),
                this.loadMasterAttrChoices()
            ]);
            this.intRegex = new RegExp(this.intRegexPattern);
        },
        methods: {
            resetAttributeState() {
                this.id = 0;
                this.name = '';
                this.description = '';
                this.masterAttribute = '';
                this.isValid = true;
                this.interfaceIdentity = this.selectedInterface ?? '';
                this.displaySequenceNo = null;
                this.datatype = '';
                this.enumLibrary = null;
                this.enumValues = null;
                this.regex = '';
                this.quantity = '';
                this.defaultUoM = '';
                this.hasUoMString = false;
                this.min = null;
                this.max = null;
                this.minPrecision = null;
                this.maxPrecision = null;
                this.decimalPrecision = null;
                this.decimalScale = null;
                this.defaultValue = '';
                this.isInIdentity = false;
                this.isMandatory = false;
                this.isPublic = true;
                this.isDeliverable = true;
                this.isLocal = false;
                this.isOfficial = true;
                this.isList = false;
                this.listSeparatorCharacter = '';
                this.tieName = '';
                this.versionIn = null;
                this.versionOut = null;
                this.comments = '';
                this.references = '';
                this.internalComments = '';

                this.validationIssues = [];
            },
            async loadAttribute(id) {
                const response = await genericViewQueryAsText(
                    this,
                    'FROM SchemaAttribute WHERE Id = @id',
                    [{ name: '@id', value: id }]);

                const a = response.data[0];

                this.id = a.Id;
                this.name = a.Name;
                this.description = a.Description;
                this.masterAttribute = a.MasterAttribute;
                this.isValid = a.IsValid;
                this.interfaceIdentity = a.Interface;
                this.displaySequenceNo = a.DisplaySequenceNo;
                this.datatype = a.Datatype;
                this.enumLibrary = a.EnumLibrary;
                this.enumValues = a.EnumValues;
                this.regex = a.Regex;
                this.quantity = a.Quantity;
                this.defaultUoM = a.DefaultUoM;
                this.hasUoMString = a.HasUoMString !== null ? a.HasUoMString : false;
                this.min = a.Min;
                this.max = a.Max;
                this.minPrecision = a.MinPrecision;
                this.maxPrecision = a.MaxPrecision;
                this.decimalPrecision = a.DecimalPrecision;
                this.decimalScale = a.DecimalScale;
                this.defaultValue = a.DefaultValue;
                this.isInIdentity = a.IsInIdentity !== null ? a.IsInIdentity : false;
                this.isMandatory = a.IsMandatory !== null ? a.IsMandatory : false;
                this.isPublic = a.IsPublic !== null ? a.IsPublic : true;
                this.isDeliverable = a.IsDeliverable !== null ? a.IsDeliverable : true;
                this.isLocal = a.IsLocal !== null ? a.IsLocal : false;
                this.isOfficial = a.IsOfficial !== null ? a.IsOfficial : true;
                this.isList = a.IsList !== null ? a.IsList : false;
                this.listSeparatorCharacter = a.ListSeparatorCharacter;
                this.tieName = a.TIEName;
                this.comments = a.Comments;
                this.references = a.References;
                this.internalComments = a.InternalComments;

                this.validationIssues = [];
                this.originalAttribute = a;
            },
            async loadEnumLibraries() {
                this.enumLibraries = (await getLibraryNames(this))
                    .sort((a, b) => a.localeCompare(b));
            },
            async loadQuantities() {
                this.quantities = (await genericViewQueryAsText(
                    this,
                    'FROM Quantity SELECT Name, Identity'
                )).data.sort((a, b) => a.Name.localeCompare(b.Name));
            },
            async loadUnitOfMeasures() {
                this.unitOfMeasures = (await genericViewQueryAsText(
                    this,
                    'FROM UnitOfMeasure SELECT Name, Identity, Quantity, Description'
                )).data.sort((a, b) => a.Description.localeCompare(b.Description));
            },
            async loadSchemaVersions() {
                this.schemaVersions = (await genericViewQueryAsText(
                    this,
                    'FROM SchemaVersion WHERE Schema = @schema',
                    [{ name: '@schema', value: this.schemaIdentity }]
                )).data.sort((a, b) => a.Name.localeCompare(b.Name));
            },
            async loadMasterAttrChoices() {
                this.masterAttrChoices = (await genericViewQueryAsText(
                    this,
                    'FROM SchemaMasterAttribute SELECT Name, Identity'
                )).data.sort((a, b) => a.Name.localeCompare(b.Name));
            },
            clickCancel() {
                this.resetAttributeState();
                this.$emit('cancel-edit', null);
            },
            async clickSave() {
                this.saving = true;
                const isNewAttribute = this.id === 0;

                try {
                    if (!this.validate())
                        return;

                    const changeDoc = {
                        Name: this.name,
                        Description: this.description,
                        MasterAttribute: this.masterAttribute !== null && this.masterAttribute.length > 0
                            ? this.masterAttribute
                            : '{null}',
                        IsValid: this.isValid,
                        Schema: this.schemaIdentity,
                        Interface: this.interfaceIdentity,
                        DisplaySequenceNo: this.displaySequenceNo !== null ? this.displaySequenceNo : '{null}',
                        Datatype: this.datatype,
                        Regex: this.regex !== null ? this.regex : '{null}',
                        DefaultValue: this.defaultValue !== null ? this.defaultValue : '{null}',
                        IsInIdentity: this.isInIdentity,
                        IsMandatory: this.isMandatory,
                        IsPublic: this.isPublic,
                        IsDeliverable: this.isDeliverable,
                        IsLocal: this.isLocal,
                        IsOfficial: this.isOfficial,
                        IsList: this.isList,
                        ListSeparatorCharacter: this.listSeparatorCharacter !== null ? this.listSeparatorCharacter : '{null}',
                        TIEName: this.tieName !== null ? this.tieName : '{null}',
                        Comments: this.comments !== null ? this.comments : '{null}',
                        References: this.references !== null ? this.references : '{null}',
                        InternalComments: this.internalComments !== null ? this.internalComments : '{null}'
                    };

                    this.addChangeForEnums(changeDoc);
                    this.addChangeForQuantities(changeDoc);
                    this.addMinMaxAttributes(changeDoc);
                    this.addPrecisionAttributes(changeDoc);
                    const res = await createChangeDocAndCommit(this, this.attributeCodeSet.name, [changeDoc]);
                    this.showInfo(res.data.message);
                    if (res.data.releaseId > 0) {
                        this.resetAttributeState();
                        this.$emit(
                            isNewAttribute ? 'new-attribute-saved' : 'modified-attribute-saved',
                            changeDoc);
                    }
                } catch (generalError) {
                    this.showError(generalError);
                } finally {
                    this.saving = false;
                }
            },
            validate() {
                const issues = [];

                this.name = this.name.trim();

                if (this.name === '') {
                    issues.push('Missing required field: Name');
                }

                if (this.interfaceIdentity === '') {
                    issues.push('Missing required field: Interface');
                }
                if (!this.intRegex.test(this.displaySequenceNo)) {
                    issues.push('Display sequence number must be a positive integer greater than 0');
                }
                /*
                    Will not validate DATE and DATETIME formats,
                    as it can not be assumed across all schemas.
                    Can't validate BOOL for the same reason
                */

                switch (this.datatype) {
                    case '':
                        issues.push('Missing required field: Datatype');
                        break;
                    case 'ENUM':
                        if ((!this.enumLibrary || this.enumLibrary.length === 0)
                            && (!this.enumValues || this.enumValues.replace(/\s/g, '').length < 1)) {
                            issues.push('Missing required field for enumerations: EnumLibrary or EnumValues');
                        }
                        break;
                    case 'FLOAT':
                    case 'DECIMAL':
                    case 'QUANTITY':
                        if (this.datatype === 'QUANTITY') {
                            if (!this.quantity || this.quantity.length === 0) {
                                issues.push('Missing required field for quantities: Quantity');
                            }
                            if (!this.defaultUoM || this.defaultUoM.length === 0) {
                                issues.push('Missing required field for quantities: DefaultUoM');
                            }
                        }
                        if (this.min !== null && this.max !== null && this.min > this.max) {
                            issues.push('Minimum must not be greater than Maximum in Value range');
                        }
                        if (this.minPrecision !== null
                            && !this.intRegex.test(this.minPrecision)) {
                            issues.push('Minimum value in Data delivery precision must be a positive integer');
                        }
                        if (this.maxPrecision !== null) {
                            if (Number(this.maxPrecision) === 0
                                || !this.intRegex.test(this.maxPrecision)) {
                                issues.push('Maximum value in Data delivery precision must be a positive integer greater than 0');
                            }
                        }
                        if (this.decimalPrecision !== null) {
                            if (Number(this.decimalPrecision) === 0
                                || !this.intRegex.test(this.decimalPrecision)) {
                                issues.push('Precision value in Decimal storage requirement must be a positive integer greater than 0');
                            }
                        }
                        if (this.decimalScale !== null) {
                            if (Number(this.decimalScale) === 0
                                || !this.intRegex.test(this.decimalScale)) {
                                issues.push('Scale value in Decimal storage requirement  must be a positive integer greater than 0');
                            }
                        }
                        if (this.decimalPrecision !== null && this.decimalScale !== null && this.decimalScale > this.decimalPrecision) {
                            issues.push('Scale must not be greater than Precision in Decimal storage requirement');
                        }
                        if (this.defaultValue) {
                            const defaultNumberValue = Number(this.defaultValue);
                            if (isNaN(defaultNumberValue)) {
                                issues.push('Default value for float must be a number');
                            } else {
                                if (this.min !== null && defaultNumberValue < this.min) {
                                    issues.push('Default value must not be less than Minimum value');
                                } else if (this.max !== null && defaultNumberValue > this.max) {
                                    issues.push('Default value must not be greater than Maximum value');
                                }
                            }
                        }
                        break;
                    case 'INTEGER':
                        if (this.min !== null && !Number.isInteger(this.min)) {
                            issues.push('Integer minimum value must be an integer');
                        }
                        if (this.max !== null && !Number.isInteger(this.max)) {
                            issues.push('Integer maximum value must be an integer');
                        }
                        if (this.min !== null && this.max !== null && this.min > this.max) {
                            issues.push('Minimum must not be greater than Maximum');
                        }
                        if (this.defaultValue) {
                            if (!stringIsInteger(this.defaultValue)) {
                                issues.push('Default value for integer must be an integer');
                            } else {
                                const defaultIntegerValue = parseInt(this.defaultValue);
                                if (this.min !== null && defaultIntegerValue < this.min) {
                                    issues.push('Default value must not be less than Minimum value');
                                } else if (this.max !== null && defaultIntegerValue > this.max) {
                                    issues.push('Default value must not be greater than Maximum value');
                                }
                            }
                        }
                        break;
                }

                if (this.regex) {
                    try {
                        const regEx = new RegExp(this.regex);
                        if (this.defaultValue) {
                            const validDefaultValue = regEx.test(this.defaultValue);
                            if (!validDefaultValue) issues.push('Default value is not valid according to the Regular Expression');
                        }
                    } catch (e) {
                        issues.push('This is not a valid Regular Expression');
                    }
                }

                this.validationIssues = issues;
                return this.validationIssues.length === 0;
            },
            addChangeForEnums(changeDoc) {
                if (this.datatype === 'ENUM') {
                    changeDoc.EnumLibrary = this.enumLibrary === null ? '{null}' : this.enumLibrary;
                    changeDoc.EnumValues = this.enumValues === null ? '{null}' : this.enumValues;
                } else {
                    if (this.originalAttribute?.EnumLibrary) {
                        changeDoc.EnumLibrary = '{null}';
                    }
                    if (this.originalAttribute?.EnumValues) {
                        changeDoc.EnumValues = '{null}';
                    }
                }
            },
            addChangeForQuantities(changeDoc) {
                if (this.datatype === 'QUANTITY') {
                    changeDoc.Quantity = this.quantity;
                    changeDoc.DefaultUoM = this.defaultUoM;
                    changeDoc.HasUoMString = this.hasUoMString;
                } else {
                    if (this.originalAttribute?.Quantity) {
                        changeDoc.Quantity = '{null}';
                    }
                    if (this.originalAttribute?.DefaultUoM) {
                        changeDoc.DefaultUoM = '{null}';
                    }
                    if (this.originalAttribute?.HasUoMString !== null) {
                        changeDoc.HasUoMString = '{null}';
                    }
                }
            },
            addMinMaxAttributes(changeDoc) {
                changeDoc.Max = this.showMax && this.max !== null
                    ? this.max.toString()
                    : this.originalAttribute?.Max !== null
                        ? '{null}'
                        : changeDoc.Max;
                changeDoc.Min = this.showMin && this.min !== null
                    ? this.min.toString()
                    : this.originalAttribute?.Min !== null
                        ? '{null}'
                        : changeDoc.Min;
            },
            addPrecisionAttributes(changeDoc) {
                if (this.showMaxAndMinPrecision) {
                    if (this.minPrecision !== null) {
                        changeDoc.MinPrecision = this.minPrecision;
                    } else if (this.originalAttribute?.MinPrecision !== null) {
                        changeDoc.MinPrecision = '{null}';
                    }
                    if (this.maxPrecision !== null) {
                        changeDoc.MaxPrecision = this.maxPrecision;
                    } else if (this.originalAttribute?.MaxPrecision !== null) {
                        changeDoc.MaxPrecision = '{null}';
                    }
                    if (this.decimalPrecision !== null) {
                        changeDoc.DecimalPrecision = this.decimalPrecision;
                    } else if (this.originalAttribute?.DecimalPrecision !== null) {
                        changeDoc.DecimalPrecision = '{null}';
                    }
                    if (this.decimalScale !== null) {
                        changeDoc.DecimalScale = this.decimalScale;
                    } else if (this.originalAttribute?.DecimalScale !== null) {
                        changeDoc.DecimalScale = '{null}';
                    }
                } else {
                    if (this.originalAttribute?.MinPrecision !== null) {
                        changeDoc.MinPrecision = '{null}';
                    }
                    if (this.originalAttribute?.MaxPrecision !== null) {
                        changeDoc.MaxPrecision = '{null}';
                    }
                }
            },
            setMasterAttribute(event) {
                this.masterAttribute = event;
                this.masterAttrModalActive = false;
            }
        }
    };
</script>

<style scoped>
.button-container {
  display: flex;
}

.margin-right {
  margin-right: 5px;
}
.displaySequenceNo-width {
    width: 150px;
}
</style>
