<template>
    <div
        v-cloak
        class="navbar-start">
        <b-modal
            v-model="searchModalActive"
            trap-focus
            full-screen
            :destroy-on-hide="false">
            <div
                class="modal-card"
                style="width: auto">
                <section class="modal-card-body">
                    <global-search @select="onGlobalSearchSelect" />
                </section>
            </div>
        </b-modal>
        <top-menu-item
            v-for="item in menuItems"
            :key="item.text"
            v-require-any-role="item.permissions"
            :item="item" />
        <div
            v-show="favourites.length > 0"
            class="navbar-item has-dropdown is-size-5">
            <a
                ref="favouritesHeader"
                class="navbar-link"
                role="button">
                <span
                    class="icon fav-star-active"
                    title="My favourites">
                    <i class="fas fa-star is-small" />
                </span>
            </a>
            <top-sub-menu
                ref="favoritesSubMenu"
                :items="favourites" />
        </div>
    </div>
</template>

<script>
    import * as signalR from '@microsoft/signalr';
    import { requireAnyPermission } from '../directives/requirePermission';
    import GlobalSearch from './GlobalSearch.vue';
    import favouriteService from '../services/favouriteService';
    import { getDynamicMenuItems } from '../helpers/api';
    import TopMenuItem from './TopMenuItem.vue';
    import TopSubMenu from './TopSubMenu.vue';
    import { flip, offset, shift } from '@floating-ui/vue';

    export const defaultMenuItems = [
        {
            text: 'Libraries',
            link: '/Library',
            permissions: ['IsAuthenticatedUser'],
            subItems: [
                {
                    text: 'Library list',
                    link: '/Library',
                    permissions: ['IsAuthenticatedUser']
                },
                {
                    text: 'Library explorer',
                    link: '/Library/library-network',
                    permissions: ['IsAuthenticatedUser']
                },
                {
                    text: 'Query builder',
                    link: '/Query',
                    permissions: ['IsAuthenticatedUser']
                },
                {
                    text: 'SQL query',
                    link: '/Sql',
                    permissions: ['IsAuthenticatedUser']
                }
            ]
        },
        {
            text: 'Releases',
            link: '/Release',
            permissions: ['IsAuthenticatedUser'],
            notifications: null,
            subItems: [
                {
                    text: 'All releases',
                    link: '/Release/',
                    permissions: ['IsAuthenticatedUser']
                },
                {
                    text: 'My releases',
                    link: '/Release/MyReleases',
                    permissions: ['IsAdministrator', 'CanEditRelease'],
                    notifications: null
                },
                {
                    text: 'Excel import',
                    link: '/CodeSetImport',
                    permissions: ['IsAdministrator', 'CanEditRelease'],
                    hasSeparator: true
                }
            ]
        },
        {
            text: 'Function',
            link: '/Function',
            permissions: ['IsAuthenticatedUser'],
            subItems: [
                {
                    text: 'Cable codes',
                    link: '/CableEdit',
                    permissions: ['IsAuthenticatedUser']
                },
                {
                    text: 'Electric component type',
                    link: '/EleComponentType',
                    permissions: ['IsAuthenticatedUser']
                },
                {
                    text: 'Ens Doc format',
                    link: '/DocumentNumberingFormat',
                    permissions: ['IsAuthenticatedUser']
                },
                {
                    text: 'Ens number validation',
                    link: '/EnsNumber',
                    permissions: ['IsAuthenticatedUser']
                },
                {
                    text: 'Ens Tag format',
                    link: '/TagFormat',
                    permissions: ['IsAuthenticatedUser']
                },
                {
                    text: 'Facility and Project UI',
                    link: '/FacilityAndProject',
                    permissions: ['IsAuthenticatedUser']
                },
                {
                    text: 'Piping class and size',
                    link: '/PipeClassEditor',
                    permissions: ['IsAuthenticatedUser']
                },
                {
                    text: 'Project, Contractor and Purchase order UI',
                    link: '/LCI/Projects',
                    permissions: ['IsAuthenticatedUser']
                },
                {
                    text: 'Schema and Mapping',
                    link: '/Schema',
                    permissions: ['IsAuthenticatedUser']
                },
                {
                    text: 'Unit of Measure',
                    link: '/UoM',
                    permissions: ['IsAuthenticatedUser']
                }
            ]
        },
        {
            text: 'Reports',
            link: '/Report',
            permissions: ['IsAuthenticatedUser'],
            subItems: [
                {
                    text: 'Azure dashboard',
                    link: 'https://portal.azure.com/#@statoilsrm.onmicrosoft.com/dashboard/arm/subscriptions/151e9104-8c74-4515-8ed2-710076076f34/resourcegroups/cl-prod-rg/providers/microsoft.portal/dashboards/69c98a83-19fa-451e-8ae6-04d4aeb1b4bc',
                    target: '_blank',
                    permissions: ['IsAuthenticatedUser']
                },
                {
                    text: 'Export TR3111 code tables',
                    link: '/CodeTables?group=TR3111',
                    permissions: ['IsAuthenticatedUser']
                },
                {
                    text: 'Handy lists (from queries)',
                    link: '/Report/Query',
                    permissions: ['IsAuthenticatedUser']
                },
                {
                    text: 'KPI',
                    link: '/Report/KPI',
                    permissions: ['IsAuthenticatedUser']
                },
                {
                    text: 'Library code count',
                    link: '/Report/LibraryCodeCount',
                    permissions: ['IsAuthenticatedUser']
                },
                {
                    text: 'Library scope distribution',
                    link: '/Report/LibraryScopeDistribution',
                    permissions: ['IsAuthenticatedUser']
                },
                {
                    text: 'Library statistics',
                    link: '/Report/LibraryStatistics',
                    permissions: ['IsAuthenticatedUser']
                },
                {
                    text: 'MasterTagType and MasterDocType mapping',
                    link: '/Report/MasterMapping',
                    permissions: ['IsAuthenticatedUser']
                },
                {
                    text: 'Schedules report',
                    link: '/Report/Schedules',
                    permissions: ['IsAuthenticatedUser']
                },
                {
                    text: 'Treemap chart - all libraries',
                    link: '/TreeMap',
                    permissions: ['IsAuthenticatedUser']
                }
            ]
        },
        {
            text: 'Configuration',
            link: '/Config',
            permissions: ['IsAuthenticatedUser'],
            subItems: [
                {
                    text: 'Access groups',
                    link: '/Config/AccessGroups',
                    permissions: ['IsAdministrator']
                },
                {
                    text: 'Access policy',
                    link: '/Config/AccessPolicy',
                    permissions: ['IsAdministrator']
                },
                {
                    text: 'Admin commands',
                    link: '/Config/AdminCommands',
                    permissions: ['IsAdministrator']
                },
                {
                    text: 'Aliasing',
                    link: '/Config/Aliasing',
                    permissions: ['IsAdministrator']
                },
                {
                    text: 'Applications',
                    link: '/Config/Application',
                    permissions: ['IsAuthenticatedUser']
                },
                {
                    text: 'Attachment search',
                    link: '/Config/AttachmentSearch',
                    permissions: ['IsAuthenticatedUser']
                },
                {
                    text: 'Changelog admin',
                    link: '/Config/ChangeLogAdmin',
                    permissions: ['IsAdministrator']
                },
                {
                    text: 'Disabled features',
                    link: '/Config/DisabledFeatures',
                    permissions: ['IsAdministrator']
                },
                {
                    text: 'Library groups',
                    link: '/Config/Tags',
                    permissions: ['IsAdministrator', 'CanEditLibrary']
                },
                {
                    text: 'Monitoring',
                    link: '/Config/Monitoring',
                    permissions: ['IsAdministrator']
                },
                {
                    text: 'Publisher',
                    link: '/Config/Publisher',
                    permissions: ['IsAdministrator']
                },
                {
                    text: 'Schedules',
                    link: '/Config/Schedules',
                    permissions: ['IsAuthenticatedUser']
                },
                {
                    text: 'UI design system',
                    link: '/Config/UI',
                    permissions: ['IsAdministrator']
                },
                {
                    text: 'Webhooks',
                    link: '/Config/Webhooks',
                    permissions: ['IsAdministrator']
                }
            ]
        },
        {
            text: 'Help',
            link: '/Help/',
            permissions: ['IsAuthenticatedUser']
        }
    ];

    export default {
        directives: {
            'require-any-role': requireAnyPermission
        },
        components: {
            TopSubMenu,
            GlobalSearch,
            TopMenuItem
        },
        data() {
            return {
                menuItems: defaultMenuItems,
                searchModalActive: false,
                favourites: []
            };
        },
        created() {
            this.connection = new signalR.HubConnectionBuilder()
                .withUrl(window.config.signalRUrlNotification, {
                    accessTokenFactory: async () =>
                        await window.authService.acquireToken()
                })
                .configureLogging(signalR.LogLevel.None)
                .build();
        },
        async mounted() {
            const self = this;
            window.addEventListener('keypress', e => {
                if (e.ctrlKey && e.code === 'KeyQ') {
                    self.searchModalActive = true;
                }
            });

            const reference = this.$refs.favouritesHeader;
            const menu = this.$refs.favoritesSubMenu;
            menu.makeFloating(reference, reference.parentElement, {
                placement: 'bottom-start',
                middleware: [offset({ mainAxis: -2, crossAxis: -3 }), flip(), shift()]
            });

            await this.loadFavourites();
            document.headerMailbox.on('update-favourites', async function() {
                await self.loadFavourites();
            });

            await this.loadDynamicMenu();

            this.connection.on('releaseNotification', count => {
                const item = this.menuItems.find(i => i.text === 'Releases');
                item.notifications = count;

                const subItem = item.subItems.find(x => x.text === 'My Releases');
                subItem.notifications = count;
            });
            await this.connection.start();
        },
        beforeUnmount() {
            this.connection.stop();
        },
        methods: {
            async loadFavourites() {
                const favourites = await favouriteService.get(this);
                this.favourites = favourites.map(fav => this.mapFavouriteToMenuItem(fav));
            },
            async loadDynamicMenu() {
                const dynamicItems = await getDynamicMenuItems(this);
                const mappedDynamicItems = dynamicItems.map(x => this.mapToMenuItem(x));

                const helpItemIndex = this.menuItems.findLastIndex(x => x.text === 'Help');
                const dynamicItemsStartIndex = helpItemIndex !== -1 ? helpItemIndex : this.menuItems.length;
                this.menuItems.splice(dynamicItemsStartIndex, 0, ...mappedDynamicItems);
            },
            mapToMenuItem(dynamicMenuItem) {
                return {
                    text: dynamicMenuItem.name,
                    link: dynamicMenuItem.path,
                    permissions: ['IsAuthenticatedUser'],
                    highlight: dynamicMenuItem.isHighlighted,
                    subItems: dynamicMenuItem.childItems.map(x => this.mapToMenuItem(x)),
                    isDynamic: true
                };
            },
            mapFavouriteToMenuItem(favourite) {
                return {
                    text: favourite.title,
                    link: favourite.url,
                    permissions: ['IsAuthenticatedUser']
                };
            },
            onGlobalSearchSelect() {
                this.searchModalActive = false;
            }
        }
    };
</script>
