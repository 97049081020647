<template>
    <b-tooltip
        :label="tooltip"
        :position="tooltipPosition"
        :size="size"
        animated>
        <b-icon
            :size="size"
            type="is-info"
            :icon="icon" />
    </b-tooltip>
</template>

<script>
    export default {
        name: 'TypeIcon',
        props: {
            size: {
                type: String,
                default: 'is-small'
            },
            tooltipPosition: {
                type: String,
                default: 'is-left'
            },
            type: {
                type: String,
                required: true
            }
        },
        computed: {
            icon() {
                switch (this.type.toLowerCase()) {
                    case 'global': return 'earth';
                    case 'facility': return 'factory';
                    case 'foreign': return 'alien';
                    case 'virtual': return 'alpha-v-circle';
                    case 'uppercase': return 'alphabetical-variant';
                    case 'readonly': return 'lock';
                    default: return 'help-circle';
                }
            },
            tooltip() {
                switch (this.type.toLowerCase()) {
                    case 'global': return 'Global';
                    case 'facility': return 'Scoped by Facility';
                    case 'foreign': return 'Foreign object library';
                    case 'virtual': return 'Virtual library';
                    case 'uppercase': return 'Names uppercase only';
                    case 'readonly': return 'Read-only in CommonLibrary';
                    default: return '?';
                }
            }
        }
    };
</script>
