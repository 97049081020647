/*
 * This store is a port of the Vuex code needed to support the Cable UI.
 */

/* eslint pinia/require-setup-store-properties-export: 0 */
/* ^^^ The pinia plugin is confused by the use of storeToRefs in the return statement */
/* eslint pinia/no-duplicate-store-ids: 0 */
/* ^^^ The pinia reports a false positive about duplicate store IDs */

import _ from 'lodash';
import { defineStore } from 'pinia';
import { computed, reactive, toRefs, watch } from 'vue';
import { genericViewQueryAsText, getCodeSets, getIdentity, getLibrary } from '@/shared/helpers/api.ts';
import { http } from '@/shared/httpWrapper.js';
import { createCodeDefArray, createCodeObject4, transformToChangeDocCode } from '@/shared/helpers/dataHelpers.js';

function initialState() {
    return {
        scope: null,
        // library name keyed objects
        selectedCodes: {},
        filters: {},
        codes: {},
        changes: {},
        libraries: {},
        attributeDefinitions: {},
        templates: {},
        fullyLoaded: {}
    };
}

export const useCodeEditStore = defineStore('codeEdit', () => {
    // == State ==

    const state = reactive(initialState());

    // == Getters ==

    const changesCount = computed(() =>
        Object.values(state.changes)
            .reduce((a, b) => a + b.length, 0)
    );

    const isDirty = computed(() => changesCount.value > 0);

    const codeSetNames = computed(() =>
        Object.fromEntries(
            Object.keys(state.libraries)
                .map(libraryName => [libraryName, getCodeSet(libraryName).name])
        )
    );

    // == 'Accessor' actions ==

    function unfilteredCodes(libraryName) {
        const originalCodes = state.codes[libraryName] ?? [];
        const changedCodes = state.changes[libraryName] ?? [];
        return _.unionBy(changedCodes, originalCodes, 'values.identity');
    }

    function filteredCodes(libraryName) {
        const unfiltered = unfilteredCodes(libraryName);
        const filter = state.filters[libraryName];
        return filter ? unfiltered.filter(filter) : unfiltered;
    }

    function getCodeSet(libraryName) {
        const library = state.libraries[libraryName];
        return library.isGlobal
            ? library.codeSets[0]
            : library.codeSets.find(cs => cs.scopes.includes(state.scope));
    }

    function isFullyLoaded(libraryName) {
        return !!state.fullyLoaded[libraryName];
    }

    function attributes(libraryName) {
        return state.attributeDefinitions[libraryName] ?? [];
    }

    function originalCodes(libraryName) {
        return state.codes[libraryName] ?? [];
    }

    function selectedCode(libraryName) {
        const identity = state.selectedCodes[libraryName];
        return identity && filteredCodes(libraryName).find(c => c.values.identity === identity);
    }

    // == Actions ==

    function $reset() {
        Object.assign(state, initialState());
    }

    function setLibrary(libraryName, library) {
        state.libraries[libraryName] = library;

        state.attributeDefinitions[libraryName] = createCodeDefArray(library.attributeDefinitions, {
            nameInIdentity: library.nameInIdentity,
            descriptionIsRequired: library.descriptionIsRequired
        });

        const values = state.attributeDefinitions[libraryName]
            .map(c => c.field)
            .reduce((o, key) => ({ ...o, [key]: '' }), {});
        state.attributeDefinitions[libraryName].forEach(a => {
            if (a.type === 'Boolean')
                values[a.field] = 'False';
        });
        state.templates[libraryName] = { values, meta: {} };

        state.changes[libraryName] = [];
    }

    async function loadLibrary(libraryName) {
        if (state.libraries[libraryName])
            return;

        // For improved performance, we load the library and the code set in parallel. Instead of waiting for the
        // library request to complete and checking if the library is scoped, we simply supply the scope to the code
        // sets request if we have one. This is OK because the API will ignore the scope if the library is global
        // and return all code sets if the scope is not provided. We consider it a programmer error if the library
        // is not global and the scope is not provided (checked below).

        const [library, codeSets] = await Promise.all([
            getLibrary(http, libraryName, {
                includeAttributeDefintions: true,
                includeAccessGroups: false,
                includeAttachment: false,
                includeTags: false,
                includeCodeSets: false
            }),
            getCodeSets(http, libraryName, state.scope ?? '')
        ]);

        if (!library.isGlobal && !state.scope)
            throw new Error('Cannot load library without setting context (scope)');
        if (!(codeSets && codeSets.length > 0))
            throw new Error(`No data for ${libraryName} and ${state.scope} combo`);

        library.codeSets = codeSets;

        setLibrary(libraryName, library);
    }

    function addCodes(libraryName, newCodes) {
        const mappedCodes = newCodes.map(c =>
            createCodeObject4(c, state.attributeDefinitions[libraryName])
        );
        state.codes[libraryName] = _.unionBy(mappedCodes, state.codes[libraryName], 'values.identity');
    }

    function setCodes(libraryName, codes) {
        state.codes[libraryName] = [];
        addCodes(libraryName, codes);
        state.fullyLoaded[libraryName] = true;
    }

    async function loadCodes(libraryName, { isValid = false } = {}) {
        await loadLibrary(libraryName);

        if (state.fullyLoaded[libraryName])
            return;

        let query;
        if (state.libraries[libraryName].isGlobal) {
            query = isValid
                ? `FROM ${libraryName} WHERE IsValid = true`
                : `FROM ${libraryName}`;
        } else {
            query = isValid
                ? `FROM ${libraryName} WHERE IsValid = true AND Scope = @scope`
                : `FROM ${libraryName} WHERE Scope = @scope`;
        }

        const fetchedCodes = await genericViewQueryAsText(
            http,
            query,
            [{ name: '@scope', value: state.scope }]
        ).then(res => res.data);

        setCodes(libraryName, fetchedCodes);
    }

    function selectCode(libraryName, identity) {
        state.selectedCodes[libraryName] = identity;
    }

    function registerFilter(libraryName, filter) {
        state.filters[libraryName] = filter;
    }

    async function changeCode(libraryName, code, action) {
        if (code.values.identity === '') {
            const codeSet = getCodeSet(libraryName).name;
            const codeAttributes = transformToChangeDocCode(code.values);
            code.values.identity = (await getIdentity(http, codeSet, codeAttributes)).data;
        }

        if (action === 'create') {
            const originalCode = state.codes[libraryName].find(c => c.values.identity === code.values.identity);
            if (originalCode)
                throw new Error('Code already exists');

            state.changes[libraryName] = [...state.changes[libraryName], code];
        } else if (action === 'update') {
            const originalCode = state.codes[libraryName].find(c => c.values.identity === code.values.identity);
            const existingChange = state.changes[libraryName].find(c => c.values.identity === code.values.identity);

            if (!originalCode && !existingChange)
                throw new Error('Code does not exist');

            const hasChanged = !_.isEqual(originalCode?.values, code.values);
            if (!hasChanged) {
                if (existingChange) {
                    removeChange(libraryName, code.values.identity);
                }
            } else {
                if (existingChange) {
                    const index = state.changes[libraryName].indexOf(existingChange);
                    state.changes[libraryName][index] = code;
                } else {
                    state.changes[libraryName] = [...state.changes[libraryName], code];
                }
            }
        } else {
            throw new Error(`Invalid action: ${action}`);
        }
    }

    function revertChanges() {
        for (const libraryName in state.changes) {
            state.changes[libraryName] = [];
        }
    }

    function removeChange(libraryName, identity) {
        state.changes[libraryName] = state.changes[libraryName].filter(c => c.values.identity !== identity);
    }

    // == Other ==

    watch(() => state.scope, (newScope) => {
        $reset();
        state.scope = newScope;
    });

    return {
        // state
        ...toRefs(state),
        // getters
        isDirty,
        changesCount,
        codeSetNames,
        // 'accessor' actions
        isFullyLoaded,
        attributes,
        filteredCodes,
        unfilteredCodes,
        originalCodes,
        selectedCode,
        // actions
        $reset,
        setLibrary,
        loadLibrary,
        addCodes,
        setCodes,
        loadCodes,
        selectCode,
        registerFilter,
        changeCode,
        revertChanges,
        removeChange
    };
});
