import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useScopeStore } from '@/stores/scopeStore.js';

export const usePipeClassStore = defineStore('pipeClass', () => {
    const pipingClassId = ref(null);
    const pipingClassSizeId = ref(null);
    const lineSizeId = ref(null);

    const scopeStore = useScopeStore();

    function setPipingClass(value) {
        pipingClassId.value = value;
    }

    function setPipingClassSize(value) {
        pipingClassSizeId.value = value;
    }

    function getSelectedScope() {
        return scopeStore.getSelectedScope();
    }

    function setLineSize(value) {
        lineSizeId.value = value;
    }

    function updateFacilityScope(value) {
        scopeStore.updateSelectedScope(value);
    }

    return {
        pipingClassId,
        pipingClassSizeId,
        lineSizeId,
        setPipingClass,
        setPipingClassSize,
        setLineSize,
        getSelectedScope,
        updateFacilityScope
    };
});
