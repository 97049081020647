<template>
    <div>
        <div
            v-cloak>
            <div class="columns">
                <div class="column">
                    <h3 class="title is-4">
                        {{ title }}
                    </h3>
                </div>
                <div class="column is-clearfix">
                    <div class="field is-grouped is-pulled-right">
                        <p class="control">
                            <button
                                class="button is-info"
                                :disabled="addDialogVisible || !canEdit"
                                @click="add">
                                <b-icon
                                    icon="plus-circle-outline"
                                    size="is-small" />
                                <span>Add element</span>
                            </button>
                        </p>
                        <p
                            v-if="saveButtonEnabled"
                            class="control">
                            <button
                                class="button is-primary"
                                :disabled="addDialogVisible || !canEdit"
                                @click="save">
                                <b-icon
                                    icon="floppy"
                                    size="is-small" />
                                <span>Save</span>
                            </button>
                        </p>
                    </div>
                </div>
            </div>

            <div
                v-show="addDialogVisible"
                class="columns">
                <div class="column">
                    <div class="box">
                        <p class="title is-5">
                            Add element
                        </p>
                        <b-field>
                            <template #label>
                                Element name <span class="is-italic is-size-7">(re-use name from another library group or define a new)</span>:
                            </template>
                            <b-field
                                grouped
                                :message="newKeyMessage"
                                :type="newKeyMessageType">
                                <b-autocomplete
                                    v-model="newKey"
                                    :data="filteredAllUniqueKeys"
                                    rounded
                                    expanded
                                    placeholder="..."
                                    icon="magnify"
                                    :open-on-focus="openOnFocus"
                                    @update:model-value="newKeyInputChanged">
                                    <template #empty>
                                        No existing element found
                                    </template>
                                </b-autocomplete>
                                <p class="control">
                                    <button
                                        class="button is-primary"
                                        :disabled="newKey.length === 0 || !canEdit"
                                        @click="addConfirm">
                                        Add
                                    </button>
                                    <button
                                        class="button"
                                        @click="addDialogVisible = false">
                                        Cancel
                                    </button>
                                </p>
                            </b-field>
                        </b-field>
                    </div>
                </div>
            </div>

            <div class="columns">
                <div class="column">
                    <p
                        v-if="fields.length > 0 && subtitle"
                        style="padding-bottom:25px;">
                        {{ subtitle }}
                    </p>
                    <div
                        v-for="key in fields"
                        :key="key"
                        style="margin-bottom:20px;">
                        <b-field :label="key">
                            <b-field
                                grouped>
                                <b-input
                                    v-model="metadata[key]"
                                    expanded
                                    type="textarea" />
                                <p class="control">
                                    <button
                                        class="button"
                                        :disabled="!canEdit"
                                        @click="remove(key)">
                                        <b-icon
                                            icon="close-circle-outline"
                                            size="is-small" />
                                        <span>Delete</span>
                                    </button>
                                </p>
                            </b-field>
                        </b-field>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import messageDialog from '@/shared/mixins/messageDialogMixin';
    import { showMixin } from '@/shared/mixins/showMixin';
    import _ from 'lodash';
    import { metadataServiceProvider } from '@/shared/services/metadataService';

    export default {
        mixins: [
            showMixin,
            messageDialog
        ],
        props: {
            title: {
                required: false,
                type: String,
                default: ''
            },
            subtitle: {
                required: false,
                type: String,
                default: ''
            },
            type: {
                required: true,
                type: String,
                validator(value) {
                    return !!value;
                }
            },
            selectedName: {
                required: true,
                type: String
            },
            saveButtonEnabled: {
                required: false,
                type: Boolean,
                default: true
            },
            saveMessageEnabled: {
                required: false,
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                loading: true,
                metadata: {},
                addDialogVisible: false,
                newKey: '',
                allUniqueKeys: [],
                newKeyMessage: null,
                newKeyMessageType: null,
                openOnFocus: true,
                canEdit: false
            };
        },
        computed: {
            fields() {
                return Object.keys(this.metadata).sort();
            },
            lowercaseKeys() {
                return Object.keys(this.metadata).map(x => x.toLowerCase());
            },
            filteredAllUniqueKeys() {
                return this.allUniqueKeys.filter((option) => {
                    const lowerCased = option.toString().toLowerCase();

                    // Avoid duplicates by not showing already used keys
                    if (_.indexOf(this.lowercaseKeys, lowerCased) >= 0)
                        return false;

                    return lowerCased.indexOf(this.newKey.toLowerCase()) >= 0;
                });
            },
            metadataService() {
                return metadataServiceProvider(this.type);
            }
        },
        watch: {
            async selectedName() {
                await this.load();
            },
            async type() {
                await this.load();
            }
        },
        async created() {
            await this.load();
        },
        methods: {
            async load() {
                if (this.selectedName) {
                    this.loading = true;
                    this.canEdit = await this.metadataService.canEdit(this.selectedName);
                    this.metadata = await this.metadataService.get(this.selectedName);
                    this.allUniqueKeys = await this.metadataService.uniqueKeys();
                    this.loading = false;
                }
            },
            async save() {
                try {
                    await this.metadataService.save(this.selectedName, this.metadata);
                    if (this.saveMessageEnabled) {
                        this.showInfo(`${this.selectedName} saved`);
                    }
                    return true;
                } catch {
                    if (this.saveMessageEnabled) {
                        this.showError(`Failed to save ${this.selectedName}`);
                    }
                    return false;
                }
            },
            add() {
                this.newKeyInputChanged();
                this.newKey = '';
                this.addDialogVisible = true;
            },
            addConfirm() {
                const input = this.newKey.trim();

                if (input.length === 0) {
                    this.newKeyMessage = 'Element name can not be empty!';
                    this.newKeyMessageType = 'is-danger';
                    return;
                }

                if (_.indexOf(this.lowercaseKeys, input.toLowerCase()) >= 0) {
                    this.newKeyMessage = `Element name '${input}' already exists!`;
                    this.newKeyMessageType = 'is-danger';
                    return;
                }

                this.metadata[input] = '';
                this.addDialogVisible = false;
            },
            newKeyInputChanged() {
                // Re-set validation error
                this.newKeyMessage = null;
                this.newKeyMessageType = null;
            },
            async remove(key) {
                this.messageDialog(`This will permanently delete ${key}`, false, 'Confirm delete')
                    .then(async () => {
                        try {
                            await this.metadataService.delete(this.selectedName, key);
                            delete this.metadata[key];
                        } catch {
                            this.showError();
                        }
                    }).catch(() => { });
            }
        }
    };
</script>
