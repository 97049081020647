<template>
    <div>
        <h2 class="title is-3">
            Applications
        </h2>
        <div class="columns">
            <div class="column is-one-quarter">
                <spinner :loading="loading">
                    <b-table
                        :data="applications"
                        default-sort="name"
                        :striped="true"
                        :narrowed="true"
                        :hoverable="true">
                        <b-table-column
                            v-slot="props"
                            field="name"
                            label="Name"
                            sortable>
                            <router-link :to="getApplicationLink(props.row.name)">
                                {{ props.row.name }}
                            </router-link>
                        </b-table-column>
                    </b-table>
                </spinner>
            </div>
            <div class="column">
                <router-view name="applicationDetails" />
            </div>
        </div>
    </div>
</template>

<script>

    import Spinner from '@/shared/components/Spinner.vue';
    import { getAllInternalApplication } from '@/shared/helpers/api';
    import { getApplicationLink } from '@/shared/helpers/routing';
    import { showMixin } from '@/shared/mixins/showMixin';
    import Application from './Application.vue';

    export default {
        components: {
            Spinner,
            Application
        },
        mixins: [
            showMixin
        ],
        data() {
            return {
                applications: [],
                loading: true
            };
        },
        async mounted() {
            await this.load();
        },
        methods: {
            async load() {
                try {
                    this.applications = await getAllInternalApplication(this);
                    this.loading = false;
                } catch (err) {
                    this.loading = false;
                    this.showError(err);
                }
            },
            getApplicationLink
        }
    };
</script>
