<template>
    <div
        v-require-can-create-library>
        <button
            class="button is-primary is-pulled-right"
            @click="newLibrary()">
            <span class="icon is-small">
                <i
                    class="fa fa-plus-circle"
                    aria-hidden="true" />
            </span>
            <span>New library</span>
        </button>
    </div>
</template>

<script>
    import { requireCanCreateLibrary } from '@/shared/directives/requirePermission';
    import { showMixin } from '@/shared/mixins/showMixin';
    import { getLibraryLink } from '@/shared/helpers/routing';
    import { newLibrary } from '@/shared/helpers/api';

    export default {
        directives: {
            'require-can-create-library': requireCanCreateLibrary
        },
        mixins: [showMixin],
        methods: {
            newLibrary(libName) {
                const self = this;
                this.$buefy.dialog.prompt({
                    message: 'New library name (max 64 characters):',
                    inputAttrs: {
                        minlength: 1,
                        maxlength: 64,
                        value: libName,
                        placeholder: 'e.g.: LibraryName1 [ a-z, A-Z, 0-9, _ ]',
                        pattern: '([a-zA-Z0-9_]){1,64}',
                        required: true
                    },
                    trapFocus: true,
                    onConfirm: self.makeNewLibrary
                });
            },
            async makeNewLibrary(libName) {
                try {
                    await newLibrary(this, { name: libName });
                    await this.$router.push(getLibraryLink(libName));
                } catch (error) {
                    this.showError(error);
                    this.newLibrary(libName);
                }
            }
        }
    };
</script>

<style>
</style>
