<template>
    <span>
        <button
            v-show="!toggle"
            :class="'button ' + color"
            :disabled="disabled"
            @click="$emit('toggle-button')">
            <b-icon
                :icon="icon"
                size="is-small" />
            <span>{{ standardText }}</span>
        </button>
        <button
            v-show="toggle"
            class="button"
            :disabled="disabled"
            @click="$emit('toggle-button-cancel')">
            <b-icon
                icon="close-circle"
                size="is-small" />
            <span>{{ altText }}</span>
        </button>
    </span>
</template>

<script>
    export default {
        props: {
            color: {
                type: String,
                required: false,
                default: 'is-primary'
            },
            icon: {
                type: String,
                required: false,
                default: 'plus'
            },
            toggle: {
                type: Boolean,
                required: true
            },
            standardText: {
                type: String,
                required: true
            },
            altText: {
                type: String,
                required: false,
                default: 'Cancel'
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        emits: [
            'toggle-button',
            'toggle-button-cancel'
        ]
    };
</script>

<style>

</style>
