<template>
    <div
        class="box">
        <h2
            class="title is-5">
            Introduction
        </h2>
        <div>
            <!-- CSS NOTE: class "content" makes rendering of multiple newlines work -->
            <vue-markdown
                class="content"
                :source="introduction" />
        </div>
    </div>
</template>

<script>
    import { getTagMetadata } from '../helpers/api';
    import VueMarkdown from '../components/VueMarkdown.js';

    export default {
        components: {
            VueMarkdown
        },
        props: {
            tag: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                introduction: '..'
            };
        },
        async mounted() {
            const res = await getTagMetadata(this, this.tag);
            this.introduction = res.Introduction;
        }
    };
</script>
