<template>
    <div>
        <div class="is-flex is-gap-10 mb-2">
            <b-button
                :disabled="expandedRows.length === 0"
                title="Collapses all expanded rows"
                icon-left="unfold-less-horizontal"
                @click="onClickCollapseAll">
                Collapse all
            </b-button>

            <div class="is-separator" />

            <b-button
                v-require-can-edit-code="{ libraryName: 'ProjectMaster' }"
                class="is-primary"
                @click="newProjectMaster">
                <b-icon
                    icon="pencil"
                    size="is-small" />
                <span>Create new</span>
            </b-button>
        </div>

        <span
            id="newPMEditor">
            <div
                v-if="showNewPMEditor">
                <code-edit
                    :key="newPMEditorKey"
                    :quick-commit="true"
                    :show-cancel="true"
                    :code-set-name="projectMasterCodeSetName"
                    library="ProjectMaster"
                    :code-template-values="newProjectMasterTemplate"
                    :before-save-code="beforeNewProjectMaster"
                    :fields-to-disable="['GUID', 'CLID', 'CVPID']"
                    @refresh="onSaveNew"
                    @cancel="cancelSaveNew" />
                <hr>
            </div>
        </span>
        <b-table
            :data="filteredProjectMasterList"
            default-sort="Name"
            detailed
            :opened-detailed="expandedRows"
            detail-key="Name"
            :striped="true"
            :narrowed="true"
            :hoverable="true"
            class="site-sticky-header"
            @update:opened-detailed="expandedRows = $event">
            <b-table-column
                field="Name"
                label="Name"
                searchable
                sortable>
                <template #searchable>
                    <b-input
                        v-model="filters.text"
                        class="name-filter"
                        type="text"
                        placeholder="Filter on name"
                        expanded
                        icon="search"
                        icon-pack="fa" />
                </template>
                <template #default="props">
                    <router-link :to="getProjectMasterLink(props.row)">
                        {{ props.row.Name }}
                    </router-link>
                </template>
            </b-table-column>
            <b-table-column
                field="ProjectState"
                label="State"
                searchable
                sortable>
                <template #searchable>
                    <b-select v-model="filters.state">
                        <option value="">
                            All states
                        </option>
                        <option
                            v-for="x in projectStateList"
                            :key="x.identity"
                            :value="x.identity">
                            {{ x.name }}
                        </option>
                    </b-select>
                </template>
                <template #default="props">
                    {{ props.row.ProjectState }}
                </template>
            </b-table-column>
            <b-table-column
                field="ProjectCategory"
                label="Category"
                searchable
                sortable>
                <template #searchable>
                    <b-select v-model="filters.category">
                        <option value="">
                            All categories
                        </option>
                        <option
                            v-for="x in projectCategoryList"
                            :key="x.identity"
                            :value="x.identity">
                            {{ x.name }}
                        </option>
                    </b-select>
                </template>
                <template #default="props">
                    {{ props.row.ProjectCategory }}
                </template>
            </b-table-column>
            <b-table-column
                field="PortfolioOrganizationalUnit"
                label="Portfolio Org. unit"
                searchable
                sortable>
                <template #searchable>
                    <b-select v-model="filters.orgUnit">
                        <option value="">
                            All org. units
                        </option>
                        <option
                            v-for="x in orgUnitList"
                            :key="x.identity"
                            :value="x.identity">
                            {{ x.name }}
                        </option>
                    </b-select>
                </template>
                <template #default="props">
                    {{ props.row.PortfolioOrganizationalUnit }}
                </template>
            </b-table-column>
            <b-table-column
                field="Country"
                label="Country"
                searchable
                sortable>
                <template #searchable>
                    <b-select v-model="filters.country">
                        <option value="">
                            All countries
                        </option>
                        <option
                            v-for="x in countryList"
                            :key="x.identity"
                            :value="x.identity">
                            {{ x.name }}
                        </option>
                    </b-select>
                </template>
                <template #default="props">
                    {{ props.row.Country }}
                </template>
            </b-table-column>
            <template
                #detail="props">
                <div class="field is-grouped is-grouped-multiline">
                    <template
                        v-for="[key, value] in Object.entries(props.row)"
                        :key="key">
                        <div
                            v-if="value != null && includeInExpandView(key)"
                            class="control">
                            <div
                                class="tags has-addons">
                                <span class="tag is-light">{{ key }}</span>
                                <span class="tag is-white">{{ value }}</span>
                            </div>
                        </div>
                    </template>
                </div>
            </template>
        </b-table>
        <b-loading
            v-model="loading"
            :is-full-page="false"
            :can-cancel="false" />
    </div>
</template>

<script>
    import CodeEdit from '@/shared/components/CodeEdit.vue';
    import { requireCanEditCode } from '@/shared/directives/requirePermission';
    import { genericViewQueryAsText, getCodeSets, sqlQuery } from '@/shared/helpers/api';
    import { encodeIdBase64 } from '@/shared/helpers/utils';
    import _ from 'lodash';
    import { http } from '@/shared/httpWrapper.js';

    export default {
        directives: {
            'require-can-edit-code': requireCanEditCode
        },
        components: {
            CodeEdit
        },
        data() {
            return {
                loading: true,
                projectMasterList: [],
                projectCategoryList: [],
                orgUnitList: [],
                projectStateList: [],
                countryList: [],
                projectMasterCodeSetName: null,
                showNewPMEditor: false,
                newPMEditorKey: 1,
                filters: {
                    category: '',
                    orgUnit: '',
                    state: 'ACTIVE',
                    country: '',
                    text: ''
                },
                expandedRows: [],
                newProjectMasterTemplate: {
                    GUID() {
                        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
                            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
                        ).toUpperCase();
                    },
                    CLID: 'Autofilled on creation',
                    CVPID: 'Autofilled on creation'
                }
            };
        },
        computed: {
            filteredProjectMasterList() {
                const filters = this.filters;
                let list = this.projectMasterList;
                if (filters.state.length > 0) {
                    list = list.filter(p => p.ProjectState === filters.state);
                }
                if (filters.orgUnit.length > 0) {
                    list = list.filter(p => p.PortfolioOrgUnit === filters.orgUnit);
                }
                if (filters.category.length > 0) {
                    list = list.filter(p => p.ProjectCategory === filters.category);
                }
                if (filters.country.length > 0) {
                    list = list.filter(p => p.Country === filters.country);
                }
                if (filters.text.length > 0) {
                    const parts = filters.text.toLowerCase().split(' ');
                    list = list.filter(function(x) {
                        const tmp = (x.Name + x.Description).toLowerCase();
                        return _.every(parts, function(p) {
                            return tmp.indexOf(p) > -1;
                        });
                    });
                }
                list = list.filter(x => x.isValid !== false);
                return list;
            }
        },
        watch: {
            projectMasterCodeSetName() {
                this.newPMEditorKey += 1;
            }
        },
        async mounted() {
            await this.loadData();
        },
        methods: {
            async loadData() {
                this.loading = true;

                const [csReq, orgUnitReq, projCatReq, projStateReq, countryReq, pmReq] = await Promise.all([
                    getCodeSets(this, 'ProjectMaster'),
                    genericViewQueryAsText(this, 'FROM OrganizationalUnit SELECT Name as name, Identity as identity'),
                    genericViewQueryAsText(this, 'FROM ProjectCategory SELECT Name as name, Identity as identity'),
                    genericViewQueryAsText(this, 'FROM ProjectState SELECT Name as name, Identity as identity'),
                    genericViewQueryAsText(this, 'FROM Country SELECT Name as name, Identity as identity'),
                    genericViewQueryAsText(this, 'FROM ProjectMaster WHERE IsValid=true')
                ]);

                this.projectMasterCodeSetName = csReq[0].name;
                this.projectMasterList = pmReq.data;

                const self = this;
                const sortByName = xs => _.sortBy(xs, x => x.name);
                const prepFilterList = function(req, attributeName) {
                    const filterCodes = req.data;
                    return sortByName(filterCodes.filter(x => _.some(self.projectMasterList, p => p[attributeName] === x.identity)));
                };

                this.orgUnitList = prepFilterList(orgUnitReq, 'PortfolioOrganizationalUnit');
                this.countryList = prepFilterList(countryReq, 'Country');
                this.projectCategoryList = prepFilterList(projCatReq, 'ProjectCategory');
                this.projectStateList = prepFilterList(projStateReq, 'ProjectState');

                this.loading = false;
            },
            async beforeNewProjectMaster(code) {
                const [prevCLID, prevCVPID] = await Promise.all([
                    sqlQuery(http, {
                        query: 'SELECT TOP (1) [CLID]'
                            + '  FROM ProjectMaster'
                            + '  WHERE CLID LIKE \'[0-9][0-9][0-9][0-9][0-9][0-9]\''
                            + '  ORDER BY CLID DESC'
                    }),
                    sqlQuery(http, {
                        query: 'SELECT TOP (1) [CVPID]'
                            + '  FROM ProjectMaster'
                            + '  WHERE CVPID LIKE \'N[0-9][0-9][0-9][0-9]\''
                            + '  ORDER BY CVPID DESC'
                    })
                ]);
                code['CLID'].value = String(Number(prevCLID.data[0].CLID) + 1).padStart(6, '0');
                code['CVPID'].value = 'N' + String(Number(prevCVPID.data[0].CVPID.substring(1, 5)) + 1).padStart(4, '0');
            },
            includeInExpandView(key) {
                switch (key) {
                    case 'Id':
                    case 'Name':
                    case 'IsValid':
                    case 'Description':
                    case 'Identity':
                    case 'ProjectState':
                    case 'PortfolioOrganizationalUnit':
                    case 'ProjectCategory':
                    case 'Country':
                        return false;
                    default:
                        return !key.endsWith('_ID');
                }
            },
            onClickCollapseAll() {
                this.expandedRows = [];
            },
            getProjectMasterLink(c) {
                return { name: 'FacilityAndProjectById', params: { id: encodeIdBase64('Code', c.Id) } };
            },
            newProjectMaster() {
                this.showNewPMEditor = true;
            },
            onSaveNew() {
                // TODO: Only reload ProjectMaster list
                window.location.reload();
            },
            cancelSaveNew() {
                this.showNewPMEditor = false;
            }
        }
    };
</script>

<style scoped>
:deep(span):has(>.name-filter) {
    width: 100%;
}
.is-separator {
    border-left: 1px solid #eeeeee;
    height: auto;
}
</style>
