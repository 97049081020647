<template>
    <div>
        <code-edit-save-progress
            :save-process-started="releaseLoading"
            :release-id="releaseId"
            :external-error="releaseError"
            :is-release-chain="true"
            :close-enabled="false"
            @success="onSaveSuccess" />

        <spinner :loading="loading">
            <div
                v-if="!loading"
                class="columns is-multiline">
                <div class="column is-full">
                    <nav class="level">
                        <div class="level-left">
                            <div class="level-item">
                                <p class="subtitle is-4">
                                    <strong>Changes done:</strong>
                                </p>
                            </div>
                        </div>

                        <!-- Right side -->
                        <div class="level-right">
                            <p class="level-item">
                                <a
                                    class="button"
                                    @click="$emit('goBack')">
                                    <span class="icon is-small">
                                        <i
                                            class="fa fa-arrow-left"
                                            aria-hidden="true" />
                                    </span>
                                    <span>
                                        Back
                                    </span>
                                </a>
                            </p>
                            <p class="level-item">
                                <a
                                    class="button is-primary"
                                    :class="{'is-loading': releaseLoading}"
                                    @click="release">
                                    <span class="icon is-small">
                                        <i
                                            class="fa fa-file"
                                            aria-hidden="true" />
                                    </span>
                                    <span>
                                        Create release for these changes
                                    </span>
                                </a>
                            </p>
                        </div>
                    </nav>
                </div>
                <div
                    v-for="(code, i) in cableCodes"
                    :key="i"
                    class="column is-10 is-offset-1 has-margin-top2 box">
                    <b-collapse
                        :model-value="cableCodes.length === 1"
                        aria-id="code">
                        <template #trigger="props">
                            <div
                                class="level"
                                aria-controls="code"
                                :aria-expanded="props.open">
                                <a>
                                    <b-icon
                                        size="is-large"
                                        :icon="props.open ? 'menu-up' : 'menu-down'" />
                                </a>
                                <cable-change-header
                                    v-for="lib in Object.keys(libraries)"
                                    :key="lib"
                                    :change-obj="changes[code]"
                                    :header="lib"
                                    :library="libraries[lib]"
                                    :cable-code="code" />
                            </div>
                        </template>
                        <hr>
                        <div
                            v-for="key in Object.keys(libraries)"
                            :key="key">
                            <div
                                v-if="changes[code][libraries[key]]"
                                class="box has-margin-top">
                                <p class="subtitle is-6">
                                    <strong>{{ key }}</strong>
                                </p>
                                <cable-code-changes-table
                                    :changes="changes[code][libraries[key]]"
                                    :table-name="key" />
                            </div>
                        </div>
                    </b-collapse>
                </div>
            </div>
        </spinner>
    </div>
</template>

<script>
    import { createReleaseChain } from '@/shared/helpers/api.ts';
    import CableCodeChangesTable from './CableCodeChangesTable.vue';
    import { transformToChangeDocCode } from '@/shared/helpers/dataHelpers.js';
    import { filterUnique } from '@/shared/helpers/utils.js';
    import CableChangeHeader from './CableChangeHeader.vue';
    import Spinner from '../../../shared/components/Spinner.vue';
    import { libNames } from '@/shared/helpers/cableEditHelpers.js';
    import { showMixin } from '@/shared/mixins/showMixin.js';
    import _ from 'lodash';
    import CodeEditSaveProgress from '@/shared/components/CodeEditSaveProgress.vue';
    import { mapStores } from 'pinia';
    import { useCodeEditStore } from '@/stores/codeEditStore.js';

    export default {
        components: {
            CodeEditSaveProgress,
            CableCodeChangesTable,
            CableChangeHeader,
            Spinner
        },
        mixins: [
            showMixin
        ],
        emits: [
            'goBack',
            'saveCompleted'
        ],
        data() {
            return {
                collapse: null,
                loading: true,
                cableCodes: [],
                libraries: {
                    'Cable Code': libNames.cableCode,
                    'Cable Type': libNames.cableCodeType,
                    'Sheath Colour': libNames.cableCodeSheeth,
                    'Specifications': libNames.cableSpec,
                    'Wire Colour': libNames.cableSpecWire
                },
                changes: {},
                releaseLoading: false,
                releaseId: null,
                releaseError: null
            };
        },
        computed: {
            ...mapStores(useCodeEditStore)
        },
        async mounted() {
            const self = this;
            self.getChanges();
            if (this.cableCodes.length < 1)
                this.$router.push('cableEdit');
            self.collapse = new Array(self.cableCodes.length).fill(false);
            self.loading = false;
        },
        methods: {
            getChanges() {
                Object.values(this.libraries).forEach(lib => {
                    const codes = this.codeEditStore.changes[lib];
                    if (codes && codes.length) {
                        if (lib === libNames.cableCode) {
                            this.initChangeStructure(codes, lib, 'identity');
                            this.addChangesForCableCode(codes, lib, 'identity');
                        } else if (lib === libNames.cableSpecWire) {
                            this.initChangeStructure(codes, lib, 'CableSpec');
                            this.addChangesForCableCode(codes, lib, 'CableSpec');
                        } else {
                            this.initChangeStructure(codes, lib, 'CableCode');
                            this.addChangesForCableCode(codes, lib, 'CableCode');
                        }
                    }
                    this.cableCodes = this.cableCodes.filter(filterUnique);
                });
            },
            initChangeStructure(codes, library, cableCodeField) {
                codes.forEach(code => {
                    let cableCodeId;
                    if (library === libNames.cableSpecWire)
                        cableCodeId = this.resolveSpecToCableCode(code.values[cableCodeField]);
                    else
                        cableCodeId = code.values[cableCodeField];

                    if (!this.changes[cableCodeId])
                        this.changes[cableCodeId] = {};
                    this.changes[cableCodeId][library] = {};
                    this.changes[cableCodeId][library].new = [];
                    this.changes[cableCodeId][library].changed = [];
                    this.changes[cableCodeId][library].original = [];
                    this.cableCodes.push(cableCodeId);
                });
            },
            addChangesForCableCode(codes, library, cableCodeField) {
                codes.forEach(code => {
                    let cableCodeId;
                    if (library === libNames.cableSpecWire)
                        cableCodeId = this.resolveSpecToCableCode(code.values[cableCodeField]);
                    else
                        cableCodeId = code.values[cableCodeField];
                    if (code.meta.isNew) {
                        this.changes[cableCodeId][library].new.push(code);
                    } else {
                        this.changes[cableCodeId][library].changed.push(code);
                        this.changes[cableCodeId][library].original.push(this.codeEditStore.originalCodes(library)
                            .find(c => c.values.identity === code.values.identity));
                    }
                });
            },
            resolveSpecToCableCode(cableSpecId) {
                const c = this.codeEditStore.originalCodes(libNames.cableSpec)
                    .concat(this.codeEditStore.changes[libNames.cableSpec])
                    .find(c => c.values.identity === cableSpecId);
                if (!c)
                    throw 'could not find cableSpec';
                else
                    return c.values.CableCode;
            },
            onSaveSuccess() {
                this.releaseLoading = false;
                this.showInfo('Changes saved!');
                this.$emit('saveCompleted');
            },
            async release() {
                this.releaseId = null;
                this.releaseError = null;
                this.releaseLoading = true;

                const store = this.codeEditStore;

                const libraryReleaseOrder = [
                    libNames.cableCrossSectionUnit,
                    libNames.cableCat,
                    libNames.cableScreen,
                    libNames.cableType,
                    libNames.cableSheathColor,
                    libNames.cableWireGroup,
                    libNames.cableWireColour,
                    libNames.cableSize,
                    libNames.cableCode,
                    libNames.cableCodeType,
                    libNames.cableCodeSheeth,
                    libNames.cableSpec,
                    libNames.cableSpecWire
                ];
                const getReleaseOrder = libraryName => libraryReleaseOrder.indexOf(libraryName);
                const librariesWithChangesNames = _.sortBy(Object.keys(store.changes), getReleaseOrder);

                const changeDocuments = [];
                for (const libraryName of librariesWithChangesNames) {
                    const codeSetName = store.codeSetNames[libraryName];
                    const codes = store.changes[libraryName].map(change => {
                        let originalCode;
                        if (change.meta.isNew) {
                            originalCode = null;
                        } else {
                            originalCode = store.codes[libraryName].find(code =>
                                code.values.identity === change.values.identity
                            );
                        }
                        return transformToChangeDocCode(change.values, originalCode);
                    });
                    if (codes.length > 0)
                        changeDocuments.push({ libraryName, codeSetName, codes });
                }

                try {
                    const response = await createReleaseChain(this, changeDocuments);
                    this.releaseId = response.data.releaseId;
                } catch (error) {
                    this.releaseError = error;
                } finally {
                    this.releaseLoading = false;
                }
            }
        }
    };
</script>
