<template>
    <nav
        style="position: relative"
        class="panel">
        <p class="panel-heading">
            Contracts
            <a
                v-if="canEdit"
                class="button is-pulled-right is-small"
                @click="$emit('go-to-contract')"><b-icon
                    icon="pencil"
                    size="is-small" /></a>
        </p>
        <div class="panel-block">
            <b-table
                v-if="referingCodes && referingCodes.data"
                class="headless-table"
                :data="referingCodes.data"
                default-sort="name"
                :striped="false"
                :narrowed="true"
                :hoverable="false">
                <b-table-column
                    v-slot="props"
                    field="name"
                    label="Name">
                    {{ props.row.Name }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="description"
                    label="Description">
                    {{ props.row.Description }}
                </b-table-column>
            </b-table>
        </div>
        <b-loading
            v-model="isSyncing"
            :is-full-page="false"
            :can-cancel="false" />
    </nav>
</template>

<script>
    import { genericViewQueryAsText } from '@/shared/helpers/api';

    export default {
        props: {
            codeId: {
                type: String,
                default: ''
            },
            canEdit: {
                type: Boolean
            }
        },
        emits: [
            'go-to-contract'
        ],
        data() {
            return {
                referingCodes: {},
                isSyncing: true
            };
        },
        async created() {
            this.referingCodes = await this.fetchData();
            this.isSyncing = false;
        },
        methods: {
            async refresh() {
                this.referingCodes = await this.fetchData();
            },
            async fetchData() {
                return await genericViewQueryAsText(
                    this,
                    `TAKE 1000
                    FROM Contract
                    SELECT
                        Name,
                        Description,
                        ProjectMaster as Project
                    WHERE
                        ProjectMaster = @projectMaster
                        AND IsValid = true`,
                    [{ name: '@projectMaster', value: this.codeId }]
                );
            }
        }
    };
</script>
