import { getCode } from '@/shared/helpers/api';
import axios from 'axios';
import { encodeIdBase64 } from '@/shared/helpers/utils';
import { useDisabledFeaturesStore } from '@/stores/disabledFeaturesStore.js';

export const roles = {
    IsAuthenticatedUser: 'IsAuthenticatedUser',
    IsAdministrator: 'IsAdministrator',
    CanEditRelease: 'CanEditRelease',
    CanEditLibrary: 'CanEditLibrary'
};

export const ensureLoggedIn = async (to, from, next) => {
    if (window.authService.isSignedIn()) {
        next();
    } else {
        await window.authService.signIn();
        next();
    }
};

export const requireAuthForRoutes = async (to, from, next) => {
    if (to.meta.requireAuth === false) {
        next();
    } else if (!to.meta.requireAuth) {
        const requiredPermissions = to.meta.permissions ?? [roles.IsAdministrator];
        const hasAccess = await window.authService.hasAnyPermission(requiredPermissions);

        if (hasAccess) {
            next();
        } else {
            next({ name: 'Unauthorized', params: { page: to.path } });
        }
    } else {
        next({ name: 'Unauthorized', params: { page: to.path } });
    }
};
export const rewriteTitle = (to, from, next) => {
    const defaultTitleExtension = ' - Common Library';
    if (to.meta?.title instanceof Function) {
        document.title = to.meta.title(to) + defaultTitleExtension;
    } else if (to.meta?.title) {
        document.title = to.meta.title + defaultTitleExtension;
    }
    next();
};

const oldHashRoutingPaths = [
    '',
    '/ChangeLog',
    '/Library',
    '/Query',
    '/Release',
    '/FacilityAndProject',
    '/CableEdit',
    '/Schema',
    '/UoM',
    '/EleComponentType',
    '/Config',
    '/Help',
    '/Report/Query'
];

const alternatives = oldHashRoutingPaths
    .map(s => s.replaceAll('/', '\\/'))
    .join('|');

const hashPattern = /\/?#/;
const pathPattern = new RegExp(`^(?:${alternatives})${hashPattern.source}`, 'i');

export function rewriteLegacyPath(path) {
    if (!pathPattern.test(path))
        return null;

    return path.replace(
        /(\/)?#(\/)?(\w*)/,
        (_fullMatch, leadingSlash, trailingSlash, path) => {
            if (path) {
                return '/' + path;
            } else if (leadingSlash || trailingSlash) {
                return '/';
            } else {
                return '';
            }
        }
    );
}

export const rewriteLegacyRouteUrls = (to, from, next) => {
    const destination = rewriteLegacyPath(to.fullPath);
    if (destination !== null) {
        next(destination);
    } else {
        next();
    }
};

/**
 * For all routes hitting the route names 'codeOld' we redirect to the correct code.
 * Redirecting in the 'routes' const is not supported with async calls, using a beforeEnter or beforeEach,
 * is the correct way to redirect if needing to do async operations.
 *
 * https://v3.router.vuejs.org/guide/essentials/redirect-and-alias.html#redirect
 * https://github.com/vuejs/vue-router/issues/2729
 *
 * @param to
 * @param from
 * @param next
 */
export const redirectOldCode = async (to, from, next) => {
    if (to.name !== 'codeOld') {
        next();
    } else {
        const codeIdentity = to.params.identity;
        const codeSetName = to.params.codeSetName;

        try {
            const code = await getCode({ $http: axios }, codeSetName, codeIdentity);
            if (code.id) {
                const encodedId = encodeIdBase64('Code', code.id);
                const destination = { name: 'code', params: { id: encodedId } };
                next(destination);
            } else {
                next('/');
            }
        } catch {
            next('/');
        }
    }
};

export const requireFeatureEnabled = async (to, from, next) => {
    if (to.name === 'DisabledFeature') {
        next();
    } else {
        const store = useDisabledFeaturesStore();
        const disabledInfo = await store.isRouteDisabled(to.name);
        if (disabledInfo) {
            next({
                name: 'DisabledFeature',
                query: { name: disabledInfo.name }
            });
        } else {
            next();
        }
    }
};
