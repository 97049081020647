<template>
    <div
        v-if="!loading">
        <code-set-table
            :interactive="!createMode"
            :code-set="codeSet"
            @rowcount="codeCount = $event"
            @export-cols="exportColumns = $event" />
    </div>
</template>

<script setup>
    import CodeSetTable from './CodeSetTable.vue';
    import { getLibraryFromCodeSet } from '@/shared/helpers/api';
    import { onMounted, ref } from 'vue';
    import { useRoute } from 'vue-router';
    import { http } from '@/shared/httpWrapper.js';

    const createMode = ref(false);
    const loading = ref(true);
    const codeCount = ref(0);
    const codeSet = ref(null);
    const exportColumns = ref([]);

    const route = useRoute();

    onMounted(async () => {
        const codeSetName = route.params.codeSetName;
        codeSet.value = await getLibraryFromCodeSet(http, codeSetName);
        document.title = codeSet.value.description;
        loading.value = false;
    });
</script>
