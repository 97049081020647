<template>
    <span
        class="icon button is-white"
        :class="[
            (urlIsFavourite ? 'fav-star-active' : 'fav-star-inactive'),
            (locked ? 'is-loading' : '')
        ]"
        :title="iconTooltip"
        @click="clickHandler()">
        <i class="fas fa-star is-small" />
    </span>
</template>

<script>
    import favouriteService from '../../shared/services/favouriteService';

    export default {
        data() {
            return {
                urlIsFavourite: false,
                favouriteId: undefined,
                locked: true
            };
        },
        computed: {
            url() {
                return window.location.pathname + window.location.hash;
            },
            title() {
                return window.document.title;
            },
            iconTooltip() {
                return this.urlIsFavourite ? 'Favourite page' : 'Make page favourite';
            }
        },
        watch: {
            async url() {
                await this.loadState();
            }
        },
        async mounted() {
            await this.loadState();
        },
        methods: {
            async loadState() {
                try {
                    this.locked = true;
                    [this.urlIsFavourite, this.favouriteId] = await favouriteService.lookup(this, this.url);
                } finally {
                    this.locked = false;
                }
            },
            async clickHandler() {
                if (this.locked) {
                    /* Operation already in progress, abort! */
                    return;
                }
                try {
                    this.locked = true;
                    if (this.urlIsFavourite) {
                        await favouriteService.remove(this, this.favouriteId);
                        this.favouriteId = undefined;
                        this.urlIsFavourite = false;
                        await document.headerMailbox.send('update-favourites');
                    } else {
                        const self = this;
                        this.$buefy.dialog.prompt({
                            message: 'Save favourite..',
                            inputAttrs: {
                                minlength: 1,
                                maxlength: 256,
                                required: true,
                                value: favouriteService.stripPostfix(self.title)
                            },
                            onConfirm: async value => {
                                await favouriteService.add(this, value, this.url);
                                await this.loadState();
                                await document.headerMailbox.send('update-favourites');
                            }
                        });
                    }
                } finally {
                    this.locked = false;
                }
            }
        }
    };
</script>

<style>
</style>
