<template>
    <div v-if="show">
        <h2 class="title is-2 section-header">
            Create STID code sets for facility..
        </h2>
        <p class="block">
            This command allows you to introduce a new Facility for existsing libraries.<br>
            Precondition: facility <code>IsForSTID</code> must be true.
        </p>
        <p class="block">
            New code sets will be created for any library that <code>IsForSTID</code>
            and <code>IsGrouped</code> is false
        </p>
        <p class="block">
            Facility relation will be added to existing code set for libraries
            that are <code>IsForSTID</code> and <code>IsGrouped<code> is true. code set is selected
                based on <code>STIDDB</code>. If there are no other facilities with the same <code>STIDDB</code>,
                new code sets will be created.
            </code></code>
        </p>
        <div class="block">
            <b-field label="Facility name">
                <b-input v-model="facilityName" />
            </b-field>
            <b-field>
                <b-switch v-model="dryRun">
                    Dry run (rollback transaction)
                </b-switch>
            </b-field>
        </div>
        <div class="buttons">
            <b-button
                type="is-primary"
                :disabled="!enabled"
                @click="run()">
                Run
            </b-button>
            <b-button
                :disabled="!enabled"
                @click="cancelCommand()">
                Cancel
            </b-button>
        </div>
    </div>
</template>

<script>
    import runMixin from './RunMixin.js';

    export default {
        mixins: [runMixin],
        data() {
            return {
                facilityName: '',
                dryRun: true
            };
        },
        methods: {
            async run() {
                await this.submitCommand([
                    'create-facilitysets',
                    this.facilityName,
                    this.dryRun ? 'dry-run' : ''
                ]);
            }
        }
    };
</script>
