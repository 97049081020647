import _ from 'lodash';
import moment from 'moment';

class FilterBuilder {
    constructor() {
        this.validators = {
            'int': x => !isNaN(x),
            'float': x => !isNaN(x),
            'date': x => moment(x).isValid(),
            'datetime': x => moment(x).isValid()
        };

        this.columnDefinitions = [];
        this.columns = [];
        this.rows = [];
        this.reset();
    }

    getOperators(attributeType) {
        let operators = ['=', '!=', 'is', 'is not'];
        if (['string', 'coderef'].includes(attributeType))
            operators.push('like');
        if (['int', 'float', 'date', 'datetime'].includes(attributeType))
            operators = operators.concat(['>', '<']);

        return operators;
    }

    withColumns(columnDefinitions) {
        this.columnDefinitions = columnDefinitions;
        this.columns = columnDefinitions.map(c => c.field);
        return this;
    }

    withRows(rows) {
        this.rows = rows;
        return this;
    }

    withAttribute(attribute) {
        this.attribute = attribute;

        const columnDef = this.columnDefinitions.find(c => c.field === attribute);

        this.attributeType = columnDef.type.toLowerCase();
        this.columnIndex = columnDef.valueIdx;

        this.choices = this.getOperators(this.attributeType);

        return this;
    }

    withOperator(operator) {
        this.operator = operator;

        this.choices = [];
        this.allowNew = true;

        if (['is', 'is not'].includes(this.operator)) {
            this.allowNew = false;
            this.choices = ['null'];

            return this;
        }

        if (this.attributeType === 'bool') {
            this.allowNew = false;
            this.choices = ['true', 'false'];
        } else if (['string', 'coderef'].includes(this.attributeType) && this.operator !== 'like') {
            switch (this.columnDefinitions[this.columnIndex].referenceDisplayMode) {
                case 'IDENTITY': {
                    this.choices = _.chain(this.rows)
                        .map(c => {
                            return {
                                value: c[this.columnIndex].value,
                                codeIdentity: c[this.columnIndex].value
                            };
                        })
                        .filter(c => c.value)
                        .uniq()
                        .value();
                    break;
                }
                case 'NAME': {
                    this.choices = _.chain(this.rows)
                        .map(c => {
                            return {
                                value: c[this.columnIndex].name,
                                codeIdentity: c[this.columnIndex].value
                            };
                        })
                        .filter(c => c.value)
                        .uniq()
                        .value();
                    break;
                }
                case 'DESCRIPTION': {
                    this.choices = _.chain(this.rows)
                        .map(c => {
                            return {
                                value: c[this.columnIndex].description,
                                codeIdentity: c[this.columnIndex].value
                            };
                        })
                        .filter(c => c.value)
                        .uniq()
                        .value();
                    break;
                }
                case 'NAMEANDDESCRIPTION': {
                    this.choices = _.chain(this.rows)
                        .map(c => {
                            return {
                                value: c[this.columnIndex].name + ' - ' + c[this.columnIndex].description,
                                codeIdentity: c[this.columnIndex].value
                            };
                        })
                        .filter(c => c.value)
                        .uniq()
                        .value();
                    break;
                }
                default: {
                    this.choices = _.chain(this.rows)
                        .map(c => {
                            return {
                                value: c[this.columnIndex].value,
                                codeIdentity: c[this.columnIndex].value
                            };
                        })
                        .filter(c => c.value)
                        .uniq()
                        .value();
                }
            }
        }

        return this;
    }

    withCriterion(criterion) {
        if (!this.validators[this.attributeType] || criterion === 'null' || this.validators[this.attributeType](criterion)) {
            this.filter = {
                type: 'query',
                value: [this.attribute, this.operator, criterion]
            };
            this.isValid = true;
        }

        return this;
    }

    withRegex(regex) {
        this.isValid = true;
        this.filter = {
            type: 'regex',
            value: regex
        };
    }

    reset() {
        this.choices = [];

        this.attributeType = null;
        this.attribute = null;
        this.operator = null;

        this.isValid = false;

        this.allowNew = false;
        this.filter = null;
    }
}

export default FilterBuilder;
