/**
 * @param {string} query
 * @param {string} title
 * @param {number} pageSize
 * @returns {URL}
 */
export function makeSqlUrl(query, title, pageSize) {
    const url = new URL(`${window.location.origin}/Sql/Result`);
    url.searchParams.set('query', query);
    if (title) {
        url.searchParams.set('title', title);
    }
    if (pageSize) {
        url.searchParams.set('pageSize', pageSize);
    }
    return url;
}
