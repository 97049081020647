<template>
    <div v-if="tableData.length">
        <b-table
            :striped="true"
            :hoverable="true"
            :bordered="true"
            class="scroll-horizontal"
            :data="tableData">
            <b-table-column
                v-slot="props"
                width="100"
                field="type"
                label="Change type">
                <b-icon
                    v-if="!props.row.meta.isNew"
                    type="is-danger"
                    class="fa fa-fw fa-pencil-alt" />
                <b-icon
                    v-if="props.row.meta.isNew"
                    type="is-danger"
                    class="fa fa-fw fa-plus-square" />
                {{ props.row.meta.isNew ? 'New' : 'Edited' }}
            </b-table-column>
            <b-table-column
                v-for="c in col[tableName]"
                v-slot="props"
                :key="c.field"
                :field="c.field"
                :label="c.label"
                :width="c.field === 'isValid' ? '5vw' : 'auto'">
                <div :class="{'red-text': isChanged(props.row, c.field)}">
                    <span v-if="c.field === 'isValid'">{{ capitalize(props.row.values[c.field].toString()) }}</span>
                    <span v-else>{{ c.value ? c.value(props.row) : props.row.values[c.field] }}</span>
                </div>
            </b-table-column>
        </b-table>
    </div>
</template>

<script>
    import BoolElement from '../../../shared/components/BoolElement.vue';
    import { libNames } from '@/shared/helpers/cableEditHelpers';
    import { capitalize } from '@/shared/helpers/dataHelpers';
    import { mapStores } from 'pinia';
    import { useCodeEditStore } from '@/stores/codeEditStore.js';

    export default {
        components: {
            BoolElement
        },
        props: {
            changes: {
                type: Object,
                required: true
            },
            tableName: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                libNames,
                col: {
                    'Cable Code': [
                        {
                            field: 'name',
                            label: 'Cable Code Name'
                        },
                        {
                            field: 'Screen',
                            label: 'Screen'
                        },
                        {
                            field: 'CableCategory',
                            label: 'Category'
                        },
                        {
                            field: 'description',
                            label: 'Description'
                        },
                        {
                            field: 'MudResistFlag',
                            label: 'Mud Resistant'
                        },
                        {
                            field: 'isValid',
                            label: 'Valid'
                        }
                    ],
                    'Cable Type': [
                        {
                            field: 'CableType',
                            label: 'Type'
                        },
                        {
                            field: 'isValid',
                            label: 'Valid'
                        }
                    ],
                    'Sheath Colour': [
                        {
                            field: 'SheathColour',
                            label: 'Colour'
                        },
                        {
                            field: 'isValid',
                            label: 'Valid'
                        }
                    ],
                    'Specifications': [
                        {
                            field: 'NumberOfGroups',
                            label: 'No. groups'
                        },
                        {
                            field: 'NumberOfWires',
                            label: 'No. conductor'
                        },
                        {
                            field: 'BraidCrossSection',
                            label: 'Braiding mm2'
                        },
                        {
                            field: 'CrossSectionSize',
                            label: 'Cross-s'
                        },
                        {
                            field: 'GroundFlag',
                            label: 'Grounding'
                        },
                        {
                            field: 'GroundFlagValue',
                            label: 'Ground Flag Value'
                        },
                        {
                            field: 'Cladding',
                            label: 'Cladding'
                        },
                        {
                            field: 'OuterDiameter',
                            label: 'Out. diam'
                        },
                        {
                            field: 'InnerDiameter',
                            label: 'In. diam'
                        },
                        {
                            field: 'WeightPerMeter',
                            label: 'Weight'
                        },
                        {
                            field: 'BendRadius',
                            label: 'Bend Radius'
                        },
                        {
                            field: 'Impedance',
                            label: 'Impedance'
                        },
                        {
                            field: 'BlockWidth',
                            label: 'Block Width'
                        },
                        {
                            field: 'BlockDepth',
                            label: 'Block Depth'
                        },
                        {
                            field: 'CurrentRating',
                            label: 'Current (A)'
                        },
                        {
                            field: 'InductanceHz50',
                            label: 'Inductance 50Hz'
                        },
                        {
                            field: 'InductanceHz60',
                            label: 'Inductance 60Hz'
                        },
                        {
                            field: 'CapasitanceHz50',
                            label: 'Capasitance 50Hz'
                        },
                        {
                            field: 'CapasitanceHz60',
                            label: 'Capasitance 60Hz'
                        },
                        {
                            field: 'Resistance20Hz50',
                            label: 'Resistance 20V 50Hz'
                        },
                        {
                            field: 'Resistance20Hz60',
                            label: 'Resistance 20V 60Hz'
                        },
                        {
                            field: 'Resistance80Hz50',
                            label: 'Resistance 80V 50Hz'
                        },
                        {
                            field: 'Resistance80Hz60',
                            label: 'Resistance 80V 60Hz'
                        },
                        {
                            field: 'ShortCirc1',
                            label: 'Short Circ 1 '
                        },
                        {
                            field: 'ShortCirc2',
                            label: 'Short Circ 2'
                        },
                        {
                            field: 'isValid',
                            label: 'Valid'
                        }
                    ],
                    'Wire Colour': [
                        {
                            field: 'CableSpec',
                            label: 'For Specification (ID)'
                        },
                        {
                            field: 'WireGroup',
                            label: 'Wire Group',
                            sortDirection: 'asc',
                            sortPriority: 1
                        },
                        {
                            field: 'WireNo',
                            label: 'Wire Number',
                            sortDirection: 'asc',
                            sortPriority: 2
                        },
                        {
                            field: 'WireColour',
                            label: 'Wire Colour'
                        },
                        {
                            field: 'isValid',
                            label: 'Valid'
                        }
                    ]
                }
            };
        },
        computed: {
            ...mapStores(useCodeEditStore),
            tableData() {
                if (this.changes) {
                    let data = [];
                    if (this.changes.new.length) {
                        data = data.concat(this.changes.new);
                    }
                    if (this.changes.original.length) {
                        data = data.concat(this.changes.changed);
                    }
                    this.sortData(data);
                    return data;
                } else {
                    return [];
                }
            }
        },
        methods: {
            capitalize,
            isChanged(code, field) {
                if (code.meta.isNew)
                    return true;
                return !(code.values[field] === this.getOldVal(code, field));
            },
            getOldVal(code, field) {
                return this.changes.original.find(c => c.values.identity === code.values.identity).values[field];
            },
            sortData(data) {
                const sortFields = this.col[this.tableName].filter(item => item['sortPriority'] !== undefined);
                sortFields.sort((a, b) => a.sortPriority - b.sortPriority);

                data.sort((a, b) => {
                    for (const sortField of sortFields) {
                        const { field, sortDirection } = sortField;
                        let comparison = 0;
                        if (a.values[field] < b.values[field]) {
                            comparison = sortDirection === 'asc' ? -1 : 1;
                        } else if (a.values[field] > b.values[field]) {
                            comparison = sortDirection === 'asc' ? 1 : -1;
                        }
                        if (comparison !== 0) {
                            return comparison;
                        }
                    }
                    return 0;
                });
            }
        }
    };
</script>

<style scoped>
.scroll-horizontal {
    width: 100%;
    overflow: auto;
}

.red-text {
    color: red;
}
</style>
