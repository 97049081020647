<template>
    <div class="wrapper">
        <b-field>
            <router-link :to="getSchemaLink(identity)">
                &laquo; Back to schema
            </router-link>
        </b-field>
        <h1 class="title">
            Edit interfaces in Schema: {{ identity }}
        </h1>
        <div class="columns">
            <div class="column is-one-third">
                <h5
                    class="subtitle is-5 column-header-light">
                    Interfaces
                </h5>
                <div
                    v-require-can-edit-code="{ libraryName: 'SchemaInterface' }"
                    class="field is-grouped"
                    style="">
                    <div class="control buttons">
                        <b-button
                            type="is-primary"
                            @click="createNewInterface">
                            New
                        </b-button>
                        <b-tooltip
                            label="SchemaInterface library is configured locked for deletions"
                            position="is-right"
                            :active="interfaceListLockedForDelete">
                            <b-button
                                type="is-danger"
                                :disabled="interfaceListLockedForDelete || !selectedInterface"
                                @click="confirmDeleteCode(selectedInterface.Identity)">
                                <b-icon
                                    icon="skull"
                                    size="is-small" />
                                <span>Delete</span>
                            </b-button>
                        </b-tooltip>
                    </div>
                </div>
                <div class="table-overflow">
                    <b-table
                        :columns="[{field: 'Name'}]"
                        :hoverable="true"
                        :selected="selectedInterface"
                        :data="interfaces"
                        class="cursorPointer"
                        @click="clickSelectInterface" />
                </div>
            </div>
            <div class="column">
                <h5
                    class="subtitle is-5 column-header-light">
                    Properties
                </h5>
                <code-edit
                    v-if="selectedInterface !== null"
                    :key="codeEditUpdateKey"
                    :quick-commit="true"
                    library="SchemaInterface"
                    :code-id="interfaceIdEncoded" />
                <code-edit
                    v-else-if="newInterfaceEditActive"
                    library="SchemaInterface"
                    :code-set-name="interfaceCodeSet.name"
                    :code-template-values="{'Schema': identity}"
                    submit-button-label="Save new interface"
                    @refresh="afterInterfaceCreated"
                    @cancel="newInterfaceEditActive = false" />
            </div>
        </div>
    </div>
</template>

<script>
    import CodeEdit from '@/shared/components/CodeEdit.vue';
    import { requireCanEditCode } from '@/shared/directives/requirePermission';
    import { deleteCode, genericViewQueryAsText, getCodeSets } from '@/shared/helpers/api';
    import { encodeIdBase64 } from '@/shared/helpers/utils';
    import { showMixin } from '@/shared/mixins/showMixin';
    import _ from 'lodash';
    import { getSchemaLink } from '@/apps/schema/schemaHelper.js';
    import { useSchemaEditStore } from '@/stores/schemaEditStore.js';

    export default {
        directives: {
            'require-can-edit-code': requireCanEditCode
        },
        components: {
            CodeEdit
        },
        mixins: [
            showMixin
        ],
        props: {
            identity: {
                default: null,
                type: String
            }
        },
        data() {
            return {
                interfaces: [],
                selectedInterface: null,
                interfaceListLockedForDelete: true,
                interfaceCodeSet: null,
                codeEditUpdateKey: 0,
                newInterfaceEditActive: false,
                schemaEditorStore: useSchemaEditStore()
            };
        },
        computed: {
            interfaceIdEncoded() {
                if (this.selectedInterface) {
                    return encodeIdBase64('Code', this.selectedInterface.Id);
                }
                return null;
            }
        },
        async mounted() {
            await this.loadInterfaces();
            const codeSet = (await getCodeSets(this, 'SchemaInterface'))[0];
            this.interfaceListLockedForDelete = codeSet.lockedForDelete;
            this.interfaceCodeSet = codeSet;

            if (this.schemaEditorStore.interfaceId) {
                const interfaceId = this.schemaEditorStore.popInterfaceId();
                const interfaceObject = this.interfaces.find(i => i.Id === interfaceId);
                if (interfaceObject) {
                    this.selectedInterface = interfaceObject;
                }
            }
        },
        methods: {
            getSchemaLink,
            async loadInterfaces() {
                this.interfaces = (await genericViewQueryAsText(
                    this,
                    'FROM SchemaInterface WHERE IsValid = true and Schema = @Schema',
                    [{ name: '@Schema', value: this.identity }])).data.sort((a, b) => a.Name.localeCompare(b.Name));
            },
            clickSelectInterface(obj) {
                this.selectedInterface = obj;
                this.codeEditUpdateKey++;
            },
            confirmDeleteCode(codeIdentity) {
                const self = this;
                this.$buefy.dialog.confirm({
                    title: 'Please confirm delete',
                    message: 'Deleting items may cause issues if the item is known externally to Common Library, and should normally only be done during initial setup of libraries. Continue to delete item?',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: async () => {
                        await this.deleteSchemaInterfaceCode(self, codeIdentity);
                    }
                });
            },
            async deleteSchemaInterfaceCode(self, codeIdentity) {
                try {
                    await deleteCode(self, this.interfaceCodeSet.name, codeIdentity);
                    await this.loadInterfaces();
                    this.selectedInterface = null;
                } catch (error) {
                    this.showError(_.get(error, 'response.data.Message', 'Unable to delete item'));
                }
            },
            createNewInterface() {
                this.newInterfaceEditActive = true;
                this.selectedInterface = null;
            },
            async afterInterfaceCreated(codes) {
                await this.loadInterfaces();
                this.newInterfaceEditActive = false;
                this.selectedInterface = this.interfaces.find(i => i.Name === codes[0].Name);
            }
        }

    };
</script>

<style scoped>
.wrapper{
    min-height: 900px;
    height: 80vh;
}
.cursorPointer{
    cursor: pointer;
}
.table-overflow{
    overflow: auto;
    height: 70vh;
}
</style>
