<template>
    <b-modal
        :model-value="isVisible"
        :can-cancel="false"
        has-modal-card>
        <div
            class="modal-card"
            style="height: auto; width: auto;">
            <header
                class="modal-card-head">
                <div class="modal-card-title">
                    Processing changes, please wait..
                </div>
            </header>
            <section class="modal-card-body">
                <b-progress
                    v-show="!isFailure && !isReleaseEmpty"
                    size="is-small" />
                <div
                    v-if="isReleaseChain"
                    class="block">
                    <p class="block">
                        This is a release chain publishing changes to multiple libraries.
                    </p>
                    <code-edit-save-progress-state-visualizer
                        v-for="subRelease in subReleases"
                        :key="subRelease.releaseId"
                        :library-name="subRelease.library"
                        :state="subRelease.state" />
                </div>
                <code-edit-save-progress-state-visualizer
                    v-else
                    :state="releaseState" />

                <p
                    v-show="!isFailure && !isReleaseEmpty"
                    class="block">
                    <strong>This window will close</strong> once your changes have been <strong style="color:#fd5d00;">verified</strong> and <strong style="color:green;">committed</strong>.
                    This may depend on approval from external systems.
                </p>

                <b-notification
                    v-if="isFailure"
                    type="is-danger"
                    has-icon
                    :closable="false"
                    role="alert">
                    Saving your changes seem to have failed!<br>
                    Please <router-link
                        :to="getReleaseLink(releaseId)">
                        <span>open the release page</span>
                    </router-link> to examine what went wrong.
                </b-notification>

                <b-notification
                    v-show="externalError"
                    type="is-danger"
                    has-icon
                    :closable="false"
                    role="alert">
                    Unable to save changes!<br>
                    {{ externalErrorMessage }}
                </b-notification>

                <b-notification
                    v-show="isReleaseEmpty"
                    type="is-info"
                    has-icon
                    :closable="false"
                    role="alert">
                    No changes to commit!
                </b-notification>
            </section>
            <footer class="modal-card-foot is-justify-content-end">
                <b-button
                    v-if="releaseId"
                    tag="router-link"
                    icon-left="link-variant"
                    label="Go to Release"
                    :to="getReleaseLink(releaseId)" />
                <b-button
                    v-if="closeEnabled || externalError || isDiscarded"
                    icon-left="close-circle"
                    label="Close"
                    @click="closeModal" />
            </footer>
        </div>
    </b-modal>
</template>

<script>
    import * as signalR from '@microsoft/signalr';
    import CodeEditSaveProgressStateVisualizer from './CodeEditSaveProgressStateVisualizer.vue';
    import { getReleaseLink } from '../helpers/routing';
    import { getRelease } from '../helpers/api';
    import { showMixin } from '../mixins/showMixin';
    export default {
        components: {
            CodeEditSaveProgressStateVisualizer
        },
        mixins: [
            showMixin
        ],
        props: {
            saveProcessStarted: {
                type: Boolean,
                default: false
            },
            releaseId: {
                type: Number,
                default: null
            },
            externalError: {
                type: Error,
                default: null
            },
            isReleaseChain: {
                type: Boolean,
                default: false
            },
            closeEnabled: {
                type: Boolean,
                default: true
            },
            isReleaseEmpty: {
                type: Boolean,
                default: false
            }
        },
        emits: [
            'success',
            'close'
        ],
        data() {
            return {
                connectionLost: false,
                dialogClosed: false,
                releaseState: null,
                isVisible: false,
                externalErrorMessage: '',
                subReleases: []
            };
        },
        computed: {
            isDone() {
                if (this.isReleaseChain) {
                    return this.releaseState === 'Published';
                } else {
                    return ['Committed', 'Published', 'Publishing'].includes(this.releaseState);
                }
            },
            isFailure() {
                // An AutoForward job is not supposed to reach the Open state
                // unless a transition fails.
                if (this.isReleaseChain) {
                    return this.subReleases.filter(r => r.state === 'Open').length > 0;
                } else {
                    return this.releaseState === 'Open';
                }
            },
            isDiscarded() {
                if (this.isReleaseChain) {
                    return this.subReleases.filter(r => r.state === 'Discarded').length > 0;
                } else {
                    return this.releaseState === 'Discarded';
                }
            }
        },
        watch: {
            saveProcessStarted(value) {
                // New process started
                if (value) {
                    this.releaseState = null;
                    this.subReleases = [];
                    this.externalErrorMessage = '';
                    this.dialogClosed = false;
                }
                this.setIsVisible();
            },
            dialogClosed() {
                this.setIsVisible();
            },
            async releaseId(value) {
                if (value) {
                    this.setIsVisible();
                    // Have to load release now, as events might already have happened
                    await this.loadRelease();
                }
            },
            isDone(value) {
                if (value) {
                    this.signalSuccess();
                }
            },
            externalError(value) {
                if (value) {
                    this.showError(value, extractedMessage => {
                        this.externalErrorMessage = extractedMessage;
                    });
                }
            },
            async isVisible() {
                if (this.isVisible) {
                    this.connection = new signalR.HubConnectionBuilder()
                        .withUrl(window.config.signalRUrlMyReleases, {
                            accessTokenFactory: async () => await window.authService.acquireToken()
                        })
                        .configureLogging(signalR.LogLevel.Error)
                        .build();
                    this.connectionLost = false;
                    try {
                        this.connection.on('change', id => this.onReleaseChanged(id));
                        this.connection.onclose(() => {
                            this.connectionLost = true;
                        });
                        await this.connection.start();
                    } catch {
                        this.connectionLost = true;
                    }
                    if (this.releaseId) {
                        await this.loadRelease();
                    }
                } else {
                    await this.connection.stop();
                }
            }
        },
        beforeUnmount() {
            this.connection?.stop();
        },
        methods: {
            setIsVisible() {
                if (this.saveProcessStarted || this.isVisible) {
                    this.isVisible = !this.dialogClosed;
                }
            },
            async onReleaseChanged(value) {
                const id = parseInt(value);
                if (id !== this.releaseId)
                    return; // another release changed
                await this.loadRelease();
            },
            async loadRelease() {
                const release = await getRelease(this, this.releaseId);
                this.releaseState = release.state;
                if (release.specification.subReleases) {
                    this.subReleases = release.specification.subReleases;
                }
            },
            signalSuccess() {
                setTimeout(() => {
                    this.closeDialog();
                    this.$emit('success');
                }, 500);
            },
            closeModal() {
                this.closeDialog();
                this.$emit('close');
            },
            closeDialog() {
                this.dialogClosed = true;
            },
            getReleaseLink
        }
    };
</script>
