<template>
    <div>
        <div style="overflow: auto;">
            <h2 class="title is-3">
                Key Performance Indicators
            </h2>
            <template v-if="dataCounts">
                <div
                    v-for="box in kpiBoxes"
                    :key="box.heading"
                    class="box kpi-box">
                    <div><b>{{ box.heading }}</b></div>
                    <kpi-element
                        v-for="elm in box.elements"
                        :key="elm.label"
                        :label="elm.label"
                        :value="elm.value"
                        :category="box.category" />
                </div>
            </template>
        </div>
        <div>
            <h2 class="title is-3">
                Release stats
            </h2>
            <h3 class="subtitle">
                End state of releases - last 60 days
            </h3>
            <line-chart
                v-if="!loading"
                :data="chartData"
                style="max-width: 1500px; max-height: 500px;" />
        </div>
    </div>
</template>

<script>
    import release from '@/apps/release/Release.vue';
    import { getKpiDataCounts, getKpiReleaseStats } from '@/shared/helpers/api.ts';
    import { CategoryScale, Chart, Legend, LinearScale, LineElement, PointElement, Title, Tooltip } from 'chart.js';
    import _ from 'lodash';
    import { Line as LineChart } from 'vue-chartjs';
    import KpiElement from './KpiElement.vue';

    Chart.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );

    export default {
        components: {
            LineChart,
            KpiElement
        },
        data() {
            return {
                loading: true,
                dataCounts: null,
                releaseStats: null
            };
        },
        computed: {
            chartData() {
                const datasets = [];

                for (const serie in this.releaseStats.series) {
                    datasets.push({
                        label: serie,
                        fill: false,
                        data: this.releaseStats.series[serie],
                        borderColor: this.colorForStatus(serie)
                    });
                }
                return {
                    labels: this.releaseStats.labels,
                    datasets: datasets
                };
            },
            release() {
                return release;
            },
            kpiBoxes() {
                if (!this.dataCounts) return null;
                const boxData = [
                    {
                        heading: 'Scale indicators',
                        category: 'scale',
                        elements: [
                            {
                                label: 'Library groups',
                                value: this.dataCounts.tagCount
                            },
                            {
                                label: 'Libraries',
                                value: this.dataCounts.libraryCount
                            },
                            {
                                label: 'Library sets',
                                value: this.dataCounts.codeSetCount
                            },
                            {
                                label: 'Library items',
                                value: this.dataCounts.codeCount
                            },
                            {
                                label: 'Item attributes',
                                value: this.dataCounts.codeAttributeCount
                            },
                            {
                                label: 'Attachments',
                                value: this.dataCounts.attachmentCount
                            }
                        ]
                    },
                    {
                        heading: 'Usage indicators',
                        category: 'usage',
                        elements: [
                            {
                                label: 'Access groups',
                                value: this.dataCounts.accessGroupCount
                            },
                            {
                                label: 'Users last week',
                                value: this.dataCounts.usersLastWeek
                            },
                            {
                                label: 'Users last month',
                                value: this.dataCounts.usersLastMonth
                            },
                            {
                                label: 'Users last year',
                                value: this.dataCounts.usersLastYear
                            },
                            {
                                label: 'Users total',
                                value: this.dataCounts.userCount
                            }
                        ]
                    },
                    {
                        heading: 'Change & integration indicators',
                        category: 'integration',
                        elements: [
                            {
                                label: 'Subscriptions',
                                value: this.dataCounts.subscriptionCount
                            },
                            {
                                label: 'Webhooks',
                                value: this.dataCounts.webhookCount
                            },
                            {
                                label: 'Releases',
                                value: this.dataCounts.releaseCount
                            }
                        ]
                    },
                    {
                        heading: 'Other indicators',
                        elements: [
                            {
                                label: 'Aliases',
                                value: this.dataCounts.aliasCount
                            },
                            {
                                label: 'Scheduled jobs',
                                value: this.dataCounts.scheduleCount
                            }
                        ]
                    }
                ];

                if (this.releaseStats !== null) {
                    const releaseSumsElements = [];
                    for (const sum in this.releaseStats.sums) {
                        releaseSumsElements.push({
                            label: sum,
                            value: this.releaseStats.sums[sum]
                        });
                    }
                    boxData.push({
                        heading: 'Releases by state',
                        category: 'integration',
                        elements: _.sortBy(releaseSumsElements, x => x.value)
                    });
                }

                return boxData;
            }
        },
        async created() {
            const [kpiDataCount, kpiReleaseStat] = await Promise.all([
                await getKpiDataCounts(this),
                await getKpiReleaseStats(this, 60)
            ]);
            this.dataCounts = kpiDataCount;
            this.releaseStats = kpiReleaseStat;
            this.loading = false;
        },
        methods: {
            colorForStatus(status) {
                switch (status) {
                    case 'Open':
                        return 'rgb(255,215,0)';
                    case 'Verifying':
                        return 'rgb(255,255,0)';
                    case 'Committed':
                        return 'rgb(255,0,255)';
                    case 'Published':
                        return 'rgb(0,255,127)';
                    case 'Publishing':
                        return 'rgb(255,255,0)';
                    case 'Discarded':
                        return 'rgb(100,149,237)';
                    case 'Planned':
                        return 'rgb(255,0,0)';
                    case 'Creating':
                        return 'rgb(255,0,0)';
                    case 'Approved':
                        return 'rgb(255,0,0)';
                    case 'Committing':
                        return 'rgb(255,0,0)';
                    default:
                        return `rgb(${Math.round(Math.random() * 255)},${Math.round (Math.random () * 255)},${Math.round (Math.random () * 255)})`;
                }
            }
        }
    };
</script>

<style scoped>
.kpi-box {
    float: left;
    margin-right: 10px;
}
</style>
