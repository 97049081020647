<template>
    <div v-if="show">
        <h2 class="title is-2 section-header">
            Delete library..
        </h2>
        <div class="block">
            <b-field label="Library name">
                <b-input v-model="libraryName" />
            </b-field>
            <b-field>
                <b-switch v-model="dryRun">
                    Dry run (rollback transaction)
                </b-switch>
            </b-field>
        </div>
        <div class="buttons">
            <b-button
                type="is-primary"
                :disabled="!enabled"
                @click="run()">
                Run
            </b-button>
            <b-button
                :disabled="!enabled"
                @click="cancelCommand()">
                Cancel
            </b-button>
        </div>
    </div>
</template>

<script>
    import runMixin from './RunMixin.js';

    export default {
        mixins: [runMixin],
        data() {
            return {
                libraryName: '',
                dryRun: true
            };
        },
        methods: {
            async run() {
                await this.submitCommand([
                    'delete-library',
                    this.libraryName,
                    this.dryRun ? 'dry-run' : ''
                ]);
            }
        }
    };
</script>
