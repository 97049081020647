import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useSchemaEditStore = defineStore('schemaEdit', () => {
    const attributeId = ref(null);

    const classId = ref(null);
    const classInterfaceId = ref(null);
    const interfaceId = ref(null);

    const mapSchemaId = ref(null);
    const mapSchemaObject = ref(null);

    const relationId = ref(null);

    function setAttributeId(value) {
        attributeId.value = value;
    }

    function setClassId(id) {
        classId.value = id;
    }

    function setClassInterfaceId(id) {
        classInterfaceId.value = id;
    }

    function setRelationId(id) {
        relationId.value = id;
    }

    function setInterfaceId(id) {
        interfaceId.value = id;
    }

    function setMapSchemaId(id) {
        mapSchemaId.value = id;
    }

    function setMapSchemaObject(object) {
        mapSchemaObject.value = object;
    }

    function popAttributeId() {
        const value = attributeId.value;
        attributeId.value = null;
        return value;
    }

    function popClassId() {
        const value = classId.value;
        classId.value = null;
        return value;
    }

    function popClassInterfaceId() {
        const value = classInterfaceId.value;
        classInterfaceId.value = null;
        return value;
    }

    function popRelationId() {
        const value = relationId.value;
        relationId.value = null;
        return value;
    }

    function popInterfaceId() {
        const value = interfaceId.value;
        interfaceId.value = null;
        return value;
    }

    function popMapSchemaId() {
        const value = mapSchemaId.value;
        mapSchemaId.value = null;
        return value;
    }

    function popMapSchemaObject() {
        const object = mapSchemaObject.value;
        mapSchemaObject.value = null;
        return object;
    }

    return {
        attributeId,
        classId,
        classInterfaceId,
        interfaceId,
        mapSchemaId,
        mapSchemaObject,
        relationId,
        popAttributeId,
        popClassId,
        popClassInterfaceId,
        popInterfaceId,
        popMapSchemaId,
        popMapSchemaObject,
        popRelationId,
        setAttributeId,
        setClassId,
        setClassInterfaceId,
        setInterfaceId,
        setMapSchemaId,
        setMapSchemaObject,
        setRelationId
    };
});
