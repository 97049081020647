<template>
    <div>
        <div v-if="contract">
            <h1 class="subtitle">
                Contract
            </h1>
            <h1 class="title">
                {{ contract.Name }} - {{ contract.Description }}
            </h1>
            <router-link :to="{ name: 'ContractList' }">
                &laquo; Back to list
            </router-link>
            <hr>
            <span
                id="codeEditor">
                <div
                    v-if="showEditor">
                    <code-edit
                        :key="editorKey"
                        :quick-commit="true"
                        :show-cancel="true"
                        :code-set-id="editCodeSetId"
                        :code-id="editCodeId"
                        :code-template-values="codeTemplateValues"
                        :form-title="formTitle"
                        library="contract"
                        @refresh="onSave"
                        @cancel="cancelEditor" />
                    <hr>
                </div>
            </span>
            <div class="columns">
                <div class="column is-6">
                    <div class="panel">
                        <div class="panel-heading is-flex is-justify-content-space-between">
                            <p>
                                Contract attributes
                            </p>
                            <b-button
                                v-require-can-edit-code="{ libraryName: 'Contract' }"
                                class="is-primary"
                                @click="editContract">
                                <b-icon
                                    icon="pencil"
                                    size="is-small" />
                                <span>
                                    Edit
                                </span>
                            </b-button>
                        </div>
                        <div class="panel-block">
                            <b-table
                                class="headless-table"
                                :data="attributes"
                                :striped="false"
                                :narrowed="true"
                                :hoverable="false">
                                <b-table-column
                                    v-slot="props"
                                    field="name"
                                    label="Name">
                                    <b>{{ props.row.name }}</b>
                                </b-table-column>
                                <b-table-column
                                    v-slot="props"
                                    field="value"
                                    label="Value">
                                    {{ props.row.value }}
                                </b-table-column>
                            </b-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import CodeEdit from '@/shared/components/CodeEdit.vue';
    import { showMixin } from '@/shared/mixins/showMixin';
    import { getAttributeDefinitions, genericViewQueryAsText } from '@/shared/helpers/api';
    import { requireCanEditCode } from '@/shared/directives/requirePermission';
    import { decodeIdBase64 } from '@/shared/helpers/utils';
    import { mapResultToDisplaySingle } from '@/shared/helpers/queryResultToDisplay';

    export default {
        directives: {
            'require-can-edit-code': requireCanEditCode
        },
        components: {
            CodeEdit
        },
        mixins: [
            showMixin
        ],
        props: {
            id: {
                type: String,
                default: null
            }
        },

        data() {
            return {
                showEditor: false,
                formTitle: null,
                editorKey: 1,
                editCodeSetId: null,
                editCodeId: null,
                codeTemplateValues: null,
                attributeDefinitions: [],
                contract: null
            };
        },
        computed: {
            attributes() {
                return this.contract
                    ? mapResultToDisplaySingle(this.contract, this.attributeDefinitions, {
                        skipName: true,
                        skipDescription: true
                    })
                    : [];
            }
        },
        async mounted() {
            await Promise.all([
                this.loadAttributeDefinitions(),
                this.loadContract()]);
        },
        methods: {
            async loadAttributeDefinitions() {
                this.attributeDefinitions = _.sortBy(
                    await getAttributeDefinitions(this, 'contract'),
                    x => x.sequenceNumber);
            },
            async loadContract() {
                this.contract = (await genericViewQueryAsText(
                    this,
                    'FROM contract WHERE Id=@id',
                    [{ name: '@id', value: decodeIdBase64(this.id) }])).data[0];
            },
            editContract() {
                this.formTitle = null;
                this.editCodeSetId = null;
                this.editCodeId = this.id;
                this.editorKey += 1;
                this.showEditor = true;
            },
            async onSave() {
                this.showEditor = false;
                await this.loadContract();
            },
            cancelEditor() {
                this.showEditor = false;
            }
        }

    };

</script>
