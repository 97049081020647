<template>
    <div
        v-if="library && !library.isForeignObject">
        <div>
            <div class="has-margin-top">
                <div v-if="isScopedLibrary">
                    <b-field>
                        <p class="control">
                            <scope-filter-dropdown
                                :library="library"
                                scope-filter-position="is-bottom-right" />
                        </p>
                        <div class="control has-icons-left has-margin-bottom">
                            <input
                                v-model="urlSearchFilter"
                                type="text"
                                class="input"
                                placeholder="Filter by scope"
                                style="width: 60ch">
                            <span class="icon is-small is-left">
                                <i class="fa fa-search" />
                            </span>
                        </div>
                    </b-field>
                    <div v-if="selectedScopeFilter?.length">
                        <div>
                            Showing library sets for the following scopes:
                        </div>
                        <div
                            class="column is-half">
                            <tag-list
                                :tags="selectedScopeFilter"
                                :icon-class="'fa fa-trash'"
                                @click-tag="componentUpdateScopeFilter" />
                        </div>
                    </div>
                </div>
            </div>

            <b-table
                :data="filteredCodeSets"
                default-sort="description"
                :striped="true"
                :narrowed="false"
                :hoverable="true"
                class="site-sticky-header">
                <b-table-column
                    v-slot="props"
                    field="description"
                    label="Description"
                    sortable>
                    <router-link :to="getCodeSetLink(props.row.name)">
                        {{ props.row.description }}
                    </router-link>
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="scopes"
                    label="Scopes">
                    <div class="tags">
                        <span
                            v-for="scope in props.row.scopes.toSorted()"
                            :key="scope"
                            class="tag is-small is-success">
                            {{ scope }}
                        </span>
                    </div>
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="isValid"
                    label="Valid"
                    centered>
                    <bool-element :value="props.row.isValid" />
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="lockedForDelete"
                    label="Delete lock"
                    title="Locked for item delete operations"
                    centered>
                    <bool-element :value="props.row.lockedForDelete" />
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="isReleaseReady"
                    label="Release ready"
                    title="Is published to external systems"
                    centered>
                    <bool-element :value="props.row.isReleaseReady" />
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    label="Export"
                    centered>
                    <button
                        class="button is-small"
                        :class="{'is-loading': exportLoading === props.row.name}"
                        @click="exportCodeSet(props.row.name, props.row.description)">
                        <i class="fa fa-download" />
                    </button>
                </b-table-column>
            </b-table>

            <div
                v-show="filteredCodeSets.length === 1"
                class="click-here-container">
                <span style="font-size:3.5em">↑</span><br><span style="font-size:2.5em">Click above</span><br><span style="font-size:1.5em">to access library content</span>
            </div>
        </div>
    </div>
</template>

<script>

    import saveAs from 'file-saver';
    import TagList from '@/shared/components/TagList.vue';
    import ScopeFilterDropdown from '@/shared/components/ScopeFilterDropdown.vue';
    import BoolElement from '@/shared/components/BoolElement.vue';
    import { getCodeSetLink } from '@/shared/helpers/routing';
    import { excelExportCodeSet } from '@/shared/helpers/api';
    import { useScopeFilterStore } from '@/stores/scopeFilterStore.js';
    import { mapActions, mapState } from 'pinia';

    export default {
        components: {
            BoolElement,
            TagList,
            ScopeFilterDropdown
        },
        props: {
            library: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                exportLoading: '',
                urlSearchFilter: ''
            };
        },
        url: {
            urlScopeFilter: {
                param: 'ScopeFilter',
                noHistory: true
            },
            urlSearchFilter: {
                param: 'SearchFilter',
                noHistory: true
            }
        },
        computed: {
            ...mapState(useScopeFilterStore, ['selectedScopeFilter']),
            urlScopeFilter: {
                get() {
                    return this.selectedScopeFilter;
                },
                async set(value) {
                    if (this.isScopedLibrary) {
                        await this.setScopeType(this.library.scopeType);
                        await this.componentUpdateScopeFilter(value);
                    }
                }
            },
            isScopedLibrary() {
                return !this.library.isGlobal;
            },
            filteredCodeSets() {
                let filtered = this.library.codeSets;

                if (this.urlSearchFilter?.length) {
                    filtered = filtered.filter(
                        cs => this.urlSearchFilter === undefined
                            || cs.description.toLowerCase().includes(this.urlSearchFilter.toLowerCase()));
                }

                if (this.isScopedLibrary && this.selectedScopeFilter?.length) {
                    filtered = filtered.filter(l => l.scopes.some(t => this.selectedScopeFilter.includes(t)));
                }

                return filtered;
            }
        },
        methods: {
            async exportCodeSet(codeSetName, codeSetDescription) {
                this.exportLoading = codeSetName;
                this.codeFilter = this.codeFilter || { textFilter: '' };
                const requestFilter = { Regex: [], Queries: [] };
                const response = await excelExportCodeSet(this, codeSetName, requestFilter);

                if (response.data) {
                    const filename = codeSetDescription ? codeSetDescription.replace(/\s/g, '-') + '.xlsx' : 'export.xlsx';
                    const blob = new Blob([response.data], { type: response.headers['Content-Type'] });
                    saveAs(blob, filename);
                }

                this.exportLoading = '';
            },
            ...mapActions(useScopeFilterStore, ['updateScopeFilter', 'setScopeType']),
            async componentUpdateScopeFilter(scope) {
                await this.updateScopeFilter(scope);
            },
            getCodeSetLink
        }
    };
</script>

<style scoped>
.click-here-container {
    margin-top: 10px;
    margin-left: 30px;
    color: red;
    font-weight: bold;
}
</style>
