<template>
    <b-field>
        <div
            v-require-can-edit-code="{ libraryName: library.name, scopes: codeSet?.scopes }">
            <b-button
                v-if="library.specializedUI === 'Schema' && isAddItem"
                :label="buttonLabel"
                :icon-left="buttonIcon"
                :icon-right="'open-in-new'"
                :disabled="disabled"
                class="specialized-ui-button"
                type="is-primary"
                @click="showSchemaModal = !showSchemaModal" />
            <b-button
                v-else-if="library.specializedUI && !library.isGlobal && (isAddItem || isMultiScoped)"
                :label="buttonLabel"
                :icon-left="buttonIcon"
                :icon-right="'open-in-new'"
                :disabled="disabled"
                class="specialized-ui-button"
                type="is-primary"
                @click="onOpenScopeModal" />
            <b-button
                v-else-if="library.specializedUI"
                :label="buttonLabel"
                :icon-left="buttonIcon"
                :icon-right="'open-in-new'"
                :disabled="disabled"
                class="specialized-ui-button"
                type="is-primary"
                @click="reRouteToSpecializedUI" />
            <b-button
                v-else
                :label="buttonLabel"
                :icon-left="buttonIcon"
                :disabled="disabled"
                type="is-primary"
                @click="toggleEditMode" />
        </div>
        <div
            v-if="library.specializedUI"
            v-require-can-edit-library="library.name">
            <b-dropdown position="is-bottom-left">
                <template #trigger>
                    <b-button
                        type="is-primary"
                        icon-left="menu-down"
                        class="specialized-ui-button-dropdown"
                        :disabled="disabled" />
                </template>
                <b-dropdown-item @click="toggleEditMode">
                    {{ dropdownText }}
                </b-dropdown-item>
            </b-dropdown>
        </div>
    </b-field>
    <b-modal
        v-model="showSchemaModal"
        has-modal-card>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">
                    Select Schema
                </p>
            </header>
            <section class="modal-card-body ">
                <b-field
                    horizontal
                    label="Schema Name:">
                    <b-select
                        v-model="selectedSchema"
                        placeholder="Select Schema">
                        <option
                            v-for="schema in schemaList"
                            :key="schema.Identity"
                            :value="schema">
                            {{ schema.Name }}
                        </option>
                    </b-select>
                </b-field>
            </section>
            <footer class="modal-card-foot modal-foot-buttons">
                <b-field
                    grouped
                    position="is-right">
                    <p class="control">
                        <b-button
                            label="Cancel"
                            @click="onSchemaModalCancel" />
                    </p>
                    <p class="control">
                        <b-button
                            :label="buttonLabel"
                            :icon-right="'open-in-new'"
                            :disabled="hasSelectedSchema"
                            type="is-primary"
                            @click="reRouteToSpecializedUI" />
                    </p>
                </b-field>
            </footer>
        </div>
    </b-modal>
    <b-modal
        v-model="showScopedModal"
        has-modal-card>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">
                    Select Scope for {{ library.name }}
                </p>
            </header>
            <section class="modal-card-body ">
                <b-field
                    horizontal
                    label="Scope:">
                    <b-select
                        v-model="selectedScope"
                        placeholder="Select Scope">
                        <option
                            v-for="scope in props.codeSet.scopes.toSorted()"
                            :key="scope"
                            :value="scope">
                            {{ scope }}
                        </option>
                    </b-select>
                </b-field>
            </section>
            <footer class="modal-card-foot modal-foot-buttons">
                <b-field
                    grouped
                    position="is-right">
                    <p class="control">
                        <b-button
                            label="Cancel"
                            @click="onScopedModalCancel" />
                    </p>
                    <p class="control">
                        <b-button
                            :label="buttonLabel"
                            :icon-right="'open-in-new'"
                            :disabled="!hasSelectedScope"
                            type="is-primary"
                            @click="reRouteToSpecializedUI" />
                    </p>
                </b-field>
            </footer>
        </div>
    </b-modal>
</template>

<script setup>
    import {
        requireCanEditCode as vRequireCanEditCode,
        requireCanEditLibrary as vRequireCanEditLibrary
    } from '@/shared/directives/requirePermission';
    import { genericViewQuery } from '@/shared/helpers/api';
    import { computed, onMounted, ref } from 'vue';
    import { http } from '@/shared/httpWrapper.js';
    import { useRouter } from 'vue-router';
    import { compareByProperty } from '@/shared/helpers/utils.js';
    import {
        getCableEditRoute, getElectricComponentRoute,
        getEnsFormatRoute,
        getLCIRoute,
        getPipeClassEditorRoute,
        getRoute,
        getSchemaRoute
    } from '@/shared/components/specializedUiButton.helper.js';
    import { useScopeStore } from '@/stores/scopeStore.js';
    import { usePipeClassStore } from '@/stores/pipeClassStore.js';
    import { useElectricalComponentStore } from '@/stores/electricalComponentStore.js';

    const props = defineProps({
        library: {
            type: Object,
            required: true
        },
        codeSet: {
            type: Object,
            required: true
        },
        code: {
            type: Object,
            required: false,
            default: () => {}
        },
        buttonLabel: {
            type: String,
            required: true
        },
        buttonIcon: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        dropdownText: {
            type: String,
            required: true
        }
    });

    const emits = defineEmits([
        'clickEdit'
    ]);

    const router = useRouter();

    const schemaList = ref([]);
    const selectedSchema = ref(null);
    const showSchemaModal = ref(false);
    const selectedScope = ref(null);
    const showScopedModal = ref(false);

    const schemaQuery = {
        version: 1,
        name: 'Schema',
        include: [
            {
                field: 'Name'
            },
            {
                field: 'Description'
            },
            {
                field: 'Identity'
            }
        ],
        where: {
            field: 'isValid',
            value: true
        }
    };

    const scopeStore = useScopeStore();
    const pipingClassStore = usePipeClassStore();
    const electricalComponentStore = useElectricalComponentStore();

    onMounted(async () => {
        if (!props.library.isGlobal) {
            scopeStore.setScopeType(props.library.scopeType);
            await scopeStore.init();
        }

        if (props.library.specializedUI === 'Schema') {
            const response = (await genericViewQuery(http, schemaQuery)).data;
            schemaList.value = response.toSorted(compareByProperty('Name'));
        }
    });

    const isAddItem = computed(() => {
        return props.code === undefined;
    });

    const hasSelectedSchema = computed(() => {
        return selectedSchema.value === null;
    });

    const hasSelectedScope = computed(() => {
        return selectedScope.value !== null;
    });

    const isMultiScoped = computed(() => {
        return !props.library.isGlobal
            && props.codeSet.scopes.length > 1;
    });

    function onSchemaModalCancel() {
        showSchemaModal.value = !showSchemaModal.value;
        selectedSchema.value = null;
    }

    function onOpenScopeModal() {
        if (props.codeSet.scopes.length === 1) {
            selectedScope.value = props.codeSet.scopes[0];
            reRouteToSpecializedUI();
        } else {
            showScopedModal.value = !showScopedModal.value;
        }
    }

    function onScopedModalCancel() {
        showScopedModal.value = !showScopedModal.value;
        selectedScope.value = null;
    }

    async function specializedUiRouteName(specializedUI) {
        const route = {
            'CableEdit': [getCableEditRoute, props.library, props.code],
            'EleComponentType': [getElectricComponentRoute, electricalComponentStore, props.codeSet, props.code],
            'DocumentNumberingFormat': [getEnsFormatRoute, specializedUI, props.library, props.codeSet, props.code],
            'TagFormat': [getEnsFormatRoute, specializedUI, props.library, props.codeSet, props.code],
            'PipeClassEditor': [getPipeClassEditorRoute, pipingClassStore, props.library, props.codeSet, props.code],
            'FacilityAndProject': [getRoute, 'ProjectMasterList'],
            'Schema': [getSchemaRoute, props.library, props.code, selectedSchema.value],
            'ProjectFacility': [getLCIRoute, 'Projects', props.codeSet, props.code],
            'LCIContractor': [getLCIRoute, 'Contractors', props.codeSet, props.code],
            'LCIPurchaseOrder': [getLCIRoute, 'PurchaseOrders', props.codeSet, props.code]
        };

        const expr = route[specializedUI];
        const fun = expr[0];
        const args = expr.slice(1);

        return fun(...args);
    }

    function toggleEditMode() {
        emits('clickEdit');
    }

    async function reRouteToSpecializedUI() {
        if (!props.library.isGlobal) {
            if (props.codeSet.scopes.length === 1) {
                const scope = props.codeSet.scopes[0];
                scopeStore.updateSelectedScope(scope);
            } else {
                scopeStore.updateSelectedScope(selectedScope.value);
            }
        }
        const uiRouteName = await specializedUiRouteName(props.library.specializedUI);
        if (uiRouteName) {
            await router.push(uiRouteName);
        } else {
            toggleEditMode();
        }
    }
</script>

<style scoped>
.specialized-ui-button-dropdown {
    border-left-color: #ec1635 !important;
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
}

.specialized-ui-button {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
}
.modal-foot-buttons {
    display: initial;
}

</style>
