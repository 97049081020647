<template>
    <div>
        <div class="block">
            <h2 class="title is-3">
                Library Scope distribution report
            </h2>
            <button
                class="button"
                @click="showMenu = !showMenu">
                <span>{{ showMenu ? 'Hide' : 'Show' }} top menu</span>
            </button>
        </div>
        <div
            v-show="showMenu"
            class="columns">
            <div
                class="column">
                <label class="label">Select scope type:</label>
                <div
                    v-if="scopeTypes.length"
                    v-cloak
                    class="select">
                    <select
                        v-model="selectedScopeType"
                        accesskey="s">
                        <option value="">
                            No library selected (Alt+S)
                        </option>
                        <option
                            v-for="scope in scopeTypes"
                            :key="scope">
                            {{ scope }}
                        </option>
                    </select>
                </div>
                <button
                    class="button is-primary"
                    :disabled="selectedScopeType === ''"
                    @click.prevent="runReport">
                    <span class="icon is-small">
                        <i
                            class="fa fa-play"
                            aria-hidden="true" />
                    </span>
                    <span>Run report</span>
                </button>
            </div>

            <div
                v-if="report"
                v-cloak
                class="column">
                <table class="block table is-narrow is-bordered">
                    <tbody>
                        <tr>
                            <td class="library-grouped">
                                Grouped
                            </td>
                            <td>
                                <b-switch v-model="showGroupedLibraries">
                                    Library is shared between several scopes
                                </b-switch>
                            </td>
                            <td class="has-codes">
                                1234
                            </td>
                            <td>Number of codes in set</td>
                        </tr>
                        <tr>
                            <td class="library-not-grouped">
                                Not grouped
                            </td>
                            <td>
                                <b-switch v-model="showUngroupedLibraries">
                                    Library is specific for every scope
                                </b-switch>
                            </td>
                            <td class="no-codes">
                                0
                            </td>
                            <td>The set is empty</td>
                        </tr>
                        <tr>
                            <td class="library-grouping-unknown">
                                Not specified
                            </td>
                            <td>
                                <b-switch v-model="showUnknownGroupingLibraries">
                                    LibraryMetadata has no grouping information
                                </b-switch>
                            </td>
                            <td class="not-release-ready" />
                            <td>The set is not ready for release</td>
                        </tr>
                        <tr>
                            <td class="library-global">
                                Global
                            </td>
                            <td>
                                <b-switch v-model="showGlobalLibraries">
                                    Library is shared between all scopes
                                </b-switch>
                            </td>
                            <td class="no-set" />
                            <td>No set exist</td>
                        </tr>
                    </tbody>
                </table>
                <button
                    v-if="scopeIsFacility && stidDatabases"
                    class="button is-info"
                    @click="toggleShowHideFacilities">
                    <b-icon
                        icon="filter"
                        size="is-small" />
                    <span>Select facility groups</span>
                </button>
                <button
                    v-if="report"
                    class="button is-info"
                    @click="toggleShowHideGroups">
                    <b-icon
                        icon="filter"
                        size="is-small" />
                    <span>Select library groups</span>
                </button>
            </div>
        </div>
        <spinner :loading="loading">
            <b-modal
                :model-value="showHideFacilitiesActive"
                has-modal-card
                @close="toggleShowHideFacilities">
                <div
                    class="modal-card"
                    style="height: auto; width: auto;">
                    <section class="modal-card-body">
                        <div
                            v-if="scopeIsFacility && stidDatabases"
                            class="block">
                            <b-button @click="setVisibilityAllFacilities(false)">
                                Hide all
                            </b-button>
                            <b-button @click="setVisibilityAllFacilities(true)">
                                Show all
                            </b-button>
                            <b-switch v-model="showDatabaselessFacilities">
                                Show <i>databaseless</i> facilities
                            </b-switch>
                            <table class="block table">
                                <tbody>
                                    <tr>
                                        <th>STID DB</th>
                                        <th>Show/hide</th>
                                        <th>Facilities</th>
                                    </tr>
                                    <tr
                                        v-for="(info, db) in stidDatabases"
                                        :key="db">
                                        <td>{{ db }}</td>
                                        <td>
                                            <b-switch v-model="info.show" />
                                        </td>
                                        <td>{{ info.facilities.join(', ') }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>
                </div>
            </b-modal>

            <b-modal
                :model-value="showHideGroupsActive"
                has-modal-card
                @close="toggleShowHideGroups">
                <div
                    class="modal-card"
                    style="height: auto; width: auto;">
                    <header class="modal-card-head">
                        <p class="modal-card-title title is-4">
                            Select which library groups to include..
                        </p>
                    </header>
                    <section class="modal-card-body">
                        <div
                            v-if="report"
                            class="block">
                            <b-button @click="setVisibilityAllGroups(false)">
                                Hide all
                            </b-button>
                            <b-button @click="setVisibilityAllGroups(true)">
                                Show all
                            </b-button>
                            <b-switch v-model="showGrouplessLibraries">
                                Show <i>groupless</i> libraries
                            </b-switch>
                        </div>
                        <div
                            class="block">
                            <div
                                v-for="group in allGroups"
                                :key="group"
                                style="width:33%; float:left;">
                                <b-switch
                                    v-model="report.groups[group]">
                                    {{ group }}
                                </b-switch>
                            </div>
                        </div>
                    </section>
                </div>
            </b-modal>

            <div
                v-if="report"
                class="report-container">
                <div>
                    <table class="table">
                        <tbody>
                            <tr class="header-row">
                                <th class="sticky-corner-header">
                                    <b>Library</b> ({{ filteredLibraries.length }})
                                </th>
                                <th
                                    v-for="scope in scopes"
                                    :key="scope">
                                    <template v-if="scope">
                                        {{ scope }}
                                    </template>
                                    <template v-else>
                                        <b-icon
                                            icon="earth"
                                            title="Global" />
                                    </template>
                                </th>
                            </tr>
                            <tr
                                v-for="lib in filteredLibraries"
                                :key="lib.library"
                                class="row">
                                <td
                                    :class="['sticky-column', libraryCellClasses(lib)]">
                                    <div
                                        class="library-cell-content"
                                        :title="libraryTooltip(lib)">
                                        {{ lib.library }}
                                    </div>
                                </td>
                                <td
                                    v-for="scope in scopes"
                                    :key="scope"
                                    :class="cellClasses(lib, scope)">
                                    {{ lib.scopeCounts[scope] !== undefined ? lib.scopeCounts[scope] : '&nbsp;' }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </spinner>
    </div>
</template>

<script>
    import Spinner from '@/shared/components/Spinner.vue';
    import { genericViewQueryAsText, getScopeTypeLibraryReport, getUsedScopeTypes } from '@/shared/helpers/api';
    import _ from 'lodash';

    export default {
        components: {
            Spinner
        },
        data() {
            return {
                scopeTypes: [],
                selectedScopeType: '',
                report: null,
                loading: false,
                showGroupedLibraries: true,
                showUngroupedLibraries: true,
                showUnknownGroupingLibraries: true,
                showGlobalLibraries: false,
                showHideGroupsActive: false,
                showGrouplessLibraries: true,
                showMenu: true,

                /* Below only used when scope === 'Facility' */
                facilityInfo: null,
                stidDatabases: null,
                showDatabaselessFacilities: true,
                showHideFacilitiesActive: false
            };
        },
        computed: {
            scopes() {
                if (this.report) {
                    if (this.scopeIsFacility && this.stidDatabases) {
                        const tmp = this.report.scopes.filter(x => {
                            const facilityInfo = this.facilityInfo[x];
                            if (facilityInfo) {
                                return this.stidDatabases[facilityInfo.STIDDB].show;
                            }
                            return this.showDatabaselessFacilities;
                        });
                        return tmp;
                    } else {
                        return this.report.scopes;
                    }
                }
                return [];
            },
            filteredLibraries() {
                if (!this.report) return [];

                return this.report.libraries.filter(x => {
                    if (!(x.isGlobal === true && this.showGlobalLibraries
                        || x.isGlobal === false && (
                            x.isGrouped === true && this.showGroupedLibraries
                            || x.isGrouped === false && this.showUngroupedLibraries
                            || x.isGrouped === null && this.showUnknownGroupingLibraries))) {
                        return false; // Hide if not included by scope grouping filters
                    }

                    if (this.showGrouplessLibraries && x.groups.length === 0) {
                        return true;
                    }

                    for (let i = 0; i < x.groups.length; i++) {
                        if (this.report.groups[x.groups[i]]) {
                            return true; // Show because of library group membership
                        }
                    }

                    return false;
                });
            },
            allGroups() {
                if (!this.report) return [];

                return Object.keys(this.report.groups).sort((a, b) => a.localeCompare(b));
            },
            scopeIsFacility() {
                return this.selectedScopeType === 'Facility';
            }
        },
        watch: {
            selectedScopeType() {
                this.report = null;
            }
        },
        async mounted() {
            const tmp = await getUsedScopeTypes(this);
            this.scopeTypes = _.sortBy(tmp, (x) => x);
        },
        methods: {
            async runReport() {
                this.loading = true;
                this.report = null;
                const tmp = await getScopeTypeLibraryReport(this, this.selectedScopeType);
                tmp.scopes = _.sortBy(tmp.scopes);
                this.report = tmp;

                if (this.scopeIsFacility) {
                    await this.getFacilityInfo();
                }
                this.loading = false;
            },
            async getFacilityInfo() {
                const response = await genericViewQueryAsText(this, `
                    FROM Facility
                    SELECT Name, STIDDB
                    WHERE STIDDB is not null
                `);

                const facilityDictionary = {};
                response.data.forEach(x => {
                    facilityDictionary[x.Name] = x;
                });
                this.facilityInfo = facilityDictionary;

                this.stidDatabases = response.data.reduce((acc, x) => {
                    if (acc[x.STIDDB]) {
                        acc[x.STIDDB].facilities.push(x.Name);
                    } else {
                        acc[x.STIDDB] = {
                            facilities: [x.Name],
                            show: true
                        };
                    }
                    return acc;
                }, {});
            },
            libraryCellClasses(lib) {
                return {
                    'library-grouped': !lib.isGlobal && lib.isGrouped === true,
                    'library-not-grouped': !lib.isGlobal && lib.isGrouped === false,
                    'library-grouping-unknown': !lib.isGlobal && lib.isGrouped === null,
                    'library-global': lib.isGlobal
                };
            },
            cellClasses(lib, scope) {
                const scopeValue = lib.scopeCounts[scope];
                return {
                    'has-text-right': true,
                    'no-set': scopeValue === undefined,
                    'has-codes': scopeValue !== undefined && scopeValue > 0,
                    'no-codes': scopeValue === 0,
                    'not-release-ready': lib.codeSetIsReleaseReady[scope] !== undefined && !lib.codeSetIsReleaseReady[scope]
                };
            },
            libraryTooltip(lib) {
                let tooltip = lib.library;
                if (lib.alias) {
                    tooltip += ` alias ${lib.alias}`;
                }
                tooltip += `\nTotal count: ${lib.total}\nIs grouped: ${lib.isGrouped}\nPart of: ${lib.groups?.join(', ')}`;
                return tooltip;
            },
            toggleShowHideGroups() {
                this.showHideGroupsActive = !this.showHideGroupsActive;
            },
            toggleShowHideFacilities() {
                this.showHideFacilitiesActive = !this.showHideFacilitiesActive;
            },
            setVisibilityAllFacilities(value) {
                Object.keys(this.stidDatabases).forEach(facility => {
                    this.stidDatabases[facility].show = value;
                });
            },
            setVisibilityAllGroups(value) {
                Object.keys(this.report.groups).forEach(group => {
                    this.report.groups[group] = value;
                });
            }
        }
    };
</script>

<style scoped>
.report-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-y: scroll;
    height: 70vh;
}
.header-row {
    height: 55px;
    background-color: #48cae4;
    text-align: center;
    position: sticky;
    top: 0;
    z-index: 1;
}
.row {
    line-height: 10px;
}
.library-grouped {
    background-color: #a2d2ff;
}
.library-not-grouped {
    background-color: #bde0fe;
}
.library-grouping-unknown {
    background-color: #a6b6cf;
}
.library-global {
    background-color: #dbabe0;
}
.library-cell-content {
    height: 10px;
    overflow: hidden;
    min-width: 200px;
    cursor: help;
}
.has-codes {
    background-color: #68de7c;
}
.no-codes {
    background-color: #98f1a7;
    color: #9fa19f;
}
.no-set {
    background-color: #c2c2c2;
}
.sticky-column {
  position: sticky;
  left: 0;
}
.sticky-corner-header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: #48cae4;
}
.not-release-ready {
    background-color: #ff8080;
}
</style>
