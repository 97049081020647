<template>
    <div
        v-if="showTags"
        class="box">
        <p class="title is-5">
            Library groups
        </p>
        <div class="tags are-medium">
            <span
                v-for="(tag, idx) in tags"
                :key="idx"
                class="tag is-info">{{ tag }}</span>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            tags: {
                type: Array,
                required: true
            }
        },
        computed: {
            showTags() {
                return this.tags.length > 0;
            }
        }
    };
</script>
