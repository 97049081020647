import moment from 'moment';

export function doQuery(type, operator, queryOperand, rowOperand) {
    type = type.toLowerCase();

    if (operator === 'is')
        return !rowOperand;
    else if (operator === 'is not')
        return !!rowOperand;
    else if (!rowOperand) {
        return false;
    }

    switch (type) {
        case 'bool':
            if (operator === '!=')
                return !(queryOperand.toLowerCase() === rowOperand.toLowerCase());
            else
                return queryOperand.toLowerCase() === rowOperand.toLowerCase();
        case 'string':
            return StringQuery(operator, queryOperand, rowOperand);
        case 'coderef':
            return StringQuery(operator, queryOperand, rowOperand);
        case 'int':
            return numericQuery(operator, parseInt(queryOperand, 10), parseInt(rowOperand, 10));
        case 'float':
            return numericQuery(operator, parseFloat(queryOperand), parseFloat(rowOperand));
        case 'date':
            return DateQuery(operator, queryOperand, rowOperand);
        case 'datetime':
            return DateQuery(operator, queryOperand, rowOperand);
    }
}

function StringQuery(operator, queryOperand, rowOperand) {
    switch (operator) {
        case 'like':
            return rowOperand.toLowerCase().includes(queryOperand.toLowerCase());
        case '=':
            return queryOperand.toLowerCase() === rowOperand.toLowerCase();
        case '!=':
            return queryOperand.toLowerCase() !== rowOperand.toLowerCase();
    }
}

function DateQuery(operator, queryOperand, rowOperand) {
    switch (operator) {
        case '!=':
            return !moment(rowOperand).isSame(queryOperand);
        case '=':
            return moment(rowOperand).isSame(queryOperand);
        case '>':
            return moment(rowOperand).isAfter(queryOperand);
        case '<':
            return moment(rowOperand).isBefore(queryOperand);
    }
}

function numericQuery(operator, queryOperand, rowOperand) {
    switch (operator) {
        case '=':
            return rowOperand === queryOperand;
        case '!=':
            return rowOperand !== queryOperand;
        case '>':
            return rowOperand > queryOperand;
        case '<':
            return rowOperand < queryOperand;
    }
}
