<template>
    <div class="max-width">
        <h1 class="title">
            Schema specifications
        </h1>
        <div class="columns">
            <div class="column">
                <b-table
                    :data="schemata"
                    default-sort="Name"
                    :striped="true"
                    :narrowed="true"
                    :hoverable="true"
                    class="table-height site-sticky-header">
                    <b-table-column
                        v-slot="props"
                        field="Name"
                        label="Name"
                        sortable>
                        <span class="name_column">
                            <router-link :to="getSchemaLink(props.row.Identity)">
                                {{ props.row.Name }}
                            </router-link>
                        </span>
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="Description"
                        label="Description">
                        {{ props.row.Description }}
                    </b-table-column>
                </b-table>
            </div>
            <div class="column is-3">
                <tag-introduction :tag="'Schema (Ontology)'" />
            </div>
        </div>
    </div>
</template>

<script setup>
    import TagIntroduction from '@/shared/components/TagIntroduction.vue';
    import { genericViewQuery } from '@/shared/helpers/api.ts';
    import { getSchemaLink } from '@/apps/schema/schemaHelper.js';
    import { onMounted, ref } from 'vue';
    import { http } from '@/shared/httpWrapper';

    const schemata = ref([]);

    const schemaQuery = {
        version: 1,
        name: 'Schema',
        include: [
            {
                field: 'Name'
            },
            {
                field: 'Description'
            },
            {
                field: 'Identity'
            }
        ],
        where: {
            field: 'isValid',
            value: true
        }
    };

    onMounted(async () => {
        schemata.value = (await genericViewQuery(http, schemaQuery)).data;
    });
</script>
<style scoped>
.name_column{
    white-space: nowrap;
    overflow: visible;
    text-overflow: ellipsis;
}
.max-width{
    width: 100%;
}
</style>
