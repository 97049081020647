import { EventType, InteractionRequiredAuthError, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import _ from 'lodash';
import { clearAttributesApiCache, getAccessGroups, getMyPermissions } from '../helpers/api';

class AuthService {
    constructor($http, config) {
        this.authConfig = config;
        this.$http = $http;

        this.pca = new PublicClientApplication({
            auth: {
                clientId: config.clientId,
                authority: config.instance + config.tenant,
                redirectUri: config.redirectUri,
                postLogoutRedirectUri: config.redirectUri

            },
            cache: {
                cacheLocation: 'sessionStorage',
                claimsBasedCachingEnabled: true
            },
            system: {
                loggerOptions: {
                    loggerCallback(loglevel, message) {
                        // eslint-disable-next-line no-console
                        console.error(message);
                    },
                    logLevel: LogLevel.Error
                }
            }
        });

        this.pca.addEventCallback((event) => {
            if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
                const payload = event.payload;
                const account = payload.account;
                this.pca.setActiveAccount(account);
            }
        });
    }

    /**
     * New in MSAL v3.x, the `PublicClientApplication` needs to be initialized before use.
     *
     * MSAL Docs: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/v2-migration.md
     *
     * @returns {Promise<void>}
     */
    async init() {
        await this.pca.initialize();
    }

    sessionStorageAccessGroupsKey = 'cl_accessgroups';
    sessionStoragePermissionsKey = 'cl_permissions';

    /**
     * Awaits for the login redirect to complete.
     *
     * @returns {Promise<AuthenticationResult & {account: AccountInfo}>}
     */
    redirectHandled = async () => await this.pca.handleRedirectPromise();

    /**
     * Checks if there is a user profile signed in, if it not exists,
     * then assumes that the user has not signed in.
     *
     * @returns {boolean}
     */
    isSignedIn() {
        return !!this.getUser();
    }

    /**
     * Signs in a user and set the signed in user as active account.
     *
     * @returns {Promise<void>}
     */
    async signIn() {
        const accounts = this.pca.getAllAccounts();
        if (accounts.length > 0) {
            this.pca.setActiveAccount(accounts[0]);
        }

        const redirectHandled = await this.pca.handleRedirectPromise();

        if (redirectHandled === null && (!accounts || accounts.length === 0)) {
            const loginRequest = { scopes: ['User.Read'] };
            await this.pca.loginRedirect(loginRequest);
        }
    }

    /**
     * SignsOut the user, clears the `sessionStorage` and removes the users cached attributes from the API cache.
     *
     * @returns {Promise<void>}
     */
    async signOut() {
        await clearAttributesApiCache(this);
        sessionStorage.clear();
        await this.pca.logoutRedirect();
        window.location.reload();
    }

    /**
     * Returns the active account from MSAL
     *
     * @returns {AccountInfo|null}
     */
    getUser() {
        return this.pca.getActiveAccount() ?? null;
    }

    /**
     * Requests an accessToken from Azure Entra ID (Azure Ad).
     * Awaits for Redirect login to be done, before it attempts to acquire an access token silently.
     *
     * If the RefreshToken is expired, an access token will be requested silently in a hidden iframe.
     * This also means that the UI hosting server CANNOT serve the header `X-Frame-Options: Deny` to it's clients.
     *
     * MSAL Docs: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/
     *
     * @returns {Promise<string>}
     */
    async acquireToken() {
        await this.pca.handleRedirectPromise();

        const accessRequest = {
            scopes: [`${this.authConfig.clientId}/user_impersonation`]
        };

        try {
            const silentTokenResponse = await this.pca.acquireTokenSilent(accessRequest);
            return silentTokenResponse.accessToken;
        } catch (authError) {
            if (authError instanceof InteractionRequiredAuthError) {
                await this.pca.acquireTokenRedirect(accessRequest);
            } else {
                throw Error(authError);
            }
        }
    }

    /**
     * Fetches, caches and returns an array of the users permissions.
     *
     * @returns {Promise<string[]>}
     */
    async getPermissions() {
        let permissions = sessionStorage.getItem(this.sessionStoragePermissionsKey);

        if (!permissions) {
            const permissionResponse = await getMyPermissions(this);
            permissions = JSON.stringify(permissionResponse);
            sessionStorage.setItem(this.sessionStoragePermissionsKey, permissions);
        }

        return JSON.parse(permissions);
    }

    /**
     * Fetches the users AccessGroups and caches them in SessionStorage.
     *
     * @returns {Promise<string[]>}
     */
    async getAccessGroups() {
        let accessGroups = sessionStorage.getItem(this.sessionStorageAccessGroupsKey);

        if (!accessGroups) {
            const agResponse = await getAccessGroups(this);
            accessGroups = this.setAccessGroupsInStorage(agResponse);
        }

        return JSON.parse(accessGroups);
    }

    /**
     * Filters and maps AccessGroups a users has, before stored in sessionStorage.
     *
     * @param accessGroupArray {AccessGroup[]}
     * @returns {string} - JSON String of the mapped groups
     */
    setAccessGroupsInStorage(accessGroupArray) {
        const userRoles = this.getUser().idTokenClaims.roles;

        const userAccessGroups = accessGroupArray
            .filter(g => userRoles.includes(g.policy))
            .map(g => g.name);

        const accessGroups = JSON.stringify(userAccessGroups);
        sessionStorage.setItem(this.sessionStorageAccessGroupsKey, accessGroups);
        return accessGroups;
    }

    /**
     * Clears the users attributes from the API cache
     *
     * @returns {Promise<void>}
     */
    async refreshUserAttributes() {
        await clearAttributesApiCache(this);
    }

    /**
     * Evaluates if user has the requested permission
     *
     * @param permission {string}
     * @returns {Promise<boolean>} - `true` if the user has the requested permission.
     */
    async hasPermission(permission) {
        const permissions = await this.getPermissions();
        return permissions.includes(permission);
    }

    /**
     * Evaluates if the user has ANY of the requested permissions.
     *
     * @param requiredPermissions {string[]}
     * @returns {Promise<boolean>}
     */
    async hasAnyPermission(requiredPermissions) {
        const permissions = await this.getPermissions();
        return _.intersection(requiredPermissions, permissions).length > 0;
    }

    /**
     * Evaluates if user has ANY accessGroups
     *
     * @param requiredGroups {string[]}
     * @returns {Promise<boolean>}
     */
    async hasAnyAccessGroup(requiredGroups) {
        if (requiredGroups.length === 0)
            return true;

        const userPermissions = await this.getPermissions();
        const userAccessGroups = await this.getAccessGroups();

        return _.indexOf(userPermissions, 'IsAdministrator') > -1
            || _.intersection(requiredGroups, userAccessGroups).length > 0;
    }
}

export default AuthService;
