<template>
    <section class="center-large-text section">
        <b-icon
            pack="fas"
            size="is-large"
            icon="do-not-enter" />
        <h1
            class="title">
            Not Found
        </h1>
        <h1>
            Library <strong> {{ libraryName }} </strong> could not be found
        </h1>
        <br>
        <router-link to="/Library">
            Return to Library List
        </router-link>
    </section>
</template>

<script>
    export default {
        name: 'NotFound',
        props: {
            libraryName: {
                type: String,
                default: ''
            }
        }
    };
</script>

<style scoped>

</style>
