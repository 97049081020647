<template>
    <b-tooltip
        :label="text"
        position="is-top">
        <b-icon
            icon="help"
            class="helpIcon"
            size="is-small" />
        <template
            v-if="!text"
            #content>
            <slot
                name="helpContent" />
        </template>
    </b-tooltip>
</template>

<script>
    export default {
        name: 'HelpIcon',
        props: {
            text: {
                type: String,
                required: false,
                default: null
            }
        }
    };
</script>

<style scoped>
    .helpIcon {
        cursor: help;
    }
</style>
