<template>
    <div v-if="show">
        <h2 class="title is-2 section-header">
            Edit attribute name
        </h2>
        <div class="block">
            <b-field label="Library name">
                <b-input v-model="libraryName" />
            </b-field>
            <b-field label="Old attribute name">
                <b-input v-model="oldAttributeName" />
            </b-field>
            <b-field label="New attribute name">
                <b-input v-model="newAttributeName" />
            </b-field>
            <b-field>
                <b-switch v-model="dryRun">
                    Dry run (rollback transaction)
                </b-switch>
            </b-field>
        </div>
        <div class="buttons">
            <b-button
                type="is-primary"
                :disabled="!enabled"
                @click="run()">
                Run
            </b-button>
            <b-button
                :disabled="!enabled"
                @click="cancelCommand()">
                Cancel
            </b-button>
        </div>
    </div>
</template>

<script>
    import runMixin from './RunMixin.js';

    export default {
        mixins: [runMixin],
        props: {
            command: {
                type: String,
                required: true
            }

        },
        data() {
            return {
                libraryName: '',
                oldAttributeName: '',
                newAttributeName: '',
                dryRun: true
            };
        },
        methods: {
            async run() {
                await this.submitCommand([
                    'edit-attribute-name',
                    this.libraryName,
                    this.oldAttributeName,
                    this.newAttributeName,
                    this.dryRun ? 'dry-run' : ''
                ]);
            }
        }
    };
</script>
