<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">
                Export specification document for {{ identity }}
            </p>
        </header>

        <section
            class="modal-card-body">
            <b-field
                v-if="schemaVersions.length > 0"
                label="Version">
                <b-select
                    v-model="docOptions.version"
                    placeholder="Select a version">
                    <option
                        v-for="version in schemaVersions"
                        :key="version.versionNumber"
                        :value="version">
                        {{ version.versionNumber }}
                    </option>
                </b-select>
            </b-field>
            <b-field label="Include All Extensions">
                <b-checkbox v-model="docOptions.includeAllExtensions" />
            </b-field>
        </section>

        <footer class="modal-card-foot">
            <button
                class="button"
                @click="closeModal">
                <b-icon
                    icon="close-circle"
                    size="is-small"
                    @click="closeModal" />
                <span>
                    Cancel
                </span>
            </button>
            <b-button
                class="is-primary button"
                @click="exportDocButtonClick">
                Download document
            </b-button>
        </footer>
    </div>
</template>

<script>
    import { compileDoc, getAllSchemas } from '@/shared/helpers/api';
    import { showMixin } from '@/shared/mixins/showMixin';
    import saveAs from 'file-saver';

    export default {
        mixins: [
            showMixin
        ],
        props: {
            identity: {
                default: null,
                type: String
            }
        },
        emits: [
            'close'
        ],
        data() {
            return {
                docOptions: {
                    schemaName: this.identity,
                    version: null,
                    includeEnums: false,
                    includeAllExtensions: false,
                    scope: null,
                    includeMappingForSchema: null
                },
                schemaVersions: []
            };
        },
        async mounted() {
            this.schemaVersions = (await getAllSchemas(this)).find(schema => schema.name === this.identity).versions;
        },
        methods: {
            async exportDocButtonClick() {
                await this.exportDocument();
                this.closeModal();
            },
            closeModal() {
                this.$emit('close');
            },
            async exportDocument() {
                const options = JSON.parse(JSON.stringify(this.docOptions));
                options.version = options.version?.versionNumber ?? null;
                try {
                    const response = await compileDoc(this, options);
                    if (response.data) {
                        const filename = options.schemaName + '.docx';
                        const blob = new Blob([response.data], { type: response.headers['Content-Type'] });
                        await saveAs(blob, filename);
                    }
                } catch (ex) {
                    this.showError(ex);
                }
            }
        }
    };
</script>

<style scoped>
</style>
