<template>
    <cl-header />
    <reload-prompt />
    <section
        class="section"
        style="padding-top: 1.5rem">
        <router-view :key="refreshKey" />
    </section>
    <cl-footer />
</template>
<script>
    import ClHeader from '@/shared/ClHeader.vue';
    import ClFooter from '@/shared/ClFooter.vue';
    import ReloadPrompt from '@/apps/reloadPrompt/ReloadPrompt.vue';

    export default {
        name: 'CommonLibraryApp',
        components: { ClHeader, ClFooter, ReloadPrompt },
        data() {
            return {
                refreshKey: 0
            };
        },
        created() {
            this.$watch(
                () => this.$route.meta.refreshKey?.(this.$route),
                (newKey, oldKey) => {
                    if (newKey !== oldKey && newKey !== undefined && oldKey !== undefined) {
                        this.refreshKey++;
                    }
                }
            );
        }
    };
</script>
