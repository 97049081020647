<script setup>
    import CloneFormatModal from '@/apps/ens/components/CloneFormatModal.vue';
    import EditTableFormatElement from '@/apps/ens/components/EditTableFormatElement.vue';
    import StatusIndicator from '@/apps/ens/components/StatusIndicator.vue';
    import { newFormatElement, queries, useBuefy } from '@/apps/ens/helpers.ts';
    import CodeEditSaveProgress from '@/shared/components/CodeEditSaveProgress.vue';
    import CustomSelect from '@/shared/components/CustomSelect.vue';
    import ScopeDropdown from '@/shared/components/ScopeDropdown.vue';
    import useShow from '@/shared/composables/useShow.js';
    import { requireCanEditCode as vRequireCanEditCode } from '@/shared/directives/requirePermission';
    import {
        createReleaseChain,
        ensValidateFormat,
        getAccessGroupForLibrary,
        getAccessPolicesForLib,
        getCodeSets,
        getLibrary
    } from '@/shared/helpers/api';
    import { assert } from '@/shared/helpers/assert';
    import { InputRegexValidator } from '@/shared/helpers/inputRegexValidator.ts';
    import { spaceEncode } from '@/shared/helpers/spaceEncoding.js';
    import { cancelPreviousWhenCalledWithDebouncing, compareByProperty, equalsIgnoreCase } from '@/shared/helpers/utils.js';
    import { http } from '@/shared/httpWrapper.js';
    import { useScopeStore } from '@/stores/scopeStore.js';
    import _ from 'lodash';
    import { computed, ref, toRefs, watchEffect } from 'vue';
    import { useRoute, useRouter } from 'vue-router';

    // =================================================================================================================
    // == Props

    const props = defineProps({
        ensType: {
            type: String,
            required: true
        }
    });
    const { ensType } = toRefs(props);

    // =================================================================================================================
    // == Composables & State

    const route = useRoute();
    const router = useRouter();

    const buefy = useBuefy();

    const { showError, showInfo } = useShow();
    const getErrorMessage = (ex) => {
        return new Promise(resolve => showError(ex, resolve));
    };

    const scopeStore = useScopeStore();

    const initialLoading = ref(true);
    const fatalErrorMessage = ref(null);

    const selectedFacility = ref(null);

    const categories = ref([]);
    const selectedCategory = ref(null);

    const formats = ref([]);
    const loadingFormats = ref(false);
    const loadingFormatElements = ref(false);
    /**
     * Invariants:
     * 1) For each element e: `e.isValid == true`
     * 2) For each element e: `e.elementNo == elements.indexOf(e)`
     */
    const selectedFormat = ref(null);

    const layouts = ref([]);
    const references = ref([]);
    const paddings = ref([]);

    const cloneFormatModalActive = ref(false);

    const previousValidationRequest = ref(null);
    const previousValidationResult = ref(null);
    const validationResult = ref(null);
    const validationLoading = ref(false);

    const loadingCreateRelease = ref(false);
    const releaseId = ref(null);
    const releaseError = ref(null);

    const accessGroupsForFormatLib = ref([]);
    const policiesForFormatLib = ref([]);

    const formatDescriptionRegexValidator = ref(null);
    const elementDescriptionRegexValidator = ref(null);

    // =================================================================================================================
    // Getters

    const title = computed(() =>
        ensType.value === 'TagFormat'
            ? 'ENS Tag Format'
            : 'ENS Document Numbering Format');

    const ensNumberTypeDescription = computed(() =>
        ensType.value === 'TagFormat'
            ? 'tag number'
            : 'document number');

    const libraryNames = computed(() =>
        ensType.value === 'TagFormat'
            ? ['TagFormat', 'TagFormatElement']
            : ['DocumentNumberingFormat', 'DocumentNumberingFormatElement']);

    const filteredCategories = computed(() =>
        categories.value.filter(x => x.facility === selectedFacility.value)
    );

    const categorySelectOptions = computed(() =>
        filteredCategories.value.map(category => ({
            key: category.identity,
            string: category.description,
            value: category
        }))
    );

    const filteredFormats = computed(() => {
        if (!selectedCategory.value)
            return [];

        const maybeSelectedFormat = selectedFormat.value ? [selectedFormat.value] : [];
        const existingFormats
            = formats.value.filter(format =>
                format.category === selectedCategory.value.identity && format.isValid
            );

        return _.sortBy(_.unionBy(maybeSelectedFormat, existingFormats, 'name'), 'description');
    });

    const formatSelectOptions = computed(() =>
        filteredFormats.value.map(format => ({
            key: format.identity,
            string: format.description || '(Untitled)',
            value: format
        }))
    );

    const originalFormat = computed(() =>
        formats.value.find(x => x.name === selectedFormat.value.name) ?? null
    );

    const selectedFormatWithAllChanges = computed(() => {
        let elements = selectedFormat.value.elements;
        if (elements && originalFormat.value?.elements) {
            const removedElements
                = _.difference(
                    originalFormat.value.elements.map(x => x.elementNo),
                    selectedFormat.value.elements.map(x => x.elementNo)
                ).map(elementNo => ({
                    ...newFormatElement(ensType.value, elementNo),
                    description: 'REMOVED',
                    isValid: false
                }));
            elements = elements.concat(removedElements);
        }

        return {
            ...selectedFormat.value,
            syntax: validationResult.value?.value?.syntax ?? null,
            regex: validationResult.value?.value?.regex ?? null,
            regexGrouped: validationResult.value?.value?.regexGrouped ?? null,
            elements: elements
        };
    });

    const hasChangesWithRespectTo = (filter) => {
        if (!selectedFormat.value?.elements)
            return false;
        else if (!originalFormat.value)
            return true;
        else
            return !_.isEqual(filter(selectedFormatWithAllChanges.value), filter(originalFormat.value));
    };

    const filteredFormatForChanges = (format) => {
        return {
            ..._.omit(format, ['identity']),
            elements:
                format.elements?.map(element =>
                    _.omit(element, ['referenceError', 'minMaxError', 'layoutError', 'descriptionError', 'identity'])
                )
        };
    };

    const filteredFormatForUserChanges = (format) => {
        return {
            ..._.omit(format, ['syntax', 'regex', 'regexGrouped', 'identity']),
            elements:
                format.elements
                    ?.filter(element => element.isValid)
                    .map(element =>
                        _.omit(element, ['referenceError', 'minMaxError', 'layoutError', 'descriptionError', 'elementNo', 'identity'])
                    )
        };
    };

    const hasChanges = computed(() =>
        !loadingFormatElements.value
        && !loadingFormats.value
        && hasChangesWithRespectTo(filteredFormatForChanges));

    const hasUserChanges = computed(() =>
        !loadingFormatElements.value
        && !loadingFormats.value
        && hasChangesWithRespectTo(filteredFormatForUserChanges));

    const isDescriptionUnique = computed(() => {
        if (!selectedFormat.value)
            return false;
        const normalizedDescription = selectedFormat.value.description.toLowerCase().trim();
        return !formats.value.some(x =>
            x.name !== selectedFormat.value.name
            && x.description.toLowerCase().trim() === normalizedDescription);
    });

    const hasDescription = computed(() =>
        !!selectedFormat.value?.description.trim()
    );

    const invalidDescriptionError = computed(() => {
        if (!hasDescription.value) {
            return 'Format description cannot be empty';
        }
        if (!isDescriptionUnique.value) {
            return 'A format with the same description already exists';
        }
        const error = formatDescriptionRegexValidator.value?.validate(selectedFormat.value.description);
        return error?.error || '';
    });

    const canAddNewFormat = computed(() =>
        !!selectedFacility.value && !!selectedCategory.value
    );

    const canCloneFormat = computed(() =>
        !!selectedFormat.value?.elements
    );

    const canDeleteFormat = computed(() =>
        !!selectedFormat.value?.elements
    );

    const canCreateRelease = computed(() =>
        hasChanges.value
        && validationResult.value?.value?.isValid
        && !invalidDescriptionError.value
        && !selectedFormat.value.elements.some(elm =>
            elm.referenceError
            || elm.minMaxError
            || elm.layoutError
            || elm.descriptionError
        ));

    const isNewFormat = computed(() =>
        !originalFormat.value
    );

    const selectedFormatLabel = computed(() =>
        !!selectedCategory.value && filteredFormats.value.length === 0 ? 'No formats available' : 'Select format'
    );

    const permissionsObject = computed(() => ({
        libraryName: ensType.value,
        scopes: selectedFacility.value ? [selectedFacility.value] : []
    }));

    // =================================================================================================================
    // == Data fetching

    const loadDescriptionRegex = async () => {
        const [formatLibrary, formatElementLibrary] = await Promise.all(
            libraryNames.value.map(name => getLibrary(http, name))
        );

        const formatDescriptionRegex = formatLibrary.descriptionRegex;
        const elementDescriptionRegex = formatElementLibrary.descriptionRegex;

        formatDescriptionRegexValidator.value = formatDescriptionRegex ? new InputRegexValidator(new RegExp(formatDescriptionRegex)) : null;
        elementDescriptionRegexValidator.value = elementDescriptionRegex ? new InputRegexValidator(new RegExp(elementDescriptionRegex)) : null;
    };

    const loadAccessGroupsForFormatLib = async () => {
        accessGroupsForFormatLib.value = await getAccessGroupForLibrary(http, ensType.value);
    };

    const loadPoliciesForFormatLib = async () => {
        const response = await getAccessPolicesForLib(http, ensType.value);
        policiesForFormatLib.value = response.policies;
    };

    const loadCategories = async () => {
        try {
            categories.value = await queries.fetchCategories(ensType.value);
        } catch (ex) {
            showError(ex, message => fatalErrorMessage.value = message);
        }
    };

    const loadFormats = async () => {
        if (!selectedFacility.value)
            return;

        loadingFormats.value = true;
        try {
            const fetchedFormats = await queries.fetchFormats(ensType.value, selectedFacility.value);

            for (const format of fetchedFormats) {
                format.elements = null;
            }

            formats.value = fetchedFormats;
        } catch (ex) {
            showError(ex);
        }
        loadingFormats.value = false;
    };

    const loadLayouts = async () => {
        try {
            layouts.value = await queries.fetchLayouts(ensType.value);
        } catch (ex) {
            showError(ex, message => fatalErrorMessage.value = message);
        }
    };

    const loadReferences = async () => {
        try {
            references.value = await queries.fetchReferences(ensType.value);
        } catch (ex) {
            showError(ex, message => fatalErrorMessage.value = message);
        }
    };

    const loadPaddings = async () => {
        if (ensType.value !== 'TagFormat')
            return;

        try {
            paddings.value = await queries.fetchPaddings(ensType.value);
        } catch (ex) {
            showError(ex, message => fatalErrorMessage.value = message);
        }
    };

    const loadFormatElements = async (formatIdentity) => {
        try {
            const elements = await queries.fetchFormatElements(ensType.value, formatIdentity, selectedFacility.value);

            for (const element of elements) {
                element.elementNo = parseInt(element.name);
                delete element.name;

                // Normalize optional attribute values
                element.include ??= false;
                element.show ??= false;
                element.required ??= false;
                element.constantValue = element.constantValue ?? '';
            }

            elements.sort(compareByProperty('elementNo'));

            return elements;
        } catch (ex) {
            showError(ex, message => fatalErrorMessage.value = message);
        }
    };

    // =================================================================================================================
    // == Application logic

    const filteredValidationRequest = (items) => {
        return items && {
            ...items,
            elements: items.elements?.map(element => _.omit(element, ['description']))
        };
    };

    const getValidationResult = async (abortSignal, validationRequest) => {
        try {
            const response = await ensValidateFormat(
                http,
                ensType.value,
                validationRequest,
                { signal: abortSignal }
            );
            return {
                value: response.data,
                error: null
            };
        } catch (error) {
            return {
                value: null,
                error: await getErrorMessage(error)
            };
        }
    };

    const validateFormat = cancelPreviousWhenCalledWithDebouncing(
        async function(abortSignal, readyPromise) {
            function isNullish(item) {
                return item === null || item === undefined;
            }

            // Avoid format validation if there are client-side errors
            const invalidElement = (element) =>
                isNullish(element.minimumCharacters) || isNullish(element.maximumCharacters);
            if (!selectedFormat.value.elements || selectedFormat.value.elements.some(invalidElement)) {
                validationResult.value = null;
                validationLoading.value = false;
                return;
            }

            const validationRequest = {
                category: selectedCategory.value.identity,
                facility: selectedFacility.value,
                name: selectedFormat.value.name,
                elements: selectedFormat.value.elements
                    .filter(e => e.include) // Only include elements that are in the ENS number
                    .map(e => ({
                        name: e.elementNo.toString(),
                        description: e.description,
                        constantValue: e.constantValue,
                        minimumCharacters: e.minimumCharacters,
                        maximumCharacters: e.maximumCharacters,
                        required: e.required,
                        include: true,
                        layout: e.layout
                    }))
            };

            if (_.isEqual(
                filteredValidationRequest(validationRequest),
                filteredValidationRequest(previousValidationRequest.value)
            )) {
                validationResult.value = previousValidationResult.value;
                validationLoading.value = false;
                return;
            }

            validationLoading.value = true;
            validationResult.value = null;

            await readyPromise;

            const result = await getValidationResult(abortSignal, validationRequest);

            // It is important to check for cancellation before resuming synchronous execution because the signal may
            // have been aborted between the completion of the async function and the resumption of this continuation.
            if (abortSignal.aborted)
                return;

            validationResult.value = result;
            validationLoading.value = false;

            previousValidationRequest.value = validationRequest;
            previousValidationResult.value = result;
        },
        {
            delay: 1000,
            debounceSettings: { leading: true }
        }
    );

    const changeSelectedFormat = async (newFormat, setElementsNull = false) => {
        if (!newFormat) {
            selectedFormat.value = null;
            return;
        }
        selectedFormat.value = {
            ...newFormat,
            elements: setElementsNull
                ? null
                : newFormat.elements || null
        };

        if (originalFormat.value) {
            if (!originalFormat.value.elements) {
                loadingFormatElements.value = true;
                originalFormat.value.elements = await loadFormatElements(originalFormat.value.identity);
                loadingFormatElements.value = false;
            }
            selectedFormat.value.elements ??= originalFormat.value.elements
                .filter(element => element.isValid)
                .map((element, index) => ({ ...element, elementNo: index }));
        }

        await validateFormat();
    };

    const deselectFormat = async () => {
        await changeSelectedFormat(null);
    };

    const deselectCategory = async () => {
        await deselectFormat();
        selectedCategory.value = null;
    };

    const changeSelectedFacility = async (facility) => {
        selectedFacility.value = facility;
        if (facility !== scopeStore.selectedScope) {
            scopeStore.updateSelectedScope(facility);
        }
        await deselectCategory();
        await loadFormats();
    };

    const changeSelectedCategory = async (category) => {
        selectedCategory.value = category;
        await deselectFormat();
    };

    const onSaveSuccess = async () => {
        showInfo('Changes saved!');
        loadingCreateRelease.value = false;
        loadingFormatElements.value = true;
        await loadFormats();
        if (originalFormat.value) {
            await changeSelectedFormat(selectedFormat.value);
        } else {
            await deselectFormat();
        }
        loadingFormatElements.value = false;
    };

    const createRelease = async (format) => {
        releaseId.value = null;
        releaseError.value = null;
        loadingCreateRelease.value = true;

        const getCodeSetLookup = async (libraryName) => {
            const codeSets = await getCodeSets(http, libraryName);
            return Object.fromEntries(
                codeSets.flatMap(codeSet =>
                    codeSet.scopes.map(facility => [facility, codeSet.name])
                )
            );
        };

        const formatToChangeCode = ensType.value === 'TagFormat'
            ? format => ({
                Name: format.name,
                IsValid: format.isValid,
                Description: format.description,
                TagCategory: format.category,
                Syntax: format.syntax,
                Regex: format.regex,
                RegexGrouped: format.regexGrouped
            })
            : format => ({
                Name: format.name,
                IsValid: format.isValid,
                Description: format.description,
                DocCategory: format.category,
                Syntax: format.syntax,
                Regex: format.regex,
                RegexGrouped: format.regexGrouped
            });

        const elementToChangeCode = ensType.value === 'TagFormat'
            ? (format, element) => ({
                Name: element.elementNo.toString(),
                IsValid: element.isValid,
                Description: element.description,
                MinimumCharacters: element.minimumCharacters,
                MaximumCharacters: element.maximumCharacters,
                InTagNo: element.include,
                Show: element.show,
                Required: element.required,
                SelectFlag: element.selectFlag,
                ConstantValue: element.constantValue ? spaceEncode(element.constantValue) : '{null}',
                Layout: element.layout,
                Reference: element.reference ?? '{null}',
                TagFormat: format.name,
                Padding: element.padding ?? '{null}'
            })
            : (format, element) => ({
                Name: element.elementNo.toString(),
                IsValid: element.isValid,
                Description: element.description,
                MinimumCharacters: element.minimumCharacters,
                MaximumCharacters: element.maximumCharacters,
                InDocNo: element.include,
                Show: element.show,
                Required: element.required,
                SelectFlag: element.selectFlag,
                ConstantValue: element.constantValue ? spaceEncode(element.constantValue) : '{null}',
                Layout: element.layout,
                Reference: element.reference ?? '{null}',
                DocNoFormat: format.name
            });

        const formatCodeSets = await getCodeSetLookup(ensType.value);
        const elementCodeSets = await getCodeSetLookup(ensType.value + 'Element');

        const changeDocuments = [
            {
                codeSetName: formatCodeSets[selectedFacility.value],
                libraryName: ensType.value,
                codes: [formatToChangeCode(format)]
            },
            {
                codeSetName: elementCodeSets[selectedFacility.value],
                libraryName: ensType.value + 'Element',
                codes: format.elements.map(element => elementToChangeCode(format, element))
            }
        ];

        if (!format.isValid) {
            changeDocuments.reverse();
        }

        try {
            const response = await createReleaseChain(http, changeDocuments);
            releaseId.value = response.data.releaseId;
        } catch (error) {
            releaseError.value = error;
        }
    };

    // =================================================================================================================
    // == Event handlers

    const showConfirmDialog = (params) => {
        buefy.dialog.confirm(params);
    };

    const confirmIfFormatIsDirty = (onConfirm, onCancel = null) => {
        if (hasUserChanges.value) {
            showConfirmDialog({
                message: 'Format has changes. If you continue, changes will be discarded.',
                cancelText: 'Cancel',
                confirmText: 'Continue',
                onConfirm: () => onConfirm(),
                onCancel: () => onCancel?.()
            });
        } else {
            onConfirm();
        }
    };

    const onElementsChanged = async (newElements) => {
        selectedFormat.value.elements = newElements;
        await validateFormat();
    };

    const onSelectFacility = (facility) => {
        if (selectedFacility.value === facility)
            return;
        if (cloneFormatModalActive.value)
            return;

        confirmIfFormatIsDirty(
            () => changeSelectedFacility(facility),
            () => {
                // Revert to previous scope
                scopeStore.updateSelectedScope(selectedFacility.value);
            }
        );
    };

    const onSelectCategory = (category) => {
        confirmIfFormatIsDirty(
            () => changeSelectedCategory(category)
        );
    };

    const onClickDiscardChanges = () => {
        showConfirmDialog({
            message: 'Are you sure you want to discard changes?',
            cancelText: 'No',
            confirmText: 'Yes',
            onConfirm: () => changeSelectedFormat(originalFormat.value, true)
        });
    };

    const onSelectFormat = (format) => {
        confirmIfFormatIsDirty(
            () => changeSelectedFormat(format, true)
        );
    };

    const onClickCreateNewFormat = () => {
        confirmIfFormatIsDirty(() => {
            changeSelectedFormat({
                name: crypto.randomUUID(),
                description: '',
                isValid: true,
                facility: selectedFacility.value,
                category: selectedCategory.value.identity,
                syntax: null,
                regex: null,
                regexGrouped: null,
                elements: []
            });
        });
    };

    const onClickCloneFormat = () => {
        confirmIfFormatIsDirty(() => {
            cloneFormatModalActive.value = true;
        });
    };

    const onCloseCloneFormatModal = () => {
        cloneFormatModalActive.value = false;
        scopeStore.updateSelectedScope(selectedFacility.value);
    };

    const onSubmitCloneFormat = async (event) => {
        const newFormat = {
            ...selectedFormat.value,
            name: crypto.randomUUID(),
            facility: event.facility,
            category: event.category,
            description: event.description,
            elements: selectedFormat.value.elements.map(element => {
                const newElement = { ...element };
                delete newElement.identity;
                return newElement;
            })
        };
        delete newFormat.identity;

        await changeSelectedFacility(newFormat.facility);

        selectedCategory.value = filteredCategories.value.find(x => x.identity === newFormat.category);

        await changeSelectedFormat(newFormat);
    };

    const onClickDeleteFormat = () => {
        showConfirmDialog({
            message: `Are you sure you want to delete ${selectedFormat.value.description}?`,
            onConfirm: async () => {
                if (isNewFormat.value) {
                    await deselectFormat();
                } else {
                    await createRelease({
                        ...originalFormat.value,
                        isValid: false,
                        elements: originalFormat.value.elements.map(element => ({
                            ...element,
                            isValid: false
                        }))
                    });
                }
            }
        });
    };

    const onClickCreateRelease = async () => {
        await createRelease(selectedFormatWithAllChanges.value);
    };

    // =================================================================================================================
    // == URL handling

    const initializeSelectionsFromRoute = async () => {
        try {
            const facility
                = scopeStore.scopes
                    .find(scope => equalsIgnoreCase(scope.identity, route.query.facility))
                    ?.identity
                    ?? null;

            // If the facility can't be found, this will clear the preselected facility
            await changeSelectedFacility(facility);

            if (!facility)
                return;

            // AB#169461: 'formatid' and 'name' are deprecated and will be removed in the future
            const identity = (
                route.query.formatIdentity
                || route.query.formatid
                || route.query.name
            )?.toUpperCase(); // Identity is case-insensitive

            if (!identity)
                return;

            const format = formats.value.find(format => format.identity === identity && format.facility === selectedFacility.value);

            if (!format)
                return;

            const category = filteredCategories.value.find(category => category.identity === format.category);
            assert(category, 'Category not found for format');

            await changeSelectedCategory(category);
            await changeSelectedFormat(format);
        } catch (ex) {
            showError(ex, message => fatalErrorMessage.value = 'Unexpected error while loading selection from URL: ' + message);
        }
    };

    const updateRouteFromSelections = async () => {
        if (initialLoading.value)
            return;
        await router.replace({
            query: {
                facility: selectedFacility.value,
                formatIdentity: selectedFormat.value?.identity
            }
        });
    };

    watchEffect(async () => {
        if (initialLoading.value)
            return;
        await updateRouteFromSelections();
    });

    // =================================================================================================================
    // == Initialization

    (async function init() {
        scopeStore.setScopeType('Facility');

        await Promise.all([
            scopeStore.init(),
            loadAccessGroupsForFormatLib(),
            loadPoliciesForFormatLib(),
            loadCategories(),
            loadLayouts(),
            loadReferences(),
            loadPaddings(),
            loadDescriptionRegex()
        ]);

        if (route.query.facility !== undefined) {
            await initializeSelectionsFromRoute();
        } else if (scopeStore.selectedScope) {
            await changeSelectedFacility(scopeStore.selectedScope);
        }

        initialLoading.value = false;
    })();
</script>

<template>
    <div>
        <b-loading :model-value="initialLoading" />

        <clone-format-modal
            v-if="cloneFormatModalActive"
            :ens-type="ensType"
            :categories="categories"
            :original-format="selectedFormat"
            @close="onCloseCloneFormatModal"
            @submit="onSubmitCloneFormat" />

        <code-edit-save-progress
            :save-process-started="loadingCreateRelease"
            :release-id="releaseId"
            :external-error="releaseError"
            :is-release-chain="true"
            :close-enabled="false"
            @success="onSaveSuccess" />

        <h1 class="title">
            {{ title }}
        </h1>

        <b-message
            type="is-danger"
            has-icon
            title="Error"
            :model-value="!!fatalErrorMessage"
            @update:model-value="fatalErrorMessage = null">
            {{ fatalErrorMessage }}
        </b-message>

        <b-field grouped>
            <scope-dropdown
                @update:selected-scope="onSelectFacility" />
            <b-field>
                <custom-select
                    class="category-select"
                    :model-value="selectedCategory"
                    :options="categorySelectOptions"
                    :disabled="!selectedFacility"
                    placeholder="Select category"
                    :label="selectedCategory?.description"
                    icon="folder"
                    icon-pack="fa"
                    @update:model-value="onSelectCategory" />
            </b-field>
            <b-field>
                <custom-select
                    class="format-select"
                    :model-value="selectedFormat"
                    :options="formatSelectOptions"
                    :disabled="!selectedCategory || !filteredFormats.length"
                    :placeholder="selectedFormatLabel"
                    :label="selectedFormat?.description"
                    icon="file"
                    icon-pack="fa"
                    :loading="loadingFormats"
                    @update:model-value="onSelectFormat" />
            </b-field>
        </b-field>
        <hr>

        <b-field
            grouped>
            <b-field>
                <p
                    class="control">
                    <b-button
                        v-require-can-edit-code="permissionsObject"
                        icon-left="plus"
                        icon-pack="fa"
                        :disabled="!canAddNewFormat"
                        @click="onClickCreateNewFormat">
                        Add format
                    </b-button>
                </p>
            </b-field>
            <b-field>
                <p
                    class="control">
                    <b-button
                        icon-left="copy"
                        icon-pack="fa"
                        :disabled="!canCloneFormat"
                        @click="onClickCloneFormat">
                        Clone format
                    </b-button>
                </p>
            </b-field>
            <b-field>
                <p
                    class="control">
                    <b-button
                        v-require-can-edit-code="permissionsObject"
                        icon-left="trash"
                        icon-pack="fa"
                        :disabled="!canDeleteFormat"
                        @click="onClickDeleteFormat">
                        Delete format
                    </b-button>
                </p>
            </b-field>
            <b-field
                class="is-right-justified" />
            <status-indicator
                :has-user-changes="hasUserChanges"
                :validation-loading="validationLoading"
                :has-validation-error="!!validationResult?.error" />
            <b-field>
                <p
                    class="control">
                    <b-button
                        icon-left="undo"
                        icon-pack="fa"
                        :disabled="!hasUserChanges"
                        @click="onClickDiscardChanges">
                        Revert
                    </b-button>
                </p>
            </b-field>
            <b-field>
                <p
                    class="control">
                    <b-button
                        v-require-can-edit-code="permissionsObject"
                        :loading="loadingCreateRelease"
                        :disabled="!canCreateRelease"
                        icon-left="save"
                        icon-pack="fa"
                        type="is-primary"
                        @click="onClickCreateRelease">
                        Save
                    </b-button>
                </p>
            </b-field>
        </b-field>

        <b-field
            v-if="selectedFormat"
            label="Format Description"
            :type="invalidDescriptionError ? 'is-danger' : ''"
            :message="invalidDescriptionError">
            <b-input
                v-model="selectedFormat.description"
                :maxlength="invalidDescriptionError ? formatDescriptionRegexValidator?.maxLength: undefined"
                expanded />
        </b-field>

        <edit-table-format-element
            v-if="selectedFormat"
            class="has-margin-top"
            :ens-type="ensType"
            :loading="loadingFormatElements"
            :initial-elements="selectedFormat.elements ?? []"
            :layouts="layouts"
            :references="references"
            :paddings="paddings"
            :format-name="selectedFormat.name"
            :is-format-new="isNewFormat"
            :libraries="libraryNames"
            :description-regex-validator="elementDescriptionRegexValidator"
            :permissions-object="permissionsObject"
            :validation-loading="validationLoading"
            :validation-result="validationResult?.value"
            :validation-error="validationResult?.error"
            @change="onElementsChanged" />
        <p
            v-else
            style="text-align: center"
            class="has-margin-top2">
            No format selected.
        </p>

        <p class="has-margin-top2">
            To validate a {{ ensNumberTypeDescription }} using the defined formats, use the
            <router-link
                to="/EnsNumber">
                <span>ENS number validator</span>
            </router-link>.
        </p>
    </div>
</template>

<style scoped>
.category-select :deep(button),
.format-select :deep(button) {
    min-width: 200px;
}

.is-right-justified {
    margin-left: auto;
}
</style>
