<template>
    <b-field
        :type="isValid ? '' : 'is-danger'">
        <b-taginput
            id="filterInput"
            v-model="frags"
            tabindex="0"
            :data="suggestions"
            :open-on-focus="true"
            :closable="false"
            :keep-first="true"
            autocomplete
            field="description"
            :allow-new="false"
            :placeholder="getPlaceHolder"
            :confirm-keys="['Tab', 'Enter']"
            @keydown.tab.prevent
            @typing="currentText = $event" />
        <p class="control">
            <b-button
                @click="helpActive = true">
                <b-icon
                    size="is-small"
                    icon="help" />
            </b-button>
        </p>
    </b-field>

    <b-modal
        v-model="helpActive"
        :width="640"
        scroll="keep">
        <div class="card">
            <div class="card-content">
                <div class="content">
                    <h1>Filtering</h1>Searches or queries can be used to filter the data in the table.
                    Queries are constructed using a column name, then a operator, and then a operand.
                    Example: Diameter &lt; 12
                    <br>Available operators for queries depend on the type of data in the column.
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
    import _ from 'lodash';
    import FilterBuilder from '../helpers/filterBuilder.js';
    import { compareByProperty, isNullish } from '@/shared/helpers/utils.js';

    export default {
        props: {
            operands: {
                type: Array,
                required: true
            },
            searchElements: {
                type: Array,
                required: true
            },
            columnDefinitions: {
                type: Array,
                required: true
            }
        },
        emits: [
            'query-filter',
            'regex-filter'
        ],
        data() {
            return {
                frags: [],
                suggestions: [],
                currentText: '',
                helpActive: false
            };
        },
        computed: {
            prioritySortedOperands() {
                if (isNullish(this.operands) || this.operands.length === 0)
                    return [];

                const prioritizedAttributeNames = new Set([
                    'identity',
                    'name',
                    'isValid',
                    'description',
                    'dateCreated',
                    'dateUpdated',
                    'attachmentKey'
                ]);
                const [prioritizedOperands, notPrioritizedOperands]
                    = _.partition(this.operands,
                                  function(operand) {
                                      return prioritizedAttributeNames.has(operand.field);
                                  });
                notPrioritizedOperands.sort(compareByProperty('field'));
                return [...prioritizedOperands, ...notPrioritizedOperands];
            },
            getPlaceHolder() {
                switch (this.frags.length) {
                    case 0:
                        return 'Column Name or Search';
                    case 1:
                        return 'Operator';
                    case 2:
                        return this.filterBuilder.attributeType + ' value';
                    default:
                        return 'search';
                }
            },
            columns() {
                return this.prioritySortedOperands.map(o => o.field);
            },
            isValid() {
                return this.frags.length < 3 || this.filterBuilder.isValid;
            },
            computedSuggestions() {
                if (this.frags.length === 3)
                    return [];

                const text = this.currentText;

                const filterSortAC = o => {
                    if (o.value) {
                        o = o.value;
                    }
                    return o
                        .toString()
                        .toLowerCase()
                        .includes(text);
                };

                let data;
                if (this.frags.length === 0) {
                    data = this.transformToObjectArray(this.columns.filter(filterSortAC));
                    data.push({ description: `Use "${text}" as filter`, value: text, isNew: true });
                } else if (this.filterBuilder.choices) {
                    if (this.frags.length === 2) {
                        data = _.chain(this.transformToObjectArray(this.filterBuilder.choices))
                            .filter(filterSortAC)
                            .uniqBy(e => e.value)
                            .slice(0, 25)
                            .sortBy('value')
                            .value();
                        if (data.some(o => o.codeIdentity)) {
                            data = _.chain(data)
                                .uniqBy(e => e.codeIdentity)
                                .sortBy(e => e.value)
                                .value();
                        }
                    } else {
                        data = this.transformToObjectArray(this.filterBuilder.choices
                            .filter(filterSortAC)
                            .slice(0, 25));
                    }
                } else {
                    data = [];
                }

                if (this.frags.length > 0 && this.filterBuilder.allowNew) {
                    data.push({ description: `"${text}"`, value: text, isNew: true });
                }

                return data;
            },
            filterBuilder() {
                const filterBuilder = new FilterBuilder()
                    .withColumns(this.prioritySortedOperands)
                    .withRows(this.searchElements);
                const fragments = this.frags;

                if (fragments.length >= 1) {
                    if (!fragments[0].isNew) {
                        filterBuilder.withAttribute(fragments[0].value);
                    } else {
                        filterBuilder.withRegex(fragments[0].value);
                    }
                }
                if (fragments.length >= 2) {
                    filterBuilder.withOperator(fragments[1].value);
                }
                if (fragments.length >= 3) {
                    if (fragments[2].codeIdentity && filterBuilder.operator !== 'like') {
                        filterBuilder.withCriterion(fragments[2].codeIdentity);
                    } else {
                        filterBuilder.withCriterion(fragments[2].value);
                    }
                }
                return filterBuilder;
            }
        },
        watch: {
            // As a workaround for https://github.com/ntohq/buefy-next/issues/247, we need to mirror the computed
            // property to a data property. The data property wraps each element in a proxy, which makes the reference
            // comparison done by the autocomplete component work correctly.
            computedSuggestions: {
                handler(suggestions) {
                    this.suggestions = suggestions;
                },
                immediate: true
            },
            filterBuilder(filterBuilder) {
                if (filterBuilder.filter && filterBuilder.isValid) {
                    const filter = filterBuilder.filter;

                    this.$emit(`${filter.type}-filter`, filter.value);
                    this.clearFilter();
                }
            }
        },
        methods: {
            clearFilter() {
                this.frags = [];
            },
            transformToObjectArray(oldArray) {
                return _.chain(oldArray)
                    .map(e => {
                        return typeof e === 'string'
                            ? {
                                description: e,
                                value: e
                            }
                            : {
                                description: e.value,
                                value: e.value,
                                codeIdentity: e.codeIdentity
                            };
                    })
                    .uniqBy(e => e.value)
                    .sortBy(e => e.codeIdentity ?? e)
                    .value();
            }
        }
    };
</script>

<style scoped>
#filterInput {
    flex-basis: 69ch;

    /* Buefy bugfix */
    & :deep(.taginput-container) {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        padding-right: 1px;
        height: 100%;
    }
}
</style>
