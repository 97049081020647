// @ts-nocheck

import _ from 'lodash';

function joinWithOptionalLinebreak(elements, separator) {
    if (elements.length <= 5) {
        return elements.join(separator);
    } else {
        const elementsWithSep = elements.slice(0, -1).map(e => e + separator);
        elementsWithSep.push(elements[elements.length - 1]);
        return _.chunk(elementsWithSep, 4).map(chunk => '\n    ' + chunk.join('')).join('');
    }
}

export const makeDefaultQuery = function(lib, attributes) {
    const selects = new Array('Name', 'Description');

    if (!lib.isGlobal)
        selects.push('Scope');

    attributes.forEach(a => {
        selects.push(a.name);
    });

    let q = `TAKE 1000\nFROM ${lib.name}\nSELECT ${joinWithOptionalLinebreak(selects, ', ')}\n`;

    q += 'WHERE IsValid = true';

    return q;
};
