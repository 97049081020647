<template>
    <div v-if="result">
        <h3 class="title is-3">
            <b-icon
                v-if="!allReferenceValuesFoundInLookup"
                icon="alert-circle"
                style="color: #FF9200;" />
            <b-icon
                v-else-if="numberOfMatchedFormats > 1"
                icon="check-circle"
                style="color: #FF9200;" />
            <b-icon
                v-else-if="numberOfMatchedFormats === 1 && allReferenceValuesFoundInLookup"
                icon="check-circle"
                style="color: green;" />
            <b-icon
                v-else
                icon="close-circle"
                style="color: red;" />
            {{ result.number }}
        </h3>

        <template
            v-for="(warning, i) in result.warnings"
            :key="i + warning.message">
            <template v-if="warning.type === 'NON_STANDARD_CHARACTERS' && !result.matchFound">
                <b-message
                    type="is-warning"
                    has-icon>
                    The ENS number contains some non-standard characters that may have caused matching to fail:
                    <br>
                    {{ replaceInvalidChars(result.number) }}
                </b-message>
            </template>
            <template v-else>
                <!--Any unhandled warning types are displayed directly-->
                <b-message
                    type="is-warning"
                    has-icon>
                    {{ warning.type }}: {{ warning.message }}
                </b-message>
            </template>
        </template>

        <div>
            <h2 class="title is-4">
                Matched formats: {{ numberOfMatchedFormats }}
            </h2>
            <div v-if="result.matchFound">
                <div
                    v-for="match in result.matches"
                    :key="match.formatName"
                    class="box">
                    <h4 class="subtitle is-6">
                        Format description: <b> {{ match.formatDescription }} </b>
                    </h4>

                    <h4 class="subtitle is-6">
                        Syntax: <b>{{ match.syntax }}</b>
                    </h4>
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Element name</th>
                                <th>Reference</th>
                                <th>Value</th>
                                <th>Value description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="element in match.elements">
                                <td>{{ element.description }}</td>
                                <td>{{ element.referenceType }}</td>
                                <td>{{ element.value }}</td>
                                <td
                                    v-if=" element.referenceLibraryName && !element.valueDescription"
                                    style="color: red;">
                                    {{ `Value not valid. Value not found in the ${element.referenceLibraryName} library.` }}
                                </td>
                                <td
                                    v-if=" element.referenceLibraryName && element.valueDescription && !element.isValid"
                                    style="color: #FF9200;">
                                    {{ `Value is set historic (IsValid=false) in the ${element.referenceLibraryName} library.` }}
                                </td>
                                <td v-else>
                                    {{ element.valueDescription }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <br>
            <template v-if="result.unmatchedFormats.length > 1">
                <h2
                    class="title is-4">
                    Unmatched formats: {{ result.unmatchedFormats.length }}
                </h2>
                <div
                    v-for="match in result.unmatchedFormats"
                    :key="match.formatName"
                    class="box">
                    <h4 class="subtitle is-6">
                        Format description: <b> {{ match.formatDescription }} </b>
                    </h4>

                    <h4 class="subtitle is-6">
                        Syntax: <b>{{ match.syntax }}</b>
                    </h4>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'EnsNumberValidationResult',
        props: {
            result: {
                type: Object,
                default: null
            }
        },
        computed: {
            allReferenceValuesFoundInLookup() {
                if (!this.result?.matches) {
                    return false;
                }
                return !this.result.matches
                    .some(match => match.elements
                        .some(element => element.referenceLibraryName && element.valueDescription === null));
            },
            numberOfMatchedFormats() {
                return this.result?.matches?.length;
            }
        },
        methods: {
            replaceInvalidChars(string) {
                return string.replace(/[^ -~]+/g, '�');
            }
        }
    };
</script>

<style scoped>

</style>
