<template>
    <spinner
        :loading="loading">
        <div>
            <div class="columns">
                <div class="column">
                    <div class="panel is-success">
                        <div class="panel-heading">
                            <b-icon
                                icon="graph"
                                size="is-small" />
                            Library network explorer
                            <a
                                class="is-pulled-right"
                                @click="showSettings = !showSettings">
                                <b-icon
                                    icon="cog"
                                    size="is-small" /></a>
                        </div>
                        <div class="panel-block">
                            <network-visualization
                                ref="network"
                                class="network"
                                :nodes="nodes"
                                :edges="edges"
                                :options="options"
                                @click="networkClick" />
                        </div>
                    </div>
                    <p>
                        Click nodes to view details. Grab nodes to move them, grab the background to pan, and use the mousewheel to zoom. Right-click should give you the option to save the graph as an image.
                    </p>
                </div>
                <div class="column is-one-quarter">
                    <div class="panel is-success">
                        <div class="panel-heading">
                            <b-icon
                                icon="filter"
                                size="is-small" />
                            Library group filter
                        </div>
                        <div>
                            <div class="filter-set-operation-container columns column is-full">
                                <b-field class="library-group-container">
                                    <b-radio
                                        v-model="filterSetOperation"
                                        native-value="OR">
                                        OR
                                    </b-radio>
                                    <b-radio
                                        v-model="filterSetOperation"
                                        native-value="AND">
                                        AND
                                    </b-radio>
                                </b-field>
                            </div>
                            <hr>
                            <tag-filter-columns
                                class="tag-filter-columns"
                                :active-tags="selectedTags"
                                :show-other="showOther"
                                :display-show-other-option="true"
                                @update:tags="updateTagsFilter"
                                @update:show-other="setShowOther" />
                        </div>
                        <div class="panel-block">
                            <div class="field">
                                <div class="buttons has-addons">
                                    <button
                                        class="button"
                                        @click="selectAllGroups">
                                        Select all
                                    </button>
                                    <button
                                        class="button"
                                        @click="unselectAllGroups">
                                        Deselect all
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        v-show="showSettings"
                        class="panel is-info">
                        <div class="panel-heading">
                            <b-icon
                                icon="cog"
                                size="is-small" />
                            Physics options
                        </div>
                        <div class="panel-block is-block">
                            <div class="field is-horizontal">
                                <div class="field-label is-normal">
                                    <label class="label">Solver</label>
                                </div>
                                <div class="field-body">
                                    <div class="field">
                                        <div class="select is-fullwidth">
                                            <select v-model="options.physics.solver">
                                                <option value="barnesHut">
                                                    Barnes-Hut
                                                </option>
                                                <option value="repulsion">
                                                    Repulsion
                                                </option>
                                                <option value="forceAtlas2Based">
                                                    ForceAtlas2
                                                </option>
                                                <option value="hierarchicalRepulsion">
                                                    Hierarchical repulsion
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="field">
                                        <div class="control">
                                            <b-checkbox v-model="options.physics.enabled">
                                                Enabled
                                            </b-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="selectedLibrary"
                        class="panel is-primary">
                        <p class="panel-heading">
                            <b-icon
                                icon="book"
                                size="is-small" />
                            Library details
                        </p>
                        <div class="panel-block">
                            <table
                                class="table"
                                style="width:100%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <router-link :to="getLibraryLink(selectedLibrary.name)">
                                                {{ selectedLibrary.name }}
                                            </router-link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ selectedLibrary.description }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-show="selectedLibrary.tags.length > 0">
                            <div
                                class="panel-block">
                                <p><b>Library groups:</b></p>
                            </div>
                            <div class="panel-block">
                                <table class="table is-striped is-narrow is-fullwidth">
                                    <tr
                                        v-for="tag in selectedLibrary.tags"
                                        :key="tag">
                                        <td>{{ tag }}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div v-show="selectedLibrary.attributeDefinitions.length > 0">
                            <div
                                class="panel-block">
                                <p><b>Attributes:</b></p>
                            </div>
                            <div class="panel-block">
                                <table class="table is-striped is-narrow is-fullwidth">
                                    <tr
                                        v-for="attr in selectedLibraryAttributeDefinitions"
                                        :key="attr.name">
                                        <td>{{ attr.name }}</td>
                                        <td v-if="attr.referenceLibraryName">
                                            <span
                                                class="library-reference"
                                                @mouseover="referenceHover(attr.referenceLibraryName)"
                                                @mouseleave="referenceLeave(attr.referenceLibraryName)">
                                                {{ attr.referenceLibraryName }}
                                            </span>
                                        </td>
                                        <td v-else>
                                            {{ attr.attributeType }}
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div
                            v-show="selectedLibraryToEdges.length > 0">
                            <div
                                class="panel-block">
                                <p><b>References:</b></p>
                            </div>
                            <div class="panel-block">
                                <table class="table is-striped is-narrow is-fullwidth">
                                    <tr
                                        v-for="edge in selectedLibraryToEdges"
                                        :key="edge.sourceLib+edge.attribute">
                                        <td>
                                            <span
                                                class="library-reference"
                                                @mouseover="referenceHover(edge.sourceLib)"
                                                @mouseleave="referenceLeave(edge.sourceLib)">
                                                {{ edge.sourceLib }}
                                            </span>
                                        </td>
                                        <td>{{ edge.attribute }}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </spinner>
</template>

<script>
    import _ from 'lodash';
    import { getLibraryLink } from '@/shared/helpers/routing';
    import { getLibraries } from '@/shared/helpers/api';
    import Spinner from '@/shared/components/Spinner.vue';
    import TagFilterColumns from '@/shared/components/TagFilterColumns.vue';
    import NetworkVisualization from '@/shared/vis/NetworkVisualization.vue';

    export default {
        components: {
            NetworkVisualization,
            Spinner,
            TagFilterColumns
        },
        data: () => ({
            libraries: null,
            tags: null,
            options: {
                nodes: {
                    shape: 'box'
                },
                physics: {
                    enabled: true,
                    solver: 'forceAtlas2Based'
                }
            },
            nodes: [],
            selectedLibrary: null,
            selectedTags: [],
            showSettings: false,
            showOther: false,
            displayLibrary: null,
            loading: true,
            filterSetOperation: null
        }),
        computed: {
            edges() {
                if (this.libraries === null)
                    return [];
                const edges = [];
                this.libraries.forEach(lib => {
                    lib.attributeDefinitions.forEach(ad => {
                        if (ad.referenceLibraryName !== null) {
                            edges.push({
                                from: lib.name,
                                to: ad.referenceLibraryName,
                                arrows: 'to'
                            });
                        }
                    });
                });
                return edges;
            },
            selectedLibraryAttributeDefinitions() {
                return _.sortBy(this.selectedLibrary.attributeDefinitions, 'sequenceNumber');
            },
            selectedLibraryToEdges() {
                if (this.libraries === null)
                    return [];
                if (this.selectedLibrary === null)
                    return [];
                const targetLib = this.selectedLibrary.name;
                const edges = [];
                this.libraries.forEach(lib => {
                    lib.attributeDefinitions.forEach(ad => {
                        if (ad.referenceLibraryName && ad.referenceLibraryName === targetLib) {
                            edges.push({
                                sourceLib: lib.name,
                                attribute: ad.name
                            });
                        }
                    });
                });
                return _.sortBy(edges, 'sourceLib');
            }
        },
        watch: {
            selectedTags() {
                localStorage.setItem('LibraryList.tagFilter', JSON.stringify(this.selectedTags));
                this.makeNodes();
            },
            showOther() {
                this.makeNodes();
            },
            libraries() {
                this.makeNodes();
            },
            filterSetOperation() {
                localStorage.setItem('LibraryList.filterSetOperation', this.filterSetOperation);
                this.makeNodes();
            }
        },
        async mounted() {
            this.filterSetOperation = localStorage.getItem('LibraryList.filterSetOperation') || 'OR';
            this.selectedTags = JSON.parse(localStorage.getItem('LibraryList.tagFilter')) || [];
            this.libraries = await getLibraries(this, '', '', true);
            const temp = new Set();
            this.libraries.forEach(lib => {
                lib.tags.forEach(tag => {
                    temp.add(tag);
                });
            });
            this.tags = [...temp].sort();
            this.displayLibrary = decodeURIComponent(this.$route.params.library);
            this.loading = false;
        },
        methods: {
            networkClick(e) {
                if (this.libraries === null)
                    return;
                if (e.nodes.length === 1) {
                    this.selectedLibrary = _.find(this.libraries, { name: e.nodes[0] });
                } else {
                    this.selectedLibrary = null;
                }
            },
            setShowOther(val) {
                this.showOther = val;
            },
            referenceHover(libName) {
                this.$refs.network.visData.nodes.update([{ id: libName, label: libName, color: '#FF3300', font: { color: '#FFFFFF' } }]);
            },
            referenceLeave(libName) {
                this.$refs.network.visData.nodes.update([{ id: libName, color: null, font: null }]);
            },
            makeNodes() {
                if (this.libraries === null)
                    return;

                if (this.displayLibrary && this.displayLibrary !== 'undefined') {
                    const l = _.find(this.libraries, { name: this.displayLibrary });
                    this.selectedLibrary = l;
                    this.displayLibrary = null;
                    const _nodes = [{
                        id: l.name,
                        label: l.name
                    }];
                    this.selectedLibrary.attributeDefinitions.forEach(ad => {
                        if (ad.referenceLibraryName) {
                            _nodes.push({
                                id: ad.referenceLibraryName,
                                label: ad.referenceLibraryName
                            });
                        }
                    });
                    this.selectedLibraryToEdges.forEach(edge => {
                        _nodes.push({
                            id: edge.sourceLib,
                            label: edge.sourceLib
                        });
                    });
                    this.nodes = _.uniqBy(_nodes, 'id');
                } else {
                    this.nodes = this.applyTagFilter(this.libraries).map(lib => {
                        return {
                            id: lib.name,
                            label: lib.name
                        };
                    });
                }
            },
            applyTagFilter(libs) {
                const tags = this.selectedTags;

                if (this.filterSetOperation === 'OR')
                    return libs.filter(l => l.tags.length === 0 && this.showOther
                        || l.tags.some(t => this.selectedTags.includes(t)));
                else if (this.filterSetOperation === 'AND')
                    if (tags && tags.length === 0)
                        return libs.filter(l => l.tags.length === 0 && this.showOther);
                    else if (tags && tags.length > 0 && this.showOther)
                        return [];
                    else
                        return libs.filter(l => this.selectedTags.every(t => l.tags.includes(t)));
            },
            selectAllGroups() {
                this.selectedTags = [...this.tags];
                this.showOther = true;
            },
            unselectAllGroups() {
                this.selectedTags = [];
                this.showOther = false;
            },
            getLibraryLink,
            updateTagsFilter(tags) {
                this.selectedTags = tags;
            }
        }
    };
</script>

<style scoped>
.network {
    height: 800px;
    width: 100%;
    border: none;
    margin: 5px 0;
}
.library-reference {
    font-weight: bold;
    color: green;
    cursor: pointer;
}
.tag-filter-columns {
    height:300px;
    margin-left:5px;
    margin-right:5px;
    margin-bottom:5px;
}
.filter-set-operation-container{
    width: 100%;
    margin-left:5px;
    margin-right:5px;
    margin-top:5px;
    padding-left:5px;
    padding-bottom:0px;
}
</style>
