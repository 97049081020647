<template>
    <div class="kpi-element">
        <div class="kpi-label">
            {{ label }}
        </div>
        <div :class="valueClass">
            {{ $filters.thousandSpaceSeparator(value) }}
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            label: {
                type: String,
                required: true
            },
            value: {
                type: Number,
                required: true
            },
            category: {
                type: String,
                required: false,
                default: '',
                enum: [null, 'scale', 'usage', 'integration']
            }
        },
        computed: {
            valueClass() {
                return {
                    'kpi-value': true,
                    'kpi-value-scale': this.category === 'scale',
                    'kpi-value-usage': this.category === 'usage',
                    'kpi-value-integration': this.category === 'integration'
                };
            }
        }
    };
</script>

<style scoped>
.kpi-element {
    margin: 4px;
    padding: 0px;

    width: 250px;
    height: 28px;
    font-size: 12px;
}
.kpi-label {
    width: 150px;
    padding: 5px;
    padding-left:10px;
    background-color: rgb(63, 63, 63);
    color: whitesmoke;
    float: left;
}
.kpi-value {
    width: 98px;
    padding: 5px;
    text-align: right;
    float: left;
    background-color: #a0ccd4;
}
.kpi-value-scale {
    background-color: #a0d4b2;
}
.kpi-value-usage {
    background-color: #d4d19d;
}
.kpi-value-integration {
    background-color: #cb9fd6;
}

</style>
