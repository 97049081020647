export function generateNameByConvention(schema1, mappingInstance1, schema2, mappingInstance2) {
    const shortHandOrName1 = schema1.Shorthand ? schema1.Shorthand : schema1.Name;
    const shortHandOrName2 = schema2.Shorthand ? schema2.Shorthand : schema2.Name;

    if (mappingInstance2) {
        return `${shortHandOrName1}-${mappingInstance1}2${shortHandOrName2}-${mappingInstance2}`;
    }

    return `${shortHandOrName1}-${mappingInstance1}2${shortHandOrName2}`;
}
