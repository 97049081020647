<template>
    <div>
        <h1 class="title">
            Attachment search
        </h1>
        <h2 class="subtitle">
            Search for files attached to library items.
        </h2>
        <hr>
        <div class="columns">
            <div class="column is-one-quarter">
                <b-field label="Library">
                    <b-select
                        v-model="selectedLibrary"
                        :loading="loadingLibraries"
                        expanded
                        placeholder="Select a library">
                        <option
                            v-for="lib in libraries"
                            :key="lib.name"
                            :value="lib">
                            {{ lib.name }}
                        </option>
                    </b-select>
                </b-field>
                <b-field label="MimeType">
                    <b-select
                        v-model="selectedMimeType"
                        :loading="loadingMimeTypes"
                        expanded
                        placeholder="Select a MimeType">
                        <option
                            v-for="type in mimeTypes"
                            :key="type"
                            :value="type">
                            {{ type }}
                        </option>
                    </b-select>
                </b-field>
                <b-field label="FileName">
                    <b-input
                        v-model="inputFileName"
                        model-value=""
                        placeholder="FileName like (use * as wildcard)" />
                </b-field>
                <b-field>
                    <b-button
                        type="is-primary"
                        expanded
                        :loading="loadingData"
                        @click="search">
                        Search
                    </b-button>
                </b-field>
            </div>
            <div class="column">
                <b-message
                    v-show="errorActive"
                    type="is-danger"
                    has-icon>
                    {{ errorMessage }}
                </b-message>
                <h3
                    v-if="showData"
                    class="title is-3">
                    Row count: {{ dataCount }}
                </h3>
                <b-table
                    v-if="showData"
                    striped
                    paginated
                    :data="data">
                    <b-table-column
                        v-slot="props"
                        field="fileName"
                        label="FileName">
                        <span>
                            <a
                                href="#"
                                @click="downloadAttachment(props.row.attachmentKey, props.row.fileName)">
                                <b-icon
                                    icon="download"
                                    size="is-small" /> {{ props.row.fileName }}</a></span>
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="codeName"
                        label="Item">
                        <span
                            v-if="props.row.codeId">
                            <a
                                :href="getCodeLinkByB64Id(encodeIdBase64('Code', props.row.codeId))">
                                {{ props.row.codeName }}</a></span>
                        <span
                            v-else-if="props.row.libraryGroup">
                            <a
                                :href="getLibraryListLink([props.row.libraryGroup])">
                                <b>Group: {{ props.row.libraryGroup }}</b></a></span>
                        <span
                            v-else-if="props.row.libraryName">
                            <router-link
                                :to="getLibraryLink(props.row.libraryName)">
                                <b>Library: {{ props.row.libraryName }}</b>
                            </router-link>
                        </span>
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="codeSetDescription"
                        label="Set">
                        {{ props.row.codeSetDescription }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="libraryName"
                        label="Library">
                        {{ props.row.libraryName }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="mimeType"
                        label="MimeType">
                        {{ props.row.mimeType }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        numeric
                        field="sizeInBytes"
                        label="Size">
                        {{ prettyBytes(props.row.sizeInBytes) }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="attachmentUpdatedDate"
                        label="Date">
                        {{ $filters.dateFormatShort(props.row.attachmentUpdatedDate) }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="storageLocation"
                        label="Storage location">
                        {{ props.row.storageLocation }}
                    </b-table-column>
                    <template #empty>
                        <section class="section">
                            <div class="content has-text-grey has-text-centered">
                                <p>No attachments matches search parameters</p>
                            </div>
                        </section>
                    </template>
                </b-table>
            </div>
        </div>
    </div>
</template>

<script>
    import { getLibraries, getMimeTypes, searchAttachments } from '@/shared/helpers/api';
    import { getAttachment } from '@/shared/helpers/api.ts';
    import { prettyBytes } from '@/shared/helpers/prettyBytes.js';
    import { getCodeLinkByB64Id, getLibraryLink, getLibraryListLink } from '@/shared/helpers/routing.js';
    import { encodeIdBase64 } from '@/shared/helpers/utils.js';
    import { showMixin } from '@/shared/mixins/showMixin';
    import saveAs from 'file-saver';
    import _ from 'lodash';

    export default {
        mixins: [
            showMixin
        ],
        data() {
            return {
                loadingData: false,
                loadingLibraries: false,
                loadingMimeTypes: false,
                showData: false,
                data: [],
                mimeTypes: [],
                libraries: [],
                selectedLibrary: null,
                selectedMimeType: null,
                inputFileName: '',
                errorActive: false,
                errorMessage: '',
                columns: [
                    {
                        field: 'libraryName',
                        label: 'Library name',
                        searchable: true,
                        sortable: true
                    },
                    {
                        field: 'fileName',
                        label: 'FileName',
                        searchable: true,
                        sortable: true
                    },
                    {
                        field: 'mimeType',
                        label: 'MimeType',
                        sortable: true
                    }
                ]
            };
        },
        computed: {
            dataCount() {
                return this.data.length;
            }
        },
        mounted() {
            this.getLibraries();
            this.getMimeTypes();
        },
        methods: {
            async search() {
                this.loadingData = true;
                this.data = [];
                this.errorActive = false;
                try {
                    this.data = (await searchAttachments(this, {
                        libraryName: this.selectedLibrary?.name,
                        mimeType: this.selectedMimeType,
                        fileNameLike: this.inputFileName
                    })).data;
                    this.showData = true;
                } catch (ex) {
                    this.showError(ex, message => {
                        this.errorMessage = message;
                        this.errorActive = true;
                    });
                }
                this.loadingData = false;
            },
            async getLibraries() {
                this.loadingLibraries = true;
                this.errorActive = false;
                try {
                    this.libraries = _.orderBy(await getLibraries(this), 'name');
                } catch {
                    this.errorMessage = 'Loading libraries failed :(';
                    this.errorActive = true;
                }
                this.loadingLibraries = false;
            },
            async getMimeTypes() {
                this.loadingMimeTypes = true;
                this.errorActive = false;
                try {
                    this.mimeTypes = _.orderBy(await getMimeTypes(this));
                } catch {
                    this.errorMessage = 'Loading MimeTypes failed :(';
                    this.errorActive = true;
                }
                this.loadingMimeTypes = false;
            },
            async downloadAttachment(key, fileName) {
                try {
                    const blob = await getAttachment(this, key);
                    if (blob) {
                        saveAs(blob, fileName);
                    }
                } catch (error) {
                    this.showError('Could not download attachment');
                }
            },
            prettyBytes,
            getCodeLinkByB64Id,
            getLibraryLink,
            getLibraryListLink,
            encodeIdBase64
        }

    };
</script>

<style>

</style>
