<script setup>
    import { ref, onBeforeMount } from 'vue';
    import { useDisabledFeaturesStore } from '@/stores/disabledFeaturesStore';

    const props = defineProps({
        name: {
            type: String,
            default: ''
        }
    });

    const message = ref('Loading..');

    const store = useDisabledFeaturesStore();

    onBeforeMount(async () => {
        const info = await store.isDisabled(props.name);
        message.value = info?.message || 'No information registered';
    });
</script>

<template>
    <b-notification
        type="is-info"
        has-icon
        icon="account-hard-hat-outline"
        :closable="false"
        role="alert">
        <h3 class="title">
            Feature temporarily disabled
        </h3>
        <p class="block">
            {{ message }}
        </p>
    </b-notification>
</template>
