import { genericViewQueryAsText } from '@/shared/helpers/api';
import { http } from '@/shared/httpWrapper';
import { ComponentPublicInstance, getCurrentInstance } from 'vue';
import { BuefyNamespace } from '@ntohq/buefy-next';

export interface EnsFormatElement {
    elementNo: number;
    identity: string | null;
    isValid: boolean;
    description: string;
    show: boolean;
    selectFlag: boolean;
    required: boolean;
    include: boolean;
    layout: string;
    reference: string;
    minimumCharacters: number;
    maximumCharacters: number;
    constantValue: string;
    padding?: string | null;
}

export function newFormatElement(ensType: string, elementNo: number) {
    const element: EnsFormatElement = {
        elementNo,
        isValid: true,
        description: '',
        identity: null,
        required: false,
        maximumCharacters: 1,
        minimumCharacters: 1,
        constantValue: '',
        layout: 'Z',
        reference: '0',
        selectFlag: false,
        show: false,
        include: false
    };
    if (ensType === 'TagFormat') {
        element.padding = null;
    }
    return element;
}

type CategoriesQueryRow = {
    identity: string;
    description: string;
    facility?: string;
};

type FormatsQueryRow = {
    name: string;
    identity: string;
    isValid: boolean;
    description: string;
    facility: string;
    category: string;
    syntax: string | null;
    regex: string | null;
    regexGrouped: string | null;
};

type FormatElementsQueryRow = {
    name: string;
    identity: string | null;
    isValid: boolean;
    description: string;
    minimumCharacters: number | null;
    maximumCharacters: number | null;
    include: boolean | null;
    show: boolean | null;
    required: boolean | null;
    selectFlag: boolean | null;
    constantValue: string | null;
    layout: string | null;
    reference: string | null;
    padding?: string | null;
};

type LayoutsQueryRow = {
    name: string;
    identity: string;
    description: string;
};

type ReferencesQueryRow = {
    name: string;
    identity: string;
    description: string;
};

type PaddingsResponse = {
    identity: string;
    description: string;
};

export const queries = {
    async fetchCategories(ensType: string) {
        const query = ensType === 'TagFormat'
            ? 'FROM TagCategory SELECT identity, description, scope as facility WHERE IsValid = true ORDER BY description'
            : 'FROM DocCategory SELECT identity, description, scope as facility WHERE IsValid = true ORDER BY description';
        return genericViewQueryAsText<CategoriesQueryRow>(http, query).then(x => x.data);
    },
    async fetchFormats(ensType: string, selectedFacility: string) {
        const query = ensType === 'TagFormat'
            ? `FROM TagFormat
                        SELECT
                            identity, name, isValid, description, scope as facility, tagCategory as category, syntax,
                            regex, regexGrouped
                        WHERE isValid = true AND scope = @scope
                        ORDER BY description`
            : `FROM DocumentNumberingFormat
                        SELECT
                            identity, name, isValid, description, scope as facility, docCategory as category, syntax,
                            regex, regexGrouped
                        WHERE isValid = true AND scope = @scope
                        ORDER BY description`;
        return genericViewQueryAsText<FormatsQueryRow>(http, query, [
            { name: '@scope', value: selectedFacility }
        ]).then(x => x.data);
    },
    async fetchLayouts(ensType: string) {
        const query = ensType === 'TagFormat'
            ? 'FROM EnsFormatElementLayout SELECT name, identity, description WHERE IsValid = true ORDER BY name'
            : 'FROM EnsDocumentElementLayout SELECT name, identity, description WHERE IsValid = true ORDER BY name';
        return genericViewQueryAsText<LayoutsQueryRow>(http, query, undefined).then(x => x.data);
    },
    async fetchReferences(ensType: string) {
        const query = ensType === 'TagFormat'
            ? 'FROM TagFormatReference SELECT name, identity, description WHERE IsValid = true ORDER BY description'
            : 'FROM DocumentNumberingFormatReference SELECT name, identity, description WHERE IsValid = true ORDER BY description';
        return genericViewQueryAsText<ReferencesQueryRow>(http, query, undefined).then(x => x.data);
    },
    async fetchPaddings(ensType: string) {
        if (ensType !== 'TagFormat')
            throw new Error('Paddings are only available for TagFormats');

        const query = 'FROM EnsPadding SELECT identity, description WHERE IsValid = true ORDER BY description';
        return genericViewQueryAsText<PaddingsResponse>(http, query, undefined).then(x => x.data);
    },
    async fetchFormatElements(ensType: string, formatIdentity: string, selectedFacility: string) {
        const query = ensType === 'TagFormat'
            ? `FROM TagFormatElement
                        SELECT
                            name, isValid, description, minimumCharacters, maximumCharacters, inTagNo as include,
                            show, required, selectFlag, constantValue, layout, reference, padding, identity
                        WHERE tagFormat = "${formatIdentity}" AND scope = @facility`
            : `FROM DocumentNumberingFormatElement
                        SELECT
                            name, isValid, description, minimumCharacters, maximumCharacters, inDocNo as include,
                            show, required, selectFlag, constantValue, layout, reference, identity
                        WHERE docNoFormat = "${formatIdentity}" AND scope = @facility`;

        return genericViewQueryAsText<FormatElementsQueryRow>(http, query, [
            { name: '@facility', value: selectedFacility }
        ]).then(x => x.data);
    }
};

// Temporary workaround
export function useBuefy() {
    const self = getCurrentInstance();
    return (self!.proxy! as ComponentPublicInstance & { $buefy: BuefyNamespace }).$buefy!;
}
