// @ts-nocheck
import _ from 'lodash';

const compileClass = function(lib, attributes) {
    let uml = `class ${lib.name} {\nName\nIdentity\nDescription\nIsValid\n`;
    if (!lib.isGlobal) {
        uml += `Scope [${lib.scopeType}]\n`;
    }
    if (attributes) {
        attributes.forEach(a => {
            uml += `${a.name}\n`;
        });
    }
    uml += '}\n';
    return uml;
};

function compileClassAndRefs(lib, libs, attributeDefs, visitedClasses) {
    if (_.get(visitedClasses, lib.name))
        return '';

    const defs = _.get(attributeDefs, lib.name);
    let uml = compileClass(lib, defs);
    visitedClasses[lib.name] = true;

    if (defs) {
        defs.filter(x => x.attributeType === 'CodeRef').forEach(ad => {
            const subLib = _.find(libs, { name: ad.referenceLibraryName });
            uml += compileClassAndRefs(subLib, libs, attributeDefs, visitedClasses);
            uml += `${lib.name}-->${subLib.name} : ${ad.name}\n`;
        });
    }
    return uml;
}

export function makeUmlSource(lib, libs, attributeDefs) {
    let uml = 'classDiagram\ndirection LR\n';
    const visitedClasses = {};
    uml += compileClassAndRefs(lib, libs, attributeDefs, visitedClasses);
    return uml;
}

/**
 * Takes the object `relatedLibraries` and uses it's `libraries` and `relations` to create a classDiagram.
 *
 * Generate mermaid class diagram code
 * See https://mermaid-js.github.io/mermaid/#/classDiagram
 *
 * @param libraryName - String identifying the library
 * @param relatedLibraries - Object - relating to the library
 * @returns {string} - Mermaid UML String
 */
export function makeLibraryRelationUml(libraryName, relatedLibraries) {
    let merString = 'classDiagram\n';

    relatedLibraries.libraries.forEach(lib => {
        merString += `class ${lib.name} {\n`;
        lib.attributes.forEach((attr) => {
            merString += `\t${attr}\n`;
        });

        merString += '}\n';

        if (lib.name !== libraryName) {
            merString += `click ${lib.name} href "/Library/${lib.name}"\n`;
        }
    });

    relatedLibraries.relations.forEach(rel => {
        merString += `${rel.fromName}-->${rel.toName} : ${rel.label}\n`;
    });

    return merString;
}

export const helpfulMermaidDiagramPlaceholder = '---\n'
    + 'title: Please select a library or type a query\n'
    + '---\n'
    + 'graph LR\n'
    + 'VisibleGraph-->id1(Select a Library)\n'
    + 'VisibleGraph-->id2(Write a Query) ';

export function mermaidQueryHelperPlaceholder(libname) {
    return `---\ntitle: Not found\n---\ngraph LR\nVisbleGraph-->id(${libname})`;
}
