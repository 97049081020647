<template>
    <div v-if="current">
        <chain-release
            v-if="current.specification.specificationType === 'ReleaseChain'"
            :release="current" />
        <template v-else>
            <div class="columns">
                <div class="column">
                    <h2 class="title is-3">
                        Release {{ current.id }}
                    </h2>
                </div>
            </div>

            <b-message
                v-if="connectionLost"
                title="Problem With Live Updates"
                type="is-warning"
                :closable="false">
                <p>Try to refresh the page </p>
            </b-message>
            <b-message
                v-if="showAccessDenied"
                title="The requested action is forbidden for the user"
                type="is-warning"
                :closable="false">
                <p>The performed action on release {{ releaseId }} resulted in forbidden. Try to refresh the page if this was an error</p>
            </b-message>

            <release-state-changer
                v-if="current"
                :release="current"
                :log-button="true"
                :log-button-class="'is-primary'"
                @transition="load($event)"
                @changeState="current.state = $event"
                @showAccessDeniedMessage="showAccessDeniedMessage" />

            <div class="columns">
                <div class="column is-one-third-desktop is-full-tablet is-full-mobile">
                    <div class="box">
                        <table class="table is-striped is-narrow is-fullwidth">
                            <tbody>
                                <tr>
                                    <th>State</th>
                                    <td>{{ current.state }}</td>
                                </tr>
                                <tr>
                                    <th>Type</th>
                                    <td>{{ current.specification.specificationType }}</td>
                                </tr>
                                <tr>
                                    <th>Library</th>
                                    <td>
                                        <router-link
                                            v-for="library in releaseLibraries"
                                            :key="library"
                                            :to="getLibraryLink(library)">
                                            {{ library }}
                                        </router-link>
                                    </td>
                                </tr>
                                <tr v-if="specIsPublisherRelease">
                                    <th>Codes to publish</th>
                                    <td>
                                        <p>{{ current.specification.codesCount }}</p>
                                    </td>
                                </tr>
                                <tr v-else>
                                    <th>Library set</th>
                                    <td>
                                        <a
                                            v-for="codeSet in releaseCodeSets"
                                            :key="codeSet.name"
                                            :href="getCodeSetLink(codeSet.name)">
                                            {{ codeSet.description }}
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Application</th>
                                    <td>
                                        <router-link
                                            v-if="releaseApplication"
                                            :to="getApplicationLink(releaseApplication)">
                                            {{ releaseApplication }}
                                        </router-link>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Created</th>
                                    <td>{{ $filters.dateFormatLong(current.dateCreated) }}</td>
                                </tr>
                                <tr>
                                    <th>Updated</th>
                                    <td>{{ $filters.dateFormatLong(current.dateUpdated) }}</td>
                                </tr>
                                <tr>
                                    <th>Created by</th>
                                    <td>
                                        <b-tooltip
                                            v-if="current.createdBy.name && current.createdBy.email"
                                            position="is-bottom"
                                            :label="current.createdBy.email">
                                            {{ current.createdBy.name }}
                                        </b-tooltip>
                                        <div v-else>
                                            {{ current.createdBy.name ?? 'Unknown user' }}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="column">
                    <div
                        v-if="current.jobs.length"
                        class="box">
                        <h2 class="title is-5">
                            Jobs
                        </h2>
                        <table class="table is-narrow is-hoverable is-fullwidth">
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Type</th>
                                    <th>Set</th>
                                    <th>Application / Webhook</th>
                                    <th>&nbsp;</th>
                                    <th>State</th>
                                    <th>Retry count</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="job in jobsDisplayModel"
                                    :key="job.id">
                                    <td>{{ job.id }}</td>
                                    <td>{{ job.type }}</td>
                                    <td>
                                        <router-link
                                            v-if="job.codeSetName"
                                            :to="getCodeSetLink(job.codeSetName)">
                                            {{ job.codeSetDescription }}
                                        </router-link>
                                    </td>
                                    <td>
                                        <router-link
                                            v-if="job.subscription"
                                            :to="getApplicationLink(job.subscription.application.name)">
                                            {{ job.subscription.application.name }}
                                        </router-link>
                                        <div
                                            v-else-if="job.webhook">
                                            <p
                                                class="webhook-url-style">
                                                {{ job.webhook.callbackUrl }}
                                            </p>
                                            <router-link
                                                style="white-space: nowrap;"
                                                :to="getWebhookLink(job.webhook.id)">
                                                Configuration
                                            </router-link>
                                        </div>
                                    </td>
                                    <td style="vertical-align: middle">
                                        <progress
                                            v-show="job.state === 'InProgress'"
                                            class="progress is-info"
                                            max="100" />
                                    </td>
                                    <td>
                                        {{ job.state }}
                                        <b-tooltip
                                            v-if="job.state === 'Fail'"
                                            label="The job failed. Please see the release log for details."
                                            position="is-bottom"
                                            type="is-primary">
                                            <b-icon
                                                icon="information-outline"
                                                size="is-small" />
                                        </b-tooltip>
                                    </td>
                                    <td>{{ job.retryCount }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <release-errors-and-warnings
                :release="current"
                :log-errors="logErrors" />
            <release-publish-box
                v-if="specIsPublisherRelease"
                :spec="current ? current.specification : {}" />
            <release-change-box
                v-else
                :changes-ready="changesReady"
                :current="current" />
        </template>
    </div>
</template>

<script>

    import { getLogs, getRelease } from '@/shared/helpers/api';
    import { getApplicationLink, getCodeSetLink, getLibraryLink, getWebhookLink } from '@/shared/helpers/routing';
    import { showMixin } from '@/shared/mixins/showMixin';
    import * as signalR from '@microsoft/signalr';
    import ChainRelease from './ChainRelease.vue';
    import ReleaseChangeBox from './ReleaseChangeBox.vue';
    import ReleaseErrorsAndWarnings from './ReleaseErrorsAndWarnings.vue';
    import ReleasePublishBox from './ReleasePublishBox.vue';
    import ReleaseStateChanger from './ReleaseStateChanger.vue';

    export default {
        components: {
            ReleaseStateChanger,
            ReleaseChangeBox,
            ReleasePublishBox,
            ChainRelease,
            ReleaseErrorsAndWarnings
        },
        mixins: [
            showMixin
        ],
        data() {
            return {
                releaseId: null,
                showAccessDenied: false,
                connectionLost: false,
                paginate: {
                    maxErrors: 10,
                    maxWarnings: 10
                },
                current: null,
                changesReady: false,
                connection: null,
                logErrors: [],
                libraries: new Map()
            };
        },
        computed: {
            specIsPublisherRelease() {
                return this.current.specification.specificationType === 'PublishFilter';
            },
            releaseLibraries() {
                const spec = this.current.specification;

                if (spec.isComposite) {
                    return spec.libraries;
                } else if (this.specIsPublisherRelease) {
                    return spec.libraryNames;
                } else {
                    if (spec.libraryName === null) {
                        return [];
                    } else {
                        return [spec.libraryName];
                    }
                }
            },
            releaseCodeSets() {
                const spec = this.current.specification;

                if (spec.isComposite) {
                    return spec.changeDocuments.map(doc => ({
                        name: doc.codeSetName,
                        description: doc.codeSetDescription
                    }));
                } else if (spec.codeSetName) {
                    return [{
                        name: spec.codeSetName,
                        description: spec.codeSetDescription
                    }];
                } else {
                    return [];
                }
            },
            releaseApplication() {
                return this.current.specification.applicationName;
            },
            jobsDisplayModel() {
                const descriptions = new Map(this.releaseCodeSets.map(x => [x.name, x.description]));
                return this.current.jobs.map(job => {
                    if (job.codeSet) {
                        return {
                            ...job,
                            codeSetName: job.codeSet,
                            codeSetDescription: descriptions.get(job.codeSet) ?? job.codeSet
                        };
                    } else {
                        return job;
                    }
                });
            }
        },
        created() {
            this.connection = new signalR.HubConnectionBuilder()
                .withUrl(window.config.signalRUrlRelease, {
                    accessTokenFactory: async () => await window.authService.acquireToken()
                })
                .configureLogging(signalR.LogLevel.None)
                .build();
        },
        async mounted() {
            const releaseId = this.$route.params.release;
            await this.load(releaseId);
            this.connection.on('Change', () => this.reLoad(this.current.id));
            try {
                await this.connection.start();
                this.connection.invoke('ReleaseId', `${this.current.id}`);
                this.connection.onclose(() => this.connectionLost = true);
            } catch {
                this.connectionLost = true;
            }
        },
        beforeUnmount() {
            this.connection.stop();
        },
        methods: {
            showAccessDeniedMessage(releaseId) {
                this.releaseId = releaseId;
                this.showAccessDenied = true;
            },
            async load(releaseId) {
                this.current = await getRelease(this, releaseId);
                if (this.current.state !== 'Planned' && this.current.state !== 'Creating')
                    this.changesReady = true;
                await this.loadJobErrors();
            },
            async reLoad(releaseId) {
                const update = await getRelease(this, releaseId);
                if (update.state === 'Open')
                    this.changesReady = true;
                this.current.state = update.state;
                this.current.jobs = update.jobs;
                this.current.specification = update.specification;
                await this.loadJobErrors();
            },
            async loadJobErrors() {
                const failingJobs
                    = new Set(
                        this.current.jobs
                            .filter(j => j.state === 'Fail' || j.state === 'Delay')
                            .map(j => j.id)
                    );

                if (failingJobs.size > 0) {
                    const log = await getLogs(this, {
                        params: {
                            releaseId: this.current.id
                        }
                    });
                    this.logErrors = log.filter(x =>
                        x.logLevel === 'Error' && failingJobs.has(x.publishJobId)
                    );
                } else {
                    this.logErrors = [];
                }
            },
            getCodeSetLink,
            getLibraryLink,
            getApplicationLink,
            getWebhookLink
        }
    };
</script>

<style>
.webhook-url-style {
    display: inline;
    margin-right: 10px;
    word-break: break-word;
}
</style>
