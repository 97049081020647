export const legacySchemaRoutes = [
    {
        path: '/Schema/specification/:identity',
        redirect: (route) => { return { path: `/Schema/${route.params.identity}` }; }
    },
    {
        path: '/Schema/specification/model/:identity',
        redirect: (route) => { return { path: `/Schema/${route.params.identity}/model` }; }
    },
    {
        path: '/Schema/specification/dto/:identity',
        redirect: (route) => { return { path: `/Schema/${route.params.identity}/dto` }; }
    },
    {
        path: '/Schema/specification/ClassEditor/:identity',
        redirect: (route) => { return { path: `/Schema/${route.params.identity}/ClassEditor` }; }
    },
    {
        path: '/Schema/specification/InterfaceEditor/:identity',
        redirect: (route) => { return { path: `/Schema/${route.params.identity}/InterfaceEditor` }; }
    },
    {
        path: '/Schema/specification/AttributeMappingEditor/:identity',
        redirect: (route) => { return { path: `/Schema/${route.params.identity}/AttributeMappingEditor` }; }
    },
    {
        path: '/Schema/specification/ClassMappingEditor/:identity',
        redirect: (route) => { return { path: `/Schema/${route.params.identity}/ClassMappingEditor` }; } },
    {
        path: '/Schema/specification/RelationMappingEditor/:identity',
        redirect: (route) => { return { path: `/Schema/${route.params.identity}/RelationMappingEditor` }; }
    },
    {
        path: '/Schema/specification/AttributeEditor/:identity',
        redirect: (route) => { return { path: `/Schema/${route.params.identity}/AttributeEditor` }; }
    },
    {
        path: '/Schema/specification/RelationEditor/:identity',
        redirect: (route) => { return { path: `/Schema/${route.params.identity}/RelationEditor` }; }
    }
];
