<template>
    <span>
        <b-tag
            class="is-danger notification-count">
            {{ count }}
        </b-tag>
    </span>
</template>
<script>
    export default {
        name: 'NotificationCount',
        props: {
            count: {
                type: Number,
                required: true
            }
        }
    };
</script>

<style scoped>
.notification-count {
    vertical-align: 2px;
    margin-left: 4px;
}
</style>
