<template>
    <b-modal
        has-modal-card
        :model-value="active"
        @close="close">
        <div class="modal-card">
            <section class="modal-card-body box">
                <h5 class="title is-5">
                    Query specification
                </h5>
                <pre class="snippet">{{ queryParseResult ? queryParseResult.json : 'Loading..' }}</pre>
                <h5
                    class="title is-5"
                    style="margin-top:10px;">
                    SQL
                </h5>
                <pre class="snippet">{{ queryParseResult ? queryParseResult.sql : 'Loading..' }}</pre>
            </section>
        </div>
    </b-modal>
</template>

<script>
    import { format } from 'sql-formatter';
    import { genericViewParseText } from '@/shared/helpers/api';
    import { showMixin } from '@/shared/mixins/showMixin';
    export default {
        mixins: [
            showMixin
        ],
        props: {
            query: {
                type: String,
                default: ''
            },
            show: {
                type: Boolean,
                required: true
            }
        },
        emits: [
            'update:show'
        ],
        data() {
            return {
                queryParseResult: null,
                active: false
            };
        },
        watch: {
            async show(show) {
                if (show) {
                    this.queryParseResult = null;
                    this.active = true;
                    try {
                        const parseResult = (await genericViewParseText(this, this.query)).data;
                        this.queryParseResult = {
                            json: JSON.stringify(parseResult.query, null, 2),
                            sql: format(parseResult.sql, { language: 'tsql' })
                        };
                    } catch (ex) {
                        this.showError(ex, m => {
                            this.queryParseResult = {
                                json: m,
                                sql: ''
                            };
                        });
                    }
                } else {
                    this.active = false;
                }
            }
        },
        methods: {
            close() {
                this.active = false;
                this.$emit('update:show', this.active);
            }
        }
    };
</script>

<style scoped>
    .snippet {
        max-height: 200px;
        padding: 5px;
        border: solid 1px silver;
    }
</style>
