<template>
    <div class="box mb-2">
        <b-notification
            v-if="isUserPolicy && selectedSystem === stidSystem"
            type="is-warning"
            has-icon
            :closable="false">
            Creating attributes for {{ stidSystem }} is currently not supported
        </b-notification>
        <div v-if="isUserPolicy">
            Add role
            <b-autocomplete
                v-model="roleSearch"
                placehoder="Choose role"
                :data="filteredRoles"
                open-on-focus
                clearable
                @select="option => (selectedRole = option)" />
            for scope
            <b-autocomplete
                v-model="scopeSearch"
                placehoder="Choose scope"
                :data="filteredScopes"
                open-on-focus
                clearable
                @select="option => (selectedScope = option)" />
            for system
            <b-autocomplete
                v-model="systemSearch"
                placehoder="Choose system"
                :data="filteredSystems"
                open-on-focus
                clearable
                @select="option => (selectedSystem = option)" />
        </div>
        <div v-else>
            User(s) with role
            <b-autocomplete
                v-model="roleSearch"
                placehoder="Choose role"
                :data="filteredRoles"
                open-on-focus
                clearable
                @select="option => (selectedRole = option)" />
            from system
            <b-autocomplete
                v-model="systemSearch"
                placehoder="Choose system"
                :data="filteredSystems"
                open-on-focus
                clearable
                @select="option => (selectedSystem = option)" />
            is GRANTED ACCESS
        </div>
        <div
            class="buttons is-flex is-justify-content-flex-end mt-1">
            <div v-require-is-administrator>
                <b-button
                    class="is-danger"
                    @click="cancelCreateNew">
                    Cancel
                </b-button>
                <b-button
                    :disabled="!allFieldsHaveValueAndSystemIsNotStid"
                    class="is-info"
                    @click="savePolicy">
                    Save
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
    import { requireIsAdministrator } from '@/shared/directives/requirePermission.js';

    export default {
        directives: {
            'require-is-administrator': requireIsAdministrator
        },
        props: {
            systemsAndRoles: {
                type: Object,
                required: true
            },
            scopes: {
                type: Array,
                default: () => []
            },
            isUserPolicy: {
                type: Boolean,
                default: false
            }
        },
        emits: ['savePolicy', 'cancel'],
        data: () => {
            return {
                editable: false,
                roleSearch: '',
                systemSearch: '',
                scopeSearch: '',
                stidSystem: 'STIDCODE',
                selectedRole: null,
                selectedSystem: null,
                selectedScope: null
            };
        },
        computed: {
            allFieldsHaveValueAndSystemIsNotStid() {
                if (this.isUserPolicy && this.selectedSystem === this.stidSystem)
                    return false;
                if (this.isUserPolicy)
                    return this.selectedRole !== null && this.selectedSystem !== null && this.selectedScope !== null;
                return this.selectedRole !== null && this.selectedSystem !== null;
            },
            filteredRoles() {
                const search = this.roleSearch?.toLowerCase();
                return this.systemsAndRoles.facilityRoles
                    .filter(r => search === null || r.toLowerCase().includes(search));
            },
            filteredSystems() {
                const search = this.systemSearch?.toLowerCase();
                return this.systemsAndRoles.sourceSystems
                    .filter(r => search === null || r.toLowerCase().includes(search));
            },
            filteredScopes() {
                const search = this.scopeSearch?.toLowerCase();
                return this.scopes
                    .filter(r => search === null || r.toLowerCase().includes(search));
            }
        },
        methods: {
            savePolicy() {
                const newPolicy = {
                    facilityRole: this.selectedRole,
                    sourceSystem: this.selectedSystem
                };
                if (this.isUserPolicy)
                    newPolicy.scope = this.selectedScope;
                this.$emit('savePolicy', newPolicy);
            },
            cancelCreateNew() {
                this.$emit('cancel');
            }
        }
    };
</script>
