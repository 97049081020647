<template>
    <attachment
        :attachment="attachment">
        <template #upload-button>
            <div
                v-require-can-edit-code="{ libraryName, scopes }"
                class="control horizontal-buttons">
                <b-upload
                    class="file-label"
                    @update:model-value="onUpload">
                    <span class="file-cta">
                        <b-icon
                            class="pr-3"
                            size="is-small"
                            icon="upload" />
                        <span class="file-label">Upload</span>
                    </span>
                </b-upload>
            </div>
        </template>
    </attachment>
</template>

<script>
    import { requireCanEditCode } from '@/shared/directives/requirePermission.js';
    import { uploadAttachmentToCode } from '@/shared/helpers/api.ts';
    import Attachment from '@/shared/components/Attachment.vue';

    export default {
        directives: {
            requireCanEditCode
        },
        components: {
            Attachment
        },
        props: {
            attachment: {
                type: Object,
                default: () => null
            },
            codeId: {
                type: Number,
                required: true
            },
            libraryName: {
                type: String,
                required: true
            },
            scopes: {
                type: Array,
                default: () => []
            }
        },
        emits: [
            'updateAttachment'
        ],
        methods: {
            async onUpload(file) {
                const form = new FormData();
                form.append('file', file);
                await uploadAttachmentToCode(this, this.codeId, form);
                this.$emit('updateAttachment');
            }
        }
    };
</script>

<style scoped>
.horizontal-buttons {
    display: inline-block;
}
</style>
