<template>
    <tab-layout
        :has-selected-scope="!!selectedScope"
        :is-form-active="isFormActive"
        :exists-code-set-for-scope="existsCodeSetForScope"
        :code-edit-title="codeEditTitle"
        one-third>
        <template #toolbar>
            <div
                v-require-can-edit-code="{ libraryName: 'LineSize', scopes: selectedScope ? [selectedScope] : [] }"
                class="level has-margin-bottom">
                <div
                    class="level-left">
                    <b-button
                        class="level-item"
                        type="is-info"
                        :disabled="!selectedScope"
                        :model-value="isCreating"
                        @click="onNewLineSizeClicked">
                        <span class="icon is-small">
                            <i
                                class="fa fa-plus"
                                aria-hidden="true" />
                        </span>
                        <span>
                            New Line Size
                        </span>
                    </b-button>
                </div>
            </div>
        </template>
        <template #picker>
            <b-table
                v-model:selected="selectedLineSize"
                class="is-flex"
                height="60vh"
                sticky-header
                narrowed
                striped
                hoverable
                bordered
                :data="visibleLineSizes"
                :loading="loadingLineSizes"
                :mobile-cards="false">
                <b-table-column
                    field="Name"
                    label="Nominal diameter"
                    width="100px">
                    <template #default="{ row }">
                        {{ row.Name }}
                    </template>
                </b-table-column>
                <b-table-column
                    field="NomDiamUnit"
                    label="Unit"
                    width="110px"
                    sortable>
                    <template #default="{ row }">
                        {{ row.NomDiamUnit }}
                    </template>
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    sortable
                    centered
                    field="IsValid"
                    label="Valid">
                    <bool-element :value="props.row.IsValid" />
                </b-table-column>
                <template #empty>
                    <div class="has-text-centered">
                        No sizes
                    </div>
                </template>
            </b-table>
        </template>
        <template #form>
            <code-edit
                :key="selectedLineSizeId"
                library="LineSize"
                :scopes="selectedScope"
                :code-id="selectedLineSizeId"
                :code-set-name="codeSetName"
                :fields-to-ignore="['attachmentKey']"
                :custom-labels="{ name: 'Nominal diameter' }"
                :use-box-render="false"
                use-columns
                @cancel="onCancelClicked"
                @refresh="onSavedLineSize" />
        </template>
    </tab-layout>
</template>
<script>
    import CodeEdit from '@/shared/components/CodeEdit.vue';
    import { encodeIdBase64 } from '@/shared/helpers/utils';
    import TabLayout from './TabLayout.vue';
    import BoolElement from '@/shared/components/BoolElement.vue';
    import { requireCanEditCode } from '@/shared/directives/requirePermission';
    import _ from 'lodash';
    import { usePipeClassStore } from '@/stores/pipeClassStore.js';

    export default {
        directives: {
            'require-can-edit-code': requireCanEditCode
        },
        components: {
            CodeEdit,
            TabLayout,
            BoolElement
        },
        props: {
            lineSizes: {
                type: Array,
                required: true
            },
            loadingLineSizes: {
                type: Boolean,
                required: true
            },
            codeSetName: {
                type: String,
                default: null
            },
            loadingCodeSetName: {
                type: Boolean,
                required: true
            },
            selectedScope: {
                type: String,
                default: null
            },
            viewValidOnly: {
                type: Boolean,
                required: true
            }
        },
        emits: [
            'savedLineSize'
        ],
        data() {
            return {
                isFormActive: false,
                selectedLineSize: null,
                pipeClassStore: usePipeClassStore()
            };
        },
        computed: {
            selectedLineSizeId() {
                return this.selectedLineSize ? encodeIdBase64('Code', this.selectedLineSize.Id) : null;
            },
            codeEditTitle() {
                const verb = this.isEditing ? 'Edit' : 'New';
                return verb + ' Line Size';
            },
            isCreating() {
                return this.isFormActive && !this.selectedLineSize;
            },
            isEditing() {
                return this.isFormActive && !!this.selectedLineSize;
            },
            visibleLineSizes() {
                return this.viewValidOnly ? this.lineSizes.filter(code => code.IsValid) : this.lineSizes;
            },
            existsCodeSetForScope() {
                return !this.loadingCodeSetName && this.codeSetName !== null;
            }
        },
        watch: {
            selectedScope() {
                this.selectedLineSize = null;
                this.isFormActive = false;
            },
            selectedLineSize(newValue, oldValue) {
                if (newValue) {
                    this.isFormActive = true;
                } else if (!oldValue) {
                    this.isFormActive = false;
                }
            },
            lineSizes(newLineSizes, oldLineSizes) {
                if (this.isEditing) {
                    this.selectedLineSize = newLineSizes.find(ls => ls.Id === this.selectedLineSize.Id);
                }
                if (this.isCreating) {
                    const addedLineSize = _.differenceBy(newLineSizes, oldLineSizes, 'Id');
                    this.selectedLineSize = addedLineSize.length > 0 ? addedLineSize[0] : null;
                }
                if (this.pipeClassStore.lineSizeId) {
                    const id = this.pipeClassStore.lineSizeId;

                    const found = this.lineSizes.find(ls => ls.Id === id);
                    if (found) {
                        this.selectedLineSize = found;
                    }
                    this.pipeClassStore.setLineSize(null);
                }
            }
        },
        methods: {
            onNewLineSizeClicked() {
                this.isFormActive = true;
                this.selectedLineSize = null;
            },
            onCancelClicked() {
                this.selectedLineSize = null;
                this.isFormActive = false;
            },
            onSavedLineSize() {
                this.$emit('savedLineSize');
            }
        }
    };
</script>
<style scoped>

</style>
