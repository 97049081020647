<template>
    <div>
        <h1 class="title">
            Project, Contractor and Purchase Order
        </h1>
        <scope-dropdown scope-type="Facility" />
        <hr>
        <b-tabs
            v-model="activeTab"
            type="is-boxed">
            <b-tab-item
                value="Projects"
                label="Projects">
                <project-tab
                    :navigate-to-project="project"
                    :selected-scope="selectedScope"
                    @update-project="(proj) => project = proj" />
            </b-tab-item>
            <b-tab-item
                value="Contractors"
                label="Contractors">
                <contractor-tab
                    ref="contractorTab"
                    :navigate-to-contractor="contractor"
                    :selected-scope="selectedScope"
                    @update-contractor="(id) => contractor = id" />
            </b-tab-item>
            <b-tab-item
                value="PurchaseOrders"
                label="Purchase Orders">
                <purchase-order-tab
                    :navigate-to-purchase-order="purchaseOrder"
                    :selected-scope="selectedScope"
                    @update-purchase-order="(po) => purchaseOrder = po" />
            </b-tab-item>
        </b-tabs>
    </div>
</template>

<script>
    import ContractorTab from '@/apps/project/components/ContractorTab.vue';
    import ProjectTab from '@/apps/project/components/ProjectTab.vue';
    import PurchaseOrderTab from '@/apps/project/components/PurchaseOrderTab.vue';
    import ScopeDropdown from '@/shared/components/ScopeDropdown.vue';
    import { useScopeStore } from '@/stores/scopeStore.js';

    export default {
        components: {
            ProjectTab,
            ContractorTab,
            PurchaseOrderTab,
            ScopeDropdown
        },
        props: {
            tab: {
                type: String,
                default: 'Project'
            },
            routeParams: {
                type: Object,
                default: () => {}
            }
        },
        url: {
            selectedScope: {
                param: 'facility',
                noHistory: true
            },
            contractor: {
                param: 'contractor',
                noHistory: true
            },
            project: {
                param: 'project',
                noHistory: true
            },
            purchaseOrder: {
                param: 'purchaseOrder',
                noHistory: true
            }
        },
        data() {
            return {
                activeTab: '',
                contractor: null,
                project: null,
                purchaseOrder: null,
                scopeStore: useScopeStore()
            };
        },
        computed: {
            selectedScope: {
                get() {
                    return this.scopeStore.getSelectedScope();
                }, set(value) {
                    this.scopeStore.updateSelectedScope(value);
                }
            }
        },
        created() {
            if (this.tab) {
                this.activeTab = this.tab;
            }

            this.$watch('activeTab', (value) => {
                const destination = this.resolveRoute(value);
                this.$router.push(destination);
            });
        },
        methods: {
            resolveRoute(value) {
                const route = {};
                switch (value) {
                    case 'Projects':
                        route.path = 'Projects';
                        if (this.$route.query.project) {
                            route.query = { project: this.$route.query.project };
                        }
                        break;
                    case 'Contractors':
                        route.path = 'Contractors';
                        if (this.$route.query.contractor) {
                            route.query = { contractor: this.$route.query.contractor };
                        }
                        break;
                    case 'PurchaseOrders':
                        route.path = 'PurchaseOrders';
                        if (this.$route.query.purchaseOrder) {
                            route.query = { purchaseOrder: this.$route.query.purchaseOrder };
                        }
                        break;
                    default:
                        route.path = 'Projects';
                        if (this.$route.query.facility) {
                            route.query = { facility: this.$route.query.facility };
                        }
                }
                const query = { ...route.query, ...{ facility: this.$route.query.facility } };
                return { ...route, query };
            }
        }
    };
</script>

<style scoped>

</style>
