import { showMixin } from '@/shared/mixins/showMixin';

export default {
    methods: {
        async copyTextToClipboard(text) {
            try {
                await navigator.clipboard.writeText(text);
                showMixin.methods.showInfo.call(this, 'Value copied to clipboard');
            } catch {
                showMixin.methods.showError.call(this, 'Copy to clipboard failed!');
            }
        },
        async copyUrlToClipboard(url, title) {
            // Copy url both as plain text and html, to allow pasting hyperlinks with title in Word etc.
            // and plain URL in text based editors

            try {
                await navigator.clipboard.write([
                    new ClipboardItem({
                        'text/plain': new Blob([url], {
                            type: 'text/plain'
                        }),
                        'text/html': new Blob(
                            [
                                `<a href="${url}">${title}</a>`
                            ],
                            {
                                type: 'text/html'
                            }
                        )
                    })
                ]);
                showMixin.methods.showInfo.call(this, 'URL copied to clipboard');
            } catch {
                showMixin.methods.showError.call(this, 'Copy to clipboard failed!');
            }
        }
    }
};
