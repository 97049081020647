<template>
    <div>
        <h2 class="title is-3">
            Schedules
        </h2>
        <div class="column is-clearfix">
            <div class="field is-grouped is-pulled-right">
                <p class="control">
                    <button
                        v-require-is-administrator
                        class="button is-info"
                        @click="clickNewSchedule">
                        <span class="icon is-small">
                            <i
                                class="fa fa-plus-circle"
                                aria-hidden="true" />
                        </span>
                        <span>New schedule</span>
                    </button>
                </p>
            </div>
        </div>
        <b-collapse
            v-model="scheduleListOpen"
            aria-id="attributeCollapse">
            <template #trigger>
                <h2
                    class="title is-4"
                    aria-controls="attributeCollapse">
                    All tasks
                    <span class="icon align-icon-left">
                        <i
                            v-if="!scheduleListOpen"
                            class="fa fa-fw fa-chevron-down" />
                        <i
                            v-else
                            class="fa fa-fw fa-chevron-up" />
                    </span>
                </h2>
            </template>
            &nbsp;
            <spinner :loading="loading">
                <b-table
                    v-model:selected="editSchedule"
                    :data="schedules"
                    default-sort="category"
                    :striped="true"
                    :narrowed="true"
                    :hoverable="true"
                    class="site-sticky-header">
                    <b-table-column
                        v-slot="props"
                        field="icons"
                        label="Icons"
                        sortable>
                        {{ props.row.icons }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="category"
                        label="Category"
                        sortable
                        searchable>
                        {{ props.row.category }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="description"
                        label="Description"
                        sortable
                        searchable>
                        {{ props.row.description }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        label="Schedule (UTC based)">
                        {{ $filters.formatCronstrueString(props.row.scheduleSpec) }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="lastExecutionTime"
                        label="Last run (UTC)"
                        sortable>
                        {{ $filters.dateFormatLong(props.row.lastExecutionTime) }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="lastKnownState"
                        label="State"
                        sortable>
                        <i
                            v-if="props.row.lastKnownState === 'Failed'"
                            class="fa fa-times-circle"
                            style="color:red;" />
                        <i
                            v-else-if="props.row.lastKnownState === 'Running'"
                            class="fa fa-spinner"
                            style="color:blue;" />
                        <i
                            v-else
                            class="fa fa-check-circle"
                            style="color:green;" />
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="enabled"
                        label="Enabled"
                        sortable>
                        <bool-element :value="props.row.enabled" />
                    </b-table-column>
                </b-table>
            </spinner>
        </b-collapse>
        <div
            v-if="editSchedule"
            style="margin-top:20px;margin-bottom: 10px">
            <b-field
                label="Icons"
                label-position="on-border">
                <b-input
                    v-model="editSchedule.icons"
                    type="text"
                    placeholder="icons" />
            </b-field>
            <b-field
                label="Category"
                label-position="on-border">
                <b-input
                    v-model="editSchedule.category"
                    type="text"
                    placeholder="category" />
            </b-field>
            <b-field
                label="Description"
                label-position="on-border">
                <b-input
                    v-model="editSchedule.description"
                    type="text"
                    placeholder="description" />
            </b-field>
            <b-field
                label="Action"
                label-position="on-border">
                <b-input
                    v-model="editSchedule.action"
                    type="text"
                    placeholder="action"
                    required />
            </b-field>
            <b-field
                label="Configuration"
                label-position="on-border">
                <b-input
                    v-model="editSchedule.configuration"
                    class="configuration"
                    type="textarea" />
            </b-field>
            <b-field>
                <b-field
                    label="Schedule"
                    label-position="on-border">
                    <b-input
                        v-model="editSchedule.scheduleSpec"
                        type="text"
                        placeholder="* * * * *" />
                    <p class="control">
                        <span class="button is-static">
                            {{ $filters.formatCronstrueString(editSchedule.scheduleSpec) }}
                        </span>
                    </p>
                </b-field>
            </b-field>
            <b-field>
                <b-switch
                    v-model="editSchedule.enabled"
                    v-require-is-administrator>
                    <strong>{{ editSchedule.enabled ? 'Enabled' : 'Disabled' }}</strong>
                </b-switch>
            </b-field>
            <hr>
            <button
                v-require-is-administrator
                class="button is-primary is-outlined"
                @click="clickSaveSchedule">
                <span class="icon is-small">
                    <i
                        class="fa fa-file"
                        aria-hidden="true" />
                </span>
                <span>Save</span>
            </button>
            <button
                v-show="editSchedule.id"
                v-require-is-administrator
                class="button is-danger is-outlined"
                @click="clickDeleteSchedule(editSchedule.id)">
                <span class="icon is-small">
                    <i
                        class="fa fa-trash"
                        aria-hidden="true" />
                </span>
                <span>Delete</span>
            </button>
            <button
                v-show="editSchedule.id"
                v-require-is-administrator
                class="button is-info is-outlined"
                @click="clickRunNow()">
                <span class="icon is-small">
                    <i
                        class="fa fa-rocket"
                        aria-hidden="true" />
                </span>
                <span>Run NOW!</span>
            </button>
            <button
                v-show="editSchedule.id"
                class="button is-default is-outlined"
                @click="loadExecutions(editSchedule.id)">
                <span class="icon is-small">
                    <i
                        class="fa fa-redo"
                        aria-hidden="true" />
                </span>
                <span>Reload log</span>
            </button>
        </div>
        <b-table
            :data="executions"
            default-sort="startDate"
            default-sort-direction="desc"
            :show-detail-icon="true"
            detailed
            :striped="true"
            :narrowed="true"
            :hoverable="true"
            class="site-sticky-header"
            @details-open="(row, index) => ensureOutput(row)">
            <b-table-column
                v-slot="props"
                label="State">
                <i
                    v-if="props.row.failed"
                    class="fa fa-times-circle"
                    style="color:red;" />
                <i
                    v-else-if="props.row.running"
                    class="fa fa-spinner"
                    style="color:blue;" />
                <i
                    v-else
                    class="fa fa-check-circle"
                    style="color:green;" />
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="startDate"
                label="Start"
                sortable>
                {{ $filters.dateFormatLong(props.row.startDate) }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="endDate"
                label="End"
                sortable>
                {{ $filters.dateFormatLong(props.row.endDate) }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                label="Duration">
                {{ executionDuration(props.row) }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="id"
                style="justify-content:flex-end"
                label="Delete"
                numeric>
                <a
                    class="button"
                    @click="clickDeleteExecution(props.row.id)"><b-icon
                        icon="delete"
                        size="is-small" /></a>
            </b-table-column>
            <template #detail="props">
                <pre>{{ props.row.output }}</pre>
            </template>
        </b-table>
    </div>
</template>

<script>

    import { formatCronstrueString } from '@/filters.js';
    import BoolElement from '@/shared/components/BoolElement.vue';
    import Spinner from '@/shared/components/Spinner.vue';
    import { requireIsAdministrator } from '@/shared/directives/requirePermission.js';

    import {
        addSchedule,
        adminPageScheduleOutput,
        deleteSchedule,
        deleteScheduleExecution,
        getScheduleExecutions,
        getSchedules,
        runSchedule,
        updateSchedule
    } from '@/shared/helpers/api.ts';
    import messageDialog from '@/shared/mixins/messageDialogMixin';
    import { showMixin } from '@/shared/mixins/showMixin.js';
    import _ from 'lodash';
    import moment from 'moment';

    export default {
        directives: {
            'require-is-administrator': requireIsAdministrator
        },
        components: {
            Spinner,
            BoolElement
        },
        filters: {
            formatCronstrueString
        },
        mixins: [
            showMixin,
            messageDialog
        ],
        data() {
            return {
                schedules: [],
                executions: [],
                scheduleListOpen: true,
                loading: true,
                editSchedule: null
            };
        },
        watch: {
            async editSchedule(value) {
                if (value?.id) {
                    await this.loadExecutions(value.id);
                } else {
                    this.executions = [];
                }
            }
        },
        async mounted() {
            await this.load();
        },
        methods: {
            async load() {
                const self = this;
                this.schedules = _.sortBy(await getSchedules(self), 'description');
                this.loading = false;
            },
            async loadExecutions(id) {
                this.executions = [];
                this.executions = await getScheduleExecutions(this, id);
            },
            async ensureOutput(row) {
                if (!row.output) {
                    const tmp = await adminPageScheduleOutput(this, row.id);
                    row.output = tmp.output;
                }
            },
            clearSelected() {
                this.editSchedule = null;
                this.executions = [];
            },
            clickNewSchedule() {
                this.editSchedule = {
                    action: '',
                    scheduleSpec: '0 0 * * *',
                    enabled: true,
                    description: '',
                    category: '',
                    icons: ''
                };
            },
            async clickSaveSchedule() {
                const self = this;
                if (_.has(self.editSchedule, 'id')) {
                    const result = await updateSchedule(
                        self,
                        { ... self.editSchedule
                        });
                    const index = _.findIndex(this.schedules, (s) => s.id === self.editSchedule.id);
                    self.schedules[index] = result.data;
                } else {
                    const result = await addSchedule(self, self.editSchedule);
                    self.schedules.push(result.data);
                    self.editSchedule = null;
                }
            },
            async clickRunNow() {
                await runSchedule(this, this.editSchedule);
            },
            async clickDeleteSchedule(id) {
                this.messageDialog('This will permanently delete schedule', false, 'Confirm delete')
                    .then(async () => {
                        try {
                            await deleteSchedule(this, id);
                            this.schedules = this.schedules.filter((s) => s.id !== id);
                            this.clearSelected();
                        } catch {
                            this.showError();
                        }
                    }).catch(() => { });
            },
            clickDeleteExecution(id) {
                this.messageDialog('This will permanently delete schedule execution from log', false, 'Confirm delete')
                    .then(async () => {
                        try {
                            await deleteScheduleExecution(this, id);
                            this.executions = this.executions.filter((s) => s.id !== id);
                        } catch {
                            this.showError();
                        }
                    }).catch(() => { });
            },
            executionDuration(execution) {
                const start = execution.startDate;
                const end = execution.endDate;
                if (start && end) {
                    return moment.duration(moment(start).diff(end)).humanize();
                }
                return '';
            }
        }

    };
</script>

<style scoped>
.configuration textarea {
    font-family: 'Courier New', Courier, monospace;
}
.detail-container pre {
    padding: 0px;
    background-color: black;
    color: lightgreen;
}
</style>
