<template>
    <tab-layout
        :has-selected-scope="!!selectedScope"
        :is-form-active="isFormActive"
        :exists-code-set-for-scope="existsCodeSetForScope"
        :code-edit-title="codeEditTitle">
        <template #toolbar>
            <div
                v-require-can-edit-code="{ libraryName: 'PipingClass', scopes: selectedScope ? [selectedScope] : [] }"
                class="level has-margin-bottom">
                <div class=" level-left">
                    <b-button
                        class="level-item"
                        type="is-info"
                        :disabled="!selectedScope"
                        :model-value="isCreating"
                        @click="onNewPipingClassClicked">
                        <span class="icon is-small">
                            <i
                                class="fa fa-plus"
                                aria-hidden="true" />
                        </span>
                        <span>
                            New Piping Class
                        </span>
                    </b-button>
                </div>
            </div>
        </template>
        <template
            #picker>
            <b-table
                v-model:selected="selectedPipingClass"
                height="80vh"
                sticky-header
                hoverable
                striped
                bordered
                default-sort="Name"
                :data="visiblePipingClasses"
                :loading="loadingPipingClasses"
                :mobile-cards="false">
                <b-table-column
                    sortable
                    searchable
                    centered
                    width="25px"
                    field="PressureClass"
                    label="Pressure class">
                    <template
                        #searchable="props">
                        <b-autocomplete
                            v-model="props.filters.PressureClass"
                            placeholder="Filter..."
                            open-on-focus
                            :data="pressureClassCodes"
                            field="identity"
                            clearable>
                            <template #default="{ option }">
                                {{ option.name + " - " + option.description }}
                            </template>
                        </b-autocomplete>
                    </template>
                    <template #default="props">
                        {{ props.row.PressureClass }}
                    </template>
                </b-table-column>
                <b-table-column
                    searchable
                    sortable
                    centered
                    width="25px"
                    field="MaterialClass"
                    label="Material class">
                    <template
                        #searchable="props">
                        <b-autocomplete
                            v-model="props.filters.MaterialClass"
                            placeholder="Filter..."
                            open-on-focus
                            :data="materialClasses"
                            field="identity"
                            clearable>
                            <template #default="{ option }">
                                {{ option.name + " - " + option.description }}
                            </template>
                        </b-autocomplete>
                    </template>
                    <template #default="props">
                        {{ props.row.MaterialClass }}
                    </template>
                </b-table-column>
                <b-table-column
                    searchable
                    sortable
                    width="35px"
                    field="Name"
                    label="Piping class">
                    <template
                        #searchable="props">
                        <b-input
                            v-model="props.filters.Name"
                            placeholder="Filter..." />
                    </template>
                    <template #default="props">
                        <a @click="onPipingClassNamedClicked">
                            {{ props.row.Name }}
                        </a>
                    </template>
                </b-table-column>
                <b-table-column
                    searchable
                    sortable
                    field="Description"
                    label="Description">
                    <template
                        #searchable="props">
                        <b-input
                            v-model="props.filters.Description"
                            placeholder="Filter..."
                            icon="filter" />
                    </template>
                    <template #default="props">
                        {{ props.row.Description }}
                    </template>
                </b-table-column>
                <b-table-column
                    searchable
                    sortable
                    centered
                    field="Corrosion"
                    width="25px"
                    label="Corrosion">
                    <template
                        #searchable="props">
                        <b-input
                            v-model="props.filters.Corrosion"
                            placeholder="Filter..." />
                    </template>
                    <template #default="props">
                        {{ props.row.Corrosion }}
                    </template>
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    label="Reference">
                    {{ props.row.updateRef }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    sortable
                    centered
                    field="IsValid"
                    label="Valid">
                    <bool-element :value="props.row.IsValid" />
                </b-table-column>
                <template #empty>
                    <div class="has-text-centered">
                        No piping classes
                    </div>
                </template>
            </b-table>
        </template>
        <template #form>
            <code-edit
                :key="selectedPipingClassId"
                library="PipingClass"
                :scopes="selectedScope"
                :code-id="selectedPipingClassId"
                :code-set-name="codeSetName"
                :fields-to-ignore="['attachmentKey']"
                :custom-labels="{ name: 'Piping class'}"
                :use-box-render="false"
                :use-columns="true"
                @cancel="onCancelClicked"
                @refresh="onSavedPipingClass" />
        </template>
    </tab-layout>
</template>

<script>
    import CodeEdit from '@/shared/components/CodeEdit.vue';
    import { encodeIdBase64 } from '@/shared/helpers/utils';
    import { requireCanEditCode } from '@/shared/directives/requirePermission';
    import TabLayout from './TabLayout.vue';
    import BoolElement from '@/shared/components/BoolElement.vue';
    import _ from 'lodash';

    export default {
        directives: {
            'require-can-edit-code': requireCanEditCode
        },
        components: {
            CodeEdit,
            TabLayout,
            BoolElement
        },
        props: {
            pipingClasses: {
                type: Array,
                required: true
            },
            loadingPipingClasses: {
                type: Boolean,
                required: true
            },
            codeSetName: {
                type: String,
                default: null
            },
            loadingCodeSetName: {
                type: Boolean,
                required: true
            },
            referableCodes: {
                type: Object,
                default: null
            },
            selectedScope: {
                type: String,
                default: null
            },
            viewValidOnly: {
                type: Boolean,
                required: true
            },
            userSelectedPipingClass: {
                type: Object,
                default: null
            }
        },
        emits: [
            'selectPipingClass',
            'navigateToPipingClassTab',
            'savedPipingClass'
        ],
        data() {
            return {
                isFormActive: false,
                selectedPipingClass: null
            };
        },
        computed: {
            selectedPipingClassId() {
                return this.selectedPipingClass ? encodeIdBase64('Code', this.selectedPipingClass.Id) : null;
            },
            codeEditTitle() {
                const verb = this.isEditing ? 'Edit' : 'New';
                return verb + ' Piping Class';
            },
            isCreating() {
                return this.isFormActive && !this.selectedPipingClass;
            },
            isEditing() {
                return this.isFormActive && !!this.selectedPipingClass;
            },
            visiblePipingClasses() {
                return this.viewValidOnly ? this.pipingClasses.filter(code => code.IsValid) : this.pipingClasses;
            },
            existsCodeSetForScope() {
                return !this.loadingCodeSetName && this.codeSetName !== null;
            },
            pressureClassCodes() {
                return this.referableCodes?.PressureClass;
            },
            materialClasses() {
                return this.referableCodes?.MaterialClass;
            }
        },
        watch: {
            selectedScope() {
                this.selectedPipingClass = null;
                this.isFormActive = false;
            },
            selectedPipingClass(newValue) {
                if (newValue !== null) {
                    this.isFormActive = true;
                }

                if (!newValue) { return; }
                this.$emit('selectPipingClass', newValue);
            },
            userSelectedPipingClass(newValue) {
                if (newValue !== null) {
                    this.selectedPipingClass = newValue;
                }
            },
            pipingClasses(newPipingClasses, oldPipingClasses) {
                if (this.isEditing) {
                    this.selectedPipingClass = newPipingClasses.find(ls => ls.Id === this.selectedPipingClass.Id);
                }
                if (this.isCreating) {
                    const createdPipingClass = _.differenceBy(newPipingClasses, oldPipingClasses, 'Id');
                    this.selectedPipingClass = createdPipingClass.length > 0 ? createdPipingClass[0] : null;
                }
            }
        },
        methods: {
            onNewPipingClassClicked() {
                this.isFormActive = true;
                this.selectedPipingClass = null;
            },
            onCancelClicked() {
                this.selectedPipingClass = null;
                this.isFormActive = false;
            },
            onPipingClassNamedClicked() {
                this.$emit('navigateToPipingClassTab');
            },
            onSavedPipingClass() {
                this.$emit('savedPipingClass');
            }
        }
    };
</script>
