<template>
    <div>
        <h1 class="title">
            Foreign library search
        </h1>
        <h2 class="subtitle">
            Query libraries that retrieve data directly from other systems.
        </h2>
        <hr>
        <div class="columns">
            <div class="column is-one-quarter">
                <b-field label="Library">
                    <b-select
                        v-model="selectedLibrary"
                        :loading="loadingLibraries"
                        expanded
                        placeholder="Select a library">
                        <option
                            v-for="lib in libraries"
                            :key="lib.name"
                            :value="lib">
                            {{ lib.name }}
                        </option>
                    </b-select>
                </b-field>
                <b-field
                    v-if="scopes"
                    label="Scope">
                    <b-select
                        v-model="selectedScope"
                        :loading="loadingScopes"
                        expanded
                        placeholder="Select a scope">
                        <option
                            v-for="scope in scopes"
                            :key="scope.Identity"
                            :value="scope">
                            {{ scope.Name }}
                        </option>
                    </b-select>
                </b-field>
                <b-field label="Name">
                    <b-input
                        v-model="inputName"
                        value=""
                        placeholder="Name" />
                </b-field>
                <b-field label="Description">
                    <b-input
                        v-model="inputDescription"
                        value=""
                        placeholder="Description" />
                </b-field>
                <b-field label="Project">
                    <b-input
                        v-model="inputProject"
                        value=""
                        placeholder="Project" />
                </b-field>
                <b-field>
                    <b-switch v-model="inputIsValid">
                        IsValid
                    </b-switch>
                </b-field>
                <b-field>
                    <b-button
                        type="is-primary"
                        expanded
                        :disabled="!searchEnabled"
                        :loading="loadingData"
                        @click="search">
                        Search
                    </b-button>
                </b-field>
            </div>
            <div class="column">
                <b-message
                    v-show="errorActive"
                    type="is-danger"
                    has-icon>
                    {{ errorMessage }}
                </b-message>
                <h3
                    v-if="showData"
                    class="title is-3">
                    Row count: {{ dataCount }}
                </h3>
                <b-table
                    v-if="showData"
                    paginated
                    :data="data"
                    :columns="columns" />
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import { getCodesPublicApi, getLibraries, genericViewQueryAsText } from '@/shared/helpers/api';
    import { showMixin } from '@/shared/mixins/showMixin';

    export default {
        mixins: [
            showMixin
        ],
        props: {
            libraryParameter: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                loadingData: false,
                loadingLibraries: false,
                loadingScopes: false,
                showData: false,
                data: [],
                scopes: null,
                libraries: [],
                selectedLibrary: null,
                selectedScope: null,
                inputName: '',
                inputDescription: '',
                inputProject: '',
                inputIsValid: true,
                errorActive: false,
                errorMessage: '',
                columns: [
                    {
                        field: 'name',
                        label: 'Name',
                        searchable: true,
                        sortable: true
                    },
                    {
                        field: 'description',
                        label: 'Description',
                        searchable: true,
                        sortable: true
                    },
                    {
                        field: 'isValid',
                        label: 'Is valid',
                        sortable: true
                    }
                ]
            };
        },
        computed: {
            dataCount() {
                return this.data.length;
            },
            searchEnabled() {
                return this.selectedLibrary && (this.selectedLibrary.isGlobal || this.selectedScope || this.inputProject.length > 0);
            },
            searchParams() {
                if (!this.selectedLibrary) return [];

                const parameters = [
                    {
                        name: 'name',
                        value: this.inputName
                    },
                    {
                        name: 'description',
                        value: this.inputDescription
                    },
                    {
                        name: 'project',
                        value: this.inputProject
                    },
                    {
                        name: 'isValid',
                        value: this.inputIsValid
                    }
                ];
                if (this.selectedLibrary && !this.selectedLibrary.isGlobal && this.selectedScope) {
                    parameters.push(
                        {
                            name: 'scope',
                            value: this.selectedScope.Identity
                        }
                    );
                }
                return parameters;
            }
        },
        watch: {
            async selectedLibrary(selectedLib) {
                if (selectedLib) {
                    if (selectedLib.isGlobal) {
                        this.scopes = null;
                    } else {
                        this.scopes = await this.getScopes();
                    }
                }
            }
        },
        mounted() {
            this.getLibraries();
        },
        methods: {
            async search() {
                this.loadingData = true;
                this.data = [];
                this.errorActive = false;
                try {
                    this.data = await getCodesPublicApi(this, this.selectedLibrary.name, this.searchParams);
                    this.showData = true;
                } catch (ex) {
                    this.showError(ex, message => {
                        this.errorMessage = message;
                        this.errorActive = true;
                    });
                }
                this.loadingData = false;
            },
            async getLibraries() {
                this.loadingLibraries = true;
                this.errorActive = false;
                try {
                    this.libraries = _.orderBy((await getLibraries(this))
                        .filter(lib => lib.isForeignObject), 'name');

                    if (this.libraryParameter) {
                        this.selectedLibrary = this.libraries.find(x => x.name === this.libraryParameter);
                    }
                } catch {
                    this.errorMessage = 'Loading libraries failed :(';
                    this.errorActive = true;
                }
                this.loadingLibraries = false;
            },
            async getScopes() {
                this.loadingScopes = true;
                this.errorActive = false;
                let result = undefined;
                try {
                    const query = `from ${this.selectedLibrary.scopeType} select Name, Identity`;
                    result = (await genericViewQueryAsText(this, query)).data;
                    result = _.orderBy(result, 'Name');
                } catch (e) {
                    this.errorMessage = `Error loading scope type '${this.selectedLibrary.scopeType}'`;
                    this.errorActive = true;
                    result = [];
                }
                this.loadingScopes = false;
                return result;
            }
        }
    };
</script>

<style scoped>
</style>
