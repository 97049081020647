<template>
    <div>
        <div
            v-if="selectedScope"
            class="columns">
            <project-list
                v-model:selected-project="selectedProject"
                :projects="projects"
                :loading="projectsLoading" />
            <div
                class="column is-half">
                <div
                    class="is-flex is-gap-10 mb-3">
                    <existing-project-search
                        class="ml-auto"
                        @selected="onExistingProjectSelected" />
                    <b-button
                        v-require-can-edit-code="accessArguments"
                        :model-value="isEditing"
                        type="is-primary"
                        @click="onNewProjectClicked">
                        <b-icon
                            icon="plus"
                            size="is-small" />
                        <span>Create new project</span>
                    </b-button>
                </div>
                <section
                    v-if="selectedProject && !editFormActive && !editProjectFacilityActive">
                    <project-attributes-panel
                        :selected-project-attributes="selectedProjectAttributes"
                        :access-arguments="accessArguments"
                        @click-edit-project="editFormActive = true" />
                    <facility-associations-panel
                        v-model:selected-project-facility="selectedProjectFacility"
                        :project-facility-loading="projectFacilityLoading"
                        :selected-project-facilities="selectedProjectFacilities"
                        :access-arguments="accessArguments"
                        @click-edit-associations="editProjectFacilityActive = true"
                        @click-new-association="onNewProjectFacilityClicked" />
                </section>
                <div
                    v-if="editFormActive"
                    class="column is-one third">
                    <code-edit
                        :key="selectedProjectId"
                        :show-cancel="true"
                        :code-id="selectedProjectId"
                        :form-title="codeEditTitle"
                        :code-set-name="codeSetNameProject"
                        :release-chain-factory="newProjectReleaseChainFactory"
                        :fields-to-ignore="['ConstructionKPIStartDate', 'TargetFactorProductivity', 'TargetFactorInstallation']"
                        library="Project"
                        :scopes="selectedScope"
                        form-title-class="is-4"
                        submit-button-label="Save"
                        @refresh="afterProjectSave"
                        @cancel="editFormActive = false" />
                </div>
                <div
                    v-else-if="editProjectFacilityActive"
                    class="column is-one third">
                    <code-edit
                        :key="selectedProjectFacilityId"
                        library="ProjectFacility"
                        :scopes="selectedScope"
                        :show-cancel="true"
                        :form-title="projectFacilityCodeEditTitle"
                        :code-id="selectedProjectFacilityId"
                        :code-set-name="codeSetNameProjectFacility"
                        :code-template-values="{ 'name': selectedProject.Name, 'Project': selectedProject.Name, 'description': selectedProject.Description }"
                        :fields-to-disable="[ 'Project' ]"
                        :referable-codes-not-to-load="[ 'Project' ]"
                        :fields-to-ignore="[ 'name', 'description' ]"
                        form-title-class="is-4"
                        submit-button-label="Save"
                        @refresh="afterProjectFacilitySave"
                        @cancel="editProjectFacilityActive = false" />
                </div>
            </div>
        </div>
        <b-message v-else>
            Please select a scope.
        </b-message>
    </div>
</template>

<script>
    import ExistingProjectSearch from '@/apps/project/components/ExistingProjectSearch.vue';
    import FacilityAssociationsPanel from '@/apps/project/components/FacilityAssociationsPanel.vue';
    import ProjectAttributesPanel from '@/apps/project/components/ProjectAttributesPanel.vue';
    import ProjectList from '@/apps/project/components/ProjectList.vue';
    import CodeEdit from '@/shared/components/CodeEdit.vue';
    import { requireCanEditCode } from '@/shared/directives/requirePermission';
    import { genericViewQueryAsText, getCodeSets } from '@/shared/helpers/api';
    import { encodeIdBase64 } from '@/shared/helpers/utils';

    export default {
        directives: {
            'require-can-edit-code': requireCanEditCode
        },
        components: { ExistingProjectSearch, FacilityAssociationsPanel, ProjectAttributesPanel, ProjectList, CodeEdit },
        props: {
            selectedScope: {
                type: String,
                required: false,
                default: () => null
            },
            navigateToProject: {
                type: String,
                required: false,
                default: () => null
            }
        },
        emits: [
            'updateProject'
        ],
        data() {
            return {
                projects: [],
                projectsLoading: false,
                selectedProject: null,
                editFormActive: false,
                codeSetNameProject: '',
                codeSetNameProjectFacility: '',
                projectFacilityLoading: false,
                selectedProjectFacilities: [],
                editProjectFacilityActive: false,
                selectedProjectFacility: null
            };
        },
        computed: {
            accessArguments() {
                // Note: Using ProjectFacility (not Project) as adding a new Project will also add a ProjectFacility,
                // and Project is originally derived from ProjectFacility. ProjectFacility will generally have
                // stronger access restrictions than Project.
                return {
                    libraryName: 'ProjectFacility',
                    scopes: [this.selectedScope]
                };
            },
            selectedProjectId() {
                return this.selectedProject ? encodeIdBase64('Code', this.selectedProject.Id) : null;
            },
            selectedProjectFacilityId() {
                return this.selectedProjectFacility ? encodeIdBase64('Code', this.selectedProjectFacility.Id) : null;
            },
            codeEditTitle() {
                const verb = this.isEditing ? 'Edit' : 'New';
                return verb + ' Project';
            },
            isEditing() {
                return this.editFormActive && !!this.selectedProjectId;
            },
            isEditingProjectFacility() {
                return this.editProjectFacilityActive && !!this.selectedProjectFacility;
            },
            projectFacilityCodeEditTitle() {
                const verb = this.isEditingProjectFacility ? 'Edit' : 'New';
                return verb + ' Facility association';
            },
            selectedProjectAttributes() {
                return Object.keys(this.selectedProject)
                    .filter(key =>
                        key !== 'Id'
                        && this.selectedProject[key] !== null
                        && this.selectedProject[key] !== '')
                    .map(key => ({
                        property: key,
                        value: this.selectedProject[key]
                    }));
            },
            newProjectReleaseChainFactory() {
                if (this.isEditing)
                    return null;

                const self = this;
                return function(chain) {
                    const project = chain[0].codes[0];
                    chain.push({
                        codeSetName: self.codeSetNameProjectFacility,
                        libraryName: 'ProjectFacility',
                        codes: [
                            {
                                Name: project.Name,
                                Description: project.Description,
                                IsValid: true,
                                Project: project.Name,
                                Facility: self.selectedScope
                            }
                        ]
                    });
                    return chain;
                };
            }
        },
        watch: {
            selectedScope: {
                async handler(newValue) {
                    if (newValue) {
                        await this.loadProjects();
                    } else {
                        this.projects = [];
                    }
                },
                immediate: true
            },
            selectedProject: {
                handler(newValue, oldValue) {
                    if (!oldValue) {
                        this.editFormActive = false;
                    }
                    this.editProjectFacilityActive = false;
                    this.loadProjectFacility();
                    if (this.selectedProject) {
                        this.$emit('updateProject', this.selectedProject.Name);
                    }
                },
                immediate: true
            }
        },
        async mounted() {
            this.codeSetNameProject = await this.fetchCodeSetName('Project');
            this.codeSetNameProjectFacility = await this.fetchCodeSetName('ProjectFacility');
        },
        methods: {
            async loadProjects() {
                this.resetUI();
                this.projectsLoading = true;
                const res = await genericViewQueryAsText(
                    this,
                    `FROM ProjectFacility
                        WHERE IsValid = true AND Facility = @facility
                        JOIN Project
                          SELECT Id, Name, Description, ProjectMaster, IsValid, InternalComment, IsClosed
                          JOIN ProjectMaster
                            SELECT Name AS ProjectMasterName
                          END
                        END
                        ORDER BY Name`,
                    [{ name: '@facility', value: `"${this.selectedScope}"` }]
                );
                this.projects = res.data;
                this.openProject(this.navigateToProject);
                this.projectsLoading = false;
            },
            openProject(project) {
                if (project) {
                    this.selectedProject = this.projects.find(x => x.Name === project);
                }
            },
            resetUI() {
                this.selectedProject = null;
                this.editFormActive = false;
                this.projectFacilityLoading = false;
            },
            onNewProjectClicked() {
                this.editFormActive = true;
                this.selectedProject = null;
            },
            async fetchCodeSetName(libraryName) {
                const res = await getCodeSets(this, libraryName);
                return res.length === 1 ? res[0].name : null;
            },
            async loadProjectFacility() {
                if (!this.selectedProject)
                    return;
                this.projectFacilityLoading = true;
                this.selectedProjectFacilities = [];
                const res = await genericViewQueryAsText(
                    this,
                    `FROM ProjectFacility
                        SELECT Id, facility, deliveryCode
                        WHERE Project = @name
                        ORDER BY facility`,
                    [{ name: '@name', value: `"${this.selectedProject.Name}"` }]
                );
                this.selectedProjectFacilities = res.data;
                this.projectFacilityLoading = false;
            },
            onNewProjectFacilityClicked() {
                this.editProjectFacilityActive = true;
                this.selectedProjectFacility = null;
            },
            async afterProjectSave() {
                this.editFormActive = false;
                await this.loadProjects();
            },
            async afterProjectFacilitySave() {
                this.editProjectFacilityActive = false;
                await this.loadProjects();
            },
            onExistingProjectSelected(project) {
                this.selectedProject = project;
            }
        }
    };
</script>

<style scoped>
.headless-table thead {
    display: none;
}
</style>
