<template>
    <div>
        <b-field>
            <router-link to="/Schema">
                &laquo; Back to list
            </router-link>
        </b-field>
        <div
            v-if="error">
            <h1 class="title is-2">
                Something went wrong when composing schema {{ identity }}!!!
            </h1>
            <div class="columns">
                <div class="column is-6">
                    <div
                        class="notification is-danger">
                        <p>{{ error }}</p>
                    </div>
                </div>
                <div class="column is-6">
                    <schema-buttons :identity="identity" />
                </div>
            </div>
        </div>
        <div
            v-else-if="schema">
            <h1 class="title">
                Schema: {{ schema.name }}
            </h1>
            <h2 class="subtitle">
                {{ schema.description }}
            </h2>

            <b-tabs
                v-model="activeTab"
                type="is-boxed">
                <b-tab-item
                    label="Info"
                    icon="information">
                    <div class="columns">
                        <div class="column is-half">
                            <h4 class="title is-4 block">
                                Comments
                            </h4>
                            <p class="block">
                                {{ schema.comments }}
                            </p>
                            <h4 class="title is-4 block">
                                Versions
                            </h4>
                            <b-table
                                :data="schema.versions"
                                :striped="true"
                                :narrowed="true"
                                :hoverable="false"
                                class="classTable">
                                <b-table-column
                                    v-slot="props"
                                    field="versionNumber"
                                    label="Version number">
                                    {{ props.row.versionNumber }}
                                </b-table-column>
                                <b-table-column
                                    v-slot="props"
                                    field="description"
                                    label="Description">
                                    {{ props.row.description }}
                                </b-table-column>
                                <b-table-column
                                    v-slot="props"
                                    field="releaseDate"
                                    label="Release date">
                                    {{ $filters.dateFormatShort(props.row.releaseDate) }}
                                </b-table-column>
                            </b-table>
                            <h4 class="title is-4 block">
                                References
                            </h4>
                            <p class="block">
                                {{ schema.references }}
                            </p>
                        </div>
                        <div
                            class="column">
                            <schema-buttons :identity="identity" />
                        </div>
                    </div>
                </b-tab-item>
                <b-tab-item
                    label="Classes"
                    icon="format-list-bulleted">
                    <schema-classes :schema="schema" />
                </b-tab-item>
                <b-tab-item
                    label="Graph"
                    icon="graph">
                    <schema-model :identity="identity" />
                </b-tab-item>
                <b-tab-item>
                    <template #header>
                        <b-icon icon="alert" />
                        <span> Issues <b-tag
                            v-show="schema.warnings.length > 0"
                            type="is-danger"
                            rounded> {{ schema.warnings.length }} </b-tag> </span>
                    </template>
                    <div
                        v-for="(w, i) in schema.warnings"
                        :key="i"
                        class="notification is-warning">
                        {{ w.description }}
                    </div>
                    <p v-show="schema.warnings.length === 0">
                        There are no issues.
                    </p>
                </b-tab-item>
            </b-tabs>
        </div>
    </div>
</template>

<script>
    import { getSchemaDTO } from '@/shared/helpers/api.ts';
    import { showMixin } from '@/shared/mixins/showMixin.js';
    import _ from 'lodash';
    import SchemaButtons from './components/SchemaButtons.vue';
    import SchemaModel from './views/SchemaModel.vue';
    import SchemaClasses from './views/SchemaClasses.vue';

    export default {
        components: {
            SchemaModel,
            SchemaButtons,
            SchemaClasses
        },
        mixins: [
            showMixin
        ],
        props: {
            identity: {
                default: null,
                type: String
            }
        },
        data() {
            return {
                schema: null,
                activeTab: 0,
                error: null
            };
        },
        async mounted() {
            try {
                const schemaOptions = {
                    schemaName: this.identity
                };
                this.schema = (await getSchemaDTO(this, schemaOptions)).data;
                this.schema.classes = _.sortBy(this.schema.classes, x => x.name);
            } catch (err) {
                this.showError(err, e => {
                    this.error = e;
                });
            }
        }
    };
</script>
<style scoped>
p {
    margin-bottom:10px;
}
h2 {
    font-weight: bold;
}
h3 {
    font-weight: bold;
}
.classTable {
    margin-bottom: 25px;
    overflow-x: hidden;
}
</style>
