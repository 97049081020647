export class InputRegexValidator {
    private lengthBounds: { minLength: number; maxLength: number } | undefined;

    constructor(private readonly regexp: RegExp) {
        this.lengthBounds = InputRegexValidator.getLengthBounds(regexp);
    }

    get maxLength(): number | undefined {
        return this.lengthBounds?.maxLength;
    }

    get minLength(): number | undefined {
        return this.lengthBounds?.minLength;
    }

    get regExp(): RegExp {
        return this.regexp;
    }

    isInputValid(input: string): boolean {
        return this.regexp.test(input);
    }

    validate(input: string): { error: string } | undefined {
        if (this.isInputValid(input)) {
            return undefined;
        }

        if (this.minLength && input.length < this.minLength) {
            return { error: `Input cannot be shorter than ${this.minLength} characters` };
        }

        if (this.maxLength && input.length > this.maxLength) {
            return { error: `Input cannot be longer than ${this.maxLength} characters` };
        }

        return { error: `Input does not match regex pattern '${this.regexp.toString()}'` };
    }

    private static getLengthBounds(regexp: RegExp): { minLength: number; maxLength: number } | undefined {
        const lengthRangePattern = /\^(?:\.|\[(?:\\\]|[^\]])*\]){(\s*\d*\s*),(\s*\d*\s*)}\$/;

        const match = regexp.toString().match(lengthRangePattern);

        if (!match)
            return undefined;
        return {
            minLength: parseInt(match[1]),
            maxLength: parseInt(match[2])
        };
    }
}
