<script setup lang="ts">
    import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue';
    import { ComponentPublicInstance, ref } from 'vue';
    import { autoUpdate, flip, offset, shift, size, useFloating } from '@floating-ui/vue';

    type Option = {
        key: string;
        value: string | number | boolean | object | null | undefined;
        string: string;
    };

    type Props = {
        modelValue?: string | number | boolean | object | null | undefined;
        label?: string;
        options?: Option[];
        loading?: boolean;
        disabled?: boolean;
        placeholder?: string;
        width?: string;
        icon?: string;
        iconPack?: string;
        iconSize?: string;
    };

    withDefaults(
        defineProps<Props>(),
        {
            modelValue: undefined,
            label: '',
            options: () => [],
            loading: false,
            disabled: false,
            placeholder: '',
            width: undefined,
            icon: undefined,
            iconPack: undefined,
            iconSize: undefined
        }
    );

    const emit = defineEmits<{
        'update:modelValue': [string];
    }>();

    const button = ref<ComponentPublicInstance | null>(null);
    const panel = ref<HTMLElement | null>(null);

    const { floatingStyles }
        = useFloating(button, panel, {
            placement: 'bottom-start',
            middleware: [
                offset({ mainAxis: 3, crossAxis: 0 }), flip(), shift(),
                size({
                    apply({ availableWidth, availableHeight, elements }) {
                        Object.assign(elements.floating.style, {
                            maxWidth: `${availableWidth}px`,
                            maxHeight: `calc(${availableHeight}px - 2rem)`
                        });
                    }
                })
            ],
            strategy: 'fixed',
            whileElementsMounted: autoUpdate
        });
</script>

<template>
    <div
        class="rich-select">
        <listbox
            :model-value="modelValue"
            @update:model-value="emit('update:modelValue', $event)">
            <div
                class="control"
                :class="{ 'has-icons-left': icon }">
                <listbox-button
                    ref="button"
                    :disabled="disabled"
                    :class="{'is-loading': loading, 'has-placeholder': !modelValue}"
                    class="button is-dropdown-trigger">
                    {{ label || modelValue || placeholder }}
                </listbox-button>
                <b-icon
                    v-if="icon"
                    class="is-left"
                    :icon="icon"
                    :pack="iconPack"
                    :size="iconSize" />
            </div>
            <listbox-options
                v-if="options?.length"
                ref="panel"
                :style="floatingStyles"
                class="rich-select-panel">
                <listbox-option
                    v-for="option in options"
                    :key="option.key"
                    v-slot="{ active, selected }"
                    :value="option.value"
                    :disabled="false">
                    <div
                        class="dropdown-item"
                        :class="{'is-selected': selected, 'is-hovered': active}">
                        <div>
                            <i class="icon fa fa-check" />
                        </div>
                        <div>
                            {{ option.string }}
                        </div>
                    </div>
                </listbox-option>
            </listbox-options>
        </listbox>
    </div>
</template>

<style scoped>
.rich-select-panel {
    /* Floating UI styles */
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;

    /* Sizing */
    width: v-bind(width);
    height: auto;

    /* Scrollbar styles */
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #888 transparent;

    /* Other */
    padding: 4px;

    background-color: white;
    border-radius: 6px;
    box-shadow: 0 8px 8px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);

    &:focus-visible {
        outline: none;
    }
}

.dropdown-item {
    padding: 0.5rem;
    display: flex;
    align-items: center;
    flex-direction: row;

    border-radius: 4px;

    & .icon {
        display: inline;
        width: 1rem;
        height: 1rem;
        margin-left: 0.25rem;
        margin-right: 0.5rem;
    }
}

.dropdown-item {
    color: black;
}

.dropdown-item.is-hovered {
    background-color: rgba(220, 220, 220, 1); /* Equinor's hover color */
}

.dropdown-item .icon {
    visibility: hidden;
}

.dropdown-item.is-selected .icon {
    visibility: visible;
}

.control.has-icons-left .button.is-dropdown-trigger {
    padding-left: 2.5em;
}
.button.is-dropdown-trigger {
    justify-content: flex-start;
}
</style>
