// a simple replacement for the vue-sync package with the same interface.

class QueryParams {
    set(key, value, noHistory) {
        const newUrl = new URL(window.location);
        newUrl.searchParams.set(key, value);
        if (noHistory) {
            window.history.replaceState({}, '', newUrl);
        } else {
            window.history.pushState({}, '', newUrl);
        }
    }

    get(key) {
        return new URLSearchParams(window.location.search).get(key) ?? undefined;
    }
}

class VueRouterQueryParams {
    constructor(vm) {
        this.vm = vm;
    }

    set(key, value, noHistory) {
        const newQuery = { ...this.vm.$route.query, [key]: value };
        if (noHistory) {
            void this.vm.$router.replace({ query: newQuery });
        } else {
            void this.vm.$router.push({ query: newQuery });
        }
    }

    get(key) {
        return this.vm.$route.query[key];
    }
}

const urlSyncMixin = {
    created() {
        const urlOptions = this.$options.url;
        if (!urlOptions) {
            return;
        }

        const params = this.$router ? new VueRouterQueryParams(this) : new QueryParams();

        const stringify = value => {
            if (typeof value !== 'object') {
                return '' + value;
            }
            return JSON.stringify(value);
        };

        const parse = rawValue => {
            try {
                return JSON.parse(rawValue);
            } catch {
                return rawValue;
            }
        };

        const setQueryValue = (key, newValue, noHistory) => {
            const rawValue = newValue ? stringify(newValue) : undefined;
            if (params.get(key) === rawValue) {
                return;
            }
            params.set(key, rawValue, noHistory);
        };

        const getQueryValue = key => {
            const rawValue = params.get(key);
            return rawValue ? parse(rawValue) : undefined;
        };

        for (const [varName, urlOption] of Object.entries(urlOptions)) {
            const { param: key, noHistory } = urlOption;
            const fromQuery = getQueryValue(key);
            if (fromQuery) {
                this[varName] = fromQuery;
            } else {
                const fromInstance = this[varName];
                if (fromInstance) {
                    setQueryValue(key, fromInstance, noHistory);
                }
            }

            this.$watch(varName, newValue => {
                setQueryValue(key, newValue, noHistory);
            }, { deep: true });
        }
    }
};

export default {
    install(Vue) {
        Vue.mixin(urlSyncMixin);
    }
};
