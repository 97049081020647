<script setup>
    import { computed, ref } from 'vue';
    import { getLibraryLink, getQuantityLink } from '@/shared/helpers/routing.js';

    const p = defineProps({
        schema: {
            required: true,
            type: Object,
            default: () => ({})
        }
    });

    const selectedClass = ref(null);

    const selectedClassAttributes = computed(() => {
        return selectedClass.value?.attributes;
    });

    const selectedClassRelations = computed(() => {
        const relevantRelations = [];
        p.schema.relations.forEach(schemaRelation => {
            if (selectedClass.value?.interfaces.includes(schemaRelation.left.interfaceName)) {
                relevantRelations.push({
                    description: schemaRelation.description,
                    name: schemaRelation.name,
                    primeInterfaceName: schemaRelation.left.interfaceName,
                    Cardinality: `${schemaRelation.left.cardinality ?? 'null'}:${schemaRelation.right.cardinality ?? 'null'}`,
                    primeRole: schemaRelation.left.name,
                    secondaryRole: schemaRelation.right.name,
                    secondaryInterfaceName: schemaRelation.right.interfaceName
                });
            }
            if (selectedClass.value?.interfaces.includes(schemaRelation.right.interfaceName)) {
                relevantRelations.push({
                    description: schemaRelation.description,
                    name: schemaRelation.name,
                    primeInterfaceName: schemaRelation.right.interfaceName,
                    Cardinality: `${schemaRelation.right.cardinality ?? 'null'}:${schemaRelation.left.cardinality ?? 'null'}`,
                    primeRole: schemaRelation.right.name,
                    secondaryRole: schemaRelation.left.name,
                    secondaryInterfaceName: schemaRelation.left.interfaceName
                });
            }
        });
        return relevantRelations;
    });

    function selectClass(c) {
        selectedClass.value = c;
    }

    function datatype(attribute) {
        switch (attribute.dataType) {
            case 'enum':
                if (attribute.enumLibrary)
                    return 'enum:';
                return 'enum';
            case 'quantity':
                return 'quantity:';
            case 'integer':
                return `integer (${attribute.minValue}-${attribute.maxValue})`;
            case 'string':
                return `string (${attribute.maxValue})`;
            default:
                return attribute.dataType;
        }
    }

    function required(x) {
        return x ? 'yes' : '';
    }

    function defaultUoM(attribute) {
        if (attribute.defaultUnitOfMeasure) {
            const suffixLength = attribute.quantity.length + 1;
            // example defaultUnitOfMeasure: m|length
            return attribute.defaultUnitOfMeasure.substring(0, attribute.defaultUnitOfMeasure.length - suffixLength);
        }
        return '';
    }
</script>

<template>
    <div class="columns">
        <div class="column is-2">
            <b-menu>
                <b-menu-list label="Select class">
                    <b-menu-item
                        v-for="c in p.schema.classes"
                        :id="c.name"
                        :key="c.name"
                        icon="format-list-bulleted"
                        :label="c.name"
                        @click="selectClass(c)" />
                </b-menu-list>
            </b-menu>
        </div>
        <div class="column">
            <div v-if="selectedClass">
                <h2 class="title">
                    {{ selectedClass.name }}
                </h2>
                <p>{{ selectedClass.comments }}</p>
                <b-table
                    :data="selectedClassAttributes"
                    :striped="true"
                    :narrowed="true"
                    :hoverable="true"
                    class="classTable">
                    <b-table-column
                        v-slot="props"
                        sortable
                        field="interfaceName"
                        label="Interface">
                        {{ props.row.interfaceName }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        sortable
                        field="name"
                        label="Attribute name">
                        {{ props.row.name }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        sortable
                        field="description"
                        label="Suggested display as">
                        {{ props.row.description }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        sortable
                        field="dataType"
                        label="Type">
                        {{ datatype(props.row) }}
                        <router-link
                            v-if="props.row.enumLibrary || props.row.quantity"
                            :to="props.row.enumLibrary ?
                                getLibraryLink(props.row.enumLibrary) : getQuantityLink(props.row.quantity)">
                            {{ props.row.enumLibrary ? props.row.enumLibrary : props.row.quantity }}
                        </router-link>
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        sortable
                        field="defaultUnitOfMeasure"
                        label="Default UoM">
                        <b-tooltip label="Default Unit of Measure">
                            {{ defaultUoM(props.row) }}
                        </b-tooltip>
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        sortable
                        field="required"
                        label="Required">
                        {{ required(props.row.required) }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        sortable
                        field="comments"
                        label="Comments">
                        {{ props.row.comments }}
                    </b-table-column>
                </b-table>
            </div>
            <div v-if="selectedClassRelations.length > 0">
                <h3>{{ selectedClass.name }} may participate in following relations:</h3>
                <b-table
                    :data="selectedClassRelations"
                    :narrowed="true"
                    class="class-table">
                    <b-table-column
                        v-slot="props"
                        sortable
                        field="name"
                        label="Relation Name">
                        {{ props.row.name }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        sortable
                        field="description"
                        label="Description">
                        {{ props.row.description }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        sortable
                        field="primeInterfaceName"
                        :label="selectedClass.name + ' interface'">
                        {{ props.row.primeInterfaceName }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        sortable
                        field="primeRole"
                        label="Role">
                        {{ props.row.primeRole }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        sortable
                        field="Cardinality"
                        label="Cardinality">
                        {{ props.row.Cardinality }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        sortable
                        field="secondaryRole"
                        label="Other side role">
                        {{ props.row.secondaryRole }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        sortable
                        field="secondaryInterfaceName"
                        label="Other side interface">
                        {{ props.row.secondaryInterfaceName }}
                    </b-table-column>
                </b-table>
            </div>
            <div v-else-if="selectedClass">
                No relations found for {{ selectedClass.name }}
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
